import React from "react";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { ProfileAvatar } from "../../Components/ProfileAvatar/ProfileAvatar";
import { useGetProfileQuery } from "../../../redux/reducers/apiSlice";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import { api } from "../../../redux/reducers/apiSlice";
import { customConsole } from "../../utils/helper";
import useScreenType from "react-screentype-hook";
import * as Sentry from "@sentry/react";

const ProfileMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile, isTablet } = useScreenType();
  const { currentData: currentProfile } = useGetProfileQuery();

  const signOut = async () => {
    try {
      navigate("/");
      dispatch(api.util.resetApiState()); // resetting api after calling Auth.signout will break
      await Auth.signOut();
    } catch (error) {
      Sentry.captureException(error);
      customConsole("log", "error signing out: ", error);
    }
  };

  return (
    <Menu>
      <MenuButton>
        <ProfileAvatar
          size={isTablet || isMobile ? "sm" : "md"}
          src={currentProfile?.image?.url}
          name={`${currentProfile?.name.firstName} ${currentProfile?.name.lastName}`}
        />
      </MenuButton>

      <MenuList>
        <MenuItem as={Link} to={`/users/${currentProfile?.id}`}>
          View my profile
        </MenuItem>
        <MenuItem
          style={{ fontWeight: "bold", color: "red" }}
          onClick={signOut}
        >
          Sign out
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default ProfileMenu;
