import React from "react";
import { formatDistanceToNow } from "date-fns";
import { useNavigate } from "react-router-dom";
import { ArrowForwardIcon, DeleteIcon } from "@chakra-ui/icons";

import {
  HeartReact,
  SnapReact,
  FistReact,
  ClappingReact,
} from "../../Assets/SvgIcons";

import { generateNotificationMessage } from "../../utils/helper";
import { useViewNotificationsMutation } from "../../../redux/reducers/apiSlice";

import {
  MenuItem,
  IconContainer,
  ReactionContainer,
} from "./NotificationStyles";

const keywordToSVG = {
  HEART: <HeartReact />,
  SNAPS: <SnapReact />,
  HIGHFIVE: <FistReact />,
  CLAP: <ClappingReact />,
  FIST: <FistReact />,
};

export const Notification = ({
  notification,
  setShowDeleteNotificationModal,
}) => {
  const navigate = useNavigate();

  const [viewNotifications] = useViewNotificationsMutation();

  return (
    <MenuItem
      style={{
        background:
          notification.status === "VIEWED"
            ? "transparent"
            : "rgba(0,0,255,0.05)",
      }}
    >
      <div className="menu-wrapper">
        <div className="time-ago">
          {notification?.createdAt &&
            formatDistanceToNow(new Date(notification.createdAt), {
              addSuffix: true,
            }).replace("about", "")}
        </div>
        <div className="row">
          <div className="from">
            <span>{notification.from}</span>
          </div>
          <div className="message">
            {generateNotificationMessage(
              notification.action,
              notification.postId,
              notification.interactionId
            )}
            <ReactionContainer>
              {keywordToSVG[notification.action]}
            </ReactionContainer>
          </div>
          <div className="icons">
            <IconContainer>
              <button
                onClick={() => {
                  setShowDeleteNotificationModal(notification.id);
                }}
              >
                <DeleteIcon className="icon" color="#777" />
              </button>
            </IconContainer>
            <IconContainer>
              <button
                onClick={async () => {
                  await viewNotifications({
                    id: notification.id,
                  });
                  if (notification.postId) {
                    if (
                      notification.interactionId &&
                      (notification.action === "COMMENT" ||
                        notification.action === "TAG")
                    ) {
                      navigate(
                        `/posts/${notification.postId}/interactions/${notification.interactionId}?showComment=true`
                      );
                    } else {
                      navigate(`/posts/${notification.postId}`);
                    }
                  } else if (notification.userId) {
                    navigate(`/users/${notification.userId}`);
                  }
                }}
              >
                <ArrowForwardIcon className="icon" color="#777" />
              </button>
            </IconContainer>
          </div>
        </div>
      </div>
    </MenuItem>
  );
};
