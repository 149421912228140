import React from "react";
import { Link } from "react-router-dom";

const processCaption = (caption: string) => {
  const regex = /@\[(.+?)\]\((.+?)\)/g;
  const parts: any = [];
  let lastIndex = 0;
  let match;

  while ((match = regex.exec(caption)) !== null) {
    const [_, name, uuid] = match;

    // Push the text before the current match
    if (match.index > lastIndex) {
      parts.push(caption.slice(lastIndex, match.index));
    }

    // Push the Link component
    parts.push(
      <Link key={uuid} to={`/users/${uuid}`}>
        @{name}
      </Link>
    );

    lastIndex = regex.lastIndex;
  }

  // Push any remaining text after the last match
  if (lastIndex < caption?.length) {
    parts.push(caption.slice(lastIndex));
  }

  return parts;
};

const CaptionComponent = ({ caption }) => {
  const processedCaption = processCaption(caption);
  return <div>{processedCaption}</div>;
};

export { CaptionComponent };
