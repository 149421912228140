import { css } from 'styled-components';

export const Form = css`
  body {
    // form
    .chakra-form__label {
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }
    .chakra-form-control {
      &[aria-invalid='true'] {
        .chakra-input__left-element {
          color: ${({ theme }) => theme.color.lightRed};
        }
      }
    }
    .chakra-form__error-message {
      font-size: 12px;
      color: ${({ theme }) => theme.color.lightRed};
      margin-bottom: 4px;
    }

    // input
    .chakra-input {
      font-size: 14px;
      border-radius: 22px;
      border: 0;
      outline: 0;
      box-shadow: 1px 1px 6px rgba(64, 26, 15, 0.25);
      &[aria-invalid='true'] {
        color: ${({ theme }) => theme.color.lightRed};
        border-color: ${({ theme }) => theme.color.lightRed};
        box-shadow: 0 0 0 1px ${({ theme }) => theme.color.lightRed};
        &:-ms-input-placeholder {
          color: ${({ theme }) => theme.color.lightRed};
        }
        &:-moz-placeholder {
          color: ${({ theme }) => theme.color.lightRed};
        }
        &::-moz-placeholder {
          color: ${({ theme }) => theme.color.lightRed};
        }
        &::-webkit-input-placeholder {
          color: ${({ theme }) => theme.color.lightRed};
        }
      }
      &.flushed {
        border-radius: 0;
        box-shadow: none;
        border-bottom: 1px solid #000;
        &:focus,
        &:active {
          border-bottom-color: ${({ theme }) => theme.color.primary};
        }
      }
      &.bg-input-gray {
        color: #000;
        background: #eee;
        border-color: #eee;
        box-shadow: none;
        &:-ms-input-placeholder {
          color: #262626;
        }
        &:-moz-placeholder {
          color: #262626;
        }
        &::-moz-placeholder {
          color: #262626;
        }
        &::-webkit-input-placeholder {
          color: #262626;
        }
      }
    }

    // textarea
    .chakra-textarea {
      min-height: var(--chakra-sizes-10);
      font-size: 14px;
      border-radius: 22px;
      border: 0;
      outline: 0;
      box-shadow: 1px 1px 6px rgba(64, 26, 15, 0.25);
      resize: none;
      padding-top: 12px;
      &:hover,
      &:focus {
        resize: vertical;
      }
      &[aria-invalid='true'] {
        color: ${({ theme }) => theme.color.lightRed};
        border-color: ${({ theme }) => theme.color.lightRed};
        box-shadow: 0 0 0 1px ${({ theme }) => theme.color.lightRed};
        &:-ms-input-placeholder {
          color: ${({ theme }) => theme.color.lightRed};
        }
        &:-moz-placeholder {
          color: ${({ theme }) => theme.color.lightRed};
        }
        &::-moz-placeholder {
          color: ${({ theme }) => theme.color.lightRed};
        }
        &::-webkit-input-placeholder {
          color: ${({ theme }) => theme.color.lightRed};
        }
      }
      &.bg-input-gray {
        color: #000;
        background: #eee;
        border-color: #eee;
        box-shadow: none;
        &:-ms-input-placeholder {
          color: #262626;
        }
        &:-moz-placeholder {
          color: #262626;
        }
        &::-moz-placeholder {
          color: #262626;
        }
        &::-webkit-input-placeholder {
          color: #262626;
        }
      }
    }
    .chakra-input__left-element {
      & + {
        .chakra-textarea {
          padding-left: 40px;
        }
      }
    }

    // checkbox
    .chakra-checkbox {
      &.checkbox-similar-button {
        display: flex;
        .chakra-checkbox__control {
          position: absolute;
          opacity: 0;
          visibility: hidden;
        }
        .chakra-checkbox__label {
          display: flex;
          align-items: center;
          width: 100%;
          font-size: 14px;
          color: #262626;
          background: #fff;
          border: 1px solid #f9f9f9;
          box-shadow: 1px 1px 6px rgba(64, 26, 15, 0.25);
          border-radius: 22px;
          padding: 8px 16px;
          margin: 0;
          &[aria-checked='true'],
          &[data-checked],
          &:hover {
            background: #f9f9f9;
            border: 1px solid #f9f9f9;
          }
        }
      }
    }

    // radio
    .chakra-radio__label {
      font-size: 14px;
      color: #000;
    }
    .chakra-radio__control {
      border: 1px solid #000;
      &[aria-checked='true'],
      &[data-checked] {
        color: ${({ theme }) => theme.color.primary};
        background: #fff;
        border-color: #000;
        &:hover,
        &[data-hover] {
          background: #fff;
          border-color: #000;
        }
        &:before {
          width: 8px;
          height: 8px;
        }
      }
    }
    .radio-black {
      .chakra-radio__control {
        &:focus-visible,
        &[data-focus-visible] {
          box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2) !important;
        }
        &[aria-checked='true'],
        &[data-checked] {
          &:before {
            background: #000;
          }
        }
      }
    }
  }
`;
