import styled from "styled-components";
import { ModalContent } from "@chakra-ui/react";

import { devices } from "../../Theme.css";

export const ProfilePage = styled.div`
  padding: 0 0 50px;
`;

export const MyGeneralInfo = styled.div`
  /* padding-left: 70px;
  padding-right: 35px; */
  padding: 24px;
  margin-bottom: 45px;
  background: #fff;
  box-shadow: 1px 1px 6px rgba(64, 26, 15, 0.25);
  border-radius: 0px 0px 40px 40px;

  @media ${devices.max_sm} {
    border-radius: 0px;
    box-shadow: none;
    width: 100%;
    margin: 0;
    margin-right: -24px;
  }
  @media ${devices.max_lg} {
    padding: 0;
  }
  @media ${devices.max_md} {
  }
  .box-row {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    @media ${devices.max_lg} {
      padding: 20px;
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 20px;
      @media ${devices.max_lg} {
        bottom: 0;
      }
    }
    > * {
      position: relative;
    }
  }
  .box-info {
    flex: 0 0 auto;
    width: calc(100% - 230px);
    @media ${devices.max_lg} {
      width: calc(100% - 180px);
    }
    @media ${devices.max_md} {
      width: 100%;
      margin-bottom: 20px;
    }
    .box-name {
      text-align: center;
      margin-bottom: 21px;
      @media ${devices.max_lg} {
        text-align: left;
      }
      @media ${devices.max_md} {
        text-align: center;
      }
      .box-name-inner {
        display: table;
        min-width: 285px;
        @media ${devices.max_lg} {
          display: block;
          min-width: 0;
        }
      }
      .fullname {
        font-size: 24px;
        line-height: calc(29 / 24);
        font-weight: 600;
        color: #000;
        margin-bottom: 3px;
      }
      .sub {
        font-size: 20px;
        line-height: calc(24 / 20);
        font-weight: 600;
        color: #262626;
      }
    }
    .box-avatar {
      padding-left: 15px;
      margin: auto;
      @media ${devices.max_lg} {
        padding-left: 0;
      }
      @media ${devices.max_md} {
        display: table;
      }
      .box-avatar-inner {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
      }
      .chakra-avatar {
        @media ${devices.max_lg} {
          width: 140px;
          height: 140px;
        }
      }
      .col-avatar {
        flex: 0 0 auto;
        width: 230px;
        @media ${devices.max_lg} {
          width: 140px;
        }
      }
      .col-detail {
        flex: 0 0 auto;
        width: calc(100% - 210px);
        padding-left: 44px;
        @media ${devices.max_lg} {
          padding-left: 20px;
          width: calc(100% - 140px);
        }
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          li {
            font-size: 16px;
            line-height: calc(21 / 16);
            color: #000;
            margin-bottom: 8px;
            @media ${devices.max_lg} {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
  .box-action {
    flex: 0 0 auto;
    width: 220px;
    display: flex;
    flex-direction: column;
    padding-bottom: 64px;
    @media ${devices.max_lg} {
      width: 180px;
      padding-bottom: 22px;
    }
    @media ${devices.max_md} {
      width: 100%;
      padding-bottom: 0;
    }
    .box-follow {
      flex: 1 1 auto;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      width: 100%;
      list-style: none;
      padding: 0;
      margin: auto;
      @media ${devices.max_md} {
        width: 100%;
        max-width: 230px;
      }
      li {
        text-align: center;
      }
      .txt-title {
        font-weight: 600;
        font-size: 14px;
        line-height: calc(19 / 14);
        margin-bottom: 8px;
      }
      .txt {
        font-size: 16px;
        line-height: calc(21 / 16);
      }
    }
    .box-btn {
      text-align: center;

      @media ${devices.max_sm} {
        margin-top: 8px;
      }
    }
  }
`;

export const MySparks = styled.div`
  background: #fff;
  box-shadow: 1px 1px 6px rgba(64, 26, 15, 0.25);
  border-radius: 40px;
  padding: 24px 34px;
  .box-header {
    display: flex;
    margin-bottom: 20px;
    .box-name {
      flex: 0 0 auto;
      width: 70%;
      font-weight: 600;
      font-size: 20px;
      line-height: calc(24 / 20);
      color: #000;
    }
    .box-btn {
      flex: 0 0 auto;
      width: 30%;
      text-align: right;
    }
  }
  .box-list {
    .item {
      font-weight: 600;
      font-size: 14px;
      line-height: 1.4;
      text-align: center;
    }
  }

  @media ${devices.max_sm} {
    width: 100%;
    margin: 0;
    border-radius: 0;
    box-shadow: none;

    border-top: 1px solid #eee;
    border-botom: 1px solid #eee;
  }
`;

export const BoxContainPost = styled.div`
  margin-top: 4.625rem;
  display: flex;
  justify-content: center;
  .user-post-wrapper {
    max-width: 555px;
  }
`;

export const ProfileSpark = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    margin-right: 12px;
  }

  @media ${devices.max_sm} {
    margin-bottom: 24px;
  }
`;
