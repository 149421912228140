import React from "react";
import { Back } from "../../Components/Back/Back";
import useScreenType from "react-screentype-hook";
import { useNavigate } from "react-router-dom";

const HeaderLeft = () => {
  const navigate = useNavigate();

  const { isMobile } = useScreenType();
  if (
    isMobile &&
    (location.pathname.startsWith("/organizations") ||
      location.pathname.startsWith("/users"))
  ) {
    const onClick = () =>
      location.pathname.startsWith("/organizations")
        ? navigate("/my-organizations")
        : navigate(-1);

    return (
      <div className="column first-column">
        <div className="logo-container">
          <Back onClick={isMobile ? onClick : undefined} />
        </div>
      </div>
    );
  }

  return (
    <div className="column first-column">
      <div className="logo-container">
        <img src="/images/logo.png" />
      </div>
    </div>
  );
};

export default HeaderLeft;
