import { Route, Routes } from "react-router-dom";
import { Navigate } from "react-router";
import SignUpConfirm from "./Pages/SignUp/SignUpConfirm";
import Login from "./Pages/Login/Login";
import NewPasswordRequired from "./Pages/NewPasswordRequired/NewPasswordRequired";
import SignUp from "./Pages/SignUp/SignUp";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./Pages/ForgotPassword/ResetPassword";
import SignUpOnboarding from "./Pages/SignUp/SignUpOnboarding/SignUpOnboarding";
import PageNotFound from "./Components/PageNotFound/PageNotFound";

const AuthRoutes = ({ setUser }) => (
  <Routes>
    <Route path="/" element={<Login />} />
    <Route path="/new-password-required" element={<NewPasswordRequired />} />
    <Route path="logout" element={<Navigate to="/" />} />
    <Route path="signup" element={<SignUp />} />
    <Route
      path="confirm-signup"
      element={<SignUpConfirm setUser={setUser} />}
    />
    <Route path="forgot-password" element={<ForgotPassword />} />
    <Route path="reset-password" element={<ResetPassword />} />
    <Route path="onboarding" element={<SignUpOnboarding />} />
    <Route path="*" element={<Navigate to="/" />} />
  </Routes>
);

export default AuthRoutes;
