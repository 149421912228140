import React from "react";
import { Spinner } from "@chakra-ui/react";
import { useGetSparksQuery } from "../../../redux/reducers/apiSlice";
import {
  Card,
  CardDetails,
  CardLabel,
  GoalsCount,
  IconContainer,
} from "./Styles";

export const SparkCard = ({ name, goalsCount }) => {
  const { data: sparksData, isLoading: sparksDataLoading } =
    useGetSparksQuery();

  const getSpark = (search: string) => {
    return sparksData?.find((spark) =>
      spark.name.toLowerCase().startsWith(search.toLowerCase())
    );
  };

  return (
    <Card>
      <IconContainer>
        {sparksDataLoading && <Spinner color="red" />}
        {!sparksDataLoading && <img src={getSpark(name)?.logo} />}
      </IconContainer>
      <CardDetails>
        <CardLabel>{name}</CardLabel>
        <GoalsCount> {goalsCount} goals</GoalsCount>
      </CardDetails>
    </Card>
  );
};
