export const notListedInputFieldHasError = (
  data: any,
  field: string,
  hotToast
) => {
  if (data[field].includes("not-listed")) {
    const fieldText = data[`${field}Text`];
    if (fieldText === undefined || fieldText === "") {
      hotToast.error(
        `If you check Not Listed for the ${field} field, you should fill the input besides it.`
      );
      return true;
    }
  }
  return false;
};

export const updateFieldNotListedForSubmission = (
  notListedFieldValue: string,
  notListedFieldTextValue: string
) => {
  return notListedFieldValue.includes("not-listed")
    ? `not-listed: ${notListedFieldTextValue}`
    : notListedFieldValue;
};

export const updateFieldNotListedFromData = (
  notListedField: string,
  notListedFieldValue: string
) => {
  if (notListedFieldValue.includes("not-listed")) {
    return {
      [notListedField]: "not-listed",
      [`${notListedField}Text`]: notListedFieldValue
        .replace("not-listed: ", "")
        .trim(),
    };
  }

  return {
    [notListedField]: notListedFieldValue,
    [`${notListedField}Text`]: undefined,
  };
};
