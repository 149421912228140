import React from "react";
import {
  AboutCard,
  GridLayout,
  EditButtonContainer,
  EditLinkButton,
  Category,
  Label,
  LinkItem,
} from "./styles";

const AboutLinksCard = ({ isAdmin, links, onEdit }) => {
  return (
    <AboutCard>
      {isAdmin && (
        <EditButtonContainer>
          <EditLinkButton onClick={() => onEdit(true)}>
            Edit links
          </EditLinkButton>
        </EditButtonContainer>
      )}
      <GridLayout>
        {links &&
          links.map((link: { label: string; url: string }, id: string) => (
            <Category key={id}>
              <Label>{link.label}</Label>
              <LinkItem href={link.url} target="_blank">
                {link.url}
              </LinkItem>
            </Category>
          ))}
      </GridLayout>
    </AboutCard>
  );
};

export default AboutLinksCard;
