import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Input } from "./Styles";
import { useLazySearchUserQuery } from "../../../redux/reducers/apiSlice";
import { SearchIcon } from "../../Assets/SvgIcons";
import { CloseIcon } from "@chakra-ui/icons";
import useScreenType from "react-screentype-hook";
import SearchResults from "./SearchResults";
import { customConsole } from "../../utils/helper";
import _ from "lodash";
import * as Sentry from "@sentry/react";

const HeaderMiddle = () => {
  const [searchInputValue, setSearchInputValue] = useState("");
  const [isOpenSearchList, setIsOpenSearchList] = useState(false);
  const location = useLocation();
  const [searchUser, { isLoading: searchLoading, data: searchResults }] =
    useLazySearchUserQuery();
  const [inputIsFocused, setInputIsFocused] = useState(false);
  const [isToggleSearch, setToggleSearch] = useState(false);
  const { isMobile } = useScreenType();

  const handleChange = (e) => {
    setSearchInputValue(e.target.value);
    debouncedHandleSearchuser(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && inputIsFocused && searchInputValue !== "") {
      handleSearchUser(searchInputValue);
    }
  };

  const handleSearchUser = async (searchValue) => {
    if (searchValue !== "") {
      try {
        await searchUser(searchValue).unwrap();
        setIsOpenSearchList(true);
      } catch (error) {
        Sentry.captureException(error);
        customConsole(
          "error",
          "🚀 ~ file: Header.ts ~ line 118 ~ handleSearchUser ~ error",
          error
        );
      }
    }
  };

  const debouncedHandleSearchuser = _.debounce(handleSearchUser, 350);

  const onSearchIconClicked = () => {
    if (isMobile) {
      if (!isToggleSearch) {
        setToggleSearch(true);
      } else {
        handleSearchUser(searchInputValue);
      }
    } else {
      handleSearchUser(searchInputValue);
    }
  };

  const onCloseIconClicked = () => {
    if (isMobile) {
      if (searchInputValue !== "") {
        setSearchInputValue("");
      } else {
        setToggleSearch(false);
      }
    } else {
      setSearchInputValue("");
    }
  };

  const showCloseIcon = () => {
    if (isMobile) {
      if (isToggleSearch) {
        return true;
      }
      return false;
    } else {
      if (searchInputValue !== "") {
        return true;
      } else {
        return false;
      }
    }
  };

  if (
    isMobile &&
    location.pathname.includes("/organizations") &&
    !location.pathname.includes("profile")
  ) {
    return null;
  }
  return (
    <div className="input-and-search-results-container">
      <Input isToggled={isToggleSearch}>
        <div className="icon-container search-icon">
          <button onClick={() => onSearchIconClicked()}>
            <SearchIcon />
          </button>
        </div>
        <input
          value={searchInputValue}
          onChange={handleChange}
          className="input"
          onKeyDown={handleKeyPress}
          onFocus={() => setInputIsFocused(true)}
          onBlur={() => setInputIsFocused(false)}
        />
        {showCloseIcon() && (
          <div className="icon-container">
            <button onClick={() => onCloseIconClicked()}>
              <CloseIcon fontSize={"11px"} />
            </button>
          </div>
        )}
      </Input>
      {searchInputValue !== "" && searchInputValue.length > 0 && (
        <SearchResults
          data={searchResults}
          isOpenSearchList={isOpenSearchList}
          setIsOpenSearchList={setIsOpenSearchList}
          handleClickOutside={() => setIsOpenSearchList(false)}
          searchLoading={searchLoading}
        />
      )}
    </div>
  );
};

export default HeaderMiddle;
