import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import {
  OrganizationDetailsCardWrapper,
  CardHeader,
  CardHeaderGroup1,
  CardHeaderSubGroup2,
  CardTitle,
  CardCaption,
  CardHeaderGroup2,
  BadgeWrapper,
  CardSeparator,
  CardBody,
  CardBodySubGroupIcon,
  CardBodySubGroup,
  CardBodyText,
  FloatingDropdownLayout,
} from "./Styles";
import {
  Flag,
  TwoUsers,
  MoreIcon,
  VerticalMoreIcon,
} from "../../Assets/SvgIcons";
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";
import { MESSAGE } from "../../Constants/Message";
import { Modal } from "../../Components/Modal/Modal";
import { useNavigate, useLocation } from "react-router-dom";
import { FloatingDropdown } from "../FloatingDropdown/FloatingDropdown";
import {
  useGetProfileQuery,
  useDeleteOrganizationMutation,
  useRemoveMemberMutation,
} from "../../../redux/reducers/apiSlice";
import { customConsole } from "../../utils/helper";
// @ts-ignore
import careCertifiedImage from "/images/care-certified.png";
import { floatingDropdownActionsToArray } from "../../utils/helper";
import { ProfileAvatar } from "../ProfileAvatar/ProfileAvatar";
import { useOrganization } from "../../hooks/useOrganization";
import * as Sentry from "@sentry/react";

export const OrganizationDetailsCard = () => {
  const { data: currentProfileData } = useGetProfileQuery();
  const { currentOrganization } = useOrganization();
  const [deleteOrganization, { isLoading: deleteOrganizationLoading }] =
    useDeleteOrganizationMutation();
  const [leaveOrganization, { isLoading: leaveOrganizationLoading }] =
    useRemoveMemberMutation();

  const navigate = useNavigate();
  const location = useLocation();
  const [isMoreModalVisible, setIsMoreModalVisible] = useState(false);
  const [isDeleteOrganizationModalOpen, setIsDeleteOrganizationModalOpen] =
    useState(false);
  const [isLeaveOrganizationModalOpen, setIsLeaveOrganizationModalOpen] =
    useState(false);
  const [floatingDropDownActions, setFloatingDropdownActions] = useState({
    "View profile": {
      action: () =>
        navigate(`/organizations/${currentOrganization?.id}/profile`),
    },
  });

  useEffect(() => {
    const myRole = location?.state?.role;
    if (myRole) {
      const actionsToAdd = [
        {
          label: "Leave organization",
          action: () => setIsLeaveOrganizationModalOpen(true),
          isDestructiveAction: true,
          role: "MEMBER",
        },
        {
          label: "Edit organization",
          action: () =>
            navigate(`/organizations/${currentOrganization?.id}/edit`),
          isDestructiveAction: false,
          role: "ADMIN",
        },
        {
          label: "Delete organization",
          action: () => setIsDeleteOrganizationModalOpen(true),
          isDestructiveAction: true,
          role: "ADMIN",
        },
      ];

      const defaultActions = {
        "View profile": {
          action: () =>
            navigate(`/organizations/${currentOrganization?.id}/profile`, {
              state: { myRole },
            }),
        },
      };
      const newFloatingDropdownActions = { ...defaultActions };
      actionsToAdd.forEach(({ action, label, role, isDestructiveAction }) => {
        if (role === myRole) {
          newFloatingDropdownActions[label] = {
            action,
            isDestructiveAction,
          };
        }
      });

      setFloatingDropdownActions(newFloatingDropdownActions);
    }
  }, [location?.state?.role, currentOrganization?.id]);

  const modalRef = useRef(null);

  const onOutsideClicked = () => {
    setIsMoreModalVisible(false);
  };

  useDetectOutsideClick({ customRef: modalRef, onOutsideClicked });

  const onDeleteOrganization = async () => {
    try {
      if (currentOrganization?.id) {
        await deleteOrganization(currentOrganization.id).unwrap();
        navigate("/my-organizations");
        toast.success("Successfully deleted the organization");
      }
    } catch (e) {
      Sentry.captureException(e);
      toast.error(MESSAGE.SYSTEM_ERROR);
    }
  };

  const onLeaveOrganization = async () => {
    try {
      if (currentProfileData?.id && currentOrganization?.id) {
        await leaveOrganization({
          orgId: currentOrganization.id,
          memberId: currentProfileData?.id,
        });
        navigate("/my-organizations");
        toast.success("Successfully deleted the organization");
      }
    } catch (e) {
      Sentry.captureException(e);
      customConsole("log", e);
      toast.error(MESSAGE.SYSTEM_ERROR);
    }
  };

  return (
    <OrganizationDetailsCardWrapper>
      <CardHeader>
        <CardHeaderGroup1>
          <div style={{ marginRight: "12px" }}>
            <ProfileAvatar
              size={"lg"}
              src={currentOrganization?.image?.url}
              name={currentOrganization?.name}
              border={`2px solid #eee`}
            />
          </div>
          <CardHeaderSubGroup2>
            <CardTitle>{currentOrganization?.name}</CardTitle>
            <CardCaption>{currentOrganization?.type.toUpperCase()}</CardCaption>
          </CardHeaderSubGroup2>
        </CardHeaderGroup1>
        <CardHeaderGroup2>
          {currentOrganization?.orgFields?.careModelCertified && (
            <BadgeWrapper>
              <img src={careCertifiedImage} />
            </BadgeWrapper>
          )}
          <VerticalMoreIcon
            style={{ cursor: "pointer" }}
            aria-label="more"
            onClick={() => setIsMoreModalVisible(true)}
          >
            <MoreIcon />
          </VerticalMoreIcon>
          <FloatingDropdownLayout>
            <FloatingDropdown
              visible={isMoreModalVisible}
              onOutsideClicked={() => setIsMoreModalVisible(false)}
              items={floatingDropdownActionsToArray(floatingDropDownActions)}
            />
          </FloatingDropdownLayout>
        </CardHeaderGroup2>
      </CardHeader>
      <CardSeparator />
      <CardBody>
        <CardBodySubGroup>
          <CardBodySubGroupIcon>
            <TwoUsers />
          </CardBodySubGroupIcon>
          <CardBodyText>
            {currentOrganization?.members?.length || 0} Members
          </CardBodyText>
        </CardBodySubGroup>
        <CardBodySubGroup>
          <CardBodySubGroupIcon>
            <Flag />
          </CardBodySubGroupIcon>
          <CardBodyText>
            {currentOrganization?.goals?.length} Active Goals
          </CardBodyText>
        </CardBodySubGroup>
      </CardBody>
      {isDeleteOrganizationModalOpen && (
        <Modal
          isOpen={isDeleteOrganizationModalOpen}
          onClose={() => setIsDeleteOrganizationModalOpen(false)}
          modalText=""
          primaryButtonText={"Yes, delete organization"}
          primaryLoading={deleteOrganizationLoading}
          disablePrimaryButton={deleteOrganizationLoading}
          secondaryButtonText={"Cancel"}
          modalTitle={"Are you sure you want to delete this organization?"}
          onPrimaryButtonClicked={() => onDeleteOrganization()}
        />
      )}
      {isLeaveOrganizationModalOpen && (
        <Modal
          isOpen={isLeaveOrganizationModalOpen}
          onClose={() => setIsLeaveOrganizationModalOpen(false)}
          modalText=""
          primaryButtonText={"Yes, leave organization"}
          primaryLoading={leaveOrganizationLoading}
          disablePrimaryButton={leaveOrganizationLoading}
          secondaryButtonText={"Cancel"}
          modalTitle={"Are you sure you want to leave this organization?"}
          onPrimaryButtonClicked={() => onLeaveOrganization()}
        />
      )}
    </OrganizationDetailsCardWrapper>
  );
};
