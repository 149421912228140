import React from "react";
import { FloatingDropdownWrapper, FloatingDropdownItemWrapper } from "./styles";
import { useRef } from "react";
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";
import { Props } from "./types";

export const FloatingDropdown = ({
  items,
  visible,
  onOutsideClicked,
}: Props) => {
  const modalRef = useRef(null);

  useDetectOutsideClick({ customRef: modalRef, onOutsideClicked });

  if (!visible) return null;
  return (
    <FloatingDropdownWrapper ref={modalRef}>
      {items.map((item, id) => (
        <FloatingDropdownItemWrapper
          isDestructiveAction={item.isDestructiveAction}
          isHighlighted={item.isHighlighted}
          isLast={item.isLast}
          key={id}
        >
          <button style= {{width: '100%'}}onClick={item.action}>{item.label}</button>
        </FloatingDropdownItemWrapper>
      ))}
    </FloatingDropdownWrapper>
  );
};
