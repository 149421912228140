import styled, { keyframes } from 'styled-components';

const dotAnimation = keyframes`
  20% {
    background-position: top;
  }
  40% {
    background-position: bottom;
  }
  60% {
    background-position: center;
  }
`;

export const NexusLogoAnimationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  .logo {
    width: 139px;
    margin-bottom: 20px;
  }
  .txt-loading {
    display: inline-flex;
    align-items: flex-end;
    font-weight: bold;
    font-size: 24px;
    color: #fff;
    .text {
      margin-right: 12px;
    }
    .dots {
      position: relative;
      top: -8px;
    }
    .dots,
    .dots:before,
    .dots:after {
      width: 5px;
      aspect-ratio: 0.5;
      display: grid;
      background: radial-gradient(#fff 68%, #0000 72%) center/100% 50% no-repeat;
      animation: ${dotAnimation} 1.2s infinite linear calc(var(--_loading-dot, 0) * 0.4s);
      transform: translate(calc(var(--_loading-dot, 0) * 150%));
    }
    .dots:before,
    .dots:after {
      content: '';
      grid-area: 1/1;
    }
    .dots:before {
      --_loading-dot: -1;
    }
    .dots:after {
      --_loading-dot: 1;
    }
  }
`;
