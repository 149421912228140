import { css } from 'styled-components';

export const Popover = css`
  body {
    .chakra-popover__content {
      box-shadow: 1px 1px 6px rgba(64, 26, 15, 0.25);
      border-radius: 20px;
    }
  }
`;
