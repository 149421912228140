import styled from "styled-components";
import { devices } from "../../../Theme.css";

export const SignUpWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  min-height: 100vh;
  .col-logo {
    flex: 1 1 500px;
    width: 500px;
    background: transparent url(/images/bg-signup.jpg) no-repeat left top;
    background-size: cover;
    padding: 138px 20px;
    @media ${devices.max_lg} {
      flex: 1 1 30%;
      width: 30%;
    }
    @media ${devices.max_sm} {
      display: none;
    }
    &.bg-light-gradient {
      background: transparent url(/images/bg-light-signup.jpg) no-repeat left
        top;
      background-size: cover;
      padding: 110px 20px;
    }
    .img-logo {
      max-width: 230px;
    }
  }
  .col-form {
    flex: 1 1 calc(100% - 500px);
    width: calc(100% - 500px);
    padding: 68px 20px;
    @media ${devices.max_lg} {
      flex: 1 1 70%;
      width: 70%;
    }
    .img-logo {
      max-width: 180px;
      margin-bottom: 65px;
    }
  }
  .box-divider {
    overflow: hidden;
    max-width: 70%;
    text-align: center;
    margin-bottom: 24px;
    span {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      &:before {
        content: "";
        position: absolute;
        left: -14px;
        top: 50%;
        transform: translate(-100%, 0);
        width: 100vw;
        height: 1px;
        background: #c4c4c4;
      }
      &:after {
        content: "";
        position: absolute;
        right: -14px;
        top: 50%;
        transform: translate(100%, 0);
        width: 100vw;
        height: 1px;
        background: #c4c4c4;
      }
    }
  }
  .form-control-password {
    .chakra-popover__popper {
      position: absolute !important;
      inset: auto auto 100% 0px !important;
      transform: none !important;
    }
  }
`;

export const SignUpOnboardingWrapper = styled(SignUpWrapper)`
  .not-listed-field {
    .chakra-radio {
      display: flex;
      .chakra-radio__label {
        flex-grow: 1;
        user-select: all;
      }
    }
    .chakra-input {
      height: 20px;
      padding: 0;
    }
  }
  .box-submit-btns {
    max-width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 1px 1px 6px rgba(64, 26, 15, 0.25);
    background: #fff;
    z-index: 1000;
    padding: 8px 24px;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
`;

export const ErrorSpacer = styled.div`
  margin-top: 24px;
`;

export const RequiredAsterisk = styled.span`
  &:before {
    content: "*";
  }

  font-size: 16px;
  font-weight: bold;
  color: red;
`;
