import React from "react";
import toast from "react-hot-toast";
import { Modal } from "./Modal";
import { InputV2 } from "../../Reusable/InputV2/InputV2";
import { Formik } from "formik";
import { Spinner } from "@chakra-ui/react";
import { GoalFormWrapper } from "./Styles";
import { useUpdateMetricMutation } from "../../../redux/reducers/apiSlice";
import * as yup from "yup";
import { customConsole } from "../../utils/helper";
import * as Sentry from "@sentry/react";

const UpdateMetricModal = ({ isOpen, goalId, orgId, onClose, metric }) => {
  const [updateMetric, { isLoading: updateMetricLoading }] =
    useUpdateMetricMutation();

  const handleSubmit = async (data) => {
    const finalData = {
      ...metric,
      progress: {
        ...metric.progress,
        current: parseFloat(data.progress),
      },
    };

    delete finalData.id;
    customConsole("log", "final data", finalData);

    try {
      await updateMetric({
        orgId,
        goalId,
        metricId: metric.id,
        data: {
          ...finalData,
        },
      }).unwrap();
      toast.success(`"${metric.description}" has been edited`), onClose();
    } catch (e) {
      Sentry.captureException(e);
      toast.error(
        e?.data?.message ||
          "Something unexpected happened while trying to add your metric"
      );
    }
  };

  customConsole("log", "metric progress", metric.progress.current);

  if (updateMetricLoading) return <Spinner color="red" />;

  return (
    <Formik
      validationSchema={yup.object({
        progress: yup.number().required(),
      })}
      initialValues={{
        progress: metric.progress.current,
      }}
      onSubmit={async (data) => {
        customConsole("log", "onSubmit called");
        handleSubmit(data);
      }}
    >
      {(formik) => {
        return (
          <Modal
            isOpen={isOpen}
            onClose={onClose}
            primaryButtonText={"Update"}
            secondaryButtonText={"Cancel"}
            modalTitle={"Update metric"}
            onPrimaryButtonClicked={formik.handleSubmit}
            primaryLoading={updateMetricLoading}
            disablePrimaryButton={updateMetricLoading}
          >
            <GoalFormWrapper>
              <InputV2
                title="Current progress"
                placeholder="Update the overall progress of this metric"
                name="progress"
                value={formik.values.progress}
              />
            </GoalFormWrapper>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default UpdateMetricModal;
