import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  ModalFooter,
  Button,
  Spinner,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { ModalBody } from "./Styles";
import useScreenType from "react-screentype-hook";

const ReportAnIssueModal = ({ isOpen, onClose }) => {
  const { isMobile } = useScreenType();
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const clickupIframeReportForm = () => {
    return (
      <iframe
        title="Report an issue"
        className="clickup-embed clickup-dynamic-height"
        src="https://forms.clickup.com/2369003/f/289fb-26477/DOXB7JJNDORX4CLBR3"
        width="100%"
        height="500px"
        style={{
          background: "transparent",
          border: "1px solid #ccc",
          display: iframeLoaded ? "block" : "none",
        }}
        onLoad={() => setIframeLoaded(true)}
      ></iframe>
    );
  };

  return (
    <Modal
      size={isMobile ? "full" : "xl"}
      onClose={onClose}
      isOpen={isOpen}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>REPORT AN ISSUE</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {clickupIframeReportForm()}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "8px",
            }}
          >
            {!iframeLoaded && <Spinner color="#ee5a29" />}
          </div>
          {!iframeLoaded && (
            <h3
              style={{ color: "#ee5a29", textAlign: "center", marginTop: 10 }}
            >
              Loading bug report form...
            </h3>
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReportAnIssueModal;
