import styled from "styled-components";

import { devices } from "../../Theme.css";

export const DashboardLayoutWrapper = styled.div`
  padding-top: ${({ theme }) => theme.height.header};
`;

export const MainBody = styled.div`
  position: relative;
  padding-left: ${({ theme }) => theme.width.sidebar};

  @media ${devices.max_sm} {
    padding-left: 0;
  }
`;

export const MainContent = styled.div`
  position: relative;
  padding: 0 75px;
  &.message-main-content {
    padding: 0;
  }
  @media ${devices.max_xl} {
    padding: 0 45px;
  }
  @media ${devices.max_md} {
    padding: 0 30px;
  }

  @media ${devices.max_sm} {
    padding: 0;
  }
`;
