import React from "react";
import { Form } from "formik";
import { Box } from "@chakra-ui/react";

import { RadioGroupV2 } from "../../../Reusable";

const SettingsModal = ({ formik }) => {
  return (
    <Form>
      <Box mb="24px">
        <RadioGroupV2
          name="locationVisibility"
          title="Make my location (city and state) public"
          options={[
            { value: true, label: "Yes" },
            { value: false, label: "No" },
          ]}
          restRadioGroup={{ className: "radio-black" }}
          onChange={(val) =>
            formik.setFieldValue("locationVisibility", val === "true")
          }
        />
      </Box>

      <Box mb="24px" className="radio-black">
        <RadioGroupV2
          name="emailVisibility"
          title="Make my email public"
          options={[
            { value: true, label: "Yes" },
            { value: false, label: "No" },
          ]}
          restRadioGroup={{ className: "radio-black" }}
          onChange={(val) =>
            formik.setFieldValue("emailVisibility", val === "true")
          }
        />
      </Box>

      <Box mb="24px" className="radio-black">
        <RadioGroupV2
          name="pronounsVisibility"
          title="Make my pronouns public"
          options={[
            { value: true, label: "Yes" },
            { value: false, label: "No" },
          ]}
          restRadioGroup={{ className: "radio-black" }}
          onChange={(val) =>
            formik.setFieldValue("pronounsVisibility", val === "true")
          }
        />
      </Box>

      <Box mb="24px" className="radio-black">
        <RadioGroupV2
          name="genderVisibility"
          title="Make my gender public"
          options={[
            { value: true, label: "Yes" },
            { value: false, label: "No" },
          ]}
          restRadioGroup={{ className: "radio-black" }}
          onChange={(val) =>
            formik.setFieldValue("genderVisibility", val === "true")
          }
        />
      </Box>
    </Form>
  );
};

export default SettingsModal;
