import styled from "styled-components";

export const Card = styled.div`
  min-width: 320px;
  width: max-content;
  height: 86px;
  background: #fff;
  color: #000;
  box-shadow: 1px 1px 6px rgba(64, 26, 15, 0.25);
  border-radius: 25px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CardDetails = styled.div`
  margin-left: 10px;
`;

export const CardLabel = styled.div`
  font-weight: bold;
`;

export const GoalsCount = styled.div``;

export const IconContainer = styled.div`
  width: 32px;
  height: 32px;

  img {
    display: block;
    width: 100%:
  }
`;
