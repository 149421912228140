import { Button } from "@chakra-ui/react";
import { ErrorContainer, ErrorIcon, ErrorMessage } from "./Styles";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import { vars } from "../../Theme.css";
//@ts-ignore
import ErrorSign from "../../Assets/error-sign.svg";
import { AiOutlineHome } from "react-icons/ai";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }
  componentDidCatch(error, errorInfo) {
    this.setState({ error });
  }
  goToHome = () => {
    //@ts-ignore
    this.props.navigate(`/`);
    window.location.reload();
  };
  render() {
    //@ts-ignore
    if (this.state.error) {
      //render fallback UI
      return (
        <ErrorContainer>
          <ErrorIcon>
            <img src={ErrorSign} alt="error-sign" />
          </ErrorIcon>
          <ErrorMessage>
            <span
              style={{
                color: vars.color.fontGray,
                margin: "20px 16px",
                fontSize: "18px",
                textAlign: "center",
              }}
            >
              Oops.. Something went wrong, Please try again Later
            </span>
            <Button
              onClick={this.goToHome}
              iconRight={<AiOutlineHome fontSize="18px" />}
              style={{ maxWidth: "300px" }}
            >
              Go Back to Home Page
            </Button>
          </ErrorMessage>
        </ErrorContainer>
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}
function withRouter(Child) {
  return (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    return <Child {...props} navigate={navigate} location={location} />;
  };
}

export default withRouter(ErrorBoundary);
