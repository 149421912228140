import React from "react";
import AddNewOrganizationForm from "./AddNewOrganizationForm/AddNewOrganizationForm";
import FullPageNexusLogoAnimation from "../../Reusable/FullPageNexusLogoAnimation/FullPageNexusLogoAnimation";
import { useOrganization } from "../../hooks/useOrganization";

export const EditOrganizationForm = () => {
  const { currentOrganization, currentOrganizationLoading } = useOrganization();

  if (currentOrganizationLoading || !currentOrganization) {
    return <FullPageNexusLogoAnimation />;
  }

  return <AddNewOrganizationForm organization={currentOrganization} />;
};
