import { useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetSparksQuery } from "../../redux/reducers/apiSlice";
import { Spark } from "../../redux/reducers/types";

export const useSpark = () => {
  const { data: sparksData, isLoading: sparksDataLoading } =
    useGetSparksQuery();

  const { sparkName } = useParams();

  const spark = useMemo(() => {
    if (sparkName && sparkName !== "") {
      return sparksData?.find((spark) =>
        spark.name.toLowerCase().startsWith(sparkName.toLowerCase())
      );
    }
  }, [sparkName, sparksData]);

  const getSpark = (search: string): Spark | undefined => {
    return sparksData?.find((spark) =>
      spark.name.toLowerCase().startsWith(search.toLowerCase())
    );
  };

  return {
    sparks: sparksData,
    getSpark,
    sparksLoading: sparksDataLoading,
    spark,
  };
};
