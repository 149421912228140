import styled from "styled-components";

export const FloatingDropdownWrapper = styled.ul`
  list-style-type: none;
  box-shadow: 1px 1px 6px 0px rgba(64, 26, 15, 0.25);
  border-radius: 6px;
  background-color: white;
`;

type FloatingDropdownItemWrapperProps = {
  isLast?: boolean;
  isDestructiveAction?: boolean;
  isHighlighted?: boolean;
};

export const FloatingDropdownItemWrapper = styled.li<FloatingDropdownItemWrapperProps>`
  background-color: ${(props) => (props.isHighlighted ? ` #eee` : "white")};
  border-bottom: 1px solid #d1d1d1;
  ${(props) => props.isHighlighted && `border-bottom: none;`}
  padding: 12px;
  position: relative;
  :last-child {
    border-bottom: none;
  }

  button {
    color: ${(props) => (props.isDestructiveAction ? "red" : "black")};
    font-family: Montserrat;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
  }
`;
