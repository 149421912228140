import { useState, useEffect } from "react";
import Linkify from "react-linkify";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "../Modal/Modal";
// @ts-ignore
import Microlink from "@microlink/react";
import { formatDistanceToNow } from "date-fns";
import { Share, Comment, VerticalMoreIcon } from "../../Assets/SvgIcons";
import {
  PostCardWrapper,
  CardHeader,
  CommentButton,
  Author,
  NameAndTime,
} from "./styles";
import { Props } from "./types";
import { FloatingDropdown } from "../FloatingDropdown/FloatingDropdown";
import { Comments } from "../Comments/Comments";
import { Reactions } from "../Reactions/Reactions";
import { ProfileAvatar } from "../ProfileAvatar/ProfileAvatar";
import { usePost } from "../../hooks/usePost";
import queryStrings from "query-string";
import { CaptionComponent } from "../CaptionComponent/CaptionComponent";
import { truncate } from "../../utils/helper";

export const PostCard = ({
  post,
  sharedPost,
  onShare,
  commentsFullHeight,
}: Props) => {
  const [isFloatingDropdownVisible, setIsFloatingDropdownVisible] =
    useState(false);
  const [showComment, setShowComment] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const location = useLocation();
  const [isHovered, setIsHovered] = useState(false);

  const navigate = useNavigate();

  const {
    captionLink,
    deletePostLoading,
    handleDeletePost,
    comments,
    commentCount,
    optionsEnabled,
  } = usePost(post);

  useEffect(() => {
    const queryString = queryStrings.parse(location.search);
    if (queryString?.showComment && queryString.showComment === "true") {
      setShowComment(true);
    }
  }, [location.search]);
  const isTruncated =
    (post?.ownerType === "USER" && post?.ownerName?.length > 68) ||
    (post?.ownerType === "ORGANIZATION" && post?.ownerName?.length > 127);

  return (
    <PostCardWrapper isShared={!!sharedPost} loading={deletePostLoading}>
      <CardHeader>
        <Author
          onClick={() =>
            navigate(
              post.ownerType === "USER"
                ? `/users/${post?.ownerId}`
                : `/organizations/${post.ownerId}/profile`
            )
          }
        >
          <ProfileAvatar
            border={`2px solid #eee`}
            src={post?.ownerProfilePhoto}
            name={post?.ownerName}
          />
          <NameAndTime
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            isHoveredAndTruncated={isHovered && isTruncated}
          >
            <span className="name">
              {post?.ownerType === "USER" &&
                post?.ownerName &&
                !isHovered &&
                truncate(post?.ownerName)}
              {post?.ownerType === "ORGANIZATION" &&
                post?.ownerName &&
                !isHovered &&
                truncate(post?.ownerName, 127)}
              {isHovered && post?.ownerName}
            </span>
            <span className="time">
              {post?.updatedAt &&
                formatDistanceToNow(new Date(post.updatedAt), {
                  addSuffix: true,
                }).replace("about", "")}
            </span>
          </NameAndTime>
        </Author>
        <div className="more-icon-container">
          {isFloatingDropdownVisible && optionsEnabled && (
            <FloatingDropdown
              onOutsideClicked={() => setIsFloatingDropdownVisible(false)}
              items={[
                {
                  label: "Delete post",
                  action: () => setShowDeleteModal(true),
                  isDestructiveAction: true,
                },
              ]}
              visible={isFloatingDropdownVisible}
            />
          )}
          {!sharedPost && (
            <button
              disabled={!optionsEnabled}
              onClick={() => setIsFloatingDropdownVisible(true)}
            >
              <VerticalMoreIcon color={optionsEnabled ? "black" : "#eee"} />{" "}
            </button>
          )}
        </div>
      </CardHeader>
      <p className="description">
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a
              href={decoratedHref}
              target="_blank"
              rel="noopener noreferrer"
              dangerouslySetInnerHTML={{ __html: decoratedText }}
              key={key}
            />
          )}
        >
          <CaptionComponent caption={post?.caption} />
        </Linkify>
      </p>
      {captionLink && (
        <div style={{ position: "relative", zIndex: 0 }}>
          <Microlink url={captionLink} />
        </div>
      )}

      {post.type === "MEDIA" && post?.media && post?.media[0]?.url && (
        <div className="image-container">
          <img src={post?.media[0]?.url} />
        </div>
      )}
      {!sharedPost && onShare && (
        <>
          <div className="divider" />
          <div className="actions-container">
            <button onClick={() => onShare(post)} className="icon">
              <Share />
            </button>
            <CommentButton
              onClick={() => setShowComment((showComment) => !showComment)}
              className="icon"
            >
              <Comment /> <span className="comment-count">{commentCount}</span>
            </CommentButton>
            <Reactions post={post} />
          </div>
          <Comments
            postId={post?.id}
            comments={comments}
            visible={showComment}
            fullHeight={commentsFullHeight}
          />
        </>
      )}
      {showDeleteModal && (
        <Modal
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onPrimaryButtonClicked={() => handleDeletePost()}
          modalTitle="Are you sure you want to delete this post?"
          primaryLoading={deletePostLoading}
          disablePrimaryButton={deletePostLoading}
          primaryButtonText="Delete"
          secondaryButtonText="Cancel"
        />
      )}
    </PostCardWrapper>
  );
};
