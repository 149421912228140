import styled from "styled-components";

export const OrganizationsWrapper = styled.div``;

export const OrganizationWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  .first-column {
    /* background-color: red; */
    margin-right: 12px;
    height: 100%;
  }

  .second-column {
    width: 100%;
    height: 100%;
    padding-top: 5px;
  }

  .third-column {
    height: 100%;
  }

  img {
    width: 100%;
    display: block;
    background-color: #eee;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
  }

  .no-image-circle {
    background-color: #eee;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
  }

  .name {
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140.5%; /* 19.67px */
  }

  .caption {
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
  }

  :last-child {
    margin-bottom: 0;
  }
`;
