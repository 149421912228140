import { css } from 'styled-components';

export const Button = css`
  body {
    .chakra-button {
      border-radius: 22px;
      &.gray-for-disabled {
        &:disabled,
        &[disabled],
        &[aria-disabled='true'],
        &[data-disabled] {
          opacity: 1;
          color: #000;
          background: #d1d1d1 !important;
        }
      }
    }
    .btn-outline-dark {
      font-weight: bold;
      font-size: 16px;
      color: #262626;
      border-color: #262626;
      &:hover {
        color: #fff;
        background-color: #262626;
      }
      &:focus,
      &[data-focus] {
        box-shadow: 0 0 0 3px rgba(38, 38, 38, 0.3) !important;
      }
    }
    .btn-gray {
      font-size: 16px;
      color: #262626;
      background-color: #d1d1d1;
      &:hover {
        background-color: #a8a8a8;
      }
      &:focus,
      &[data-focus] {
        box-shadow: 0 0 0 3px rgba(38, 38, 38, 0.3) !important;
      }
    }
  }
`;
