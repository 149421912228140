import React from "react";
import HeaderMiddle from "./HeaderMiddle";
import HeaderLeft from "./HeaderLeft";
import HeaderRight from "./HeaderRight";
import { HeaderWrapper } from "./Styles";

const Header = ({ toggleSidebar }) => {
  return (
    <HeaderWrapper>
      <HeaderLeft />
      <div className="column second-column">
        <HeaderMiddle />
        <HeaderRight toggleSidebar={toggleSidebar} />
      </div>
    </HeaderWrapper>
  );
};

export default Header;
