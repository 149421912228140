import React, { useMemo, useState } from "react";
import { CarretUp, GoalCarretDown, TargetIcon } from "../../../Assets/SvgIcons";
import Metric from "../../Metric/Metric";
import { Spinner } from "@chakra-ui/react";
import {
  SparkCardCaption,
  SparkCardTitle,
  SparkCardTopLayout,
  SparkCard,
  GoalCardContainer,
  GoalCard,
  GoalCardTitleContainer,
  GoalCardTitle,
  GoalCardDescription,
  SparksTabWrapper,
  SparkCardColumn,
  CarretContainer,
  SparkCardImageColumn,
  SparkMobileDivider,
  SparkSpacer,
} from "./styles";
import { useSpark } from "../../../hooks/useSpark";
import { useOrganization } from "../../../hooks/useOrganization";
import { customConsole } from "../../../utils/helper";

export const Sparks = ({ sparks, goals, orgId }) => {
  const { sparksLoading, getSpark } = useSpark();

  const [sparkUnfolded, setSparkUnfolded] = useState<null | string>(null);

  const toggleSparkCardFolding = (sparkId: string) => {
    setSparkUnfolded((prevSparkUnfoled: null | string) => {
      if (prevSparkUnfoled === sparkId) {
        return null;
      } else {
        return sparkId;
      }
    });
  };

  const sparksWithGoals = useMemo(() => {
    return sparks?.map((spark) => {
      return {
        ...getSpark(spark),
        goals: goals?.filter((goal) => goal.spark.name === spark) || [],
      };
    });
  }, [sparks, goals]);

  customConsole("log", "Spark  with goals", sparksWithGoals);

  return (
    <SparksTabWrapper>
      {sparksLoading && <Spinner color="red" />}
      {!sparksLoading &&
        sparksWithGoals?.map((spark, id) => (
          <React.Fragment key={id}>
            <SparkSpacer>
              <SparkCard>
                <SparkCardTopLayout
                  as="button"
                  onClick={() => toggleSparkCardFolding(spark.id)}
                >
                  <SparkCardImageColumn>
                    <div style={{ width: "41px", height: "41px" }}>
                      <img src={spark.logo} />
                    </div>
                  </SparkCardImageColumn>
                  <SparkCardColumn>
                    <SparkCardTitle>{spark.name}</SparkCardTitle>
                    <SparkCardCaption>
                      {spark.goals.length} goals
                    </SparkCardCaption>
                  </SparkCardColumn>
                  {spark.goals.length > 0 && (
                    <CarretContainer>
                      {sparkUnfolded === spark.id ? (
                        <CarretUp />
                      ) : (
                        <GoalCarretDown />
                      )}
                    </CarretContainer>
                  )}
                </SparkCardTopLayout>

                {sparkUnfolded === spark.id &&
                  spark.goals?.map((goal, idGoal) => (
                    <GoalCardContainer key={idGoal}>
                      <GoalCard>
                        <GoalCardTitleContainer>
                          <TargetIcon />{" "}
                          <GoalCardTitle>{goal.name}</GoalCardTitle>
                        </GoalCardTitleContainer>
                        <GoalCardDescription>
                          {goal.description}
                        </GoalCardDescription>
                        {goal.metrics?.map((metric, idMetric) => (
                          <Metric
                            key={idMetric}
                            goalId={goal.id}
                            orgId={orgId}
                            metric={metric}
                          />
                        ))}
                      </GoalCard>
                    </GoalCardContainer>
                  ))}
              </SparkCard>
            </SparkSpacer>
            <SparkMobileDivider />
          </React.Fragment>
        ))}
    </SparksTabWrapper>
  );
};
