import { css } from "styled-components";
import { devices } from "../../Theme.css";

export const Modal = css`
  body {
    .common-modal-content {
      box-shadow: 1px 1px 6px rgba(64, 26, 15, 0.25);
      border-radius: 40px;

      @media ${devices.max_sm} {
        border-radius: 0px;
      }

      .chakra-modal__header {
        padding: 24px;
      }
      .chakra-modal__close-btn {
        font-size: 14px;
        border-radius: 50%;
        box-shadow: none;
        color: #000;
        top: 20px;
        right: 20px;
      }
      .chakra-modal__body {
        padding: 24px;
      }
    }
  }
`;
