import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import Talk from "talkjs";
import { Session } from "@talkjs/react";
import { useLazyGetUserByIdQuery } from "../../../redux/reducers/apiSlice";
import { MessagesInboxWrapper } from "./Styles";
import FullPageNexusLogoAnimation from "../../Reusable/FullPageNexusLogoAnimation/FullPageNexusLogoAnimation";

const MessagesInbox = ({ otherUser }) => {
  const syncConversation = useCallback(
    (session: Talk.Session) => {
      // regular TalkJS JavaScript code here
      if (otherUser) {
        const otherTalkUser = new Talk.User({
          id: otherUser.id,
          name: `${otherUser.name?.firstName} ${otherUser.name?.lastName}`,
          photoUrl: otherUser.image?.url,
          email: otherUser.email,
          role: "default",
        });
        const conversation = session.getOrCreateConversation(
          Talk.oneOnOneId(session.me, otherTalkUser)
        );
        conversation.setParticipant(session.me);
        conversation.setParticipant(otherTalkUser);
        return conversation;
      }

      // const conversation = session.getOrCreateConversation("welcome");

      return undefined;
    },
    [otherUser]
  );

  return (
    <MessagesInboxWrapper
      // @ts-ignore
      syncConversation={syncConversation}
      loadingComponent={<FullPageNexusLogoAnimation />}
    />
  );
};

const Messages = () => {
  const { userId } = useParams();
  const [getUserById, { isLoading: getUserByIdLoading, data: user }] =
    useLazyGetUserByIdQuery();

  useEffect(() => {
    if (userId) {
      getUserByIdFn(userId);
    }
  }, [userId]);

  const getUserByIdFn = async (userId) => {
    await getUserById(userId);
  };

  if (getUserByIdLoading) return <FullPageNexusLogoAnimation />;

  return <MessagesInbox otherUser={user} />;
};

export default Messages;
