import React, { useState, useEffect } from "react";
import { Spinner } from "@chakra-ui/react";
import { InviteMember } from "../../../Assets/SvgIcons";
import { OrganizationWrapper, OrganizationsWrapper } from "./styles";
import toast from "react-hot-toast";
import { MESSAGE } from "../../../Constants/Message";
import { useFollowUserMutation } from "../../../../redux/reducers/apiSlice";
// @ts-ignore
import { ProfileAvatar } from "../../ProfileAvatar/ProfileAvatar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Organization } from "../../../../types";
import { truncate } from "../../../utils/helper";
import * as Sentry from "@sentry/react";

export const Organizations: React.FC<{
  organizations: Organization[];
  loading?: boolean;
}> = ({ organizations, loading }) => {
  const [followUser, { data: organization }] = useFollowUserMutation();
  const navigate = useNavigate();
  const location = useLocation();

  const [loadingFollowOrganization, setLoadingFollowOrganization] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    const loadings = loadingFollowOrganization;
    organizations.forEach((person) => {
      loadings[person.id] = false;
    });
    setLoadingFollowOrganization(loadings);
  }, [organizations]);

  const followOrganizationFn = async (organization) => {
    try {
      let loadingFollowOrganizationCopy = { ...loadingFollowOrganization };
      loadingFollowOrganizationCopy[organization?.id] = true;
      setLoadingFollowOrganization(loadingFollowOrganizationCopy);
      await followUser({
        userId: organization?.id,
        type: "ORG",
      }).unwrap();
      loadingFollowOrganizationCopy = { ...loadingFollowOrganization };
      loadingFollowOrganization[organization?.id] = false;
      setLoadingFollowOrganization(loadingFollowOrganizationCopy);
      toast.success(
        `You just followed the organization: ${organization?.name}`
      );
    } catch (e) {
      Sentry.captureException(e);
      const loadingFollowOrganizationCopy = { ...loadingFollowOrganization };
      loadingFollowOrganization[organization?.id] = false;
      setLoadingFollowOrganization(loadingFollowOrganizationCopy);
      toast.error(MESSAGE.SYSTEM_ERROR);
    }
  };

  if (loading) return <p>Searching for organizations to suggest...</p>;

  return (
    <OrganizationsWrapper>
      {(!organizations || organizations.length === 0) && (
        <p>No one to suggest.</p>
      )}
      {organizations.map((organization) => (
        <OrganizationWrapper key={organization.id}>
          <Link to={`/organizations/${organization.id}/profile`}>
            <div style={{ display: "flex", width: 300, flexGrow: 1 }}>
              <div className="first-column">
                <ProfileAvatar
                  src={organization?.image?.url}
                  border={`2px solid #eee`}
                />
              </div>

              <div className="second-column">
                <p className="name">{truncate(organization.name, 15, 15)}</p>
                <p className="caption">{organization.type.toUpperCase()}</p>
              </div>
            </div>
          </Link>
          <div className="third-column">
            {loadingFollowOrganization[organization.id] && (
              <Spinner color="red" />
            )}
            {!loadingFollowOrganization[organization.id] && (
              <button onClick={() => followOrganizationFn(organization)}>
                <InviteMember boxSize={8} />
              </button>
            )}
          </div>
        </OrganizationWrapper>
      ))}
    </OrganizationsWrapper>
  );
};
