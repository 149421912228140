import styled from "styled-components";
import { devices } from "../../Theme.css";

export const AddGoalFormWrapper = styled.div`
  width: 100%;
  @media ${devices.max_sm} {
    padding: 24px;
  }

  .warning {
    opacity: 0.8;
    color: red;
    text-align: center;
    margin-top: 24px;
  }
`;

export const NoGoalCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 44px 0px;
  gap: 20px;
  background: #ffffff;
  box-shadow: 1px 1px 6px rgba(64, 26, 15, 0.25);
  border-radius: 40px;
`;

export const PageTitle = styled.h1`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 34px;
  margin-bottom: 12px;
`;

export const Subtitle = styled.h2`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 44px;
`;

export const BackButtonWrapper = styled.div`
  margin: 24px 0px;

  @media ${devices.max_sm} {
    display: none;
  }
`;

export const Goals = styled.div`
  .button-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 24px;
  }
  height: 100%;
`;

export const GoalCardWrapper = styled.div`
  border-radius: 20px;
  background: #fff;
  box-shadow: 1px 1px 6px 0px rgba(64, 26, 15, 0.25);
  padding: 24px;
  margin-bottom: 24px;
  width: 100%;
  position: relative;

  .top-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .title-and-icon {
    width: 100%;
    background-color; red;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .title {
    display: block;
    color: #ef6b2d;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 8px;
    padding-left: 8px;
  }

  .description {
    background-color: white;
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 12px;
  }

  .metric-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 18px;

    .metric-button-text {
      margin-left: 8px;
      color: "black";
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 140.5%; /* 19.67px */
    }
  }
`;

export const GoalDescription = styled.div`
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 12px;
`;

export const AddGoalButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;

  .goal-button-text {
    margin-left: 8px;
  }
`;

export const FloatingDropdownWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 5%;
`;
