import React from 'react';
import { Box } from '@chakra-ui/react';

import { NexusLogoAnimationWrapper } from './Styles';

const NexusLogoAnimation = () => {
  return (
    <NexusLogoAnimationWrapper className="nexus-logo-animation-wrapper">
      <Box>
        <img src="/images/logo-7.png" alt="Measure" className="logo" />
        <div className="txt-loading">
          <span className="text">Loading</span>
          <span className="dots"></span>
        </div>
      </Box>
    </NexusLogoAnimationWrapper>
  );
};

export default NexusLogoAnimation;
