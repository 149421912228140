import React from "react";
import { Field } from "formik";
import Select from "react-select";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputGroup,
} from "@chakra-ui/react";
import {
  getNestedFormikValue,
  isNestedFormikValuePresent,
} from "../../utils/formErrors";

export const SelectV2 = ({
  name,
  title,
  value,
  options,
  placeholder,
  inputLeftElement,
  inputRightElement,
  restSelect,
  onChange,
  disabled,
  hideError,
  ...rest
}: any) => {
  /**
   * Default value
   */
  const defaultValue = (options, value) => {
    let localOptions;

    if (value && Array.isArray(value)) {
      localOptions = options
        ? options.filter((option) => value.includes(option.value))
        : "";
    } else {
      localOptions = options
        ? options.find((option) => option.value === value)
        : "";
    }

    return localOptions;
  };

  return (
    <Field name={name} {...rest}>
      {({ field, form }) => (
        <FormControl
          isInvalid={
            isNestedFormikValuePresent(form.errors, name) &&
            isNestedFormikValuePresent(form.errors, name)
          }
          style={{ zIndex: "inherit" }}
        >
          {title && <FormLabel htmlFor={name}>{title}</FormLabel>}

          <InputGroup>
            {inputLeftElement}
            <Select
              value={defaultValue(options, value)}
              options={options}
              placeholder={placeholder || ""}
              className="react-select-container"
              classNamePrefix="react-select"
              onChange={onChange}
              {...restSelect}
            />
            {inputRightElement}
          </InputGroup>

          {!hideError && (
            <FormErrorMessage>
              {getNestedFormikValue(form.errors, name)}
            </FormErrorMessage>
          )}
        </FormControl>
      )}
    </Field>
  );
};
