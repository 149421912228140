import React, { useState } from "react";
import _ from "lodash";
import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@chakra-ui/react";
import { Modal } from "./Modal";
import toast from "react-hot-toast";
import { Form, Formik } from "formik";
import { Box, Button, Flex, Text, Stack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import StepSpark from "../../Components/StepSpark/StepSpark";
import Avatar from "../Profile/Avatar/Avatar";
import StepGeneralInfo from "../../Pages/Organizations/AddNewOrganizationForm/StepGeneralInfo";
import { MESSAGE } from "../../Constants/Message";
import {
  useAddOrganizationMutation,
  useUpdateOrganizationMutation,
  useUpdateOrganizationProfilePictureMutation,
} from "../../../redux/reducers/apiSlice";
import {
  replaceNotListedLabelWithValue,
  generateAddOrganizationInitialValues,
} from "../../utils/helper";
import { addNewOrganizationFormSchema } from "../../Pages/Organizations/AddNewOrganizationForm/schema";
import { Loading } from "../../Reusable";
import { Organization } from "../../../redux/reducers/types";
import { TitleWrapper } from "./Styles";
import * as Sentry from "@sentry/react";

type Props = {
  type: string;
  organization: Organization;
  step: "SPARK" | "GENERAL_INFO";
  isOpen: boolean;
  onClose: () => void;
};

const OrganizationEditModal = ({
  type,
  organization,
  step,
  isOpen,
  onClose,
}: Props) => {
  const navigate = useNavigate();

  // location params
  const editMode = !!organization;
  const organizationData = organization;

  // state
  const [organizationProfilePictureFile, setOrganizationProfilePictureFile] =
    useState<File | undefined>(undefined);
  const [_step, setStep] = useState(step || "GENERAL_INFO");

  const [addOrganization, { isLoading: addOrganizationLoading }] =
    useAddOrganizationMutation();
  const [updateOrganization, { isLoading: updateOrganizationLoading }] =
    useUpdateOrganizationMutation();
  const [
    updateOrganizationProfilePicture,
    { isLoading: updateOrganizationProfilePictureLoading },
  ] = useUpdateOrganizationProfilePictureMutation();

  const handleFinish = async (data) => {
    const finalOrganizationData = { ...data };
    const communityServedNotListed =
      finalOrganizationData.communityServedNotListed;
    const labelNotListed = finalOrganizationData.labelNotListed;
    const careModelCertified =
      finalOrganizationData.orgFields.careModelCertified;

    const communitiesServed = replaceNotListedLabelWithValue(
      finalOrganizationData.communitiesServed,
      communityServedNotListed
    );

    const labels = replaceNotListedLabelWithValue(
      finalOrganizationData.labels,
      labelNotListed
    );

    finalOrganizationData.communitiesServed = communitiesServed;
    finalOrganizationData.labels = labels;
    finalOrganizationData.orgFields.careModelCertified =
      careModelCertified === "true" ? true : false;
    delete finalOrganizationData.labelNotListed;
    delete finalOrganizationData.communityServedNotListed;
    delete finalOrganizationData.step;

    try {
      const call = editMode ? updateOrganization : addOrganization;

      const payload = editMode
        ? {
            orgId: organizationData?.id,
            orgData: finalOrganizationData,
          }
        : finalOrganizationData;

      const organization = await call(payload).unwrap();

      if (organizationProfilePictureFile) {
        const formData = new FormData();
        formData.append(
          "photo",
          organizationProfilePictureFile as string | Blob
        );

        await updateOrganizationProfilePicture({
          bodyFormData: formData,
          orgId: organization.id,
        });
      }
      onClose();
      toast.success(
        `You successfully ${editMode ? "edited" : "created"} an organization`
      );
    } catch (e) {
      Sentry.captureException(e);
      toast.error(MESSAGE.SYSTEM_ERROR);
    }
  };

  const loading =
    addOrganizationLoading ||
    updateOrganizationProfilePictureLoading ||
    updateOrganizationLoading;

  return (
    <>
      <Modal
        isCentered
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={onClose}
      >
        <Formik
          validationSchema={addNewOrganizationFormSchema}
          initialValues={generateAddOrganizationInitialValues(
            type,
            editMode,
            organizationData
          )}
          onSubmit={async (data, handlers) => {
            handlers.setSubmitting(true);
            handleFinish(data);
            handlers.validateForm(data);
            handlers.setSubmitting(false);
          }}
        >
          {(formik) => {
            const disableButton = (step: string) => {
              if (_step == "GENERAL_INFO") {
                if (
                  formik.values.name &&
                  formik.values.type &&
                  formik.values.mission &&
                  formik.values.vision &&
                  formik.values.problem &&
                  formik.values.phoneNumber &&
                  formik.values.sparks &&
                  formik.values.location &&
                  formik.values.labels.length > 0 &&
                  formik.values.communitiesServed.length > 0 &&
                  formik.values.orgFields.careModelCertified &&
                  Object.keys(formik.errors)?.length === 0
                ) {
                  if (type === "non-profit" || type === "foundation") {
                    if (
                      !formik.values.orgFields?.ein ||
                      formik.values.orgFields?.ein === ""
                    ) {
                      return true;
                    }
                  }

                  return false;
                }
              }
              if (_step == "SPARK") {
                if (
                  formik.values.sparks &&
                  formik.values.sparks.length == 3 &&
                  Object.keys(formik.errors)?.length === 0
                )
                  return false;
              }

              if (
                !formik.values.communityServedNotListed &&
                formik.values.communitiesServed?.includes("not-listed" as never)
              ) {
                return true;
              }

              if (
                !formik.values.labelNotListed &&
                formik.values.labels?.includes("not-listed" as never)
              ) {
                return true;
              }

              return true;
            };
            return (
              <ModalContent margin="0" className="common-modal-content">
                <ModalHeader>
                  <TitleWrapper>Edit organization sparks</TitleWrapper>
                </ModalHeader>
                <ModalCloseButton />

                <ModalBody style={{ overflowY: "scroll", padding: "24px" }}>
                  <Loading spin={loading}>
                    <Form onSubmit={formik.handleSubmit}>
                      <Box maxWidth={866} margin="auto">
                        {_step === "GENERAL_INFO" && (
                          <>
                            <Text
                              fontWeight="semibold"
                              color="#000"
                              textAlign="center"
                              mb="24px"
                            >
                              {_step === "GENERAL_INFO" && (
                                <>
                                  {!editMode ? "Add" : "Edit"}{" "}
                                  {!editMode ? "a" : "your"}{" "}
                                  {formik.values.type} organization.
                                </>
                              )}
                              {/* @ts-ignore */}
                              {_step === "SPARK" && (
                                <>
                                  Select 3 sparks for your organization to get
                                  started.
                                </>
                              )}
                            </Text>
                            <Flex justifyContent="center" mb="32px">
                              <Avatar
                                defaultImage={formik.values.image?.url}
                                onFile={setOrganizationProfilePictureFile}
                              />
                            </Flex>
                            <StepGeneralInfo
                              editMode={editMode}
                              formik={formik}
                            />
                          </>
                        )}
                        {_step === "SPARK" && <StepSpark formik={formik} />}
                      </Box>

                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        spacing="10px"
                        className="box-submit-btns"
                      >
                        {_step === "GENERAL_INFO" && (
                          <>
                            <Button
                              variant="outline"
                              loadingText="Sign in"
                              className="btn-outline-dark"
                              onClick={() => navigate(-1)}
                            >
                              Cancel
                            </Button>

                            <Button
                              colorScheme="primary"
                              minWidth="160px"
                              loadingText="Next"
                              isLoading={false}
                              isDisabled={disableButton("GENERAL_INFO")}
                              onClick={() => {
                                setStep("SPARK");
                              }}
                              className="gray-for-disabled"
                            >
                              Next
                            </Button>
                          </>
                        )}
                      </Stack>
                    </Form>
                  </Loading>
                </ModalBody>
                <ModalFooter>
                  {_step === "SPARK" && (
                    <>
                      <Button
                        type="submit"
                        colorScheme="primary"
                        minWidth="160px"
                        loadingText="Finish"
                        onClick={() => handleFinish(formik.values)}
                        isLoading={addOrganizationLoading}
                        isDisabled={disableButton("SPARK")}
                        className="gray-for-disabled"
                      >
                        Finish
                      </Button>
                    </>
                  )}
                </ModalFooter>
              </ModalContent>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default OrganizationEditModal;
