import { css } from 'styled-components';

export const Utils = css`
  .text-primary {
    color: ${({ theme }) => theme.color.primary} !important;
  }
  .text-center {
    text-align: center !important;
  }
  .text-left {
    text-align: left !important;
  }
  .text-right {
    text-align: right !important;
  }
  .text-hover-primary {
    transition: all 0.4s ease;
    &:hover {
      color: ${({ theme }) => theme.color.primary} !important;
    }
    &[disabled] {
      color: rgba(0, 0, 0, 0.25) !important;
    }
  }
  .text-hover-dark-primary {
    transition: all 0.4s ease;
    &:hover {
      color: #ad350e !important;
    }
    &[disabled] {
      color: rgba(0, 0, 0, 0.25) !important;
    }
  }
  .bg-primary {
    background: ${({ theme }) => theme.color.primary} !important;
  }
  .bg-transparent {
    background: transparent !important;
  }
  .w-auto {
    width: auto !important;
  }
  .h-auto {
    height: auto !important;
  }
  .w-100 {
    width: 100% !important;
  }
  .h-100 {
    height: 100% !important;
  }
  .cursor-pointer {
    cursor: pointer !important;
  }
  .border-transparent {
    border-color: transparent !important;
  }
  .border-0 {
    border: 0 !important;
  }
  .outline-0 {
    outline: 0 !important;
  }
  .box-shadow-none {
    box-shadow: none !important;
  }
  .border-radius-0 {
    border-radius: 0 !important;
  }
  .hover-over {
    transition: all 0.4s ease;
    &:hover {
      opacity: 0.7;
    }
  }
  .vertical-align-middle {
    vertical-align: middle !important;
  }
  .user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }
  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
`;
