import React, { useEffect, useState } from "react";
import { Spinner } from "@chakra-ui/react";
import queryStrings from "query-string";
import { formatDistanceToNow } from "date-fns";
import {
  CaptionWrapper,
  AuthorWrapper,
  Author,
  SharedPostWrapper,
  CommentButton,
  NameAndTime,
} from "./styles";
import { Comment, Share, VerticalMoreIcon } from "../../Assets/SvgIcons";
import { Reactions } from "../Reactions/Reactions";
import { usePost } from "../../hooks/usePost";
import { Modal } from "../Modal/Modal";
import { FloatingDropdown } from "../FloatingDropdown/FloatingDropdown";
import { useNavigate } from "react-router-dom";
import { customConsole, truncate } from "../../utils/helper";
import Metric from "../Metric/Metric";
import { Post } from "../../../redux/reducers/types";
import { Comments } from "../Comments/Comments";
import { ProfileAvatar } from "../ProfileAvatar/ProfileAvatar";
import { PostCard } from "./PostCard";

type Props = {
  post: Post;
  onShare: () => void;
  commentsFullHeight?: boolean;
};

const SharedPost: React.FC<Props> = ({ onShare, post, commentsFullHeight }) => {
  const [isFloatingDropdownVisible, setIsFloatingDropdownVisible] =
    useState(false);
  const [showComment, setShowComment] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const {
    comments,
    handleDeletePost,
    deletePostLoading,
    sharedPost,
    sharedPostLoading,
    commentCount,
    sharedMetric,
    optionsEnabled,
  } = usePost(post);

  useEffect(() => {
    const queryString = queryStrings.parse(location.search);
    if (queryString?.showComment && queryString.showComment === "true") {
      setShowComment(true);
    }
  }, [location.search]);

  customConsole("log", "OPTIONS ENABLED", optionsEnabled);

  const navigate = useNavigate();

  const isTruncated =
    (post?.ownerType === "USER" && post?.ownerName?.length > 68) ||
    (post?.ownerType === "ORGANIZATION" && post?.ownerName?.length > 127);

  return (
    <SharedPostWrapper>
      <AuthorWrapper>
        <Author
          onClick={() =>
            navigate(
              post.ownerType === "USER"
                ? `/users/${post?.ownerId}`
                : `/organizations/${post.ownerId}/profile`
            )
          }
        >
          <ProfileAvatar
            border={`2px solid #eee`}
            name={post?.ownerName}
            src={post?.ownerProfilePhoto}
          />
          <NameAndTime
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            isHoveredAndTruncated={isHovered && isTruncated}
          >
            <span className="name">
              {post?.ownerType === "USER" &&
                post?.ownerName &&
                !isHovered &&
                truncate(post?.ownerName)}
              {post?.ownerType === "ORGANIZATION" &&
                post?.ownerName &&
                !isHovered &&
                truncate(post?.ownerName, 127)}
              {isHovered && post?.ownerName}
            </span>
            <span className="time">
              {post?.updatedAt &&
                formatDistanceToNow(new Date(post.updatedAt), {
                  addSuffix: true,
                }).replace("about", "")}
            </span>
          </NameAndTime>
        </Author>
        <div className="more-icon-container">
          <FloatingDropdown
            items={[
              {
                label: "Delete post",
                action: () => setShowDeleteModal(true),
                isDestructiveAction: true,
              },
            ]}
            onOutsideClicked={() => setIsFloatingDropdownVisible(false)}
            visible={isFloatingDropdownVisible}
          />
          <button
            disabled={!optionsEnabled}
            onClick={() => {
              setIsFloatingDropdownVisible(true);
            }}
          >
            <VerticalMoreIcon color={optionsEnabled ? "black" : "#eee"} />
          </button>
        </div>
      </AuthorWrapper>
      {post?.caption && <CaptionWrapper>{post?.caption}</CaptionWrapper>}
      <div>
        {sharedPostLoading ? (
          <Spinner color="red" />
        ) : (
          <>
            {sharedPost && !sharedMetric && (
              <PostCard sharedPost={true} post={sharedPost} />
            )}
            {sharedMetric && <Metric metric={sharedMetric} isShared />}
          </>
        )}
      </div>

      <div className="divider" />
      <div className="actions-container">
        <button onClick={() => onShare()} className="icon">
          <Share />
        </button>
        <CommentButton
          onClick={() => setShowComment((showComment) => !showComment)}
          className="icon"
        >
          <Comment /> <span className="comment-count">{commentCount}</span>
        </CommentButton>
        <Reactions post={post} />
      </div>
      <Comments
        fullHeight={commentsFullHeight}
        postId={post?.id}
        comments={comments}
        visible={showComment}
      />

      {showDeleteModal && (
        <Modal
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onPrimaryButtonClicked={() => handleDeletePost()}
          modalTitle="Are you sure you want to delete this post?"
          primaryLoading={deletePostLoading}
          disablePrimaryButton={deletePostLoading}
          primaryButtonText="Delete"
          secondaryButtonText="Cancel"
        />
      )}
    </SharedPostWrapper>
  );
};

export default SharedPost;
