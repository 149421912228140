import React from "react";
import { ORGANIZATION_CARD_NAME_LENGTH } from "../../utils/constants";
import { Card, CardDetails, OrgName, OrgType, OrgMembers } from "./Styles";
import { ProfileAvatar } from "../ProfileAvatar/ProfileAvatar";

export const OrganizationCard = ({ name, image, type, memberCount = 0 }) => {
  const hideNameIfTooLong = (name) => {
    return name.length < ORGANIZATION_CARD_NAME_LENGTH
      ? name
      : name.substring(0, ORGANIZATION_CARD_NAME_LENGTH) + "...";
  };

  return (
    <Card>
      <div>
        <ProfileAvatar src={image?.url} border={`2px solid #eee`} />
      </div>
      <CardDetails>
        <OrgName>{hideNameIfTooLong(name)}</OrgName>
        <OrgType>{type.toUpperCase()}</OrgType>
        <OrgMembers>
          {memberCount} member{memberCount > 1 && "s"}
        </OrgMembers>
      </CardDetails>
    </Card>
  );
};
