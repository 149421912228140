import styled from "styled-components";
import QRCodeComponent from "react-qr-code";
import { ModalBody as ChakraModalBody } from "@chakra-ui/react";

export const InviteInput = styled.input`
  width: 80%;
  border-radius: 100px;
  border: 1px solid #fff;
  padding: 8px 16px;
  box-shadow: 1px 1px 6px 0px rgba(64, 26, 15, 0.75);
`;

export const Pills = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px 12px;
  margin-top: 12px;
`;

export const Pill = styled.div`
  /* typograpy */
  width: fit-content;
  height: fit-content;
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: #eee;
  padding: 8px 16px;
  border-radius: 100px;
  margin-bottom: 12px;
  margin-right: 12px;
  button {
    margin-right: 2px;
  }
`;

export const InviteModalBody = styled.div`
  width: 100%;
`;

export const EmailError = styled.p`
  color: #f00;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
  font-weight: bold;
  margin-bottom: 24px;
`;

export const InviteInputWrapper = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PillsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GoalFormWrapper = styled.div`
  padding: 24px;
`;

export const InputSpacer = styled.div`
  margin-bottom: 24px;

  :last-of-type {
    margin-bottom: 0px;
  }
`;

export const RequiredAsterisk = styled.span`
  &:before {
    content: "*";
  }

  font-size: 16px;
  font-weight: bold;
  color: red;
`;

export const Instructions = styled.p`
  margin-top: 16px;
  margin-left: 16px;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  kbd {
    background: #eee;
    border-bottom: 3px solid #aaa;
    border-left: 3px solid #777;
    padding: 2px;
  }
`;

export const FormErrorMessage = styled.p`
  color: red;
  margin-top: 8px;
  font-size: 11px;
`;

export const FormLinksContainer = styled.div``;

export const ModalWrapper = styled.div`
  padding: 24px;
`;

export const AddLinkContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  button {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  button span {
    margin-left: 8px;
    //styleName: Link;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: right;
  }
`;

export const TitleWrapper = styled.div`
  max-width: 98%;
  text-align: center;
`;

export const ShareQRCodeModalInnerWrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  h1 {
    font-weight: bold;
    margin-top: 16px;
  }
  h2 {
    margin-bottom: 24px;
  }
`;

export const QRCodeLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const QRCodeWrapper = styled.div`
  background-color: green;
  width: 250;
  height: 250;
`;

export const QRCode = styled(QRCodeComponent)`
  height: auto;
  max-width: 100%;
  width: 100%;
`;
export const UserOrOrganizationWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: -30px;
`;

export const UserOrOrganizationInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Name = styled.div`
  max-width: 100%;
  margin-left: 12px;
  font-weight: bold;
`;

export const ChevronDownIconContainer = styled.div`
  margin-left: 10px;
  font-weight: bold;
  margin-top: -4px;
`;

export const ModalBody = styled(ChakraModalBody)`
  padding: 24px;
  h2,
  h3 {
    font-weight: bold;
  }

  h2,
  h3,
  em {
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 24px;
  }

  ol {
    list-style-type: none;
  }

  ol,
  ul {
    list-style-position: inside;
    counter-reset: item;
  }

  li {
    margin-bottom: 8px;
  }

  li li {
    margin-bottom: 2px;
  }

  ol li:before {
    content: counter(item, lower-alpha) ") "; /* Lowercase letters */
    counter-increment: item;
    font-weight: bold;
    margin-right: 5px; /* Optional margin for spacing */
  }
  ol li {
    margin-bottom: 8px;
  }
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
