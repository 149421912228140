import styled from 'styled-components';

import { SignUpWrapper } from '../Styles';

export const SignUpOnboardingWrapper = styled(SignUpWrapper)`
  .not-listed-field {
    .chakra-radio {
      display: flex;
      .chakra-radio__label {
        flex-grow: 1;
        user-select: all;
      }
    }
    .chakra-input {
      height: 20px;
      padding: 0;
    }
  }
  .box-submit-btns {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 1px 1px 6px rgba(64, 26, 15, 0.25);
    background: #fff;
    z-index: 1000;
    padding: 8px 24px;
  }
`;
