import styled from "styled-components";
import { devices } from "../../../Theme.css";

export const BackButtonWrapper = styled.div`
  @media ${devices.max_sm} {
    display: none;
  }
`;

export const AddNewOrganizationWrapper = styled.div`
  margin-top: 72px;
`;

export const PageTitle = styled.h1`
  color: #000;
  text-align: center;
  /* Headline 3 */
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const CardsLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 44px;

  button {
    margin-bottom: 24px;
  }

  @media ${devices.max_sm} {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    button {
      margin-bottom: 24px;
    }
  }
`;

export const AddOrganizationCard = styled.button`
  display: block;
  max-width: 250px;
  display: block;
  border-radius: 40px;
  background: #fff;
  box-shadow: 1px 1px 6px 0px rgba(64, 26, 15, 0.25);
  padding: 24px;

  .illustration-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
  }

  .description {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin-top: 28px;
  }
`;
