import React, { useEffect } from "react";
import { Spinner } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import {
  useLazyGetOrganizationDetailsQuery,
  useLazyGetUserByIdQuery,
} from "../../../../redux/reducers/apiSlice";
import FollowButton from "../../../Pages/Profile/FollowButton";
import { ProfileAvatar } from "../../ProfileAvatar/ProfileAvatar";
import { customConsole, truncate } from "../../../utils/helper";

const FollowingDataRow = ({ index, userId, accountType = "USER" }) => {
  const [getUserLazyQuery, { isLoading: loadingUser, data: userData }] =
    useLazyGetUserByIdQuery();
  const [
    getOrganizationDetails,
    { isLoading: loadingOrganization, data: organizationDetails },
  ] = useLazyGetOrganizationDetailsQuery();

  useEffect(() => {
    if (userId && accountType == "USER") {
      getUserLazyQuery(userId);
    } else {
      getOrganizationDetails(userId);
    }
  }, [userId]);

  if (loadingUser || loadingOrganization) return <Spinner color="white" />;

  customConsole("log", "following data row", userData || organizationDetails);

  if (accountType === "USER" && !userData) return null;
  if (accountType === "ORG" && !organizationDetails) return null;

  return (
    <li key={index} className="item">
      <div className="avatar">
        <Link
          to={
            accountType === "USER"
              ? `/users/${userId}`
              : `/organizations/${userId}`
          }
        >
          <ProfileAvatar
            name={
              accountType === "ORG"
                ? organizationDetails?.name
                : `${userData?.name.firstName} ${userData?.name.lastName}`
            }
            src={
              accountType === "USER"
                ? userData?.image?.url
                : accountType === "ORG"
                ? organizationDetails?.image?.url
                : undefined
            }
            width="48px"
            height="48px"
          />
        </Link>
      </div>
      <div className="box-name">
        <Link to={`/users/${userId}`}>
          <div className="lg">
            {accountType === "USER" &&
              truncate(
                `${userData?.name.firstName} ${userData?.name.lastName}`
              )}
            {accountType === "ORG" && truncate(`${organizationDetails?.name}`)}
          </div>
          <div className="md"></div>
        </Link>
      </div>
      <FollowButton variant="ghost" userId={userId} />
    </li>
  );
};

export default FollowingDataRow;
