import styled from "styled-components";
import { devices } from "../../Theme.css";

export const mentionStyle = {
  control: {
    backgroundColor: "#fff",
    fontSize: 14,
    fontWeight: "normal",
  },

  "&multiLine": {
    width: "100%",
    minHeight: "45px",
    maxWidth: "100%",

    control: {
      minHeight: 63,
      width: "100%",
      maxWidth: "100%",
    },
    highlighter: {
      padding: 9,
      border: "1px solid transparent",
    },
    input: {
      innerWidth: "100%",

      backgroundColor: "rgb(237, 237, 237)",
      borderRadius: "100px",
      padding: "12px 16px",
      // border-radius: 100px;
      // box-sizing: border-box;
      // resize: vertical;
      // outline: 0;
      resize: "none",
    },
  },

  "&singleLine": {
    width: "100%",
    display: "inline-block",

    highlighter: {
      padding: 1,
    },
    input: {
      maxWidth: "100%",
      padding: "12px 16px",
      borderRadius: "100px",
      backgroundColor: "rgb(237, 237, 237)",
      boxSizing: "border-box",
      whiteSpace: "nowrap" /* Prevent text wrapping */,
      overflow: "hidden",
    },
  },

  suggestions: {
    marginTop: 40,
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 14,
      maxHeight: "250px",
      overflow: "auto",
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#cee4e5",
      },
    },
  },
};

export const AddNewPostCardWrapper = styled.div`
  border-radius: 40px;
  background: #fff;
  box-shadow: 1px 1px 6px 0px rgba(64, 26, 15, 0.25);
  padding: 24px;

  .mention-input--singleLine {
    width: 100%;
    max-width: 100%;
    min-height: 45px;
    background-color: rgb(237, 237, 237);
    border-radius: 100px;
    resize: none;
  }

  .mention-input--multiLine {
    width: 100%;
    max-width: 100%;
    min-height: 45px;
    background-color: rgb(237, 237, 237);
    border-radius: 100px;
    box-sizing: border-box; /* Include padding and border in the textarea's width */
    resize: vertical;
    outline: 0;
    padding: 12px 16px;

    :placeholder {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0px;
      text-align: left;
    }

    :focused {
      border: none;
    }
  }

  .mention {
  }

  @media ${devices.max_sm} {
    box-shadow: none;
  }

  .close-img-preview {
    position: absolute;
    top: 8px;
    right: 8px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    padding: 4px;
  }

  input[type="file"] {
    display: none;
  }

  .img-container {
    position: relative;
    background: #ddd;
    width: 100%;
    height: 250px;
    overflow: hidden;
    border-radius: 24px;
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
`;

export const Picture = styled.div`
  min-width: 46px;
  width: 46px;
  height: 46px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 14px;

  img {
    display: block;
    width: 100%;
  }
`;

export const Input = styled.input.attrs((props) => ({
  ref: props.ref,
}))`
  display: block;
  background: hsla(0, 0%, 93%, 1);
  width: 100%;
  height: 45px;
  padding: 12px 16px;
  border-radius: 100px;

  :placeholder {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
  }
`;

export const InputFirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: -16px;
`;

export const InputSecondRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;

  button {
    margin-left: 24px;
  }
`;

export const AddImageButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ButtonLabel = styled.span`
  display: inline-block;
  margin-left: 8px;
`;

export const PostButton = styled.button`
  border-radius: 100px;
  border-radius: 100px;
  background-color: #ee5a29;
  padding: 8px 24px;
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140.5%; /* 22.48px */
  ${(props) =>
    props.disabled &&
    `
    background-color:#eee;
    color: #aaa;
  `}
`;
