import {
  Foundations,
  Grassroots,
  NonProfit,
  Corporation,
  SnapReact,
  FistReact,
  ClappingReact,
  HeartReact,
} from "../Assets/SvgIcons";

const COGNITO = {
  CONFIRM_SIGNUP_SUCCESS_RESPONSE: "SUCCESS",
};

const ORGANIZATION_CARD_NAME_LENGTH = 20;

export const addNewOrganizationsCardsData = [
  {
    illustration: Grassroots,
    title: "Grassroots",
    description:
      "A group of BIPOC people most impacted by a problem working to ignite social change",
  },
  {
    illustration: NonProfit,
    title: "Non-profit",
    description:
      "A charitable entity organized and operated to ignite social change",
  },
  {
    illustration: Foundations,
    title: "Foundation",
    description: "An organization that provides money to ignite social change",
  },
  {
    illustration: Corporation,
    title: "Corporation",
    description:
      "A company that provides money or resources to ignite social change",
  },
];

const INTERACTION_ICONS = {
  SNAPS: SnapReact,
  HEART: HeartReact,
  FIST: FistReact,
  HIGHFIVE: ClappingReact,
};

const TARGET_SYMBOLS = {
  DOLLARS: "$",
  NUMBERS: "",
  PERCENTAGES: "%",
};

export {
  COGNITO,
  ORGANIZATION_CARD_NAME_LENGTH,
  INTERACTION_ICONS,
  TARGET_SYMBOLS,
};

export const NUMBER_OF_LOGINS_BEFORE_HIDING_INTRO = 5;

export const VALID_PHOTO_EXTENSIONS = ".jpg, .jpeg, .png";

export enum POLICY_TYPES {
  ACCEPTABLE_USE = "acceptable-use-policy",
  PRIVACY = "privacy-policy",
  TERMS_AND_CONDITIONS = "terms-and-conditions",
}
