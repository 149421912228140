import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  OrganizationTabsWrapper,
  OrganizationTabsHeader,
  OrganizationTabsButtonSpacer,
  OrganizationTabsButtonWrapper,
  OrganizationTabsButton,
  ComponentWrapper,
  ComponentSeparator,
  Selection,
} from "./Styles";
import queryString from "query-string";

type OrganizationTabsProps<T> = {
  defaultSelectedTab: T;
  tabs: { label: T; component: React.JSX.Element }[];
  customTabLabelStyle?: string;
  customDividerStyle?: string;
  customTabsSpacing?: string;
};

export const Tabs = <T,>({
  tabs,
  defaultSelectedTab,
  customTabLabelStyle,
  customDividerStyle,
  customTabsSpacing,
}: OrganizationTabsProps<T>) => {
  const [selectedTab, setSelectedTab] = useState<T>(defaultSelectedTab);

  const location = useLocation();
  const navigate = useNavigate();

  const renderTabComponent = () => {
    const tab = tabs.find((tab) => tab.label === selectedTab);
    return tab?.component;
  };

  useEffect(() => {
    const queryStrings = queryString.parse(location.search);
    const tabSelected: unknown = queryStrings.selectedTab;
    if (tabSelected) {
      setSelectedTab(tabSelected as T);
    }
  }, [location.search]);

  return (
    <OrganizationTabsWrapper>
      <OrganizationTabsHeader customTabsSpacing={customTabsSpacing}>
        {tabs.map((tab) => (
          <OrganizationTabsButtonSpacer
            // @ts-ignore
            key={tab.label}
          >
            <OrganizationTabsButtonWrapper>
              <OrganizationTabsButton
                customTabLabelStyle={customTabLabelStyle}
                onClick={() => {
                  navigate(`${location.pathname}?selectedTab=${tab.label}`);
                }}
              >
                {/** @ts-ignore */}
                {tab.label}
              </OrganizationTabsButton>
              {selectedTab === tab.label && <Selection />}
            </OrganizationTabsButtonWrapper>
          </OrganizationTabsButtonSpacer>
        ))}
      </OrganizationTabsHeader>
      <ComponentWrapper>
        <ComponentSeparator customDividerStyle={customDividerStyle} />
        {renderTabComponent()}
      </ComponentWrapper>
    </OrganizationTabsWrapper>
  );
};
