import { useState } from "react";
import { InfoOutlineIcon } from "@chakra-ui/icons";

import styled from "styled-components";
import { devices } from "../../Theme.css";

const AppAccessForm = () => {
  const [accesskey, setAccessKey] = useState("");

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    // e.preventDefault();
    if (accesskey === "ignite_dev_2024!") {
      window.localStorage.setItem(`app_access`, "true");
      window.location.reload();
    }
  };

  return (
    <Container>
      <Card>
        <h1>Welcome to Ignite Dev!</h1>
        <p>
          This environment is reserved for developers and management team! If
          you're a regular user of ignite and you ended up here I'm sure you
          meant to go to{" "}
          <a href="https://ignite.wemeasure.org">ignite.wemeasure.org</a>
        </p>
        <p>
          If not! type the <span>access key</span> 🙂
        </p>
        <input
          onChange={(e) => setAccessKey(e.target.value)}
          value={accesskey}
        />
        <div className="tooltip">
          <div className="icon-container">
            <InfoOutlineIcon />
          </div>
          Access keys are managed by the developement team. If you forgot the
          access key or it has changed without your knowledge feel free to
          contact a developer directly via{" "}
          <a
            target="_blank"
            className="regular_no_underline"
            href="https://measure.slack.com"
          >
            Slack
          </a>{" "}
          or
          <a
            className="regular_no_underline"
            href={`mailto:ignite@wemeasure.org?subject=IGNITE DEV ACCESS KEY&body=${"What is the currently used access key for Ignite DEV?"}`}
          >
            {" "}
            request one
          </a>
        </div>
        <button onClick={handleSubmit}>Submit</button>
      </Card>
    </Container>
  );
};

export default AppAccessForm;

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  max-height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 24px;
  //styleName: Headline 2;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 29.26px;
  text-align: left;
  box-sizing: border-box;

  @media ${devices.max_md} {
    width: 100%;
    padding: 0;
  }
`;

export const Card = styled.div`
  width: 50%;
  box-shadow: 0px 0px 10px rgba(0.5, 0.5, 0.5, 0.5);
  padding: 24px;
  box-sizing: border-box;

  h1 {
    font-weight: 600;
    font-size: 24px;
  }

  p {
    margin-top: 16px;
  }

  a {
    color: #ee5a29;
    font-weight: bold;
  }
  a.regular_no_underline {
    text-decoration: none;
  }

  .access_key {
    font-weight: bold;
    color: #ee5a29;
  }

  .icon-container {
    margin-right: 8px;
    display: inline-block;
  }

  .tooltip {
    background: rgba(238, 90, 41, 0.3);
    border-radius: 5px;
    color: black;
    padding: 16px;
    margin-top: 16px;
  }

  input {
    width: 100;
    display: block;
    margin: 0;
    padding: 0;
    padding-left: 8px;
    height: 28px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin-top: 16px;
  }

  button {
    background-color: #ee5a29;
    box-sizing: border-box;
    width: 100%;
    height: 36px;
    border: none;
    color: white;
    font-weight: bold;
    margin-top: 16px;
    border-radius: 12px;
    cursor: pointer;
    border: 1px solid #ee5a29;

    &:hover {
      background-color: white;
      color: #ee5a29;
      border: 1px solid #ee5a29;
      transition: 0.2s;
    }
  }

  @media ${devices.max_md} {
    width: 100%;
  }
`;
