import styled from "styled-components";

export const Card = styled.div`
  min-width: 320px;
  width: max-content;
  height: 86px;
  background: #fff;
  color: #000;
  box-shadow: 1px 1px 6px rgba(64, 26, 15, 0.25);
  border-radius: 25px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const OrgPictureContainer = styled.div`
  width: 44px;
  height: 44px;
  background-color: #d9d9d9;
  border-radius: 50%;
  overflow: hidden;
`;

export const OrgPicture = styled.img`
  display: block;
  width: 100%;
  object-fit: contain;
`;

export const CardDetails = styled.div`
  margin-left: 10px;

  span {
    display: block;
    text-align: left;
  }
`;

export const OrgName = styled.span`
  font-weight: bold;
`;

export const OrgType = styled.span`
  font-size: 14px;
`;

export const OrgMembers = styled.span`
  color: #aaa;
  font-size: 14px;
`;
