import React, { useState } from "react";
import { Spinner } from "@chakra-ui/react";
import { SpinnerWrapper } from "./Styles";

const IgniteSurveyForm = () => {
  const [isIframeLoading, setIsIframeLoading] = useState(true);

  return (
    <div>
      {isIframeLoading && (
        <SpinnerWrapper>
          <div>
            <Spinner color="#ee5a29" />
          </div>
          <p style={{ color: "#ee5a29", fontWeight: "bold" }}>
            Loading survey...
          </p>
        </SpinnerWrapper>
      )}
      <iframe
        src={"https://measure.tfaforms.net/rest/forms/view/117"}
        title="Dynamic Form"
        width="100%"
        height="500px"
        onLoad={() => setIsIframeLoading(false)}
      />
    </div>
  );
};

export default IgniteSurveyForm;
