import styled from "styled-components";
import { devices } from "../../Theme.css";

export const UserNameSpan = styled.span`
  font-weight: 600;
  font-size: 14px;
  color: black;
`;
export const SkeletonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;
export const BoxNameTitleContainer = styled.div`
  width: 100%;
 `

/** Mobile */

export const MobileHeader = styled.div`
  @media ${devices.max_sm} {
    display: initial;
  }

  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: ${({ theme }) => theme.height.header};
  width: 100%;
  background: #ffffff;
  box-shadow: 1px 1px 6px rgb(64 26 15 / 25%);
  z-index: ${({ theme }) => theme.zIndex.header};

  .mobile-logo {
    width: 10%;

    img {
      margin-left: 5%;
    }
  }
  .page-title {
    width: fit-content;
    /* width: 80%; */
    color: #000;
    text-align: center;

    /* Headline 5 */
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140.5%; /* 19.67px */
  }

  .search-button {
    width: 10%;
    display: flex;
    flex-direction: row;
    align-items: center;

    button:first-child {
      margin-right: 5%;
    }
  }

  .hamburger {
    width: 100%;

    button {
      padding: 12px;
      margin-left: 15px;
    }
  }
`;

export const MobileHeaderLayout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
`;

export const MobileDetailsViewLayout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
`;

export const AddMemberText = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140.5%; /* 19.67px */
  color: #000; /* 19.67px */
  margin-left: 5px;
`;

export const AddMemberButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ProfileImage = styled.img`
  height: 50px;
  max-height: 50px;
  max-width: 50px;
  width: 50px;
  border-radius: 100%;
  object-fit: cover;
`;

export const HeaderWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100px;
  background-color: white;
  top: 0px;
  z-index: 999;
  border-radius: 0px 0px 40px 40px;
  box-shadow: 1px 1px 6px 0px #401a0f40;

  /** layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 32px;
  padding-right: 32px;

  .logo-container {
    width: 200px;
    img {
      width: 100%;
    }
  }

  .column {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .first-column {
  }

  .second-column {
    justify-content: flex-end;
  }

  .input-and-search-results-container {
    width: 50%;
  }

  .profile-container {
    width: 10%;
    margin-left: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    svg.bell {
      width: 25px;
      height: 25px;
    }

    @media ${devices.md} {
      .box-notification svg {
        margin-right: 10px;
      }
    }

    @media ${devices.max_sm} {
      width: 30%;
    }
  }

  .hamburger-menu {
    display: none;
  }

  @media ${devices.max_sm} {
    .seach-result {
      position: absolute;
      top: 0px;
      left: -500px;
    }

    .hamburger-menu {
      display: block;
    }
    .profile-menu {
      display: none;
    }

    .second-column {
      width: 80%;
      padding-left: 8px;
      justify-content: flex-end;
    }

    .input-and-search-results-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .first-column {
      width: 25%;
    }

    padding-left: 8px;
    padding-right: 8px;
    border-radius: 0px;

    .search-loading {
      position: absolute;
      border-radius: 26px;
      top: 500px;
      left: 0px;
      width: 100%;
    }
  }
`;

export const SearchResultStyle = styled.div`
  position: absolute;
  border-radius: 40px;
  box-shadow: 1px 1px 6px 0px #401a0f40;
  background-color: white;

  @media ${devices.max_sm} {
    width: 100vw;
    top: 100px;
    left: 0px;
    overflow: scroll;
    border-radius: 0px;
  }
`;

export const Input = styled.div<{ isToggled?: boolean }>`
  width: 100%;
  border-radius: 100px;
  overflow: hidden;
  background-color: #eee;
  display: flex;
  flex-direction: row;
  align-items: center;

  .search-icon {
    transform: translateY(10%);
  }

  .icon-container {
    padding-left: 16px;
    padding-right: 16px;
  }

  .input {
    width: 100%;
    height: 100%;
    height: 40px;
    padding-left: 12px;
    background: transparent;
    outline: none;
  }

  @media ${devices.max_sm} {
    ${(props) =>
      props.isToggled
        ? `
       width: 100%;
       padding-left: 10px;
       padding-right: 10px;
      `
        : `
        width: 16px;
        padding-left: 0px;
        padding-right: 0px;
        background-color: white;
      `}
    transition: 0.5s;
    .icon-container {
      padding-left: 0px;
      padding-right: 0px;
    }

    .second-column {
      display: flex;
      justify-content: space-around;
    }
  }
`;

export const Menu = styled.div`
  /* width: 400px;
  height: 838px; */
  position: relative;
  overflow: hidden;
  overflow: scroll;
  height: max-content;
  max-height: 50vh;
`;

export const MenuList = styled.ul`
  background-color: white;
`;

export const MenuItem = styled.li`
  border-bottom: 1px solid #eee;
  align-items: center;
  padding-right: 24px;
  padding-left: 24px;
  .inner-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 24px;
  }

  :hover {
    background-color: #eee;
  }
`;

export const NotificationDropdown = styled(MenuList)`
  svg.icon {
    display: none;
    width: 10px;
    height: 10px;
  }

  &.notification-dropdown {
    max-height: 355px;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 1px 1px 6px rgba(64, 26, 15, 0.25);
    border-radius: 40px;
    border: 0;
    padding: 0;
    .no-notify {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      height: 300px;
      text-align: center;
      &__bell {
        width: 144px;
        height: 144px;
        opacity: 0.3;
      }
      &__text {
        margin-top: 32px;
        font-weight: 700;
        font-size: 18px;
      }
      &__text_sub {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  .chakra-menu__menuitem {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
    background: #fff;
    border-bottom: 1px solid #d1d1d1;
    padding: 16px 35px 16px 24px;
    &:hover {
      background: #e2e1e1 !important;
    }
    &.unreading {
      background: #eee;
    }
    &:last-child {
      border-bottom: 0;
    }
    .box-txt {
      color: #000;
      .txt {
        font-weight: 500;
        font-size: 14px;
        line-height: calc(17 / 14);
      }
      .date {
        font-size: 12px;
      }
    }
    .box-icon {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translate(0, -50%);
      font-size: 22px;
    }
  }
`;
export const TextBadgeContainer = styled.div<{
  isVisible?: boolean | 0 | undefined;
}>`
  display: ${(props) => (props.isVisible ? "block" : "none")};
  position: relative;
  bottom: 40px;
  left: 10px;
`;
