import React from "react";
import { AddMemberIcon, TwoUsers } from "../../../Assets/SvgIcons";
import {
  MembersCard,
  MembersHeader,
  MembersHeaderGroup,
  MemberAmount,
  MemberAmountLabel,
  AddMemberGroup,
  AddMemberButton,
  MembersTable,
  AddMemberText,
} from "./Styles";
import { MembersProps } from "../../../../types";
import MemberTableRow from "./MemberTableRow";

export const Members: React.FC<MembersProps> = ({
  members = [],
  onRemoveMember,
  onInviteMember,
  isAdmin,
}) => (
  <MembersCard>
    <MembersHeader>
      <MembersHeaderGroup>
        <MemberAmount>
          <TwoUsers color="#EF6B2D" />
          <MemberAmountLabel>{members.length} members</MemberAmountLabel>
        </MemberAmount>
      </MembersHeaderGroup>
      <AddMemberGroup>
        <AddMemberButton disabled={!isAdmin} onClick={() => onInviteMember()}>
          <AddMemberIcon color={isAdmin ? "black" : "#aaa"} />
          <AddMemberText color={isAdmin ? "black" : "#aaa"}>
            Invite member
          </AddMemberText>
        </AddMemberButton>
      </AddMemberGroup>
    </MembersHeader>
    <MembersTable>
      <thead>
        <th></th>
        <th>Name</th>
        <th>Email</th>
        <th>Role</th>
        {isAdmin && <th>Actions</th>}
      </thead>
      {members.map((member) => (
        <MemberTableRow
          onRemoveMember={onRemoveMember}
          key={member.id}
          member={member}
        />
      ))}
    </MembersTable>
  </MembersCard>
);
