import React from "react";
import { Menu, MenuItem, MenuList, SkeletonContainer } from "./Styles";
import { SkeletonCircle, SkeletonText } from "@chakra-ui/react";
import useScreenType from "react-screentype-hook";

const SearchSkeletonResults = () => {
  const { isMobile, isTablet } = useScreenType();

  const getMenuWidth = () => {
    if (isMobile) {
      return "768px";
    } else if (isTablet) {
      return "800px";
    }
    return "auto";
  };

  return (
    <>
      {new Array(5).fill({}).map((_, key) => (
        <MenuItem key={key}>
          <div className="inner-wrapper">
            <SkeletonContainer>
              <SkeletonCircle size="50" flex="0 0 50px" />
              <div style={{ width: "100%" }}>
                <SkeletonText
                  marginLeft="20px"
                  noOfLines={1}
                  spacing="1"
                  skeletonHeight="4"
                  marginBottom="8px"
                />
                <SkeletonText noOfLines={1} spacing="1" skeletonHeight="4" />
              </div>
            </SkeletonContainer>
          </div>
        </MenuItem>
      ))}
    </>
  );
};

export default SearchSkeletonResults;
