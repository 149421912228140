import React from "react";
import { BackIcon } from "../../Assets/SvgIcons";
import { BackButtonWrapper } from "./Styles";
import { useNavigate } from "react-router-dom";

export const Back = (props) => {
  const navigate = useNavigate();

  return (
    <BackButtonWrapper>
      <BackIcon />
      <button onClick={() => (props.onClick ? props.onClick() : navigate(-1))}>
        Back
      </button>
    </BackButtonWrapper>
  );
};
