import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router";
import PageNotFound from "./Components/PageNotFound/PageNotFound";
import Talk from "talkjs";
import { Session as TalkJSSession } from "@talkjs/react";
import DashboardLayout from "./Layout/DashboardLayout/DashboardLayout";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Profile from "./Pages/Profile/Profile";
import Messages from "./Pages/Messages/Messages";
import OrganizationDetailsPage from "./Pages/Organizations/OrganizationDetailsPage";
import MyOrganizations from "./Pages/Organizations/MyOrganizations";
import SignUpOnboarding from "./Pages/SignUp/SignUpOnboarding/SignUpOnboarding";
import { useLazyGetProfileQuery } from "../redux/reducers/apiSlice";
import OrganizationProfile from "./Pages/Organizations/OrganizationProfile/OrganizationProfile";
import { AddNewOrganization } from "./Pages/Organizations/AddNewOrganization/AddNewOrganization";
import AddNewOrganizationForm from "./Pages/Organizations/AddNewOrganizationForm/AddNewOrganizationForm";
import { EditOrganizationForm } from "./Pages/Organizations/EditOrganizationForm";
import FullPageNexusLogoAnimation from "./Reusable/FullPageNexusLogoAnimation/FullPageNexusLogoAnimation";
import GoalsManagement from "./Pages/Goals/GoalsManagement";
import { customConsole } from "./utils/helper";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import PostDetails from "./Pages/Post/PostDetails";
import ViewAllSuggestions from "./Pages/ViewAllSuggestions/ViewAllSuggestions";
import * as Sentry from "@sentry/react";

// @ts-ignore
const TALKJS_APP_ID = import.meta.env.VITE_TALKJS_APP_ID;

const AppRoutes = (props) => {
  const dispatch = useDispatch();
  const { setUser, user } = props;
  const [userData, setUserData] = useState<any>(null);
  const [isSocialLogin, setIsSocialLogin] = useState<boolean>(false);
  const [amountUnreads, setAmountUnreads] = useState(0);

  const [
    getProfile,
    {
      isLoading: profileDataIsLoading,
      data: profileData,
      isError: profileDataError,
    },
  ] = useLazyGetProfileQuery();

  useEffect(() => {
    getProfileFn();
  }, []);

  const onProfileError = async () => {
    window.localStorage.clear();
    Auth.signOut();
    await setUser(null);
  };

  useEffect(() => {
    if (profileDataError) {
      onProfileError();
    }
  }, [profileDataError]);

  const getProfileFn = async () => {
    try {
      await getProfile().unwrap();
    } catch (e) {
      Sentry.captureException(e);
      customConsole("log", "Error", e);
    }
  };

  if (profileDataError) {
    return <PageNotFound />;
  }

  if (profileDataIsLoading) {
    return <FullPageNexusLogoAnimation />;
  }

  const hasFinishedOnboarding = profileData && profileData?.onboarded;

  if (hasFinishedOnboarding) {
    return (
      <TalkJSSession
        onUnreadsChange={(unreads) => {
          const unreadMessageCount = unreads.reduce(
            // @ts-ignore
            (acc, current) => acc + current.unreadMessageCount,
            0
          );
          setAmountUnreads(unreadMessageCount);
        }}
        appId={TALKJS_APP_ID}
        syncUser={() =>
          new Talk.User({
            id: profileData.id,
            name: `${profileData.name.firstName} ${profileData.name.lastName}`,
            photoUrl: profileData.image?.url,
            email: profileData.email,
            role: "default",
          })
        }
      >
        <Routes>
          <Route
            path=""
            element={
              <DashboardLayout
                amountUnreads={amountUnreads}
                setUser={setUser}
              />
            }
          >
            <Route path="/" element={<Dashboard />} />
            <Route path="/users/:id" element={<Profile />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/messages/:userId" element={<Messages />} />
            <Route path="/my-organizations" element={<MyOrganizations />} />
            <Route
              path="/organizations/:organizationId/sparks/:sparkName/goals"
              element={<GoalsManagement />}
            />
            <Route
              path="/organizations/:organizationId"
              element={<OrganizationDetailsPage />}
            />
            <Route path="/posts/:postId" element={<PostDetails />} />
            <Route
              path="/posts/:postId/interactions/:interactionId"
              element={<PostDetails />}
            />
            <Route path="/organizations/new" element={<AddNewOrganization />} />
            <Route
              path="/organizations/:organizationId/profile"
              element={<OrganizationProfile />}
            />
          </Route>
          <Route
            path="/organizations/new/form"
            element={<AddNewOrganizationForm />}
          />
          <Route
            path="/organizations/:organizationId/edit"
            element={<EditOrganizationForm />}
          />
          <Route path="/suggestions" element={<ViewAllSuggestions />} />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </TalkJSSession>
    );
  }

  return (
    <Routes>
      {userData === undefined ? (
        <Route path="/loading" element={<FullPageNexusLogoAnimation />} />
      ) : (
        <Route
          path="/"
          element={<SignUpOnboarding isSocialLogin={isSocialLogin} />}
        />
      )}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default AppRoutes;
