import React from 'react';
import { Spinner } from '@chakra-ui/react';

import { CommonLoading } from './Styles';

export const Loading = ({ spin, children, restSpinner, ...rest }: any) => {
  return (
    <CommonLoading className="common-loading" {...rest}>
      {spin && (
        <div className="common-loading-spin">
          <Spinner color="primary.500" className="common-loading-spinner" {...restSpinner} />
        </div>
      )}

      <div className={`common-loading-container ${spin ? 'common-loading-blur' : ''}`}>{children}</div>
    </CommonLoading>
  );
};
