import React, { useState } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { Form, Formik } from "formik";
import { Box, Button, Flex, Text, Stack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import StepSpark from "../../../Components/StepSpark/StepSpark";
import { SignUpOnboardingWrapper } from "./styles";
import Avatar from "../../../Components/Profile/Avatar/Avatar";
import StepGeneralInfo from "./StepGeneralInfo";
import { MESSAGE } from "../../../Constants/Message";
import {
  useAddOrganizationMutation,
  useUpdateOrganizationMutation,
  useUpdateOrganizationProfilePictureMutation,
} from "../../../../redux/reducers/apiSlice";
import {
  replaceNotListedLabelWithValue,
  generateAddOrganizationInitialValues,
} from "../../../utils/helper";
import { addNewOrganizationFormSchema } from "./schema";
import FullPageNexusLogoAnimation from "../../../Reusable/FullPageNexusLogoAnimation/FullPageNexusLogoAnimation";
import { disableAddOrEditFormButton as disableButton } from "../../../utils/helper";
import { customConsole } from "../../../utils/helper";
import * as Sentry from "@sentry/react";

const AddNewOrganizationForm = (props) => {
  const navigate = useNavigate();

  // location params
  const location = useLocation();
  const type = location?.state?.type;
  const editMode = !!props.organization;
  const organizationData = props.organization;

  // state
  const [organizationProfilePictureFile, setOrganizationProfilePictureFile] =
    useState<File | undefined>(undefined);
  const [addOrganization, { isLoading: addOrganizationLoading }] =
    useAddOrganizationMutation();
  const [updateOrganization, { isLoading: updateOrganizationLoading }] =
    useUpdateOrganizationMutation();
  const [
    updateOrganizationProfilePicture,
    { isLoading: updateOrganizationProfilePictureLoading },
  ] = useUpdateOrganizationProfilePictureMutation();

  const handleFinish = async (data) => {
    const finalOrganizationData = { ...data };
    const communityServedNotListed =
      finalOrganizationData.communityServedNotListed;
    const labelNotListed = finalOrganizationData.labelNotListed;
    const careModelCertified =
      finalOrganizationData.orgFields.careModelCertified;

    const communitiesServed = replaceNotListedLabelWithValue(
      finalOrganizationData.communitiesServed,
      communityServedNotListed
    );

    const labels = replaceNotListedLabelWithValue(
      finalOrganizationData.labels,
      labelNotListed
    );

    finalOrganizationData.communitiesServed = communitiesServed;
    finalOrganizationData.labels = labels;
    finalOrganizationData.orgFields.careModelCertified =
      careModelCertified === "true" ? true : false;
    delete finalOrganizationData.labelNotListed;
    delete finalOrganizationData.communityServedNotListed;
    delete finalOrganizationData.step;

    try {
      const call = editMode ? updateOrganization : addOrganization;

      const payload = editMode
        ? {
            orgId: organizationData?.id,
            orgData: finalOrganizationData,
          }
        : finalOrganizationData;

      const organization = await call(payload).unwrap();

      if (organizationProfilePictureFile) {
        const formData = new FormData();
        formData.append(
          "photo",
          organizationProfilePictureFile as string | Blob
        );

        await updateOrganizationProfilePicture({
          bodyFormData: formData,
          orgId: organization.id,
        });
      }
      toast.success(
        `You successfully ${editMode ? "edited" : "created"} an organization`
      );
      navigate("/my-organizations");
    } catch (e) {
      Sentry.captureException(e);
      toast.error(MESSAGE.SYSTEM_ERROR);
    }
  };

  if (
    addOrganizationLoading ||
    updateOrganizationProfilePictureLoading ||
    updateOrganizationLoading
  ) {
    return <FullPageNexusLogoAnimation />;
  }

  return (
    <Formik
      validationSchema={addNewOrganizationFormSchema}
      initialValues={generateAddOrganizationInitialValues(
        type,
        editMode,
        organizationData
      )}
      onSubmit={async (data, handlers) => {
        handlers.setSubmitting(true);
        handleFinish(data);
        handlers.validateForm(data);
        handlers.setSubmitting(false);
      }}
    >
      {(formik) => {
        return (
          <SignUpOnboardingWrapper className="sign-up-onboarding-wrapper">
            <div className="col-logo bg-light-gradient">
              <div className="box-inner">
                <img
                  src="/images/logo-4.png"
                  alt="Measure"
                  className="img-logo mx-auto"
                />

                <Text
                  maxWidth="338px"
                  fontSize={[18, null, null, 20, 24]}
                  fontWeight="semibold"
                  color="#000"
                  margin="88px auto 0"
                >
                  {formik.values.step === "GENERAL_INFO" && (
                    <>
                      Did you know?
                      <br />
                      <br />
                      In 2020, Measure surveyed over 100 Black-led organizations
                      in Austin, TX and 79% shared that they didn’t have systems
                      for collecting data.
                      <br />
                      <br />
                      We're here to change that.
                    </>
                  )}

                  {formik.values.step === "SPARK" && (
                    <>
                      What's your spark?
                      <br />
                      <br />
                      At Measure, we identify your sparks as the causes that
                      motivate you to ignite change.
                    </>
                  )}
                </Text>
              </div>
            </div>

            <div className="col-form">
              <Form onSubmit={formik.handleSubmit}>
                <Box maxWidth={866} margin="auto">
                  {formik.values.step === "GENERAL_INFO" && (
                    <>
                      <Text
                        fontWeight="semibold"
                        color="#000"
                        textAlign="center"
                        mb="24px"
                      >
                        {formik.values.step === "GENERAL_INFO" && (
                          <>
                            {!editMode ? "Add" : "Edit"}{" "}
                            {!editMode ? "a" : "your"} {formik.values.type}{" "}
                            organization.
                          </>
                        )}
                        {/* @ts-ignore */}
                        {formik.values.step === "SPARK" && (
                          <>
                            Select 3 sparks for your organization to get
                            started.
                          </>
                        )}
                      </Text>
                      <Flex justifyContent="center" mb="32px">
                        <Avatar
                          defaultImage={formik.values.image?.url}
                          onFile={setOrganizationProfilePictureFile}
                        />
                      </Flex>
                      <StepGeneralInfo editMode={editMode} formik={formik} />
                    </>
                  )}
                  {formik.values.step === "SPARK" && (
                    <StepSpark formik={formik} />
                  )}
                </Box>

                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  spacing="10px"
                  className="box-submit-btns"
                >
                  {formik.values.step === "GENERAL_INFO" && (
                    <>
                      <Button
                        variant="outline"
                        loadingText="Sign in"
                        className="btn-outline-dark"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>

                      <Button
                        colorScheme="primary"
                        minWidth="160px"
                        loadingText="Next"
                        isLoading={false}
                        isDisabled={disableButton(
                          "GENERAL_INFO",
                          formik,
                          formik.values.type
                        )}
                        onClick={() => {
                          formik.setFieldValue("step", "SPARK");
                        }}
                        className="gray-for-disabled"
                      >
                        Next
                      </Button>
                    </>
                  )}

                  {formik.values.step === "SPARK" && (
                    <>
                      <Button
                        variant="outline"
                        className="btn-outline-dark"
                        onClick={() =>
                          formik.setFieldValue("step", "GENERAL_INFO")
                        }
                      >
                        Back
                      </Button>

                      <Button
                        type="submit"
                        colorScheme="primary"
                        minWidth="160px"
                        loadingText="Finish"
                        isLoading={addOrganizationLoading}
                        isDisabled={disableButton(
                          "SPARK",
                          formik,
                          formik.values.type
                        )}
                        className="gray-for-disabled"
                      >
                        Finish
                      </Button>
                    </>
                  )}
                </Stack>
              </Form>
            </div>
          </SignUpOnboardingWrapper>
        );
      }}
    </Formik>
  );
};

export default AddNewOrganizationForm;
