import styled from "styled-components";
import { devices } from "../../Theme.css";

export const OrganizationDetailsCardWrapper = styled.div`
  border-radius: 40px;
  background: #fff;
  /* dropshadow */
  box-shadow: 1px 1px 6px 0px rgba(64, 26, 15, 0.25);
  padding: 24px;

  @media ${devices.max_sm} {
    box-shadow: none;
    border-radius: 0;
    width: 100vw;
    padding: 12px;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CardHeaderGroup1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: max-content;
`;

export const CardHeaderGroup2 = styled.div`
  display: flex;
  flex-direction: row;
  height: max-content;
  align-items: flex-start;
`;

export const FloatingDropdownLayout = styled.div`
  position: absolute;
  right: 120px;
  z-index: 1000;

  @media ${devices.max_sm} {
    right: 30px;
  }
`;

export const CardBody = styled.div`
  @media ${devices.max_sm} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const CardBodySubGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  //styleName: Label;
`;

export const CardBodySubGroupIcon = styled.div`
  margin-right: 16px;
  @media ${devices.max_sm} {
    margin-right: 4px;
  }
`;

export const CardBodyText = styled.div`
  color: #000;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media ${devices.max_sm} {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
  }
`;

export const OrganizationImageWrapper = styled.div`
  margin-right: 16px;
`;

export const CardHeaderSubGroup2 = styled.div``;

export const BadgeWrapper = styled.div`
  margin-right: 24px;

  @media ${devices.max_sm} {
    width: 50px;
    height: 50px;
  }
`;

export const CardSeparator = styled.div`
  height: 2px;
  background-color: #eee;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const OrganizationImageContainer = styled.div`
  width: 60px;
  height: 60px;
  background-color: #d9d9d9;
  border-radius: 50%;
  overflow: hidden;
`;

export const BadgeContainer = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #d9d9d9;
  overflow: hidden;
`;

export const BadgeMask = styled.div`
  -webkit-mask-image: url("../../Assets/badge_mask.png");
  mask-image: url("../../Assets/badge_mask.png");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
`;

export const CardTitle = styled.div`
  color: #000;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const CardCaption = styled.div`
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const MoreButton = styled.button`
  background: transparent;
`;
