import React, { useEffect, useState } from "react";
//@ts-ignore
import { Formik } from "formik";
import { Box, Button, Flex, useToast } from "@chakra-ui/react";
import hotToast from "react-hot-toast";
import _ from "lodash";
import { ModalBody, ModalFooter } from "@chakra-ui/modal";
import queryString from "query-string";

import DeactivateUserModal from "../../Modal/DeactivateUserModal";
import { Modal } from "../../Modal/Modal";
import StepGeneralInfo from "../../../Pages/SignUp/SignUpOnboarding/StepGeneralInfo";
import StepSpark from "../../../Components/StepSpark/StepSpark";
import Avatar from "../Avatar/Avatar";
import { Loading } from "../../../Reusable";
import SettingsModal from "../../../Layout/Header/SettingsModal/SettingsModal";
import { MESSAGE } from "../../../Constants/Message";
import {
  notListedInputFieldHasError,
  updateFieldNotListedForSubmission,
  updateFieldNotListedFromData,
} from "../../../utils/onboarding";
import {
  useUpdateUserMutation,
  useUpdateProfilePictureMutation,
  useGetProfileQuery,
} from "../../../../redux/reducers/apiSlice";
import { customConsole } from "../../../utils/helper";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";

const ProfileModal = ({ isOpen, onClose, step, title = "Edit my profile" }) => {
  const [updateUser, { isLoading: updateUserLoading }] =
    useUpdateUserMutation();
  const [showDeactivateAccountModal, setShowDeactivateAccountModal] =
    useState(false);
  const {
    data: currentUserProfileData,
    isLoading: currentUserProfileLoading,
    isSuccess,
  } = useGetProfileQuery();
  const navigate = useNavigate();

  const [updateProfilePicture] = useUpdateProfilePictureMutation();

  useEffect(() => {
    const queryStrings = queryString.parse(location.search);
    const urlHasDeactivateUser = queryStrings.deactivateUser;
    if (urlHasDeactivateUser) {
      setShowDeactivateAccountModal(true);
    }
  }, [location.search]);

  const toast = useToast();
  const [isOpenSettingsModal, setIsOpenSettingsModal] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: {
      lastName: "",
      firstName: "",
    },
    title: "",
    location: {
      zip: "",
      city: "",
      state: "",
      address1: "",
      address2: "",
    },
    gender: "",
    genderText: "",
    pronouns: "",
    pronounsText: "",
    ethnicity: "",
    ethnicityText: "",
    sparks: [],
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isSuccess && currentUserProfileData) {
      let body: any = {
        name: currentUserProfileData?.name,
        title: currentUserProfileData?.title
          ? currentUserProfileData?.title
          : "",
        location: currentUserProfileData?.location,
        ...updateFieldNotListedFromData(
          "gender",
          currentUserProfileData?.gender
        ),
        ...updateFieldNotListedFromData(
          "pronouns",
          currentUserProfileData?.pronouns
        ),
        ...updateFieldNotListedFromData(
          "ethnicity",
          currentUserProfileData?.ethnicity
        ),
        sparks: currentUserProfileData?.sparks,
      };
      setInitialValues(body);
      setLoading(false);
    }
  }, [currentUserProfileData]);

  const handleFinish = async (data: any) => {
    const notListedInputEmpty =
      notListedInputFieldHasError(data, "gender", hotToast) ||
      notListedInputFieldHasError(data, "ethnicity", hotToast) ||
      notListedInputFieldHasError(data, "pronouns", hotToast);

    if (notListedInputEmpty) {
      return;
    }

    data.gender = updateFieldNotListedForSubmission(
      data.gender,
      data.genderText
    );
    data.pronouns = updateFieldNotListedForSubmission(
      data.pronouns,
      data.pronounsText
    );
    data.ethnicity = updateFieldNotListedForSubmission(
      data.ethnicity,
      data.ethnicityText
    );
    data.genderText = undefined;
    data.pronounsText = undefined;
    data.ethnicityText = undefined;

    try {
      let onBoarding = await updateUser(data).unwrap();
      if (onBoarding) {
        setLoading(false);
        onClose();
        toast({
          title: `Your profile has been updated`,
          position: "bottom-right",
          duration: 2000,
          status: "success",
          isClosable: true,
        });
      } else {
        hotToast.error(MESSAGE.SYSTEM_ERROR);
      }
    } catch (error) {
      Sentry.captureException(error);
      setLoading(false);
      hotToast.error(MESSAGE.SYSTEM_ERROR);
      customConsole("log", error);
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="2xl"
        isCentered
        scrollBehavior="inside"
        modalTitle={title}
      >
        <Loading spin={loading || updateUserLoading}>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={async (data, handlers) => {
              handlers.setSubmitting(true);
              handlers.validateForm(data);
              handleFinish(data);
              handlers.setSubmitting(false);
            }}
          >
            {(formik) => {
              function disableButton(step: string): boolean {
                if (step == "GENERAL_INFO") {
                  return _.isEqual(formik.values, currentUserProfileData);
                }
                if (step == "SPARK") {
                  if (
                    formik.values.sparks &&
                    formik.values.sparks.length == 3
                  ) {
                    return _.isEqual(
                      formik.values.sparks,
                      currentUserProfileData?.sparks
                    );
                  }
                }
                return true;
              }
              return (
                <>
                  <ModalBody maxHeight="calc(100vh - 179px)">
                    {step == "GENERAL_INFO" && (
                      <Flex justifyContent="center" mb="32px">
                        <Avatar
                          defaultImage={currentUserProfileData?.image?.url}
                          // @ts-ignore
                          updateImage={updateProfilePicture}
                          id={currentUserProfileData?.id}
                        />
                      </Flex>
                    )}
                    <Box maxWidth={866} margin="auto">
                      {step === "GENERAL_INFO" && (
                        <StepGeneralInfo formik={formik} />
                      )}
                      {step === "SPARK" && <StepSpark formik={formik} />}
                    </Box>
                    <Flex justifyContent="center" mb="32px">
                      <Box maxWidth={866} margin="auto">
                        <Button
                          onClick={() => setShowDeactivateAccountModal(true)}
                        >
                          Deactivate my account
                        </Button>
                      </Box>
                    </Flex>
                  </ModalBody>
                  <ModalFooter
                    justifyContent="space-between"
                    boxShadow="1px 1px 6px rgba(64, 26, 15, 0.25)"
                  >
                    <Box marginLeft="16px">
                      <Button
                        colorScheme="primary"
                        variant="link"
                        onClick={() => setIsOpenSettingsModal(true)}
                      >
                        Open privacy settings
                      </Button>
                    </Box>
                    <div>
                      <Button
                        variant="outline"
                        loadingText="Sign in"
                        className="btn-outline-dark"
                        onClick={() => onClose()}
                      >
                        Cancel
                      </Button>

                      <Button
                        onClick={() => handleFinish(formik.values)}
                        colorScheme="primary"
                        loadingText="Saving..."
                        isLoading={false}
                        isDisabled={disableButton(step)}
                        marginLeft="10px"
                        className="gray-for-disabled"
                      >
                        Save
                      </Button>
                    </div>
                  </ModalFooter>
                </>
              );
            }}
          </Formik>
        </Loading>
      </Modal>
      {isOpenSettingsModal && (
        <SettingsModal
          isOpen={isOpenSettingsModal}
          userId={currentUserProfileData?.id}
          onClose={() => {
            setIsOpenSettingsModal(false);
          }}
          refetchSetting={null}
          isPushNotification={null}
        />
      )}
      {showDeactivateAccountModal && (
        <DeactivateUserModal
          isOpen={showDeactivateAccountModal}
          onClose={() => {
            setShowDeactivateAccountModal(false);
            navigate(`${location.pathname}?editProfile=true`);
          }}
        />
      )}
    </>
  );
};

export default ProfileModal;
