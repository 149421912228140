import React, { useState } from "react";
import {
  ModalCloseButton,
  ModalBody,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  ModalOverlay,
  Button,
  ModalHeader,
  ModalFooter,
  useToast,
} from "@chakra-ui/react";
import { Modal } from "../../../Components/Modal/Modal";
import { Formik } from "formik";
import TabPrivacy from "./TabPrivacy";
import { SettingsModalContent } from "./Styles";
import { Loading } from "../../../Reusable";
import { MESSAGE } from "../../../Constants/Message";
import {
  useGetProfileQuery,
  useUpdateUserMutation,
} from "../../../../redux/reducers/apiSlice";
import { SpinnerLoading } from "../../../Components/SpinnerLoading/SpinnerLoading";
import * as Sentry from "@sentry/react";

type Settings = {
  genderVisibility: boolean;
  emailVisibility: boolean;
  locationVisiblity: boolean;
  pronounsVisibility: boolean;
};

const SettingsModal = ({ isOpen, onClose, userId, ...rest }) => {
  const { data: currentProfileData, isLoading: currentProfileDataLoading } =
    useGetProfileQuery();
  const [updateProfile, { isLoading: updateProfileLoading }] =
    useUpdateUserMutation();

  const [initialValues, setInitialValues] = useState<Settings>({
    genderVisibility: false,
    emailVisibility: false,
    locationVisiblity: false,
    pronounsVisibility: false,
  });

  const toast = useToast();

  const handleFinish = async (preferencesData: Settings) => {
    try {
      await updateProfile({
        preferences: {
          ...preferencesData,
        },
      }).unwrap();

      onClose(false);
    } catch (e) {
      Sentry.captureException(e);
      console.error("SettingsUpdateError", e);
      toast({
        title: MESSAGE.SYSTEM_ERROR,
        position: "bottom-right",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const loading =
    updateProfileLoading ||
    currentProfileDataLoading ||
    !currentProfileData?.preferences;

  if (loading) return <SpinnerLoading />;

  return (
    <Modal
      isOpen={isOpen}
      isCentered
      scrollBehavior="inside"
      onClose={onClose}
      {...rest}
    >
      <SettingsModalContent
        maxHeight="100%"
        maxWidth="1023px"
        margin="0"
        className="common-modal-content"
      >
        <Loading spin={loading}>
          <Formik
            initialValues={currentProfileData?.preferences || initialValues}
            enableReinitialize
            onSubmit={async (data, handlers) => {}}
          >
            {(formik) => {
              return (
                <>
                  <ModalCloseButton />
                  <ModalHeader>Settings</ModalHeader>
                  <ModalBody maxHeight="calc(100vh - 179px)">
                    <Tabs className="common-tabs-2">
                      <TabList>
                        <Tab>Privacy</Tab>
                      </TabList>
                      <TabPanels>
                        <TabPanel>
                          <TabPrivacy formik={formik} />
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      variant="outline"
                      className="btn-outline-dark"
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => handleFinish(formik.values)}
                      colorScheme="primary"
                      loadingText="Save"
                      isLoading={loading}
                      isDisabled={loading}
                      marginLeft="24px"
                      className="gray-for-disabled"
                    >
                      Save
                    </Button>
                  </ModalFooter>
                </>
              );
            }}
          </Formik>
        </Loading>
      </SettingsModalContent>
    </Modal>
  );
};

export default SettingsModal;
