import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { Auth } from "aws-amplify";
import toast from "react-hot-toast";
import jwt_decode from "jwt-decode";
import "react-phone-number-input/style.css";
import { Field, Form, Formik } from "formik";
import {
  Box,
  Button,
  Text,
  InputLeftElement,
  InputRightElement,
  SimpleGrid,
  FormControl,
  InputGroup,
  FormErrorMessage,
  Input,
} from "@chakra-ui/react";

import { Email, Eye, Password } from "../../Assets/SvgIcons";
import { CheckboxV2, InputV2 } from "../../Reusable";

import { signupSchema } from "../Organizations/AddNewOrganizationForm/schema";

import { PasswordGuideWrapper, SignUpWrapper } from "./Styles";
import TermsAndConditionsModal from "../../Components/Modal/TermsAndConditionsModal";
import { Policy } from "../../Components/Policy/Policy";
import * as Sentry from "@sentry/react";

const SignUp = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  let navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [decodedToken, setDecodedToken] = useState<any>();
  const [token, setToken] = useState<string>();

  const [termsAndConditionsModalVisible, setTermsAndConditionsModalVisible] =
    useState(false);

  useEffect(() => {
    const localToken = new URLSearchParams(location.search).get("token");
    if (localToken) {
      setToken(localToken);
      let localDecodedToken = jwt_decode(localToken);
      setDecodedToken(localDecodedToken);
    }
  }, [location]);

  async function signUp(data) {
    try {
      setLoading(true);
      let signUpInput = {
        username: data.email,
        password: data.password,
        attributes: {
          email: data.email,
        },
      };

      if (token) {
        signUpInput.attributes["custom:token"] = token;
      }
      const response = await Auth.signUp(signUpInput);
      if (response.userSub) {
        // const user = await Auth.signIn(data.email, data.password);

        navigate("/confirm-signup", {
          state: {
            email: data.email,
            password: data.password,
          },
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      if (error.code === "UsernameExistsException") {
        setLoading(false);
        toast.remove();
        toast.error("Email already exists");
      } else if (error.code === "UserLambdaValidationException") {
        setLoading(false);
        toast.remove();
        toast.error("Email already exists");
      } else {
        toast.remove();
        toast.error("Unable to sign Up");
        setLoading(false);
      }
    }
  }

  return (
    <SignUpWrapper>
      <div className="col-logo">
        <img
          src="/images/logo-2.png"
          alt="Measure"
          className="img-logo mx-auto"
        />
      </div>

      <div className="col-form">
        <div className="box-wrapper mx-auto" style={{ maxWidth: 410 }}>
          <img
            src="/images/logo-3.png"
            alt="Measure"
            className="img-logo mx-auto"
          />

          {/* <SocialSignUp />

          <div className="box-divider mx-auto">
            <span>Or</span>
          </div> */}
        </div>

        <Formik
          enableReinitialize
          initialValues={{
            email: decodedToken?.email ? decodedToken.email : "",
            name: {
              firstName: "",
              lastName: "",
            },
            password: "",
            confirmPassword: "",
            phoneNumber: "",
            title: "",
            gender: "",
            ethnicity: "",
            confirmTerm: false,
            confirmAdultAdultAge: false,
          }}
          validationSchema={signupSchema}
          onSubmit={async (data, handlers) => {
            handlers.setSubmitting(true);
            handlers.validateForm(data);
            await signUp(data);
            handlers.setSubmitting(false);
          }}
        >
          {(formik) => {
            return (
              <Form>
                <div className="box-wrapper mx-auto" style={{ maxWidth: 410 }}>
                  <Box mb="24px">
                    <InputV2
                      name="email"
                      placeholder="Email Address"
                      inputLeftElement={
                        <InputLeftElement
                          pointerEvents="none"
                          children={<Email />}
                        />
                      }
                      restInput={{ maxLength: 100 }}
                    />
                  </Box>

                  <Box mb="24px">
                    <Field name="password">
                      {({ field, form }) => {
                        return (
                          <FormControl
                            isInvalid={
                              form.errors.password && form.touched.password
                            }
                            className="form-control-password"
                          >
                            <InputGroup>
                              <InputLeftElement
                                pointerEvents="none"
                                children={<Password />}
                              />

                              <Input
                                {...field}
                                id="password"
                                type={showPassword ? "text" : "password"}
                                placeholder="Password"
                                {...{ maxLength: 30 }}
                              />

                              <InputRightElement
                                children={<Eye />}
                                pointerEvents="initial"
                                className="cursor-pointer"
                                onClick={() => setShowPassword(!showPassword)}
                              />
                            </InputGroup>

                            <FormErrorMessage>
                              {form.errors.password}
                            </FormErrorMessage>
                          </FormControl>
                        );
                      }}
                    </Field>
                  </Box>

                  <Box mb="24px">
                    <InputV2
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      inputLeftElement={
                        <InputLeftElement
                          pointerEvents="none"
                          children={<Password />}
                        />
                      }
                      inputRightElement={
                        <InputRightElement
                          children={<Eye />}
                          pointerEvents="initial"
                          className="cursor-pointer"
                          onClick={() =>
                            setShowPasswordConfirm(!showPasswordConfirm)
                          }
                        />
                      }
                      restInput={{
                        type: showPasswordConfirm ? "text" : "password",
                        maxlengh: 30,
                      }}
                    />
                  </Box>

                  <Box mb="12px">
                    <PasswordGuideWrapper>
                      <SimpleGrid
                        columns={[2]}
                        spacingX="20px"
                        className="list"
                      >
                        <Box className="item" mb="4px">
                          Minimum 8 characters
                        </Box>
                        <Box className="item" mb="4px">
                          30 max
                        </Box>
                        <Box className="item" mb="4px">
                          1 number
                        </Box>
                        <Box className="item" mb="4px">
                          1 uppercase letter
                        </Box>
                        <Box className="item" mb="4px">
                          1 lowercase letter
                        </Box>
                        <Box className="item" mb="4px">
                          1 special character
                        </Box>
                      </SimpleGrid>
                    </PasswordGuideWrapper>
                  </Box>

                  <Box px="15px" mb="32px">
                    <CheckboxV2 name="confirmTerm">
                      <Text
                        as="span"
                        fontSize={16}
                        color="#000"
                        textAlign="center"
                        padding="6px 0"
                      >
                        I agree to the{" "}
                        <button
                          // @ts-ignore
                          fontWeight="semibold"
                          className="cursor-pointer text-primary text-hover-dark-primary"
                          onClick={(e) => {
                            setTermsAndConditionsModalVisible(true);
                          }}
                        >
                          terms & conditions
                        </button>
                      </Text>
                    </CheckboxV2>
                    <CheckboxV2 name="confirmAdultAdultAge">
                      <Text
                        as="span"
                        fontSize={16}
                        color="#000"
                        textAlign="center"
                        padding="6px 0"
                      >
                        I am 18 years old or more
                      </Text>
                    </CheckboxV2>
                  </Box>

                  <Box mb="16px">
                    <Button
                      type="submit"
                      colorScheme="primary"
                      className="w-100"
                    >
                      {!loading ? "Sign Up" : "Signing Up..."}
                    </Button>
                  </Box>

                  <Text
                    fontSize={16}
                    fontWeight="semibold"
                    color="#000"
                    textAlign="center"
                    padding="6px 0"
                  >
                    Already a member?{" "}
                    <Text
                      as={Link}
                      to="/"
                      fontSize={16}
                      fontWeight="semibold"
                      _hover={{ color: "primary.600" }}
                    >
                      Sign in
                    </Text>
                  </Text>
                </div>
                <Policy type="footer" />
              </Form>
            );
          }}
        </Formik>
      </div>
      {termsAndConditionsModalVisible && (
        <TermsAndConditionsModal
          isOpen={termsAndConditionsModalVisible}
          onClose={() => setTermsAndConditionsModalVisible(false)}
        />
      )}
    </SignUpWrapper>
  );
};

export default SignUp;
