import styled from "styled-components";

export const StepSparkWrapper = styled.div`
  .search-container {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 24px;
  }
`;

export const SparkIcon = styled.div`
  width: 32px;
  height: 32px;
  margin-right: 12px;

  img {
    width: 100%;
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Checkbox = styled.input.attrs<{ disabled?: boolean }>({
  type: "checkbox",
})`
  margin-right: 8px;
  ${(props) => props.disabled && `background-color: red`}
`;

export const CheckboxContainer = styled.label<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 24px;
  box-shadow: 1px 1px 6px 0px #401a0f40;
  border-radius: 100px;
  ${(props) => props.disabled && `background-color: #eee`}
`;

export const LabelText = styled.p`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`;
