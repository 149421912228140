import { useEffect } from "react";

export const useDetectOutsideClick = (props) => {
  useEffect(() => {
    document.addEventListener("mousedown", handleDetectOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleDetectOutsideClick);
    };
  }, []);

  const handleDetectOutsideClick = (event) => {
    if (
      props.customRef.current &&
      !props.customRef.current?.contains(event.target)
    ) {
      props.onOutsideClicked();
    }
  };

  return null;
};
