import styled from "styled-components";

import { devices } from "../../Theme.css";

export const SignUpWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  min-height: 100vh;
  .col-logo {
    width: 20%;
    background: transparent url(/images/bg-signup.jpg) no-repeat left top;
    background-size: cover;
    padding: 138px 20px;
    @media ${devices.max_lg} {
      flex: 1 1 30%;
      width: 30%;
    }

    @media only screen and (max-width: 816px) and (min-width: 767px) {
      .img-logo {
        transform: scale(0.5);
      }
      min-height: 100vh;
      padding: 0;
      margin: 0;
    }

    @media ${devices.max_sm} {
      display: none;
    }
    &.bg-light-gradient {
      background: transparent url(/images/bg-light-signup.jpg) no-repeat left
        top;
      background-size: cover;
      padding: 110px 20px;
    }
    .img-logo {
      max-width: 230px;
    }
  }
  .col-form {
    flex: 1 1 calc(100% - 500px);
    width: calc(100% - 500px);
    padding: 68px 20px;
    @media ${devices.max_lg} {
      flex: 1 1 70%;
      width: 70%;
    }
    .img-logo {
      max-width: 180px;
      margin-bottom: 65px;
    }
  }
  .box-divider {
    overflow: hidden;
    max-width: 70%;
    text-align: center;
    margin-bottom: 24px;
    span {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      &:before {
        content: "";
        position: absolute;
        left: -14px;
        top: 50%;
        transform: translate(-100%, 0);
        width: 100vw;
        height: 1px;
        background: #c4c4c4;
      }
      &:after {
        content: "";
        position: absolute;
        right: -14px;
        top: 50%;
        transform: translate(100%, 0);
        width: 100vw;
        height: 1px;
        background: #c4c4c4;
      }
    }
  }
  .form-control-password {
    .chakra-popover__popper {
      position: absolute !important;
      inset: auto auto 100% 0px !important;
      transform: none !important;
    }
  }
`;

export const PasswordGuideWrapper = styled.div`
  .list {
    padding: 0 10px;
    .item {
      position: relative;
      font-size: 14px;
      color: #000;
      padding-left: 10px;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0.7em;
        width: 2px;
        height: 2px;
        border-radius: 50%;
        background: #000;
      }
    }
  }
`;
