import { createGlobalStyle } from "styled-components";

import { Common } from "./Common";
import { Button } from "./Button";
import { CustomPhoneNumberInput } from "./CustomPhoneNumberInput";
import { CustomReactSelect } from "./CustomReactSelect";
import { Form } from "./Form";
import { Icon } from "./Icon";
import { Menu } from "./Menu";
import { Modal } from "./Modal";
import { Popover } from "./Popover";
import { Tab } from "./Tab";
import { Utils } from "./Utils";

export const GlobalStyles = createGlobalStyle`
  ${Common}
  ${Button}
  ${CustomPhoneNumberInput}
  ${CustomReactSelect}
  ${Form}
  ${Icon}
  ${Menu}
  ${Modal}
  ${Popover}
  ${Tab}
  ${Utils}
`;
