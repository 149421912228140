export const ORGANISATION = {
  ID_DEFAULT: '01858596-d4bd-6331-8f57-b4cda9d27bac',
  NAME_DEFAULT: 'ORG_DEFAULT'
};
export const CHARACTER_LIMIT = {
  MEDIA: 250,
  NO_MEDIA: 500
};

export const FOLLOW_POST = 'FOLLOW';
export const UNFOLLOW_POST = 'UNFOLLOW';

export const MAX_COMMENT = 5;
export const MAX_IMAGE_UPLOAD = 2;
export const MAX_VIDEO_UPLOAD = 100;
export const COEFFICIENT_BYTES = 1024;
export const MAX_LOGIN_FLAG = 5;
