import React from "react";
import NexusLogoAnimation from "../NexusLogoAnimation/NexusLogoAnimation";
import { CircularProgress } from "./Styles";

const FullPageNexusLogoAnimation = () => {
  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <CircularProgress>
        <NexusLogoAnimation />
      </CircularProgress>
    </div>
  );
};

export default FullPageNexusLogoAnimation;
