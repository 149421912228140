import styled from "styled-components";

export const PageNotFoundContainer = styled.div`
  display: flex;
  font-family: Rubik, sans-serif;
  flex-flow: column;
  justify-content: center;
  text-align: center;
  width: 100vw;
  height: 100vh;
  align-items: center;

  .error-text {
    font-size: 200px;
    font-weight: 700;
    color: #f7d85f;
    opacity: 0.5;
    line-height: 1;

    @media screen and (max-width: 767px) {
      font-size: 120px;
    }
  }

  .page-not-found-text {
    font-size: 48px;
    font-weight: 600;
    color: #0052cc;

    @media screen and (max-width: 767px) {
      font-size: 32px;
      line-height: 2;
    }
  }

  .message {
    font-size: 19px;
    color: #0052cccc;
    width: 70%;
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
`;
