import { useMemo } from "react";
import {
  useGetProfileQuery,
  useGetUsersQuery,
} from "../../redux/reducers/apiSlice";

export const useMentions = () => {
  const { data: currentUserProfileData } = useGetProfileQuery();

  const { data: users, isLoading: loadingUsers } = useGetUsersQuery();

  const mentionsData = useMemo(() => {
    return (
      users
        ?.filter(
          (user) =>
            user.id !== currentUserProfileData?.id &&
            user.name.firstName !== "" &&
            user.name.lastName !== ""
        )
        ?.map((user) => ({
          id: user.id,
          display: `${user.name.firstName} ${user.name.lastName}`,
          avatar: user?.image?.url,
          title: user.title,
        })) || []
    );
  }, [users, currentUserProfileData?.id]);

  return {
    mentionsData,
  };
};
