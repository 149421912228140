import React from "react";
import { LinkIllustration } from "../../../Assets/SvgIcons";
import { EmptyState, AboutCard, Button } from "./styles";

const AboutLinksEmptyState = ({ onPrimaryButtonClicked }) => (
  <AboutCard>
    <EmptyState>
      <EmptyState>
        <div className="illustration-container">
          <LinkIllustration />
        </div>
        <p>You don't have any links</p>
        <Button onClick={() => onPrimaryButtonClicked()}>Add link</Button>
      </EmptyState>
    </EmptyState>
  </AboutCard>
);

export default AboutLinksEmptyState;
