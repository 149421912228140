import React from "react";
import { Modal } from "./Modal";

const DiscardGoalCreationModal = ({
  isOpen,
  onClose,
  onPrimaryButtonClicked,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      primaryButtonText={"Discard"}
      secondaryButtonText={"Cancel"}
      modalTitle={"Are you sure you want to leave?"}
      onPrimaryButtonClicked={onPrimaryButtonClicked}
      primaryLoading={false}
      disablePrimaryButton={false}
    >
      <p style={{ textAlign: "center" }}>
        You will loose what you added and can't get it back.
      </p>
    </Modal>
  );
};

export default DiscardGoalCreationModal;
