import React from "react";
import { Modal } from "./Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { InputV2, Loading } from "../../Reusable";
import { useUpdateOrganizationMutation } from "../../../redux/reducers/apiSlice";
import { useOrganization } from "../../hooks/useOrganization";
import toast from "react-hot-toast";
import { MESSAGE } from "../../Constants/Message";
import { customConsole } from "../../utils/helper";
import useScreenType from "react-screentype-hook";
import * as Sentry from "@sentry/react";

const AntiRacistStatementModal = ({ isOpen, onClose }) => {
  const { currentOrganization, currentOrganizationLoading } = useOrganization();
  const [updateOrganization, { isLoading: updateOrganizationLoading }] =
    useUpdateOrganizationMutation();

  const { isMobile } = useScreenType();

  const handleSubmit = async (data) => {
    try {
      if (currentOrganization) {
        await updateOrganization({
          // @ts-ignore
          orgData: {
            orgFields: {
              ...currentOrganization.orgFields,
              antiRacist: data.value,
            },
          },
          orgId: currentOrganization.id,
        }).unwrap();
        onClose();
      }
    } catch (e) {
      Sentry.captureException(e);
      toast.error(MESSAGE.SYSTEM_ERROR);
    }
  };

  customConsole(
    "log",
    "Anti racist statement",
    currentOrganization?.orgFields?.antiRacist
  );

  if (currentOrganizationLoading || !currentOrganization) return <Loading />;

  return (
    <Formik
      validationSchema={yup.object({
        value: yup
          .string()
          .required(
            "You should fill an anti-racist statement before confirming"
          ),
      })}
      initialValues={{
        value: currentOrganization?.orgFields?.antiRacist,
      }}
      onSubmit={async (data, handlers) => {
        handlers.setSubmitting(true);
        handleSubmit(data);
        handlers.validateForm(data);
        handlers.setSubmitting(false);
      }}
    >
      {(formik) => {
        return (
          <Modal
            isOpen={isOpen}
            onClose={onClose}
            primaryButtonText={"Add"}
            secondaryButtonText={"Cancel"}
            modalTitle={"Anti-racist statement"}
            onPrimaryButtonClicked={() => {
              formik.handleSubmit();
            }}
            primaryLoading={updateOrganizationLoading}
            disablePrimaryButton={updateOrganizationLoading}
          >
            <div
              style={{
                paddingLeft: "24px",
                paddingRight: "24px",
                height: "100%",
              }}
            >
              <InputV2
                inputGroupStyle={{ height: isMobile ? "80vh" : "50vh" }}
                textAreaStyle={{ height: "100%" }}
                name="value"
                formik={formik}
                value={formik.values.value}
              />
            </div>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default AntiRacistStatementModal;
