export const MESSAGE = {
  WHITE_SPACE : 'Content has whitespace',
  EMAIL_INVALID : 'Please enter a valid email address',
  INPUT_REQUIRED : 'This field is required',
  AGREE_REQUIRED : 'The terms and conditions must be accepted.',
  SYSTEM_ERROR : 'Something went wrong',
  ADULT_AGE_REQUIRED : 'Please confirm you are 18 years old or more.',
}

export function MinMaxMessage(type,inputName,lengthCharater){
  return type == 'min' ?
    inputName + ' must be at least ' + lengthCharater + ' characters' :
    inputName + ' must be less than ' + lengthCharater + ' characters'
}

export const RegexNoWhiteSpace = /^\S*$/;
