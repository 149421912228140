import { css } from 'styled-components';

export const CustomReactSelect = css`
  .react-select-container {
    width: 100%;
    z-index: inherit;
    .react-select__control {
      width: 100%;
      height: ${({ theme }) => theme.sizes['10']};
      font-size: 14px;
      border-radius: 30px;
      border: 0;
      outline: 0;
      box-shadow: 1px 1px 6px rgba(64, 26, 15, 0.25);
      cursor: pointer;
    }
    .react-select__value-container {
      padding-left: 16px;
    }
    .react-select__placeholder {
    }
    .react-select__input-container {
    }
    .react-select__input {
    }
    .react-select__indicators {
    }
    .react-select__indicator-separator {
      display: none;
    }
    .react-select__indicator {
      color: #262626;
      &:hover {
        color: #000;
      }
    }
    .react-select__menu {
      z-index: inherit;
    }
  }
`;
