import { Input as ChakraInput } from "@chakra-ui/react";
import styled from "styled-components";

type CommentsAndInputWrapperProps = {
  show: boolean;
};

export const mentionStyle = {
  control: {
    backgroundColor: "#fff",
    fontSize: 14,
    fontWeight: "normal",
  },

  "&multiLine": {
    width: "100%",
    minHeight: "45px",
    maxWidth: "100%",
    maxHeight: "100%",

    control: {
      minHeight: 63,
      width: "100%",
      maxWidth: "100%",
    },
    highlighter: {
      padding: 9,
      border: "1px solid transparent",
    },
    input: {
      innerWidth: "100%",

      backgroundColor: "rgb(237, 237, 237)",
      borderRadius: "100px",
      padding: "12px 16px",
      resize: "none",
    },
  },

  "&singleLine": {
    width: "100%",
    display: "inline-block",

    highlighter: {
      padding: 1,
    },
    input: {
      padding: "12px 16px",
      borderRadius: "100px",
      backgroundColor: "rgb(237, 237, 237)",
      boxSizing: "border-box",
      overflowWrap: "break-word",
    },
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 14,
      maxHeight: "250px",
      overflow: "auto",
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#cee4e5",
      },
    },
  },
};

export const CommentsAndInputWrapper = styled.div<CommentsAndInputWrapperProps>`
  ${(props) =>
    !props.show &&
    `
      opacity: 0;
      height: 0;
      overflow: hidden;
      transition: opacity 0.25s ease, height 0.25s ease;
    `}
  ${(props) =>
    props.show &&
    `opacity: 1;
      height: auto;
      transition: opacity 0.25s ease, height 0.25s ease;
      margin: 24px 0px;
      `}
`;

export const CommentSpacer = styled.div``;

export const CommentsWrapper = styled.div<{
  hasOneComment: boolean;
  fullHeight?: boolean;
}>`
  /** whent its has one comment the height should equal the comment card height */
  ${(props) => (!props.hasOneComment ? `height: 150px;` : `height: 100px;`)}
  ${(props) => !props.hasOneComment && `overflow-y: scroll;`}
  ${(props) => props.fullHeight && `height: 100%;`}
  margin-top: 24px;
`;

export const CommentInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  transition: opacity 0.4s ease-out;
`;
