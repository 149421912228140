import { css } from 'styled-components';

export const CustomPhoneNumberInput = css`
  .PhoneInput {
    width: 100%;
    height: ${({ theme }) => theme.sizes['10']};
    font-size: 14px;
    border-radius: 30px;
    border: 0;
    outline: 0;
    box-shadow: 1px 1px 6px rgba(64, 26, 15, 0.25);
    padding-right: 16px;
  }
  .PhoneInputCountry {
    justify-content: center;
    width: 50px;
  }
  .PhoneInputInput {
    outline: 0;
    background: none;
  }
`;
