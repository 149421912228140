import React from "react";
import { AiOutlineHome } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { PageNotFoundContainer } from "./Styles";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <PageNotFoundContainer>
      <p className="error-text">404</p>
      <p className="page-not-found-text"> PAGE NOT FOUND</p>
      <p className="message">
        The Page you are looking for might have been removed, had its name
        changed, or is temporarily unavailable
      </p>
      <Button
        text="Go to Home Page"
        onClick={() => {
          navigate("/");
        }}
        style={{ marginTop: "30px" }}
        iconLeft={<AiOutlineHome fontSize="18px" />}
      />
    </PageNotFoundContainer>
  );
};

export default PageNotFound;
