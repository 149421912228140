import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Outlet } from "react-router";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import { DashboardLayoutWrapper, MainBody, MainContent } from "./Styles";

interface DashboardLayoutProps {
  setUser: () => void;
  amountUnreads: number;
}

const DashboardLayout = (props: DashboardLayoutProps) => {
  const [sidebarAnimation, setSidebarAnimation] = useState("default");

  const location = useLocation();

  return (
    <DashboardLayoutWrapper className="dashboard-layout">
      <Header
        toggleSidebar={() => {
          if (sidebarAnimation == "default") {
            setSidebarAnimation("moveRight");
          } else {
            const toggleSidebarAnimation =
              sidebarAnimation === "moveLeft" ? "moveRight" : "moveLeft";
            setSidebarAnimation(toggleSidebarAnimation);
          }
        }}
      />

      <MainBody className="main-body">
        <Sidebar
          amountUnreads={props.amountUnreads}
          sidebarAnimation={sidebarAnimation}
        />
        <MainContent
          className={`main-content ${
            location.pathname.startsWith("/messages")
              ? "message-main-content"
              : ""
          }`}
        >
          <Outlet />
        </MainContent>
        <div
          style={{ display: "none" }}
          className="inbox__container"
          id="inbox-container"
        ></div>
      </MainBody>
    </DashboardLayoutWrapper>
  );
};

export default DashboardLayout;
