import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { Amplify, Auth, Hub } from "aws-amplify";
import { Toaster } from "react-hot-toast";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { ThemeProvider } from "styled-components";
import AppRoutes from "./AppRoutes";
import { GlobalStyles } from "./Assets/Styles/GlobalStyles";
import awsconfig from "./aws-exports";
import ErrorBoundary from "./Reusable/ErrorBoundary/ErrorBoundary";
import { vars } from "./Theme.css";
import FullPageNexusLogoAnimation from "./Reusable/FullPageNexusLogoAnimation/FullPageNexusLogoAnimation";
import { customConsole } from "./utils/helper";
import AuthRoutes from "./AuthRoutes";
import { CookiesProvider, useCookies } from "react-cookie";
import * as Sentry from "@sentry/react";
import AppAccessForm from "./Components/AppAccessForm";

// >>New - Configuring Auth Module
Amplify.configure(awsconfig);

const IS_PROD = `${import.meta.env.IS_PROD}`;

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies();

  const chakraTheme = extendTheme({
    fonts: { ...vars.fonts },
    colors: {
      primary: {
        50: "#f9c7b7",
        100: "#f7b19a",
        200: "#f59c7e",
        300: "#f38662",
        400: "#f07045",
        500: vars.color.primary,
        600: "#e64712",
        700: "#ca3e10",
        800: "#ad350e",
        900: "#912d0c",
      },
    },
    sizes: { ...vars.sizes },
    breakpoints: { ...vars.breakpoints },
    styles: {
      global: {
        a: {
          color: vars.color.primary,
        },
      },
    },
  });

  const updateUser = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      let attributes = currentUser.signInUserSession?.idToken?.payload;

      const cookieNames = Object.keys(cookies);
      if (cookieNames.length > 0) {
        let hasAUserDataCookie = false;
        cookieNames.forEach((cookieName) => {
          if (cookieName.startsWith("userdata")) {
            hasAUserDataCookie = true;
            removeCookie(cookieName);
          }
        });
        if (hasAUserDataCookie) {
          window.location.reload();
        }
      }

      setUser(attributes);
      setLoading(false);
    } catch (err) {
      Sentry.captureException(err);
      customConsole("log", "updateUser Error", err);
      setUser(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    Hub.listen("auth", updateUser);
    updateUser();
  }, []);

  if (loading) return <FullPageNexusLogoAnimation />;

  console.log("IS STAGING", `${import.meta.env.MODE === "staging"}`);

  if (
    window.localStorage.getItem("app_access") !== "true" &&
    // @ts-ignore
    import.meta.env.MODE === "staging"
  ) {
    return <AppAccessForm />;
  }

  return (
    <ChakraProvider theme={chakraTheme}>
      <CookiesProvider>
        {/* @ts-ignore */}
        <ThemeProvider theme={vars}>
          {/* @ts-ignore */}
          <GlobalStyles />
          <Router>
            <ErrorBoundary>
              {user ? (
                <AppRoutes user={user} setUser={setUser} />
              ) : (
                <AuthRoutes setUser={setUser} />
              )}
            </ErrorBoundary>
          </Router>
        </ThemeProvider>
        <Toaster />
      </CookiesProvider>
    </ChakraProvider>
  );
}

export default App;
