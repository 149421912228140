import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import React from "react";
import { ModalBody } from "./Styles";
import useScreenType from "react-screentype-hook";

const PrivacyPolicyModal = ({ isOpen, onClose }) => {
  const { isMobile } = useScreenType();

  return (
    <Modal
      size={isMobile ? "full" : "xl"}
      onClose={onClose}
      isOpen={isOpen}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>MEASURE IGNITE PRIVACY POLICY</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <h2>Last updated: December 1, 2023 </h2>
          <p>
            This Privacy Policy describes how MEASURE Austin, MEASURE Ignite and
            its affiliates (collectively “MEASURE”) collect and process your
            personal information through MEASURE websites, online stores and
            applications that reference this Privacy Notice (together “MEASURE
            Services”). 
            <strong>
              By using MEASURE Services, you are consenting to the practices
              described in this Privacy Policy.
            </strong>
          </p>
          <h2>What Personal Information Does MEASURE Collect? </h2>
          <p>
            We collect your personal information in order to provide and
            continually improve our products and services.
          </p>
          <p>Here are the types of personal information we collect:</p>
          <ul>
            <li>
              <strong>Information You Give Us:</strong> We receive and may store
              any information you provide in relation to MEASURE Services. You
              can choose not to provide certain information, but then you might
              not be able to take advantage of many of our MEASURE Services.
              Some information that you might supply us includes, but is not
              limited to, the following: Your identifying information, such as
              your name, phone number and address;{" "}
              <ul>
                <li>Your age;</li>
                <li>Registration details;</li>
                <li>Your payment information;</li>
                <li>Your location information;</li>
                <li>and Your IP address.</li>
              </ul>
            </li>
            <li>
              <strong>Automatic Information:</strong> We automatically collect
              and store certain types of information about your use of MEASURE
              Services, including information about your interaction with
              products, content, and services available through MEASURE
              Services. Like many websites, we may use “cookies” and other
              unique identifiers, and we may obtain certain types of information
              when your web browser or device accesses MEASURE Services and
              other content served by or on behalf of MEASURE on other websites.
              Some information that we may collect includes, but is not limited
              to, the following:{" "}
              <ul>
                <li>
                  The IP address used to connect your computer to the internet;
                </li>
                <li>
                  The location of your device or computer; Device metrics;
                </li>
                <li>o User activity metrics;</li>
                <li>and Version and time zone settings.</li>
              </ul>
            </li>
            <li>
              <strong>Information from Other Sources:</strong> We may receive
              information about you from other sources. Some information that we
              may collect includes, but is not limited to, the following:
              <ul>
                <li>Search results and links (including paid listings);</li>
                <li>
                  {" "}
                  Credit history information from credit bureaus, which we may
                  use to prevent fraud and
                </li>
                <li>
                  Updated delivery and address information from our carriers or
                  other third parties.
                </li>
              </ul>
            </li>
          </ul>
          <h2>
            {" "}
            For What Purposes Does MEASURE Use Your Personal Information?
          </h2>
          <p>
            We use your personal information to operate, provide, develop, and
            improve the products and services that we offer. These purposes
            include:
          </p>
          <ul>
            <li>
              <strong>Purchase and delivery of products and services.</strong>
               We use your personal information to take and handle orders,
              deliver products and services, process payments, and communicate
              with you about orders, products and services, and promotional
              offers.
            </li>
            <li>
              <strong>
                Provide, troubleshoot, and improve MEASURE Services.
              </strong>
               We use your personal information to provide functionality,
              analyze performance, fix errors, and improve the usability and
              effectiveness of the MEASURE Services.
            </li>
            <li>
              <strong>Recommendations and personalization.</strong> We may use
              your personal information to recommend features, products, and
              services that might be of interest to you and to personalize your
              experience with MEASURE Services.
            </li>
            <li>
               <strong>Comply with legal obligations.</strong> In certain
              cases, we may collect and use your personal information to comply
              with laws.
            </li>
            <li>
              <strong>Communicate with you.</strong> We use your personal
              information to communicate with you in relation to MEASURE
              Services via different channels (e.g., by phone, email, chat). o
            </li>
            <li>
              <strong>Fraud Prevention.</strong> We use personal information to
              prevent and detect fraud and abuse in order to protect the
              security of our customers, MEASURE, and others.
            </li>
          </ul>
          <h2>What About Cookies and Other Identifiers?</h2>
          <p>
            {" "}
            To enable our systems to recognize your browser or device and to
            provide and improve MEASURE Services, we may use cookies and other
            identifiers.
          </p>
          <h2>Does MEASURE Share Your Personal Information? </h2>
          <p>
            {" "}
            Information about our customers is an important part of our
            business, and we are not in the business of selling our customers’
            personal information to others. We share customers’ personal
            information only as described below.{" "}
          </p>
          <ul>
            <li>
              <strong>Transactions involving Third Parties:</strong> We may make
              available to you services or products provided by third parties
              for use on or through MEASURE Services. You can tell when a third
              party is involved in your transactions, and we share customers’
              personal information related to those transactions with that third
              party.
            </li>
            <li>
              <strong>Third-Party Service Providers:</strong> We employ other
              companies and individuals to perform functions on our behalf.
              Examples include fulfilling orders for products or services,
              sending postal mail and email, removing repetitive information
              from customer lists, analyzing data, providing marketing
              assistance, processing payments, and providing customer service.
              These third-party service providers have access to personal
              information needed to perform their functions, but may not use it
              for other purposes.
            </li>
            <li>
              <strong>Protection of MEASURE and Others:</strong> We release
              account and other personal information when we believe release is
              appropriate to comply with the law; enforce or apply our Terms of
              Service and other agreements; or protect the rights, property, or
              safety of MEASURE, our users, or others.
            </li>
          </ul>
          <p>
            Other than as set out above, you will receive notice when personal
            information about you might be shared with third parties, and you
            will have an opportunity to choose not to share the information.
          </p>
          <h2>How Secure Is Information About Me?</h2>
          <p>We design our systems with your security and privacy in mind.</p>
          <ul>
            <li>
              {" "}
              We work to protect the security of your personal information
              during transmission by using encryption protocols and software.{" "}
            </li>
            <li>
              We maintain physical, electronic, and procedural safeguards in
              connection with the collection, storage, and disclosure of
              customer personal information. Our security procedures mean that
              we may ask to verify your identity before we disclose personal
              information to you.
            </li>
            <li>
              It is important for you to protect against unauthorized access to
              your password and to your computers, devices, and applications. We
              recommend using a unique password for your MEASURE account that is
              not used for other online accounts. You are responsible for
              safeguarding your login credentials.
            </li>
          </ul>
          <h2>What Control Over My Data Do I Have?</h2>
          <p>
            You can access your information by contacting us at{" "}
            <a target="_blank" href="mailto:ignite@wemeasure.org">
              ignite@wemeasure.org
            </a>
            . Additionally, you have the right to modify or delete your personal
            information by contacting us at the above email address.
          </p>
          <h2>Are Children Allowed to Use MEASURE Services?</h2>
          <p>
            MEASURE does not sell products or services for purchase by children.
            If you are under 18, you may use MEASURE Services only with the
            involvement of a parent or guardian. We do not knowingly collect
            personal information from children under the age of 18 without the
            consent of the child’s parent or guardian.
          </p>
          <h2> United States Access Only</h2>
          <p>
            Only persons located within the United States may use the MEASURE
            Services. If you are located outside of the United States, you may
            not access the MEASURE Ignite website or any other MEASURE website.
          </p>
          <h2>Additional State-Specific Privacy Disclosures</h2>
          <ul>
            <li>
              <strong>
                California Privacy Rights Act Sensitive Personal Information
                Disclosure.
              </strong>{" "}
              The categories of data that MEASURE collects and discloses for a
              business purpose include “sensitive personal information” as
              defined under the California Privacy Rights Act. MEASURE does not
              use or disclose sensitive personal information for any purpose not
              expressly permitted by the California Privacy Rights Act.
            </li>
            <li>
              <strong>
                California Privacy Rights Act Retention Disclosure.
              </strong>{" "}
              We keep your personal information to enable your continued use of
              MEASURE Services, for as long as it is required in order to
              fulfill the relevant purposes described in this Privacy Policy, as
              permitted or as may be required by law, or as otherwise
              communicated to you.
            </li>
            <li>
              <strong>
                California Privacy Rights Act Non-discrimination Statement.
              </strong>
              MEASURE will not discriminate against any consumer for exercising
              their rights under the California Privacy Rights Act.
            </li>
            <li>
              <strong>
                California Privacy Rights Act, Colorado Privacy Act, Connecticut
                Data Privacy Act, Utah Consumer Privacy Act, and Virginia
                Consumer Data Protection Act De-identified Data Disclosure.
              </strong>{" "}
              MEASURE may use de- identified data in some instances. MEASURE
              either maintains such data without attempting to re-identify it or
              treats such data as personal data subject to applicable law.
            </li>
            <li>
              <strong>Colorado Privacy Act Profiling Disclosure.</strong>{" "}
              MEASURE does not engage in profiling of consumers in furtherance
              of automated decisions that produce legal or similarly significant
              effects, as those terms are defined under the Colorado Privacy
              Act.
            </li>
          </ul>
          <h2>Conditions of Use, Notices, and Revisions </h2>
          <p>
            {" "}
            If you choose to use MEASURE Services, your use and any dispute over
            privacy is subject to this Notice and our Terms of Service,
            including limitations on damages, resolution of disputes, and
            application of the law of the state of Texas. If you have any
            concern about privacy at MEASURE, please contact us with a thorough
            description, and we will try to resolve it. Our business changes
            constantly, and our Privacy Notice will change also. You should
            check our websites frequently to see recent changes. Unless stated
            otherwise, our current Privacy Notice applies to all information
            that we have about you and your account.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PrivacyPolicyModal;
