import styled from "styled-components";
import { devices } from "../../Theme.css";

export const OrganizationTabsWrapper = styled.div``;

type OrganizationTabsHeaderProps = {
  customTabsSpacing?: string;
};

export const OrganizationTabsHeader = styled.div<OrganizationTabsHeaderProps>`
  display: flex;
  flex-direction: row;
  margin-top: 32px;
  padding: 0 32px;

  @media ${devices.max_sm} {
    overflow: scroll;
  }

  ${(props) => `${props.customTabsSpacing}`}
`;

export const OrganizationTabsButtonSpacer = styled.div`
  margin-right: 44px;
`;

export const OrganizationTabsButtonWrapper = styled.div`
  background-color: white;
`;

type OrganizationTabsButtonWrapperProps = {
  customTabLabelStyle?: string;
};

export const OrganizationTabsButton = styled.button<OrganizationTabsButtonWrapperProps>`
  ${(props) =>
    props &&
    !props.customTabLabelStyle &&
    `

  background: transparent;

  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;

  @media ${devices.max_sm} {
    padding: 0 16px;
  }
   `}

  ${(props) => `${props.customTabLabelStyle}`}
`;

export const ComponentWrapper = styled.div``;

type ComponentSeparatorProps = {
  customDividerStyle?: string;
};

export const ComponentSeparator = styled.div<ComponentSeparatorProps>`
  height: 2px;
  background-color: #eee;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-top: 43px;
  margin-bottom: 24px;
  ${(props) => `${props.customDividerStyle}`}
  @media ${devices.max_sm} {
    margin-top: -2px;
  }
`;

export const Selection = styled.div`
  height: 4px;
  background-color: #ef6b2d;
`;
