import React, { useMemo, useState, useEffect } from "react";
import toast from "react-hot-toast";
import { Modal } from "./Modal";
import { InputV2 } from "../../Reusable/InputV2/InputV2";
import { Formik } from "formik";
import { Box, SimpleGrid, Divider, Spinner } from "@chakra-ui/react";
import { createMetricSchema } from "../../Pages/Organizations/AddNewOrganizationForm/schema";
import { GoalFormWrapper, FormErrorMessage } from "./Styles";
import { SelectV2 } from "../../Reusable";
import {
  useAddMetricMutation,
  useUpdateMetricMutation,
  useCreateOrganizationGoalMutation,
  useEditOrganizationGoalMutation,
  useLazyGetMetricBySparkQuery,
} from "../../../redux/reducers/apiSlice";
import {
  metricYearSelectInputOptions,
  metricMonthOrQuaterFieldOptions,
  generatePredeterminedMetricOptions,
  formatNumber,
  revertNumberOrCurrencyFormat,
  customConsole,
} from "../../utils/helper";
import RadioButtonGroup from "../RadioButtonGroup/RadioButtonGroup";
import { TARGET_SYMBOLS } from "../../utils/constants";
import * as Sentry from "@sentry/react";

const CreateMetricModal = ({
  isOpen,
  goalId,
  orgId,
  onClose,
  sparkId,
  metric,
}) => {
  const editMode = !!metric;
  const [addMetric, { isLoading: addMetricLoading }] = useAddMetricMutation();
  const [updateMetric, { isLoading: updateMetricLoading }] =
    useUpdateMetricMutation();
  const [
    getMetricBySpark,
    { isLoading: getMetricBySparkLoading, data: predeterminedMetrics },
  ] = useLazyGetMetricBySparkQuery();
  const [createGoal, { isLoading: createGoalLoading }] =
    useCreateOrganizationGoalMutation();
  const [editGoal, { isLoading: editGoalLoading }] =
    useEditOrganizationGoalMutation();

  const loading = createGoalLoading || editGoalLoading;

  const [disabledPredetermined, setDisabledDetermined] = useState(false);

  const fetchMetricDescription = async () => {
    try {
      const response = await getMetricBySpark(sparkId).unwrap();
      if (Array.isArray(response) && response.length === 0) {
        setDisabledDetermined(true);
      }
    } catch (e) {
      Sentry.captureException(e);
      setDisabledDetermined(true);
    }
  };

  useEffect(() => {
    fetchMetricDescription();
  }, [isOpen]);

  const predeterminedMetricOptions = useMemo(() => {
    if (
      Array.isArray(predeterminedMetrics) &&
      predeterminedMetrics.length > 0
    ) {
      customConsole(
        "log",
        generatePredeterminedMetricOptions(predeterminedMetrics)
      );
      return generatePredeterminedMetricOptions(predeterminedMetrics);
    }
    return [];
  }, [predeterminedMetrics]);

  const handleSubmit = async (data) => {
    const finalData = {};
    const startDate = `${data.year}-${data.month}`;
    const progress = {
      target: data.progress.targetValue,
      current: data.progress.current,
      targetType: data.progress.targetType,
    };
    finalData["description"] = data.description;
    finalData["startDate"] = startDate;
    finalData["progress"] = progress;
    finalData["term"] = data.term;

    let apiCall = editMode ? updateMetric : addMetric;
    let payload = editMode
      ? {
          orgId,
          goalId,
          metricId: metric.id,
          data: finalData,
        }
      : {
          orgId,
          goalId,
          data: finalData,
        };
    try {
      //@ts-ignore
      await apiCall(payload).unwrap();

      toast.success("You just added a metric"), onClose();
    } catch (e) {
      Sentry.captureException(e);
      toast.error(
        e?.data?.message ||
          "Something unexpected happened while trying to add your metric"
      );
    }
  };

  const getMonthFieldValueFromMetric = () => {
    let date = new Date(metric?.startDate);
    const monthIndex = date.getMonth() + 1;
    const month = monthIndex < 10 ? `0${monthIndex}` : monthIndex;
    return `${month}-01`;
  };

  if (loading) return <Spinner color="red" />;

  return (
    <Formik
      validationSchema={createMetricSchema}
      initialValues={{
        predetermined: "true",
        description: metric?.description,
        progress: {
          target: metric?.progress.target
            ? formatNumber(metric.progress.target, "decimal")
            : 0,
          current: 0,
          targetType: "DOLLARS",
          targetValue: metric?.progress?.target,
          ...metric?.progress,
        },
        term: metric?.term || "MONTHLY",
        year: metric?.startDate
          ? new Date(metric?.startDate).getFullYear()
          : metricYearSelectInputOptions()[0].value,
        month: metric?.startDate
          ? getMonthFieldValueFromMetric()
          : metricMonthOrQuaterFieldOptions("MONTHLY")[0].value,
      }}
      onSubmit={async (data) => {
        handleSubmit(data);
      }}
    >
      {(formik) => {
        customConsole("log", "ERRORS", formik.errors);
        return (
          <Modal
            isOpen={isOpen}
            onClose={onClose}
            primaryButtonText={editMode ? "Edit metric" : "Save metric"}
            secondaryButtonText={"Cancel"}
            modalTitle={`${editMode ? "Edit" : "Add new"} metric`}
            onPrimaryButtonClicked={formik.handleSubmit}
            primaryLoading={loading}
            disablePrimaryButton={loading}
          >
            <GoalFormWrapper>
              <RadioButtonGroup
                formik={formik}
                isDisabled={disabledPredetermined}
                onChange={(val) => {
                  if (val === "false") {
                    formik.setFieldValue("description", "");
                  }
                  formik.setFieldValue("predetermined", val);
                }}
                value={
                  disabledPredetermined ? "false" : formik.values.predetermined
                }
                name="predetermined"
                options={[
                  {
                    label: "Select pre-determined metric",
                    value: "true",
                    disabled: disabledPredetermined,
                  },
                  {
                    label: "Create my own",
                    value: "false",
                  },
                ]}
              />
              {formik.values.predetermined === "true" && (
                <Box mb="32px" style={{ position: "relative", zIndex: 100 }}>
                  <SelectV2
                    style={{ position: "relative", zIndex: 40000 }}
                    placeholder="Select a metric"
                    name="description"
                    restSelect={{ isDisabled: disabledPredetermined }}
                    options={predeterminedMetricOptions}
                    value={formik.values.description}
                    hideError={disabledPredetermined}
                    onChange={(option) =>
                      formik.setFieldValue("description", option.value)
                    }
                  />
                </Box>
              )}
              {(disabledPredetermined ||
                formik.values.predetermined !== "true") && (
                <Box mb="32px">
                  <InputV2
                    placeholder="Add a description for your metric"
                    name="description"
                    value={formik.values.description}
                  />
                </Box>
              )}
              <Divider />
              <SimpleGrid
                columns={[2]}
                style={{ marginTop: "24px" }}
                spacingX="44px"
              >
                <Box style={{ position: "relative", zIndex: 99 }}>
                  <SelectV2
                    placeholder="Progress"
                    name="progress.targetType"
                    options={[
                      { label: "Dollars", value: "DOLLARS" },
                      { label: "Numbers", value: "NUMBERS" },
                      { label: "Percentages", value: "PERCENTAGES" },
                    ]}
                    value={formik.values.progress.targetType}
                    onChange={(option) => {
                      formik.setFieldValue("progress.targetType", option.value);
                    }}
                  />
                </Box>
                <Box style={{ position: "relative", zIndex: 99 }}>
                  <InputV2
                    name="progress.target"
                    restInput={{
                      onChange: (e) => {
                        const cleanedUpValue = revertNumberOrCurrencyFormat(
                          e.target.value
                        );
                        if (!isNaN(cleanedUpValue)) {
                          formik.setFieldValue(
                            "progress.targetValue",
                            cleanedUpValue
                          );
                          formik.setFieldValue(
                            "progress.target",
                            formatNumber(cleanedUpValue, "decimal")
                          );
                        } else {
                          formik.setFieldValue("progress.target", 0);
                          formik.setFieldValue("progress.targetValue", 0);
                        }
                      },
                    }}
                    placeholder="Your target"
                    value={
                      `${TARGET_SYMBOLS[formik.values.progress.targetType]}` +
                      formik.values.progress.target
                    }
                  />
                  <FormErrorMessage>
                    {/** @ts-ignore */}
                    {formik.errors?.progress?.targetValue}
                  </FormErrorMessage>
                </Box>
              </SimpleGrid>
              <SimpleGrid
                columns={[3]}
                spacingX={10}
                style={{ marginTop: "24px" }}
              >
                <Box style={{ position: "relative", zIndex: 97 }}>
                  <SelectV2
                    name="year"
                    placeholder="Year"
                    options={metricYearSelectInputOptions()}
                    value={formik.values.year}
                    onChange={(option) =>
                      formik.setFieldValue("year", option.value)
                    }
                  />
                </Box>
                <Box style={{ position: "relative", zIndex: 97 }}>
                  <SelectV2
                    placeholder="Time period"
                    name="term"
                    options={[
                      { label: "Quarterly", value: "QUARTERLY" },
                      { label: "Monthly", value: "MONTHLY" },
                    ]}
                    value={formik.values.progress}
                    onChange={(option: {
                      label: string;
                      value: "QUATERLY" | "MONTHLY";
                    }) => formik.setFieldValue("term", option.value)}
                  />
                </Box>
                <Box style={{ position: "relative", zIndex: 97 }}>
                  <SelectV2
                    placeholder="Start of timeline"
                    name={formik.values.month}
                    onChange={(option: { label: string; value: string }) =>
                      formik.setFieldValue("month", option.value)
                    }
                    options={metricMonthOrQuaterFieldOptions(
                      formik.values.term
                    )}
                    value={formik.values.month}
                    hideError={false}
                  />
                </Box>
              </SimpleGrid>
            </GoalFormWrapper>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default CreateMetricModal;
