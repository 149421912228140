export const vars = {
  color: {
    primary: "rgb(238, 90, 41)",
    buttonBlue: "#0052CC",
    primaryColor: "#0B3765",
    accentGreen: "#2BBB63",
    borderGray: "#DDDDDD",
    fontGray: "#666666",
    dangerRed: "#FF0000",
    lightRed: "#ED3724",
    white: "#ffffff",
  },
  fonts: {
    body: "Montserrat, sans-serif",
    heading: "Montserrat, sans-serif",
  },
  sizes: {
    10: "2.75rem",
  },
  breakpoints: {
    base: "0px",
    sm: "480px",
    md: "768px",
    lg: "992px",
    xl: "1280px",
    "2xl": "1536px",
  },
  width: {
    sidebar: "250px",
  },
  height: {
    header: "100px",
  },
  zIndex: {
    sidebar: 990,
    header: 1000,
  },
};

const getBreakPoint = (breakPoint: keyof typeof vars.breakpoints) => {
  return +vars.breakpoints[breakPoint].replace(/\D+/g, "") - 1;
};

export const devices = {
  sm: `only screen and (min-width: ${vars.breakpoints.sm})`, // >= 480px
  md: `only screen and (min-width: ${vars.breakpoints.md})`, // >= 768px
  lg: `only screen and (min-width: ${vars.breakpoints.lg})`, // >= 992px
  xl: `only screen and (min-width: ${vars.breakpoints.xl})`, // >= 1280px
  "2xl": `only screen and (min-width: ${vars.breakpoints["2xl"]})`, // >= 1536px
  max_xl: `only screen and (max-width: ${getBreakPoint("2xl")}px)`, // <= 1535px
  max_lg: `only screen and (max-width: ${getBreakPoint("xl")}px)`, // <= 1279px
  max_md: `only screen and (max-width: ${getBreakPoint("lg")}px)`, // <= 991px
  max_sm: `only screen and (max-width: ${getBreakPoint("md")}px)`, // <= 767px
  max_xs: `only screen and (max-width: ${getBreakPoint("sm")}px)`, // <= 479px
};
