import styled from "styled-components";
import { MenuItem as ChakraMenuItem } from "@chakra-ui/react";

export const MenuItem = styled.div`
  padding: 8px 24px;
  .menu-wrapper {
  }

  .row {
    min-width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .from {
    width: 22.5%;
    color: #ee5a29;
    font-weight: bold;
  }

  .message {
    width: 55%;
  }

  .icons {
    width: 12.5%;
    display: flex;
  }

  .time-ago {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-size: 12px;
    color: #aaa;
    font-weight: bold;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  &:hover {
    background: rgba(238, 90, 41, 0.2);
    border-radius: 50%;
  }
`;

export const ReactionContainer = styled.div`
  svg {
    width: 20px;
    height: 20px;
  }
`;
