import styled from "styled-components";

export const BackButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    //styleName: Link;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
  }
`;
