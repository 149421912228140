import React from "react";
import { User, Organization } from "../../../redux/reducers/types";
import { Menu, MenuItem, MenuList } from "./Styles";
import { ProfileAvatar } from "../../Components/ProfileAvatar/ProfileAvatar";
import { Link } from "react-router-dom";
import { BoxNameTitleContainer, UserNameSpan } from "./Styles";
import ClickOutsideHandler from "../../Reusable/ClickOutsideHandler/ClickOutsideHandler";
import SearchSkeletonResults from "./SearchSkeletonResults";
import { SearchResultStyle } from "./Styles";
import { isOrganization } from "../../utils/helper";

const SearchResults = ({
  data,
  isOpenSearchList,
  setIsOpenSearchList,
  handleClickOutside,
  searchLoading,
}) => {
  if (!isOpenSearchList) return null;

  return (
    <SearchResultStyle>
      <ClickOutsideHandler onClickOutside={handleClickOutside}>
        <Menu>
          <MenuList>
            {searchLoading && <SearchSkeletonResults />}
            {!searchLoading &&
              Object.keys(data).map((category: string, id) => (
                <SearchResultItems
                  data={data[category]}
                  setIsOpenSearchList={setIsOpenSearchList}
                  key={id}
                />
              ))}
          </MenuList>
        </Menu>
      </ClickOutsideHandler>
    </SearchResultStyle>
  );
};

export default SearchResults;

const SearchResultItems = ({ data, setIsOpenSearchList }) => {
  return data.map((item: User | Organization, key: number) => (
    <MenuItem key={key}>
      <Link
        to={
          isOrganization(item)
            ? `/organizations/${item.id}/profile`
            : `/users/${item?.id}`
        }
        onClick={() => setIsOpenSearchList(false)}
      >
        <div className="inner-wrapper">
          <div style={{ marginRight: "12px" }}>
            <ProfileAvatar
              src={item?.image?.url}
              name={
                isOrganization(item)
                  ? `${item.name}`
                  : `${item?.name?.firstName} ${item?.name?.lastName}`
              }
            />
          </div>
          <BoxNameTitleContainer>
            <UserNameSpan>
              {isOrganization(item)
                ? `${item.name}`
                : `${item?.name?.firstName} ${item?.name?.lastName}`}
            </UserNameSpan>
            <div>{isOrganization(item) ? `${item.type}` : `${item.title}`}</div>
          </BoxNameTitleContainer>
        </div>
      </Link>
    </MenuItem>
  ));
};
