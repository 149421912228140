import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import React from "react";
import { ModalBody } from "./Styles";
import useScreenType from "react-screentype-hook";
import { POLICY_TYPES } from "../../utils/constants";
import { useGetProfileQuery } from "../../../redux/reducers/apiSlice";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const TermsAndConditionsModal = ({ isOpen, onClose }) => {
  const { isMobile } = useScreenType();

  const { data: currentUserProfileData } = useGetProfileQuery();

  const navigate = useNavigate();

  return (
    <Modal
      size={isMobile ? "full" : "xl"}
      onClose={onClose}
      isOpen={isOpen}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>MEASURE IGNITE TERMS & CONDITIONS</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <h3>
            Welcome to Measure Ignite. Before you access our platform, please
            read and understand our terms of service.
          </h3>
          <p>
            These Terms of Service (“Terms”) govern your access to and use of
            our services, including our website, SMS, APIs, email notifications,
            applications, buttons, widgets, ads, and our other covered services
            that link to these Terms (collectively, the “Services”), and any
            information, text, links, graphics, photos, audio, videos, or other
            materials or arrangements of materials uploaded, downloaded or
            appearing on the Services (collectively referred to as “Content”).
            By using the Services you agree to be bound by these Terms. If you
            do not agree to these Terms, you must refrain from using the
            Services or viewing the Content. These Terms are an agreement
            between you and Measure Austin, which maintains the Measure Ignite
            website and the Services, 305 East Huntland Drive, Austin TX 78752
            U.S.A. The words “we,” “us,” and “our” mean Measure Austin.{" "}
          </p>
          <h3>1. Who May Use the Services</h3>
          <p>
            You may use the Services only if you agree to form a binding
            contract with us and are not a person barred from receiving services
            under the laws of the applicable jurisdiction. In any case, you must
            be at least 18 years old to use the Services. If you are accepting
            these Terms and using the Services on behalf of a company,
            organization, government, or other legal entity, you represent and
            warrant that you are authorized to do so and have the authority to
            bind such entity to these Terms, in which case the words “you” and
            “your” as used in these Terms shall refer to such entity.
          </p>{" "}
          <p>
            Your Measure Ignite Platform Account. To utilize our Services, we
            may require you to establish an Account. You commit to providing
            accurate, up-to-date, and complete information for your Account and
            consent to our use of this information to communicate with you
            regarding our Services. Communications made to you through the
            details provided in your Account will fulfill any legal notice
            requirements.
          </p>
          <p>
            In our commitment to not perpetuate the harms associated with social
            media use, particularly among younger audiences, we require all
            Account holders to be over the age of 18. This policy is designed to
            ensure a responsible use of our Services, recognizing the potential
            impacts on mental health and development for underage users.
          </p>
          <p>
            You must not share your Account login details, Measure Ignite API
            key, or any Account credentials with others. Furthermore, you are
            prohibited from allowing others to use your Account. You bear full
            responsibility for any activities conducted through your Account and
            must immediately inform us of any unauthorized use of your Account
            by emailing{" "}
            <a href="mailto:ignite@wemeasure.org"> ignite@wemeasure.org</a>.
            Should you wish to terminate your Account, you may do so at any time
            by reaching out to us at{" "}
            <a href="mailto:ignitesupport@wemeasure.org">
              ignitesupport@wemeasure.org
            </a>
            .
          </p>
          <h3>2. Responsible Use Of Our Services</h3>
          <p>
            You are not permitted to access or use Ignite's Services, or assist
            someone else in doing so, in ways that:
          </p>
          <p>
            Violate any laws or regulations, including those related to the
            exportation of data or software to and from the United States or
            other countries. This means keeping it legal, especially when it
            comes to moving data or software across borders.
          </p>
          <p>
            Develop products or services that compete with ours, including using
            our resources to create or improve artificial intelligence or
            machine learning projects. Basically, don’t use what we offer to
            start a competing business or to make AI or machine learning tools.
          </p>
          <p>
            Decompile, reverse engineer, disassemble, or otherwise turn our
            Services into a format you can read, unless the law explicitly
            allows it. In simpler terms, don’t break down our services to peek
            at the underlying code, unless the law is on your side.
          </p>
          <p>
            Crawl, scrape, or harvest data from our Services without permission.
            Don’t go taking information from us without the okay.
          </p>
          <p>
            Use our Services to unauthorizedly access systems, deceive
            individuals, or any action that infringes upon someone’s privacy or
            copyrights. This includes not tricking people or messing with their
            stuff.
          </p>
          <p>
            Access our Services through automated methods, such as bots or
            scripts, except with an Ignite API Key or our express permission.
            Stick to accessing our services in the intended way, unless you’re
            using an API Key we gave you or we’ve said it’s alright.
          </p>
          <p>
            Engage in any behavior that prevents others from using our Services
            enjoyably or puts us or anyone else at risk, including harm to our
            reputation. In other words, don’t do things that could harm others’
            ability to enjoy our services or put us in a bad light.
          </p>
          <p>
            Additionally, you must avoid abusing our Services in any way that
            could introduce harmful software, overload our systems, or bypass
            security measures. We aim to maintain a respectful and safe
            environment for everyone, so let’s work together to keep it that
            way.
          </p>
          <h3>3. Privacy</h3>
          <p>
            Please refer to our{" "}
            <a
              target="_blank"
              href={`${location.pathname}?${POLICY_TYPES.PRIVACY}`}
            >
              Privacy Policy{" "}
            </a>
            to learn about how we handle the information you provide to us when
            you use our Services. By using our Services, you acknowledge and
            consent to the collection and use of your information as described
            in the{" "}
            <a
              target="_blank"
              href={`${location.pathname}?${POLICY_TYPES.PRIVACY}`}
            >
              Privacy Policy{" "}
            </a>
            .
          </p>
          <h3>4. Content on the Services</h3>
          <p>
            You are responsible for your use of the Services and for any Content
            you provide, including compliance with applicable laws, rules, and
            regulations. You should only provide Content that you are
            comfortable sharing with others. You have the right to freedom of
            expression, provided that you must adhere to our{" "}
            <a
              target="_blank"
              href={`${location.pathname}?${POLICY_TYPES.ACCEPTABLE_USE}`}
            >
              Acceptable Use Policy{" "}
            </a>{" "}
            and these Terms. By using our Services, you acknowledge and consent
            to the terms contained in our{" "}
            <a
              target="_blank"
              href={`${location.pathname}?${POLICY_TYPES.ACCEPTABLE_USE}`}
            >
              Acceptable Use Policy{" "}
            </a>
            . You are responsible for all activities under your account.
          </p>
          <p>
            The Services provide access to Content and materials that are posted
            by users, and you acknowledge and agree that any use or reliance on
            such Content is solely at your own risk. We do not endorse, support,
            represent, or guarantee the completeness, truthfulness, accuracy, or
            reliability of any Content or communications posted via the
            Services, nor do we endorse any opinions expressed via the Services.
            By using the Services, you understand that you may be exposed to
            Content that could be offensive, harmful, inaccurate, or otherwise
            inappropriate, or that may have been mislabeled or are otherwise
            deceptive. All Content is the sole responsibility of the person who
            created such Content. We do not monitor or control the Content
            posted via the Services and are not responsible for such Content.
            All user-generated Content remains the intellectual property of the
            original creator. We will not claim ownership or commercialize user
            Content without explicit permission from the original creator.
          </p>
          <p>
            We reserve the right to remove Content that violates these Terms of
            Service, including for example, copyright or trademark violations or
            other intellectual property misappropriation, impersonation,
            unlawful conduct, or harassment. Please contact us for more
            information regarding specific policies and the process for
            reporting or appealing violations.
          </p>
          <p>
            If you believe that your Content has been copied in a way that
            constitutes copyright infringement, please report this by contacting
            us at:{" "}
            <a href="mailto:ignite@wemeasure.org">ignite@wemeasure.org</a>
          </p>
          <em>Your Rights and Grant of Rights in the Content</em>
          <p>
            You maintain ownership of any Content that you submit, post, or
            display on or through the Services. Your Content, which includes any
            incorporated audio, photos, and videos, remains exclusively yours.
          </p>
          <p>
            By submitting, posting, or displaying Content on or through the
            Services, you grant us a worldwide, non-exclusive, royalty-free
            license, including the right to sublicense, to use, copy, reproduce,
            process, adapt, modify, publish, transmit, display, and distribute
            such Content through any and all media or distribution methods now
            known or later developed. This license includes the right to curate,
            transform, and translate your Content. You acknowledge that this
            license authorizes us to make your Content available to others and
            to allow them to do the same.
          </p>
          <p>
            You agree that this license includes the right for us to provide,
            promote, and improve the Services, and to make Content submitted to
            or through the Services available to other companies, organizations,
            or individuals for the syndication, broadcast, distribution,
            promotion, or publication of such Content on other media and
            services, subject to our terms and conditions for such Content use.
            Such additional uses by us, or other companies, organizations, or
            individuals, is made with no compensation paid to you with respect
            to the Content that you submit, post, transmit, or otherwise make
            available through the Services, as your use of the Services is
            considered sufficient compensation for the grant of rights and use
            of your Content herein.
          </p>
          <p>
            You declare and guarantee that you possess or have obtained all the
            rights, licenses, consents, permissions, power, and/or authority
            required to grant the rights explained in this agreement for any
            Content you submit, post or display on or through the Services. You
            acknowledge that the submitted Content will not contain any material
            that is subject to copyright or other proprietary rights, unless you
            have obtained necessary permission or are otherwise legally
            authorized to post the material and grant us the license as
            described above.
          </p>
          <h3>5. Anti-Racism</h3>
          <p>
            Our Service is committed to promoting anti-racism, inclusivity, and
            diversity. We believe that racism, hate speech, discrimination, and
            intolerance have no place in our community. We expect all users to
            comply with our anti-racism policies and to help us create a safe
            and welcoming environment for everyone.
          </p>
          <p>
            You are prohibited from posting any content that promotes racism,
            discrimination, hate speech, or any other form of intolerance,
            including but not limited to content that:
          </p>
          <ol type="a" inlist={"true"}>
            <li>
              Attacks or degrades individuals or groups based on their race,
              ethnicity, national origin, religion, gender, sexual orientation,
              or any other characteristic protected by law;
            </li>
            <li>
              Stereotypes that perpetuate harmful myths or falsehoods about
              certain racial or ethnic groups;
            </li>
            <li>
              Derogatory or inflammatory language, slurs, or symbols associated
              with hate groups;
            </li>
            <li>
              Incites or glorifies violence or promotes terrorism, extremism, or
              any other illegal activity;
            </li>
            <li>
              or Threatens, harasses, or intimidates individuals or groups based
              on their race, ethnicity, or any other characteristic protected by
              law.
            </li>
          </ol>
          <p>
            As we develop our product we aim to provide a report system that
            allows users to report content or behavior that violates our
            anti-racism policies. Reports are reviewed by our team, and we will
            take appropriate action, which may include removing content,
            disabling accounts, or reporting illegal activities to law
            enforcement. Please contact us to report content or behavior that
            you believe may violate our anti-racism policies.
          </p>
          <p>
            We reserve the right to monitor the Service for any content or
            behavior that violates our anti-racism policies. We also reserve the
            right to remove any content or terminate any user account that
            violates these policies. We may take further action, including legal
            action, against any user who engages in discriminatory behavior or
            who violates our anti-racism provisions. We may also block any user
            who violates our policies or who engages in discriminatory behavior.
          </p>
          <p>
            We have a zero-tolerance policy for racism, hate speech,
            discrimination, or any other form of intolerance. We take these
            issues seriously and will not hesitate to take appropriate action to
            protect our community from harm. We also believe in promoting open
            and respectful dialogue on important issues, and we encourage users
            to engage in constructive conversations that promote understanding
            and mutual respect.
          </p>
          <p>
            While we make every effort to ensure that our Service is free from
            racism, discrimination, and hate speech, we cannot guarantee that
            every user will comply with our policies. We are not responsible for
            any content posted by users or for any harm resulting from such
            content.
          </p>
          <h3>
            6. Unwavering Commitment to Responsible, Culturally Respectful, and
            Ethical AI Use
          </h3>
          <p>
            At Ignite, we are unwavering in our commitment to the responsible
            development and deployment of artificial intelligence (AI)
            technologies that honor and deeply respect cultural diversity while
            upholding the highest ethical standards. Our dedication to these
            principles is an integral part of our core values and is deeply
            ingrained in every aspect of our services. We require all users to
            strictly adhere to the following non-negotiable guidelines:
          </p>
          <p>
            <strong>Profound Respect for Diversity:</strong> Users must ensure
            that their use of AI and related technologies through our services
            does not, under any circumstances, perpetuate bias, discrimination,
            or harm against any individual or group. This includes a
            comprehensive and thorough consideration of cultural sensitivities,
            nuances, and the diverse backgrounds of all individuals impacted by
            AI.
          </p>
          <p>
            <strong>Steadfast Ethical Application:</strong> AI technologies must
            be used in a manner that actively promotes fairness, accountability,
            and transparency as an unwavering priority. Users should
            relentlessly strive to use AI to enhance societal well-being,
            improve human experience, and resolutely avoid any actions that
            could deceive, defraud, or disadvantage others.
          </p>
          <p>
            <strong>Uncompromising Privacy and Data Protection:</strong> The use
            of AI must rigorously respect individual privacy rights and strictly
            comply with all applicable data protection laws. Users are expected
            to handle data with the utmost ethical integrity, ensuring the
            absolute confidentiality and inviolability of personal information.
          </p>
          <p>
            <strong>Absolute Inclusivity:</strong> AI solutions must be designed
            and implemented to be inclusive to the highest degree, providing
            equitable access and benefits across all cultures, languages, and
            regions. We actively encourage and support the development of AI
            that bridges gaps and fosters profound understanding among diverse
            communities.
          </p>
          <p>
            <strong>Steadfast Collaboration for Good:</strong> We urge our users
            to engage in collaborative efforts that leverage AI for social good
            with unwavering determination, addressing global challenges and
            promoting sustainable development in a way that deeply respects
            cultural differences and staunchly upholds human rights.
          </p>
          <p>
            We will rigorously review and monitor AI applications for compliance
            with these inviolable principles. Any failure to adhere to these
            guidelines will result in immediate and resolute actions, including
            restricted access to our services or termination of the user's
            account. Together, with our commitment, we can ensure that AI serves
            as a powerful force for positive change, reflecting our shared
            dedication to a more inclusive, equitable, and ethically guided
            future.
          </p>
          <h3>7. Digital Millennium Copyright Act (“DMCA”) Notice.</h3>
          <p>
            We are committed to complying with copyright and related laws, and
            require all users of the Service to comply with these laws.
            Accordingly, you may not store any material or content or use or
            disseminate any material or content though the Service in any manner
            that constitutes an infringement of third party intellectual
            property rights, including rights granted by copyright law. Owners
            of copyrighted works who believe that their rights under copyright
            law have been infringed may take advantage of certain provisions of
            the Digital Millennium Copyright Act of 1998 (the “DMCA”) to report
            alleged infringements. You may not post, modify, distribute, or
            reproduce in any way any copyrighted material, trademarks, or other
            proprietary information belonging to others without obtaining the
            prior written consent of the owner of such proprietary rights. It is
            the policy of MEASURE Austin to terminate use privileges of any user
            who repeatedly infringes the copyright rights of others upon receipt
            of proper notification to us by the copyright owner or the copyright
            owner’s legal agent.
          </p>
          <p>
            If you feel that a post is objectionable or infringing, we encourage
            you to contact us immediately. Upon our receipt of a proper written
            notice of claimed infringement under the DMCA, we will respond
            expeditiously to remove, or disable access to, the material claimed
            to be infringing and will follow all other relevant procedures
            specified in the DMCA with regard to the claimed infringement. Our
            designated agent (i.e., the proper party) to whom you should address
            such notice is listed below.
          </p>
          <p>
            IF YOU BELIEVE THAT YOUR WORK HAS BEEN COPIED AND POSTED USING THE
            SERVICE IN A WAY THAT CONSTITUTES COPYRIGHT INFRINGEMENT, PLEASE
            PROVIDE OUR DESIGNATED AGENT WITH A WRITTEN COMMUNICATION CONTAINING
            THE FOLLOWING INFORMATION:
          </p>
          <ol type="a">
            <li>
              An electronic or physical signature of the person authorized to
              act on behalf of the owner of the exclusive right in the copyright
              that is allegedly infringed;
            </li>
            <li>
              A description of the copyrighted work that you claim has been
              infringed;
            </li>
            <li>
              A description of where the material that you claim is infringing
              is located to permit us to locate the material;
            </li>
            <li>Your address, telephone number, and email address;</li>
            <li>
              A statement by you that you have a good faith belief that the
              disputed use is not authorized by the copyright owner, its agent,
              or the law;
            </li>
            <li>
              and A statement by you, made under penalty of perjury, that the
              information contained in your report is accurate and that you are
              the owner of the exclusive right in the copyright that is
              allegedly infringed or authorized to act on the owner's behalf.
            </li>
          </ol>
          <p>
            Our designated agent for notice of claims of copyright infringement
            can be reached as follows:{" "}
            <a target="_blank" href="mailto:hello@wemeasure.org">
              hello@wemeasure.org
            </a>
          </p>
          <h3>8. Using the Services </h3>
          <p>
            We may develop Rules and Policies, which are part of the Terms of
            Service and outline what is prohibited on the Services. You may use
            the Services only in compliance with these Terms and all applicable
            laws, rules and regulations.
          </p>
          <p>
            Our Services are subject to development and improvement. Therefore,
            we reserve the right to make changes to the Services or any of its
            features, in our sole discretion, at any time and without notice. We
            may choose to discontinue, temporarily or permanently, the provision
            of the Services or any of its features to you or to users generally.
            We reserve the right to impose limits on usage and storage at our
            sole discretion, and to remove or refuse to distribute any Content
            on the Services, limit the distribution or visibility of any Content
            on the Services, suspend or terminate users, and reclaim usernames,
            without incurring any liability to you.
          </p>
          <p>
            We reserve the right to access, read, preserve, and disclose any
            information that we reasonably believe is necessary for the
            following purposes: (i) to comply with applicable laws, regulations,
            legal processes, or governmental requests; (ii) to enforce the
            Terms, including investigating potential violations; (iii) to
            detect, prevent, or address fraud, security, or technical issues;
            (iv) to respond to user support requests; or (v) to protect the
            rights, property, or safety of MEASURE Austin, its users, and the
            public. We will not disclose personally identifying information to
            third parties except in accordance with our{" "}
            <a
              target="_blank"
              href={`${location.pathname}?${POLICY_TYPES.PRIVACY}`}
            >
              Privacy Policy{" "}
            </a>
            .
          </p>
          <em>Your Account</em>
          <p>
            To access certain Services, you may be required to create an
            account. It's your responsibility to keep your account secure by
            using a strong password and not sharing it with anyone else. Failure
            to comply with these requirements may result in loss or damage for
            which we cannot be held responsible.
          </p>
          <p>
            You can control most communications from the Services. We may need
            to provide you with certain communications, such as service
            announcements and administrative messages. These communications are
            considered part of the Services and your account, and you may not be
            able to opt-out from receiving them. If you added your phone number
            to your account and you later change or deactivate that phone
            number, you must update your account information to help prevent us
            from communicating with anyone who acquires your old number.
          </p>
          <em>Your License to Use the Services</em>
          <p>
            We grant you a non-exclusive, non-assignable, personal, worldwide,
            and royalty-free license to use the software that is provided to you
            as part of the Services. The sole purpose of this license is to
            allow you to use and enjoy the benefits of the Services provided by
            Measure Ignite, as permitted by these Terms.
          </p>
          <p>
            The Services are legally protected by copyright, trademark, and
            other laws in the United States. These Terms do not grant you the
            right to use the name "MEASURE AUSTIN" or any of its trademarks,
            logos, domain names, or other unique brand features or proprietary
            rights. We and our licensors own all rights, titles, and interests
            in and to the Services, except for Content provided by users. If you
            provide us with any feedback, comments, or suggestions regarding
            MEASURE Ignite or the Services, it is entirely voluntary, and we may
            use such feedback, comments, or suggestions at our discretion
            without any obligation to you.
          </p>
          <em>Ending These Terms</em>
          <p>
            You may end your legal agreement with us at any time by deactivating
            your accounts and discontinuing your use of the Services. See{" "}
            <a
              target="_blank"
              onClick={() => {
                if (currentUserProfileData?.id) {
                  navigate(
                    `/users/${currentUserProfileData.id}?editProfile=true&deactivateUser=true`
                  );
                } else {
                  toast("You must be logged in to deactivate your account");
                }
              }}
            >
              this link
            </a>{" "}
            for instructions on how to deactivate your account and the Privacy
            Policy for more information on what happens to your information.
          </p>
          <p>
            We reserve the right to suspend or terminate your account or
            discontinue all or part of the Services at any time and for any
            reason, including, but not limited to, if we reasonably believe: (i)
            you have violated these Terms or any applicable policies; (ii) your
            actions may cause legal exposure or risk for us; (iii) you have
            engaged in unlawful conduct; (iv) your account has been inactive for
            an extended period; or (v) any other reason as we determine in our
            sole discretion. We will make reasonable efforts to notify you via
            the email address associated with your account or the next time you
            attempt to access your account, depending on the circumstances. If
            you think your account was terminated in error, you can file an
            appeal by contacting us at
            <a target="_blank" href="mailto:ignite@wemeasure.org">
              ignite@wemeasure.org
            </a>
            . For clarity, these Terms survive the deactivation or termination
            of your account.
          </p>
          <h3>9. Disclaimers and Limitations of Liability</h3>
          <p>
            The Services are available "AS-IS" By accessing and using the
            Services or any Content, you acknowledge and agree that you do so at
            your own risk. The Services are provided on an "AS IS" and "AS
            AVAILABLE" basis. The term "MEASURE Entities" includes MEASURE
            Austin, its affiliates, related companies, officers, directors,
            employees, agents, representatives, partners, and licensors. The
            MEASURE Entities DISCLAIM ALL WARRANTIES AND CONDITIONS, WHETHER
            EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT, to the
            maximum extent permitted under applicable law. The MEASURE Entities
            also make no warranty or representation and disclaim all
            responsibility and liability for: (i) the completeness, accuracy,
            availability, timeliness, security, or reliability of the Services
            or any Content; (ii) any harm to your computer system, loss of data,
            or other harm resulting from your access to or use of the Services
            or any Content; (iii) the deletion of, or the failure to store or to
            transmit, any Content and other communications maintained by the
            Services; and (iv) whether the Services will meet your requirements
            or be available on an uninterrupted, secure, or error-free basis.
            You understand that no advice or information, whether oral or
            written, obtained from the MEASURE Entities or through the Services,
            will create any warranty or representation not expressly made
            herein.
          </p>
          <em>Limitation of Liability</em>
          <p>
            THE MEASURE ENTITIES WILL NOT BE LIABLE FOR ANY INDIRECT,
            INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR ANY LOSS
            OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR
            ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, TO THE
            MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW. THIS INCLUDES (I) YOUR
            ACCESS TO OR USE OF, OR INABILITY TO ACCESS OR USE, THE SERVICES;
            (II) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE SERVICES,
            INCLUDING WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE, OR ILLEGAL
            CONDUCT OF OTHER USERS OR THIRD PARTIES; (III) ANY CONTENT OBTAINED
            FROM THE SERVICES; OR (IV) UNAUTHORIZED ACCESS, USE, OR ALTERATION
            OF YOUR TRANSMISSIONS OR CONTENT. THE AGGREGATE LIABILITY OF THE
            MEASURE ENTITIES SHALL NOT EXCEED THE LESSER OF ONE HUNDRED U.S.
            DOLLARS (U.S. $100.00) OR THE AMOUNT YOU PAID US, IF ANY, IN THE
            PAST SIX MONTHS FOR THE SERVICES GIVING RISE TO THE CLAIM. THESE
            LIMITATIONS SHALL APPLY TO ANY THEORY OF LIABILITY, WHETHER BASED ON
            WARRANTY, CONTRACT, STATUTE, TORT (INCLUDING NEGLIGENCE), OR
            OTHERWISE, AND WHETHER OR NOT THE MEASURE ENTITIES HAVE BEEN
            INFORMED OF THE POSSIBILITY OF ANY SUCH DAMAGE. THESE LIMITATIONS
            SHALL APPLY EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE
            FAILED OF ITS ESSENTIAL PURPOSE.
          </p>
          <h3>10. General</h3>
          <p>
            From time to time, we may update these Terms. The updated Terms will
            be available here and will govern our relationship with you. The
            changes will not apply retroactively, and we will make reasonable
            efforts to notify you of any material revisions, such as by sending
            a service notification or an email to the email address associated
            with your account. By continuing to access or use the Services after
            the revised Terms become effective, you agree to be bound by them.
          </p>
          <p>
            The laws of the State of Texas, excluding its choice of law
            provisions, will govern these Terms and any dispute that arises
            between you and us. All disputes related to these Terms or the
            Services will be brought solely in the federal or state courts
            located in Travis County, Texas, United States, and you consent to
            personal jurisdiction and waive any objection as to inconvenient
            forum.
          </p>
          <p>
            If you are a federal, state, or local government entity in the
            United States using the Services in your official capacity and
            legally unable to accept the controlling law, jurisdiction or venue
            clauses above, then those clauses do not apply to you. For such U.S.
            federal government entities, these Terms and any action related
            thereto will be governed by the laws of the United States of America
            (without reference to conflict of laws) and, in the absence of
            federal law and to the extent permitted under federal law, the laws
            of the State of Texas (excluding choice of law).
          </p>
          <p>
            If any part of these Terms is deemed invalid or unenforceable, that
            part will be modified or removed to the minimum extent required, and
            the remaining parts will remain in full force and effect. Our
            failure to enforce any provision of these Terms will not be
            considered a waiver of that provision or our right to enforce it.
          </p>
          <p>
            If you have any questions about these Terms, please contact{" "}
            <a target="_blank" href="mailto:ignite@wemeasure.org">
              ignite@wemeasure.org
            </a>
            .
          </p>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TermsAndConditionsModal;
