import styled from "styled-components";
import { devices } from "../../Theme.css";
import { NavLink as Link } from "react-router-dom";

type SidebarWrapperProps = {
  animation: "default" | "moveRight" | "moveLeft";
};

export const SidebarWrapper = styled.div<SidebarWrapperProps>`
  position: fixed;
  left: 0;
  top: ${({ theme }) => theme.height.header};
  right: auto;
  bottom: 0;
  width: ${({ theme }) => theme.width.sidebar};
  height: auto;
  background: #f9f9f9;
  box-shadow: 1px 1px 6px rgba(64, 26, 15, 0.05);
  overflow-y: auto;
  padding: 44px 15px 30px;
  z-index: ${({ theme }) => theme.zIndex.sidebar};
  transition: left 0.5s ease;

  .signout {
    display: none;
  }

  @media ${devices.max_sm} {
    .signout {
         display: flex;
        /* justify-content: center; */
        margin-left: 50px;
        margin-top: 50px;

        button {
          font-weight bold;
          color: red;
        }
        background-color:
    }

    left: -${({ theme }) => theme.width.sidebar};
    ${(props) =>
      props.animation === "moveRight"
        ? `left: 0`
        : props.animation === "moveLeft"
        ? `left: -${props.theme.width.sidebar}`
        : `left: -${({ theme }) => theme.width.sidebar};`}
  }
`;

export const NavLink = styled(Link)`
  display: flex;
  position: relative;
  align-items: center;
  height: 58px;
  border-radius: 100px;
  text-decoration: none;
  color: #000;
  background: transparent;
  padding: 12px 20px;
  margin-bottom: 10px;
  &:hover,
  &.active {
    color: #fff;
    background: #000;
    .icon {
      img {
        &.img-normal {
          display: none;
        }
        &.img-active {
          display: block;
        }
      }
    }
  }
  .icon {
    font-size: 26px;
    margin-right: 8px;
    img {
      width: 36px;
      &.img-normal {
        display: block;
      }
      &.img-active {
        display: none;
      }
    }
  }
  .txt {
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    transition: none;
  }
`;

export const TextBadge = styled.div`
  height: 19px;
  width: 19px;
  font-size: 13px;
  background: #ed3724;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  top: 7px;
  opacity: 0.95;
  color: white;
`;

export const TextNotification = styled.div`
  display: flex;
  align-items: center;
  .text {
    margin-left: 8px;
  }
`;

export const ProfileInformations = styled.div`
  display: none;
  width: 100%;

  p {
    margin-left: 12px;
    font-weight: bold;
    color: black;
  }

  @media ${devices.max_sm} {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
    margin-bottom: 24px;
  }
`;
