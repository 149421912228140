import styled from "styled-components";
import { devices } from "../../../Theme.css";

export const TabWrapper = styled.div<{ hasIntroCard?: boolean }>`
  padding-top: 24px;

  .postcard-and-posts-container {
    width: 60%;

    @media screen and (max-width: 1290px) and (min-width: 767px) {
      width: 100%;
    }

    ${(props) =>
      props.hasIntroCard &&
      `
      @media screen and (max-width: 973px) {
        margin-top: 40px;
      }
      @media screen and (max-width: 375px) {
        margin-top: 80px;
      }
    
    `}

    @media ${devices.max_sm} {
      width: 100%;
    }
  }
`;

export const PostsDivider = styled.div`
  height: 1px;
  background-color: #eee;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 32px;
`;

export const Card = styled.div`
  border-radius: 40px;
  background: #fff;
  box-shadow: 1px 1px 6px 0px rgba(64, 26, 15, 0.25);
  padding: 24px;
`;

export const Form = styled.form`
  .form-row {
    display: flex;
    flex-direction: row;
  }

  .post-input {
    width: 100%;
    margin-left: 13px;
  }

  .form-bottom-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
  }

  label {
    display: inline-block;
    color: #000;

    /* Paragraph */
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 24px;
  }
  input[type="file"] {
    display: none;
  }

  .add-image-text {
    margin-left: 10px;
  }
`;

export const Input = styled.input`
  background-color: #eee;
  padding: 11px 16px;
  border-radius: 100px;
  ::placeholder {
    color: var(--text-input-field, #000);
    font-feature-settings: "clig" off, "liga" off;
    /* Paragraph */
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

interface PostCardAndDividerWrapperProps {
  fixed?: boolean;
}

export const PostCardAndDividerWrapper = styled.div<PostCardAndDividerWrapperProps>`
  background-color: white;
  width: 100%;
  z-index: 80;

  @media ${devices.max_sm} {
    width: 100%;
    margin: 0;
    margin-top: -25px;
    padding: 0px;
  }
`;

export const OrganizationPicture = styled.div``;

interface LayoutProps {
  withSuggestions: boolean;
}

export const Layout = styled.div<LayoutProps>`
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  ${(props) =>
    !props.withSuggestions &&
    `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-content: center;
  `}

  .posts-container {
    width: 100%;
    flex: 1;
  }

  @media only screen and (max-width: 1290px) and (min-width: 767px) {
    flex-direction: column;
    /* background: red; */
  }

  @media ${devices.max_sm} {
    flex-direction: column;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const PostSpacer = styled.div`
  margin: 32px 0px;
`;

export const PostMobileDivider = styled.div`
  display: none;
  border-bottom: 1px solid #eee;

  @media ${devices.max_sm} {
    display: block;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px auto;
`;
