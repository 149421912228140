import styled from "styled-components";
import { devices } from "../../../Theme.css";

export const GoalsWrapper = styled.div`
  @media ${devices.max_sm} {
    padding: 12px;
  }
`;

export const GoalsDescription = styled.p``;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  margin-bottom: 24px;

  button {
    color: #000;
    text-align: right;
    font-feature-settings: "clig" off, "liga" off;

    /* Link */
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 24px;
  }
`;

export const SparksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
`;
