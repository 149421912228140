import React from "react";
import { useNavigate } from "react-router-dom";
import { addNewOrganizationsCardsData } from "../../../utils/constants";
import {
  AddNewOrganizationWrapper,
  AddOrganizationCard,
  CardsLayout,
  PageTitle,
  BackButtonWrapper,
} from "./styles";
import { Back } from "../../../Components/Back/Back";

export const AddNewOrganization = () => {
  const navigate = useNavigate();

  return (
    <AddNewOrganizationWrapper>
      <BackButtonWrapper>
        <Back />
      </BackButtonWrapper>
      <PageTitle>What kind of organization would you like to add?</PageTitle>
      <CardsLayout>
        {addNewOrganizationsCardsData.map((cardData, id) => {
          const Illustration = cardData.illustration;
          return (
            <AddOrganizationCard
              onClick={() =>
                navigate(`/organizations/new/form`, {
                  state: {
                    type: cardData.title.toLowerCase(),
                  },
                })
              }
              key={id}
            >
              <div className="illustration-container">
                <Illustration />
              </div>
              <div className="title">{cardData.title}</div>
              <div className="description">{cardData.description}</div>
            </AddOrganizationCard>
          );
        })}
      </CardsLayout>
    </AddNewOrganizationWrapper>
  );
};
