import styled from "styled-components";

export const BoxReact = styled.div`
  display: flex;
`;

export const ContainerReact = styled.div`
  padding: 0.5rem;
  cursor: pointer;
  transition: transform 0.4s;
  &:hover {
    transform: scale(1.35);
  }
`;

export const ContainerEmotion = styled.div`
  display: flex;
  margin-left: 10px;
`;

export const BoxCountEmotion = styled.div`
  border: 1px solid #ee5a29;
  color: #ee5a29;
  height: 24px;
  width: 48px;
  border-radius: 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  margin-right: 10px;
  cursor: pointer;
`;

export const CountEmotion = styled.div`
  font-size: 12px;
  width: 20px;
  text-align: center;
`;
