import React, { useState, useEffect } from "react";
import { Spinner } from "@chakra-ui/react";
import { InviteMember } from "../../../Assets/SvgIcons";
import { PersonWrapper, PeopleWrapper } from "./styles";
import toast from "react-hot-toast";
import { MESSAGE } from "../../../Constants/Message";
import { useFollowUserMutation } from "../../../../redux/reducers/apiSlice";
import { ProfileAvatar } from "../../ProfileAvatar/ProfileAvatar";
import { User } from "../../../../redux/reducers/types";
import { truncate } from "../../../utils/helper";
import { Link } from "react-router-dom";
import useScreenType from "react-screentype-hook";
import * as Sentry from "@sentry/react";

export const People: React.FC<{ people: User[]; loading?: boolean }> = ({
  people,
  loading,
}) => {
  const [followUser, { data: users }] = useFollowUserMutation();

  const [loadingFollowUser, setLoadingFollowUser] = useState<{
    [key: string]: boolean;
  }>({});

  const { isMobile } = useScreenType();

  useEffect(() => {
    const loadings = loadingFollowUser;
    people.forEach((person) => {
      loadings[person.id] = false;
    });
    setLoadingFollowUser(loadings);
  }, [people]);

  const followUserFn = async (person) => {
    try {
      let loadingFollowUserCopy = { ...loadingFollowUser };
      loadingFollowUserCopy[person?.id] = true;
      setLoadingFollowUser(loadingFollowUserCopy);
      await followUser({ userId: person?.id, type: "USER" }).unwrap();
      loadingFollowUserCopy = { ...loadingFollowUser };
      loadingFollowUser[person?.id] = false;
      setLoadingFollowUser(loadingFollowUserCopy);
      toast.success(
        `You just followed ${person.name.firstName} ${person.name.lastName}`
      );
    } catch (e) {
      Sentry.captureException(e);
      const loadingFollowUserCopy = { ...loadingFollowUser };
      loadingFollowUser[person?.id] = false;
      setLoadingFollowUser(loadingFollowUserCopy);
      toast.error(MESSAGE.SYSTEM_ERROR);
    }
  };

  if (loading) return <p>Searching...</p>;

  return (
    <PeopleWrapper>
      {(!people || people.length === 0) && <p>No one to suggest.</p>}
      {people.map((person) => (
        <PersonWrapper isMobile={isMobile} key={person.id}>
          <Link to={`/users/${person.id}`}>
            <div style={{ display: "flex", width: 300 }}>
              <div className="first-column">
                <ProfileAvatar
                  src={person?.image?.url}
                  name={truncate(
                    `${person?.name.firstName} ${person?.name.lastName}`
                  )}
                  border={`2px solid #eee`}
                />
              </div>

              <div className="second-column">
                <p className="name">
                  {truncate(`${person.name.firstName} ${person.name.lastName}`)}
                  <p className="caption">{person.email}</p>
                </p>
              </div>
            </div>
          </Link>

          <div className="third-column">
            {loadingFollowUser[person.id] && <Spinner color="red" />}
            {!loadingFollowUser[person.id] && (
              <button onClick={() => followUserFn(person)}>
                <InviteMember boxSize={8} />
              </button>
            )}
          </div>
        </PersonWrapper>
      ))}
    </PeopleWrapper>
  );
};
