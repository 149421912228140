import styled from "styled-components";

export const ProfileDetailsLeft = styled.div`
  flex: 0.2;

  .profile-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
  }

  .profile-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f0f0f0;
    height: 188px;
    border-radius: 100%;
    width: 188px;

    @media screen and (max-width: 767px) : {
      width: 135px;
      height: 135px;
      margin: 0 auto;
    }
    position: relative;
  }

  .edit-icon-wrapper {
    width: 40;
    height: 40;
    display: flex;
    align-items: center;
    justify-content: center;
    background: vars.color.white;
    border-radius: 50 / 2;
    position: absolute;
    right: 9px;
    bottom: 9px;
    cursor: pointer;
    z-index: 1000;
    box-shadow: 0px 2.975px 5.95px rgba(97, 97, 97, 0.18),
      0px 5.95px 11.9px rgba(97, 97, 97, 0.18);
  }
`;
