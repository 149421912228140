import styled from "styled-components";
import { devices } from "../../../Theme.css";

export const AboutCard = styled.div`
  border-radius: 40px;
  background: #fff;
  box-shadow: 1px 1px 6px 0px rgba(64, 26, 15, 0.25);
  padding: 32px;
  margin-bottom: 24px;

  @media ${devices.max_sm} {
    box-shadow: none;
  }
`;

export const AboutCardMobileDivider = styled.div`
  display: none;
  border-bottom: 1px solid #eee;
  @media ${devices.max_sm} {
    display: block;
  }
`;

export const GridLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(50px, 1fr));
  row-gap: 10px;
`;

export const Category = styled.div``;

export const Column = styled.div`
  background-color: red;
`;

export const Label = styled.p`
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140.5%; /* 19.67px */
`;

export const LinkItem = styled.a`
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: blue;
  text-decoration: underline;
  color: #ee5a29;

  :active {
    color: #ee5a29;
  }
  :visited {
    color: red;
  }
`;

export const Value = styled.p`
  color: #000;
  font-feature-settings: "clig" off, "liga" off;

  /* Paragraph */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const PersonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;

  .first-column {
    /* background-color: red; */
    margin-right: 12px;
    height: 100%;
  }

  .second-column {
    /* background-color: blue; */
    width: 100%;
    margin-right: 55px;
    height: 100%;
  }

  .third-column {
    /* background-color: green; */
    height: 100%;
  }

  img {
    width: 100%;
    display: block;
    background-color: #eee;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
  }

  .no-image-circle {
    background-color: #eee;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
  }

  .name {
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140.5%; /* 19.67px */
  }

  .caption {
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  :last-child {
    margin-bottom: 0;
  }
`;

export const AboutSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AboutSectionTitle = styled.h2`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140.5%;
  color: #000000;
  margin: 8px 0px;
`;

export const AboutSectionParagraph = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-bottom: 24px;
`;

export const TwoColumnLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CauseContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
`;

export const CauseTitle = styled.h2`
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140.5%;
`;

export const CauseList = styled.ul`
  list-style-type: none;
`;

export const CauseListItem = styled.li``;

export const EditLinkButton = styled.button`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #ef6b2d;
`;

export const EditButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;

export const Button = styled.div`
  background: #ee5a29;
  color: white;
  padding: 8px 16px;
  border-radius: 100px;
  :hover {
    cursor: pointer;
  }
`;

export const EmptyState = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .illustration-container {
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 31px;
  }
`;
