import React from "react";
import {
  RadioGroup,
  SimpleGrid,
  Flex,
  Box,
  Radio,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";

type Props = {
  options: { label: string; value: string; disabled?: boolean }[];
  formik: any;
  notListedFieldName?: string;
  name: string;
  isDisabled?: boolean;
  onChange?: (val?: any) => void;
  value?: string;
};

const RadioButtonGroup: React.FC<Props> = ({
  options,
  formik,
  notListedFieldName,
  name,
  isDisabled,
  onChange,
  value,
}) => (
  <RadioGroup
    isDisabled={isDisabled}
    onChange={(val) => {
      if (onChange) {
        onChange(val);
      } else {
        formik.setFieldValue(name, val);
        if (notListedFieldName) {
          formik.setFieldValue(notListedFieldName, "");
        }
      }
    }}
    value={value || formik.values[name]}
    name={name}
  >
    <SimpleGrid columns={[2]} spacingX="44px">
      {options.map((option) =>
        option.value === "not-listed" && notListedFieldName ? (
          <Box key={option.value} mb="10px" className="not-listed-field">
            <Flex minWidth="max-content" alignItems="center" gap="2">
              <Box>
                <Radio
                  key="not-listed"
                  value="not-listed"
                  colorScheme="primary"
                >
                  Not listed:
                </Radio>
              </Box>

              <Box flexGrow={1}>
                <Input
                  isDisabled={formik.values[name] != "not-listed"}
                  value={formik.values[notListedFieldName]}
                  onChange={(val) =>
                    formik.setFieldValue(notListedFieldName, val.target.value)
                  }
                  name={notListedFieldName}
                  className="flushed"
                  {...{ maxLength: 50 }}
                />
              </Box>
            </Flex>
            {notListedFieldName && (
              <p style={{ color: "red" }}>
                {formik.errors[notListedFieldName]}
              </p>
            )}
          </Box>
        ) : (
          <Box key={option.value} mb="10px">
            <Radio
              key={option.value}
              value={option.value}
              colorScheme="primary"
            >
              {option.label}
            </Radio>
          </Box>
        )
      )}
    </SimpleGrid>
  </RadioGroup>
);

export default RadioButtonGroup;
