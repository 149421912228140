import React from "react";
import {
  Button,
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
} from "@chakra-ui/react";

import useScreenType from "react-screentype-hook";

import { TitleWrapper } from "./Styles";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  disablePrimaryButton?: boolean;
  primaryLoading?: boolean;
  modalText?: string;
  modalTitle?: string;
  modalSubtitle?: string;
  children?: React.ReactNode;
  secondaryButtonText?: string;
  primaryButtonText?: string;
  onPrimaryButtonClicked?: () => void;
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "full";
  scrollBehavior?: "inside";
  isCentered?: boolean;
};
export const Modal = ({
  isOpen,
  onClose,
  modalTitle,
  modalText,
  children,
  secondaryButtonText,
  primaryLoading,
  disablePrimaryButton,
  primaryButtonText,
  onPrimaryButtonClicked,
  modalSubtitle,
  size = "xl",
  ...props
}: Props) => {
  const { isMobile } = useScreenType();

  return (
    <>
      <ChakraModal
        {...props}
        size={isMobile ? "full" : size}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent margin="0">
          <ModalHeader>
            <TitleWrapper>{modalTitle}</TitleWrapper>
          </ModalHeader>
          {modalSubtitle && (
            <div style={{ paddingLeft: "24px" }}>
              <h2>{modalSubtitle}</h2>
            </div>
          )}
          <ModalCloseButton />
          {!children && <ModalBody>{modalText}</ModalBody>}
          {children && children}
          {(primaryButtonText || secondaryButtonText) && (
            <ModalFooter>
              {secondaryButtonText && (
                <Button mr={3} onClick={onClose}>
                  {secondaryButtonText}
                </Button>
              )}
              {primaryButtonText && (
                <Button
                  type="submit"
                  isLoading={primaryLoading}
                  isDisabled={primaryLoading || disablePrimaryButton}
                  onClick={onPrimaryButtonClicked}
                  colorScheme="primary"
                >
                  {primaryButtonText}{" "}
                </Button>
              )}
            </ModalFooter>
          )}
        </ModalContent>
      </ChakraModal>
    </>
  );
};
