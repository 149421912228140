import { customConsole } from "./helper";

export const getNestedFormikValue = (fields, path: string) => {
  if (!path) return undefined;
  let targetKeys = path.split(".");
  customConsole("log", "adasfas", targetKeys);

  for (let i = 0; i < targetKeys.length; i++) {
    let element = targetKeys[i];
    if (element.includes("[")) {
      const s = targetKeys[0].replace("[", "|").replace("]", "").split("|");
      targetKeys[i] = s[0];
      targetKeys.splice(i + 1, 0, s[1]);
    }
  }

  let value = null;
  targetKeys.forEach((targetKey: string) => {
    if (!value) {
      value = fields[targetKey];
    } else {
      value = value[targetKey];
    }
  });

  customConsole("log", targetKeys);

  return value;
};

export const isNestedFormikValuePresent = (fields, path: string) => {
  return !!getNestedFormikValue(fields, path);
};
