import React, { useEffect, useState, useRef, ChangeEvent } from "react";
import { Spinner } from "@chakra-ui/react";
import toast from "react-hot-toast";
import { MdImageNotSupported } from "react-icons/md";
import { Pencil } from "../../../Assets/SvgIcons";
import { COEFFICIENT_BYTES, MAX_IMAGE_UPLOAD } from "../../../Constants/System";
import { AvatarProps } from "./types";
import { customConsole } from "../../../utils/helper";
import { VALID_PHOTO_EXTENSIONS } from "../../../utils/constants";
import { ProfileDetailsLeft } from "./Styles";
import * as Sentry from "@sentry/react";

const Avatar: React.FC<AvatarProps> = ({
  defaultImage,
  updateImage,
  onFile,
  id,
}) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [img, setImg] = useState<string | null | undefined>(null);
  const [loadingUpdateImage, setLoadingUpdateImage] = useState(false);

  useEffect(() => {
    setImg(defaultImage);
  }, [defaultImage]);

  const triggerUploadAndRefetch = async (file: File) => {
    if (updateImage) {
      const formData = new FormData();
      formData.append("photo", file);
      try {
        if (id) {
          setLoadingUpdateImage(true);
          const result = await updateImage({ id, data: formData });
          setLoadingUpdateImage(false);
          // @ts-ignore
          if (result?.error) {
            // @ts-ignore
            throw new Error(result.error.data.message);
          }
        }
      } catch (e) {
        Sentry.captureException(e);
        setLoadingUpdateImage(false);
        toast.error(e.message);
      }
    }
  };

  const onFileSelected = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const fileList = e.target.files;
    if (fileList) {
      const file = fileList[0];
      const fileExtension = file.name.split(".").pop();

      if (fileExtension && !VALID_PHOTO_EXTENSIONS.includes(fileExtension)) {
        toast.error(
          `We do not support this image extension at this time. Please upload an image with one these extensions: ${VALID_PHOTO_EXTENSIONS}`
        );
        return;
      }
      const fileSize = file?.size / COEFFICIENT_BYTES / COEFFICIENT_BYTES;
      if (fileSize >= MAX_IMAGE_UPLOAD) {
        toast.error(`The maximum file size is ${MAX_IMAGE_UPLOAD}MB`);
      } else {
        try {
          if (onFile) {
            onFile(file);
          }
          if (!updateImage) {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onloadend = () => {
              const previewableFile = fileReader.result;
              // @ts-ignore
              setImg(previewableFile);
            };
          }

          if (updateImage) {
            triggerUploadAndRefetch(file);
          }
        } catch (error) {
          Sentry.captureException(error);
          customConsole("log", "error", error);
        }
      }
    }
  };

  const handleBrowse = () => {
    fileRef.current?.click();
  };

  return (
    <ProfileDetailsLeft>
      <input
        style={{ display: "none" }}
        id="logo-input"
        type="file"
        ref={fileRef}
        accept={VALID_PHOTO_EXTENSIONS}
        onChange={onFileSelected}
      />
      <div className="profile-image-container">
        {img ? (
          <div className="profile-image">
            <img className={img} src={img} alt="avatar-org" />
          </div>
        ) : (
          <MdImageNotSupported style={{ fontSize: "32px", color: "#a3a3a3" }} />
        )}
        <div
          style={{
            position: "absolute",
          }}
        >
          {loadingUpdateImage && <Spinner color="red" />}
        </div>
        <div onClick={handleBrowse} className="edit-icon-wrapper">
          <Pencil style={{ color: "black" }} />
        </div>
      </div>
      <div
        className="image-extension-warning"
        style={{ color: "#bbb", marginTop: 10, fontSize: 12 }}
      >
        Image selected must be of type:{" "}
        <span style={{ fontWeight: "bold" }}>{VALID_PHOTO_EXTENSIONS}</span>
      </div>
    </ProfileDetailsLeft>
  );
};

export default Avatar;
