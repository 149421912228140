import styled from "styled-components";

export const SuggestionsContainer = styled.div``;

export const SuggestionsHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 16px;
`;

export const SuggestionsTitle = styled.h3`
  font-size: 18px;
  font-family: Monserrat, sans-serif;
  font-weight: 600;
`;

export const ViewAllSuggestionsButton = styled.button`
  font-size: 16px;
  font-family: Monserrat, sans-serif;
  font-weight: 600;
  color: #ef6b2d;
`;

export const SuggestionCardsCarousel = styled.div`
  overflow-x: auto;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 24px;
`;

export const SuggestionCard = styled.div`
  width: max-content;
  border-radius: 30px;
  border: 1px solid #eee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  margin-right: 12px;
  height: 206px;

  .full_name,
  .title {
    font-family: Montserrat;
    text-align: center;
  }

  .full_name {
    font-weight: 600;
    margin-top: 8px;
  }

  .button-spacer {
    margin-top: 8px;
  }
`;
