import { useState } from "react";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router";
import { Box, InputLeftElement, Text, Button } from "@chakra-ui/react";

import { MESSAGE, RegexNoWhiteSpace } from "../../Constants/Message";
import { InputV2 } from "../../Reusable";
import { Email } from "../../Assets/SvgIcons";
import { SignUpWrapper } from "../SignUp/Styles";
import * as Sentry from "@sentry/react";

const ForgotPassword = () => {
  const location = useLocation();
  //@ts-ignore
  const { email } = location.state ? location.state : {};
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const ForgotPassword = async (values) => {
    const username = values.email.trim();
    try {
      setLoading(true);
      await Auth.forgotPassword(username);
      toast.success("Reset code is mailed to your Email Id");
      navigate("/reset-Password", { state: { username } });
    } catch (err) {
      Sentry.captureException(err);
      setLoading(false);
      console.error(err);
      let errorMessage = "Something is wrong please try later";
      if (err.code === "UserNotFoundException") errorMessage = "User not found";
      if (err.code === "InvalidParameterException")
        errorMessage =
          "Cannot reset password for the user as there is no verified email, confirm the user and try again ";
      toast.error(errorMessage);
    }
  };

  const yupValidate = Yup.object({
    email: Yup.string()
      .matches(RegexNoWhiteSpace, {
        message: MESSAGE.WHITE_SPACE,
        excludeEmptyString: true,
      })
      .email(MESSAGE.EMAIL_INVALID)
      .required(MESSAGE.INPUT_REQUIRED),
  });

  return (
    <SignUpWrapper>
      <div className="col-logo">
        <img
          src="/images/logo-2.png"
          alt="Measure"
          className="img-logo mx-auto"
        />
      </div>

      <div className="col-form">
        <div className="box-wrapper mx-auto" style={{ maxWidth: 410 }}>
          <img
            src="/images/logo-3.png"
            alt="Measure"
            className="img-logo mx-auto"
          />

          <Box mb="32px">
            <Text
              color="#000"
              lineHeight={17 / 14}
              margin="auto"
              className="text-center"
            >
              Please enter the email you signed up with and we’ll send you a
              security code to reset your password.
            </Text>
          </Box>
        </div>

        <Formik
          initialValues={{
            email: email ? email : "",
          }}
          validationSchema={yupValidate}
          onSubmit={async (data, handlers) => {
            handlers.setSubmitting(true);
            handlers.validateForm(data);
            await ForgotPassword(data);
            handlers.setSubmitting(false);
          }}
        >
          {(formik) => {
            return (
              <Form>
                <div className="box-wrapper mx-auto" style={{ maxWidth: 410 }}>
                  <Box mb="32px">
                    <InputV2
                      name="email"
                      placeholder="Email Address"
                      inputLeftElement={
                        <InputLeftElement
                          pointerEvents="none"
                          children={<Email />}
                        />
                      }
                    />
                  </Box>

                  <Box mb="16px">
                    <Button
                      type="submit"
                      colorScheme="primary"
                      isLoading={loading}
                      loadingText="Get reset code"
                      className="w-100"
                    >
                      Get reset code
                    </Button>
                  </Box>
                  <div
                    style={{
                      marginTop: "16px",
                      marginBottom: "16px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      <Text
                        as={Link}
                        to="/reset-password"
                        fontSize={16}
                        fontWeight="semibold"
                        _hover={{ color: "primary.600" }}
                      >
                        I already have a code
                      </Text>
                    </div>
                  </div>

                  <Text
                    fontSize={16}
                    fontWeight="semibold"
                    color="#000"
                    textAlign="center"
                    padding="6px 0"
                  >
                    Go back to{" "}
                    <Text
                      as={Link}
                      to="/"
                      fontSize={16}
                      fontWeight="semibold"
                      _hover={{ color: "primary.600" }}
                    >
                      Login?
                    </Text>
                  </Text>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </SignUpWrapper>
  );
};

export default ForgotPassword;
