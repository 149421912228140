import { FC, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { Button, Spinner } from "@chakra-ui/react";
import { UserPlus } from "../../Assets/SvgIcons";
import {
  useUnFollowAccountMutation,
  useFollowUserMutation,
  useGetUsersFollowedQuery,
} from "../../../redux/reducers/apiSlice";

type Props = {
  variant?: string;
  userId?: string;
  following?;
  accountType?: "USER" | "ORG";
};

const FollowButton: FC<Props> = ({
  variant = "outline",
  userId,
  accountType = "USER",
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [
    unFollowAccount,
    { isLoading: loadingUnfollowAccount, error: unfollowAccountError },
  ] = useUnFollowAccountMutation();
  const [followUser, { isLoading: loadingFollowUser, error: followUserError }] =
    useFollowUserMutation();
  const { data: following } = useGetUsersFollowedQuery();

  useEffect(() => {
    if (unfollowAccountError && followUserError) {
      const error = unfollowAccountError || followUserError;
      let errorMessage: string | undefined;
      if ("status" in error) {
        errorMessage =
          "error" in error ? error.error : JSON.stringify(error.data);
      } else {
        errorMessage = error.message;
      }
      if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  }, [unfollowAccountError, followUserError]);

  const isFollowing = useMemo(
    () =>
      Array.isArray(following) &&
      !!following?.find((user) => user.following === userId),
    [following, userId]
  );

  const handleSubmit = async () => {
    if (userId) {
      const apiCall = isFollowing ? unFollowAccount : followUser;
      await apiCall({
        userId,
        type: accountType as "USER" | "ORG",
      }).unwrap();
    }
  };

  const isLoading = loadingUnfollowAccount || loadingFollowUser;

  const leftIcon = !isFollowing ? (
    <UserPlus />
  ) : isLoading ? (
    <Spinner color="white" />
  ) : undefined;

  return (
    <Button
      variant={variant}
      colorScheme="primary"
      loadingText="Loading..."
      isLoading={isLoading}
      isDisabled={isLoading}
      leftIcon={leftIcon}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleSubmit}
    >
      {isFollowing ? (isHovered ? "Unfollow" : "Following") : "Follow"}
    </Button>
  );
};

export default FollowButton;
