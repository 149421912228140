import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GoalsWrapper,
  SparksWrapper,
  GoalsDescription,
  ActionsContainer,
} from "./Styles";
import { EditGoalsIcon, ExportIcon } from "../../../Assets/SvgIcons";
import { SparkCard } from "../../../Components/SparkCard/SparkCard";
import { Link } from "react-router-dom";
import { Organization } from "../../../../redux/reducers/types";
import OrganizationEditModal from "../../Modal/OrganizationEditModal";

type GoalsProps = {
  organization?: Organization;
};

export const Goals = ({ organization }: GoalsProps) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const getGoalForSpark = (spark) =>
    organization?.goals?.filter((goal) => goal.spark.name === spark) || [];

  return (
    <GoalsWrapper>
      <GoalsDescription>
        All goals of each spark are available to the public and can be viewed on
        your organizations profile.
      </GoalsDescription>
      <ActionsContainer>
        <button>
          <ExportIcon /> Export all
        </button>
        <button onClick={() => setShowEditModal(true)}>
          <EditGoalsIcon /> Edit my sparks
        </button>
      </ActionsContainer>
      <SparksWrapper>
        {organization?.sparks?.map((spark) => (
          <Link
            key={spark}
            to={`/organizations/${organization.id}/sparks/${encodeURIComponent(
              spark
            )}/goals`}
            state={{ spark }}
          >
            <SparkCard
              name={spark}
              goalsCount={getGoalForSpark(spark)?.length}
            />
          </Link>
        ))}
      </SparksWrapper>
      {showEditModal && organization && (
        <OrganizationEditModal
          isOpen={showEditModal}
          onClose={() => setShowEditModal(false)}
          organization={organization}
          type={organization.type}
          step={"SPARK"}
        />
      )}
    </GoalsWrapper>
  );
};
