// @ts-nocheck
import _ from "lodash";
import { Organization, User } from "../../redux/reducers/types";
import { EmailState, Action, ActionList } from "../../types";
import {
  genderOptions,
  pronounsOptions,
  ethnicityOptions,
} from "../Constants/DropdownOptions";

import _clone from "lodash/clone";
import _escapeRegExp from "lodash/escapeRegExp";
import _uniqBy from "lodash/uniqBy";

export const removeDuplicates = (array: Array<EmailState>) => {
  const uniqueObjects = {};
  const resultArray: Array<EmailState> = [];

  array.forEach((obj) => {
    // Use the 'text' property as the key to check for uniqueness
    const email = obj.email;
    if (!uniqueObjects[email]) {
      // If the text is not found in the uniqueObjects, add it to the object and resultArray
      uniqueObjects[email] = true;
      resultArray.push(obj);
    }
  });

  return resultArray;
};

export const removeKeysFromEmails = (emails: Array<EmailState>) =>
  emails.map(({ email, id }) => ({ email, id }));

export const floatingDropdownActionsToArray = (
  floatingDropdownActions: ActionList
) => {
  let floatingDropdownActionArray: Action[] = [];
  for (let actionLabel in floatingDropdownActions) {
    const action = floatingDropdownActions[actionLabel];

    const newActionObj = {
      label: actionLabel,
      ...action,
    };

    floatingDropdownActionArray.push(newActionObj);
  }
  return floatingDropdownActionArray;
};

export const replaceNotListedLabelWithValue = (
  array: string[],
  elementToReplaceWith: string
) => {
  return [...array].map((element) =>
    element === "not-listed" ? elementToReplaceWith : element
  );
};

export const generateCheckboxValuesFromData = (
  checkboxesData,
  defaultValues
) => {
  const notListedFieldValue =
    checkboxesData.find((value) => !defaultValues.includes(value)) || "";

  const newCheckboxesValues = checkboxesData.map((checkboxesValue) => {
    if (!defaultValues.includes(checkboxesValue)) {
      return "not-listed";
    }
    return checkboxesValue;
  });

  return { newCheckboxesValues, notListedFieldValue };
};

const labelsCheckboxesValues = [
  "bipoc-led",
  "powerful-black-led",
  "powerful-brown-led",
  "powerful-indigenous-led",
  "woman-led",
  "lgbtq-led",
  "not-listed",
];

const communitiesServedCheckboxesValues = [
  "powerful-black-community",
  "powerful-brown-community",
  "powerful-indigenous-community",
  "woman",
  "lgbtq",
  "not-listed",
];

export const signupRadioButtons = [
  {
    label: "Which gender do you most identify with?",
    options: genderOptions,
    name: "gender",
    notListedField: "genderText",
  },
  {
    label: "Which pronouns do you most identify with?",
    options: pronounsOptions,
    name: "pronouns",
    notListedField: "pronounsText",
  },
  {
    label: "Which racial or ethnic category do you most identify with?",
    options: ethnicityOptions,
    name: "ethnicity",
    notListedField: "ethnicityText",
  },
];

export const generateAddOrganizationInitialValues = (
  type: string,
  editMode: boolean,
  organizationData: Organization
) => {
  let initialValues = {
    name: "",
    type: type,
    mission: "",
    vision: "",
    problem: "",
    phoneNumber: "",
    sparks: [],
    location: {
      zip: "",
      city: "",
      state: "",
      address1: "",
      address2: "",
    },
    orgFields: {
      careModelCertified: "false",
      ein: "",
      antiRacist: "",
    },
    labels: [],
    labelNotListed: "",
    communitiesServed: [],
    communityServedNotListed: "",
    image: {
      key: "",
      url: "",
    },
    step: "GENERAL_INFO",
  };

  if (editMode) {
    initialValues = {
      ...initialValues,
      ..._.cloneDeep(organizationData),
    };

    delete initialValues.createdAt;
    delete initialValues.updatedAt;
    delete initialValues.goals;
    delete initialValues.status;
    delete initialValues.members;
    delete initialValues.followerCount;
    delete initialValues.followingCount;

    const { newCheckboxesValues: labels, notListedFieldValue: labelNotListed } =
      generateCheckboxValuesFromData(
        initialValues.labels,
        labelsCheckboxesValues
      );
    const {
      newCheckboxesValues: communitiesServed,
      notListedFieldValue: communityServedNotListed,
    } = generateCheckboxValuesFromData(
      initialValues.communitiesServed,
      communitiesServedCheckboxesValues
    );
    initialValues.labels = labels;
    initialValues.labelNotListed = labelNotListed;
    initialValues.communitiesServed = communitiesServed;
    initialValues.communityServedNotListed = communityServedNotListed;
    initialValues.orgFields.careModelCertified = String(
      initialValues.orgFields.careModelCertified
    );
  }

  return initialValues;
};

// metric

export const yearsForMetric = () => {
  const currentYear = new Date().getFullYear();
  const options = [currentYear.toString()];
  let i = 0;
  while (i < 4) {
    i++;
    let year = currentYear - i;
    options.push(year.toString());
  }

  return options;
};

export const metricYearSelectInputOptions = () => {
  const options = yearsForMetric();
  return options.map((option) => ({
    label: option,
    value: option,
  }));
};

export const metricMonthOrQuaterFieldOptions = (quarter) => {
  if (quarter === "QUARTERLY") {
    return [
      { label: "Q1", value: "01-01" },
      { label: "Q2", value: "04-01" },
      { label: "Q3", value: "07-01" },
      { label: "Q4", value: "10-01" },
    ];
  }

  return [
    { label: "January", value: "01-01" },
    { label: "February", value: "02-01" },
    { label: "March", value: "03-01" },
    { label: "April", value: "04-01" },
    { label: "May", value: "05-01" },
    { label: "June", value: "06-01" },
    { label: "July", value: "07-01" },
    { label: "August", value: "08-01" },
    { label: "September", value: "09-01" },
    { label: "October", value: "10-01" },
    { label: "November", value: "11-01" },
    { label: "December", value: "12-01" },
  ];
};

export const generatePredeterminedMetricOptions = (metrics) =>
  metrics.map((metric) => ({ label: metric.metric, value: metric.metric }));

export const formatNumber = (
  number: number | string,
  style: "currency" | "decimal"
) => {
  const options = {
    currency: style === "currency" ? "USD" : undefined,
    style,
  };

  return new Intl.NumberFormat("en-EN", options).format(number);
};

export const urlRegex = /^(https?):\/\/[^\s/$.?#].[^\s]*$/;

export const revertNumberOrCurrencyFormat = (s) => {
  return parseFloat(s.replace(/\,|\$|\%/g, "").trim());
};

export const capitalizeFirstLetter = (word: string) => {
  const sArray = word.split("");
  return `${sArray.shift().toUpperCase()}${sArray.join("")}`;
};

export const targetTypeToSymbols = {
  DOLLARS: "$",
  NUMBERS: "",
  PERCENTAGES: "%",
};

export const disableAddOrEditFormButton = (
  step: string,
  formik,
  type: string
) => {
  if (step == "GENERAL_INFO") {
    if (
      formik.values.name &&
      formik.values.type &&
      formik.values.phoneNumber &&
      formik.values.sparks &&
      formik.values.location &&
      formik.values.labels.length > 0 &&
      formik.values.communitiesServed.length > 0 &&
      formik.values.orgFields.careModelCertified &&
      Object.keys(formik.errors)?.length === 0
    ) {
      if (type === "non-profit" || type === "foundation") {
        if (
          !formik.values.orgFields?.ein ||
          formik.values.orgFields?.ein === ""
        ) {
          return true;
        }
      }

      return false;
    }
  }
  if (step == "SPARK") {
    if (
      formik.values.sparks &&
      formik.values.sparks.length == 3 &&
      Object.keys(formik.errors)?.length === 0
    )
      return false;
  }

  if (
    !formik.values.communityServedNotListed &&
    formik.values.communitiesServed?.includes("not-listed" as never)
  ) {
    return true;
  }

  if (
    !formik.values.labelNotListed &&
    formik.values.labels?.includes("not-listed" as never)
  ) {
    return true;
  }

  return true;
};

export const customConsole = (method, ...args) => {
  const MODE = `${import.meta.env.MODE}`;
  const IS_DEV = MODE === "development";
  if (!IS_DEV) return;
  if (console[method] && typeof console[method] === "function") {
    console[method](...args);
  } else {
    console.log(`Unsupported console method: ${method}`);
  }
};

export const truncate = (
  fullName,
  stringMaxLength = 68,
  stringTargetLength = 68
) => {
  if (!fullName?.length) return "";
  return fullName.length > stringMaxLength
    ? fullName.trim().substring(0, stringTargetLength - 3) + "..."
    : fullName.trim();
};

type Reaction =
  | "FOLLOW"
  | "TAG"
  | "HEART"
  | "FIST"
  | "SNAPS"
  | "HIGHFIVE"
  | "COMMENT";

export const generateNotificationMessage = (
  action: Reaction,
  postId: string | undefined,
  interactionId: string | undefined
) => {
  const reactionMessage = "just reacted to your post ";

  const wordsMap = {
    COMMENT: "just commented on your post",
    HEART: reactionMessage,
    FIST: reactionMessage,
    SNAPS: reactionMessage,
    HIGHFIVE: reactionMessage,
    FOLLOW: "just followed you",
    TAG: "just tagged you",
  };

  if (action === "TAG") {
    if (postId) {
      if (!interactionId) {
        return `${wordsMap[action]} on a post`;
      } else {
        return `${wordsMap[action]} on a comment`;
      }
    }
  }

  return wordsMap[action];
};

export const getUsersFromTags = (text) => {
  const tags = [...text.matchAll(/@\[(.+?)\]\((.+?)\)/g)];
  const allUserIds = tags.map((myTag) => {
    console.log("My TAG", myTag);
    const id = myTag[2];
    const name = myTag[1];
    return { id, name };
  });
  return _uniqBy(allUserIds, (myUser) => myUser.id);
};

export const isOrganization = (
  entity: User | Organization
): entity is Organization => {
  return (entity as Organization).type !== undefined;
};
