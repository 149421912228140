/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

import { customConsole } from "./utils/helper";

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: `${import.meta.env.VITE_COGNITO_USER_POOL_ID}`,
  aws_user_pools_web_client_id: `${
    import.meta.env.VITE_COGNITO_USER_POOL_WEB_ID
  }`,
};

customConsole("log", "AWS ENV" + JSON.stringify(awsmobile));

export default awsmobile;
