import styled, { keyframes } from "styled-components";
import { devices } from "../../../Theme.css";

export const SparksTabWrapper = styled.div``;

export const SparkSpacer = styled.div`
  margin-bottom: 32px;
  @media ${devices.max_sm} {
    margin-bottom: 0px;
  }
`;

export const SparkMobileDivider = styled.div`
  display: none;
  @media ${devices.max_sm} {
    display: block;
    border-bottom: 1px solid #eee;
  }
`;

export const SparkCard = styled.div`
  width: 100%;
  display: block;
  background: #ffffff;
  box-shadow: 1px 1px 6px rgba(64, 26, 15, 0.25);
  border-radius: 20px;
  padding: 16px;

  @media ${devices.max_sm} {
    box-shadow: none;
  }
`;

export const SparkCardTopLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const SparkCardColumn = styled.div`
  width: 100%;
  text-align: left;
  margin-right: 32px;
`;

export const NoImageCircle = styled.div`
  width: 41px;
  height: 41px;
  border-radius: 50%;
  background: #d9d9d9;
`;

export const SparkCardImageColumn = styled.div`
  width: max-content;
  margin-right: 12px;
`;

export const SparkCardImageContainer = styled.div`
  width: 41px;
  height: 41px;
  border-radius: 50%;
  background: #d9d9d9;
  overflow: hidden;

  img {
    width: 100%;
    display: block;
  }
`;

export const SparkCardTitle = styled.h2`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`;

export const SparkCardCaption = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`;

const turnUpsideDown = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(180deg);
    }
`;

export const CarretContainer = styled.button`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;

export const GoalCardContainer = styled.div``;

export const GoalCard = styled.div`
  border-radius: 20px;
  background: var(--yt, #fff);
  box-shadow: 1px 1px 6px 0px rgba(64, 26, 15, 0.25);
  padding: 24px;
  margin-top: 12px;
`;

export const GoalCardTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 8px;
`;

export const GoalCardTitle = styled.h3`
  color: #ef6b2d;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 12px;
`;

export const GoalCardDescription = styled.p`
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
