import styled from "styled-components";
import { devices } from "../../Theme.css";

export const DotContainer = styled.div`
  margin: 0 8px;
  color: #aaa;
  font-weight: bold;
`;

export const PolicyFooterStyles = styled.div`
  width: 80%;
  position: absolute;
  bottom: 24px;
  /* left: 250px; */
  display: flex;
  margin: 0 -20px;
  justify-content: center;
  align-items: center;
  .link-wrapper {
    width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  @media ${devices.max_sm} {
    width: 100%;
    max-width: 100vw;
  }

  a {
    color: #aaa;
    font-size: 12px;
    font-weight: bold;
  }
`;

export const LinkContainer = styled.div`
  position: absolute;
  bottom: 0;
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 24px;
`;

// underline link animation
export const LegalLink = styled.a`
  display: block;
  width: 100%;
  font-weight: bold;
  :after {    
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    position: relative;
    background: #ee5a29;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  :hover:after { 
    width: 100%; 
  }
`;
