import React, { useEffect, useState } from "react";
import { Box, Text, SimpleGrid, Spinner } from "@chakra-ui/react";
import { InputV2, SelectV2 } from "../../../Reusable";
import { useLazyGetWorldCitiesQuery } from "../../../../redux/reducers/apiSlice";
import RadioButtonGroup from "../../../Components/RadioButtonGroup/RadioButtonGroup";
import { customConsole, signupRadioButtons } from "../../../utils/helper";
import * as Sentry from "@sentry/react";

const StepGeneralInfo = ({ formik }) => {
  const [cityStateDropdown, setCityStateDropdown] = useState<any>();
  const [stateOptions, setStateOptions] = useState<any>();
  const [cityOptions, setCityOptions] = useState<any>();
  const [getWorldCities, { isLoading: worldCitiesDataLoading }] =
    useLazyGetWorldCitiesQuery();
  // Get data dropdown state
  useEffect(() => {
    getWorldCities()
      .unwrap()
      .then((worldCitiesData) => {
        setCityStateDropdown(worldCitiesData);
      })
      .catch((err) => {
        Sentry.captureException(err);
        customConsole("log", "City state dropdown API error", err);
      });
  }, []);

  useEffect(() => {
    let statesList = cityStateDropdown;
    // @ts-ignore
    statesList = [...new Set(statesList?.map((data) => data.subcountry))];
    let localStateOptions = statesList?.map((item) => ({
      label: item,
      value: item,
    }));
    setStateOptions(localStateOptions);
  }, [cityStateDropdown]);

  useEffect(() => {
    if (formik.values) {
      let cityList = cityStateDropdown?.filter(
        (city) => city.subcountry === formik.values.location.state
      );
      // @ts-ignore
      cityList = [...new Set(cityList?.map((data) => data.name))];
      let localCityList = cityList?.map((item) => ({
        label: item,
        value: item,
      }));
      setCityOptions(localCityList);
    }
  }, [formik.values?.location?.state]);

  if (worldCitiesDataLoading) return <Spinner color="red" />;

  return (
    <div className="step-general-info">
      <SimpleGrid columns={{ sm: 1, md: 2 }} spacingX="44px">
        <Box mb="32px">
          <InputV2
            placeholder="First name"
            name="name.firstName"
            restInput={{ maxLength: 150 }}
            value={formik.values.name.firstName}
          />
        </Box>
        <Box mb="32px">
          <InputV2
            placeholder="Last name"
            name="name.lastName"
            restInput={{ maxLength: 150 }}
            value={formik.values.name.lastName}
          />
        </Box>
        <Box mb="32px">
          <InputV2
            placeholder="Headline (CEO of or Change maker)"
            name="title"
            restInput={{ maxLength: 150 }}
            value={formik.values.title}
          />
        </Box>
        <Box mb="32px" style={{ position: "relative", zIndex: 999 }}>
          <SelectV2
            options={stateOptions}
            placeholder="State"
            name="location.state"
            value={formik.values.location.state}
            onChange={(option) => {
              formik.setFieldValue("location.state", option.value);
              let cityList = cityStateDropdown?.filter(
                (city) => city.subcountry === option.value
              );
              // @ts-ignore
              cityList = [...new Set(cityList?.map((data) => data.name))];
              let localCityList = cityList?.map((item) => ({
                label: item,
                value: item,
              }));
              setCityOptions(localCityList);
            }}
          />
        </Box>
        <Box mb="32px" style={{ position: "relative", zIndex: 998 }}>
          <SelectV2
            isDisabled={formik.values.location.state.length === 0}
            placeholder="City"
            name="location.city"
            options={cityOptions}
            value={formik.values.location.city}
            onChange={(option) =>
              formik.setFieldValue("location.city", option.value)
            }
          />
        </Box>
      </SimpleGrid>
      {signupRadioButtons.map(({ label, name, options, notListedField }) => (
        <Box key={label} mb="32px">
          <Text fontSize="14px" fontWeight="semibold" color="#000" mb="12px">
            {label}
          </Text>
          <RadioButtonGroup
            name={name}
            options={options}
            formik={formik}
            notListedFieldName={notListedField}
          />
        </Box>
      ))}
    </div>
  );
};

export default StepGeneralInfo;
