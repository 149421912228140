import styled from "styled-components";
import { Inbox } from "@talkjs/react";
import { devices } from "../../Theme.css";

export const MessagesInboxWrapper = styled(Inbox)`
  height: 800px;

  @media ${devices.max_sm} {
    height: 768px;
  }
`;
