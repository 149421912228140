import React from "react";
import ReactPlayer from "react-player/youtube";
import { useGetProfileQuery } from "../../../../redux/reducers/apiSlice";
import { truncate } from "../../../utils/helper";

import { IntroCardWrapper } from "./Styles";

const IntroCard = () => {
  const { data: currentUserProfile } = useGetProfileQuery();

  return (
    <IntroCardWrapper className="intro-card">
      <div className="box-row">
        <div className="col-detail">
          <div className="box-txt">
            <div className="txt-title">
              HI,{" "}
              {currentUserProfile?.id
                ? truncate(currentUserProfile?.name.firstName.toUpperCase())
                : ""}
            </div>

            <p className="txt">
              Welcome to Measure Ignite! This is where you can share data,
              create goals, download dashboards and get connected to
              organizations with similar goals. Watch our 3 mins video to learn
              more
            </p>
          </div>
        </div>

        <div className="col-video">
          <div className="box-video">
            <ReactPlayer
              url="https://youtu.be/gSDZrPuUvzk?si=essCsKNujvtvqeqA"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
    </IntroCardWrapper>
  );
};

export default IntroCard;
