import React, { useState } from "react";
import toast from "react-hot-toast";
import { Modal } from "./Modal";
import { InputV2 } from "../../Reusable/InputV2/InputV2";
import { Formik } from "formik";
import { GoalFormWrapper, InputSpacer } from "./Styles";
import {
  useCreateOrganizationGoalMutation,
  useEditOrganizationGoalMutation,
} from "../../../redux/reducers/apiSlice";
import { createGoalSchema } from "../../Pages/Organizations/AddNewOrganizationForm/schema";
import DiscardGoalCreationModal from "./DiscardGoalCreationModal";
import { customConsole } from "../../utils/helper";

const CreateOrEditGoalModal = ({
  isOpen,
  goalId,
  goalName,
  goalDescription,
  organizationId,
  onClose,
  spark,
}) => {
  const [createGoal, { isLoading: createGoalLoading }] =
    useCreateOrganizationGoalMutation();
  const [editGoal, { isLoading: editGoalLoading }] =
    useEditOrganizationGoalMutation();
  const [showDiscardModal, setShowDiscardModal] = useState(false);

  const loading = createGoalLoading || editGoalLoading;

  const primaryButtonText = () => {
    const loadingText = loading ? "ing..." : "";
    let text = goalId ? "Edit" : "Add";
    return text + loadingText;
  };

  return (
    <>
      <Formik
        validationSchema={createGoalSchema}
        initialValues={{
          name: goalName,
          description: goalDescription,
        }}
        onSubmit={async (goal) => {
          if (goal && spark) {
            const editMode = goalId && organizationId;
            const apiCall = editMode ? editGoal : createGoal;
            const payload = editMode
              ? {
                  organizationId,
                  goalId,
                  data: {
                    ...goal,
                  },
                }
              : {
                  goal: {
                    ...goal,
                    spark,
                  },
                  organizationId,
                };
            // @ts-ignore
            await apiCall(payload).unwrap();
            onClose();
            let toastMessage = "";
            if (editMode) {
              toastMessage = `Edit successfull for goal with name: ${goal.name}`;
            } else {
              toastMessage = `Successfully added a goal`;
            }
            toast.success(toastMessage);
          }
        }}
      >
        {(formik) => {
          customConsole("log", "FORMIK VALUES", formik.values);
          if (
            (formik.values.description || formik.values.name) &&
            showDiscardModal
          )
            return (
              <DiscardGoalCreationModal
                isOpen={showDiscardModal}
                onClose={() => setShowDiscardModal(false)}
                onPrimaryButtonClicked={onClose}
              />
            );
          return (
            <Modal
              isOpen={isOpen}
              onClose={() => {
                if (formik.values.description || formik.values.name) {
                  setShowDiscardModal(true);
                } else {
                  onClose();
                }
              }}
              primaryButtonText={primaryButtonText()}
              secondaryButtonText={"Cancel"}
              modalTitle={"Create goal"}
              onPrimaryButtonClicked={formik.handleSubmit}
              primaryLoading={loading}
              disablePrimaryButton={loading}
            >
              <GoalFormWrapper>
                <InputSpacer>
                  <InputV2
                    placeholder="Enter goal name"
                    name="name"
                    value={formik.values.name}
                  />
                </InputSpacer>
                <InputSpacer>
                  <InputV2
                    placeholder="Enter goal description"
                    name="description"
                    value={formik.values.description}
                  />
                </InputSpacer>
              </GoalFormWrapper>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

export default CreateOrEditGoalModal;
