import React, { useState, useMemo } from "react";
import { Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import useScreenType from "react-screentype-hook";
import queryString from "query-string";
import toast from "react-hot-toast";

import ProfileMenu from "./ProfileMenu";
import { Bell, HamburgerIcon } from "../../Assets/SvgIcons";
import { AddMemberIcon } from "../../Assets/SvgIcons";
import { AddMemberButton, AddMemberText, TextBadgeContainer } from "./Styles";

import { TextBadge } from "../Sidebar/Styles";
import {
  useDeleteNotificationsMutation,
  useGetNotificationsQuery,
} from "../../../redux/reducers/apiSlice";
import { Modal } from "../../Components/Modal/Modal";
import { Notification } from "./Notification";

const HeaderRight = ({ toggleSidebar }) => {
  const location = useLocation();
  const { isMobile } = useScreenType();

  const navigate = useNavigate();
  const [showDeleteNotificationModal, setShowDeleteNotificationModal] =
    useState<string | undefined>(undefined);
  const { data: notificationsData } = useGetNotificationsQuery();
  const newNotifications = useMemo(() => {
    return notificationsData?.filter(
      (notification) => notification.status === "NEW"
    );
  }, [notificationsData]);

  const [deleteNotifications, { isLoading: loadingDeleteNotifications }] =
    useDeleteNotificationsMutation();

  const onAddMemberClicked = () => {
    const parsedQueryStrings = queryString.parse(location.search);
    parsedQueryStrings["inviteModalOpen"] = "true";
    const hasQuestionMark =
      Object.keys(parsedQueryStrings).length > 0 ? "?" : "";

    navigate(
      `${location.pathname}${hasQuestionMark}${queryString.stringify(
        parsedQueryStrings
      )}`
    );
  };

  if (
    isMobile &&
    location.pathname.includes("/organizations") &&
    location.pathname !== "/organizations/new" &&
    !location.pathname.includes("profile")
  ) {
    return (
      <AddMemberButton onClick={() => onAddMemberClicked()}>
        <AddMemberIcon />
        <AddMemberText>Invite member</AddMemberText>
      </AddMemberButton>
    );
  }

  return (
    <div className="profile-container">
      <div className="box-notification">
        <Menu placement="bottom-end">
          <MenuButton
            onClick={() => {}}
            disabled={
              !notificationsData?.length || notificationsData?.length <= 0
            }
          >
            <Bell
              color={
                notificationsData?.length && notificationsData.length > 0
                  ? "#000"
                  : "#aaa"
              }
              className="bell"
            />

            <TextBadgeContainer
              isVisible={
                newNotifications?.length && newNotifications.length > 0
              }
            >
              <TextBadge>{newNotifications?.length}</TextBadge>
            </TextBadgeContainer>
          </MenuButton>
          <MenuList>
            <div
              style={{
                overflow: "scroll",
                maxHeight: isMobile ? "80vh" : "20vh",
              }}
            >
              {notificationsData &&
                notificationsData.map((notification, id) => {
                  return (
                    <div key={notification.id}>
                      <Notification
                        notification={notification}
                        setShowDeleteNotificationModal={
                          setShowDeleteNotificationModal
                        }
                      />
                      {id !== notificationsData.length - 1 && <hr />}
                    </div>
                  );
                })}
            </div>
          </MenuList>
        </Menu>
      </div>
      <div className="profile-menu">
        <ProfileMenu />
      </div>
      <div className="hamburger-menu">
        <button onClick={() => toggleSidebar()}>
          <HamburgerIcon boxSize={25} />
        </button>
      </div>
      {!!showDeleteNotificationModal && (
        <Modal
          isOpen={!!showDeleteNotificationModal}
          onClose={() => setShowDeleteNotificationModal(undefined)}
          modalTitle="Are you sure you want to delete this notification?"
          primaryButtonText="Yes"
          secondaryButtonText="Cancel"
          primaryLoading={loadingDeleteNotifications}
          disablePrimaryButton={loadingDeleteNotifications}
          onPrimaryButtonClicked={async () => {
            await deleteNotifications({ id: showDeleteNotificationModal });
            setShowDeleteNotificationModal(undefined);
            toast.success("Notification deletion successful");
          }}
        />
      )}
    </div>
  );
};

export default HeaderRight;
