import React, { useState } from "react";
import toast from "react-hot-toast";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import {
  VerticalMoreIcon,
  MetricBarChartIcon,
  MetricStartDateIcon,
} from "../../Assets/SvgIcons";
import { FloatingDropdown } from "../FloatingDropdown/FloatingDropdown";
import {
  MetricCard,
  MetricCardTitle,
  MetricCardTitleContainer,
  MoreIconButton,
  MetricAttributesContainer,
  MetricCardTopLayout,
  MetricAttribute,
  MetricAttributeText,
  ProgressBarContainer,
  ProgressBarWrapper,
  ProgressBarBackground,
  ProgressLabelContainer,
  ProgressBarLabel,
  ProgressBar,
  FloatDropdownLayout,
} from "./styles";
import { formatNumber } from "../../utils/helper";
import UpdateMetricModal from "../Modal/UpdateMetricModal";
import { Modal } from "../Modal/Modal";
import { TARGET_SYMBOLS } from "../../utils/constants";
import { useDeleteMetricMutation } from "../../../redux/reducers/apiSlice";
import ShareModal from "../Modal/ShareModal";
import { useOrganization } from "../../hooks/useOrganization";
import { Metric as MetricInterface } from "../../../redux/reducers/types";
import * as Sentry from "@sentry/react";

type Props = {
  metric: MetricInterface;
  goalId?: string;
  orgId?: string;
  isAdmin?: boolean;
  onEdit?: () => void;
  isShared?: boolean;
};

const Metric: React.FC<Props> = ({
  metric,
  goalId,
  orgId,
  isAdmin,
  onEdit,
  isShared,
}) => {
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [deleteMetric, { isLoading: deleteMetricLoading }] =
    useDeleteMetricMutation();
  const [floatingDropdownVisible, setFloatingDropdownVisible] = useState(false);
  const [deleteMetricModalVisible, setDeleteMetricModalVisible] =
    useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const handleDelete = () => {
    if (goalId && orgId) {
      try {
        deleteMetric({
          orgId,
          goalId,
          metricId: metric.id,
        }).unwrap();
        setDeleteMetricModalVisible(false);
        toast.success(`You deleted the metric: "${metric.description}"`);
      } catch (e) {
        Sentry.captureException(e);
        toast.error(
          `Something unexpected happened while deleting the metric: "${metric.description}" `
        );
      }
    }
  };

  return (
    <MetricCard>
      <MetricCardTopLayout>
        <MetricCardTitleContainer>
          <MetricCardTitle>{metric.description}</MetricCardTitle>
        </MetricCardTitleContainer>
        {!floatingDropdownVisible && !isShared && (
          <MoreIconButton
            disabled={!isAdmin}
            color={isAdmin ? "#eee" : "#000"}
            onClick={() => setFloatingDropdownVisible(true)}
          >
            <VerticalMoreIcon />
          </MoreIconButton>
        )}
        {floatingDropdownVisible && (
          <FloatDropdownLayout>
            <FloatingDropdown
              visible={floatingDropdownVisible}
              items={[
                {
                  label: "Edit",
                  action: () => (onEdit ? onEdit() : null),
                  isHighlighted: true,
                },
                { label: "Update", action: () => setShowEditModal(true) },
                {
                  label: "Share as a post",
                  action: () => setIsShareModalVisible(true),
                },
                {
                  label: "Delete metric",
                  action: () => setDeleteMetricModalVisible(true),
                  isDestructiveAction: true,
                  isLast: true,
                },
              ]}
              onOutsideClicked={() => setFloatingDropdownVisible(false)}
            />
          </FloatDropdownLayout>
        )}
      </MetricCardTopLayout>
      <MetricAttributesContainer>
        <MetricAttribute>
          <MetricBarChartIcon />
          <MetricAttributeText>
            Target: {formatNumber(metric.progress.target, "decimal")}
            {TARGET_SYMBOLS[metric.progress.targetType]}
          </MetricAttributeText>
        </MetricAttribute>
        <MetricAttribute>
          <MetricStartDateIcon />
          <MetricAttributeText>
            Started{" "}
            {format(
              utcToZonedTime(new Date(metric.startDate), "UTC"),
              "MMMM, yyyy"
            )}
          </MetricAttributeText>
        </MetricAttribute>
        <ProgressBarContainer>
          <ProgressBarWrapper>
            <ProgressBarBackground>
              <ProgressBar
                progress={
                  (metric.progress.current / metric.progress.target) * 100
                }
              />
            </ProgressBarBackground>
            <ProgressLabelContainer>
              <ProgressBarLabel>
                {formatNumber(metric.progress.current, "decimal")} out of{" "}
                {formatNumber(metric.progress.target, "decimal")}{" "}
                {TARGET_SYMBOLS[metric.progress.targetType]}
              </ProgressBarLabel>
            </ProgressLabelContainer>
          </ProgressBarWrapper>
        </ProgressBarContainer>
      </MetricAttributesContainer>
      {showEditModal && (
        <UpdateMetricModal
          isOpen={showEditModal}
          onClose={() => setShowEditModal(false)}
          metric={metric}
          goalId={goalId}
          orgId={orgId}
        />
      )}
      {deleteMetricModalVisible && !isShared && (
        <Modal
          modalTitle="Are you sure you want to delete this metric?"
          primaryButtonText="Delete"
          secondaryButtonText="Cancel"
          isOpen={deleteMetricModalVisible}
          onClose={() => setDeleteMetricModalVisible(false)}
          onPrimaryButtonClicked={() => handleDelete()}
          disablePrimaryButton={deleteMetricLoading}
          primaryLoading={deleteMetricLoading}
        />
      )}
      {isShareModalVisible && (
        <ShareModal
          type="METRIC"
          entity={metric}
          isOpen={isShareModalVisible}
          onClose={() => setIsShareModalVisible(false)}
        />
      )}
    </MetricCard>
  );
};

export default Metric;
