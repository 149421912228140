import React from "react";
import toast from "react-hot-toast";
import { Formik } from "formik";
import { Modal } from "./Modal";
import { InputV2 } from "../../Reusable";
import { FormLinksContainer, ModalWrapper, AddLinkContainer } from "./Styles";
import * as yup from "yup";
import { Box } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { PlusRoundCircle } from "../../Assets/SvgIcons";
import { urlRegex } from "../../utils/helper";
import { useUpdateOrganizationMutation } from "../../../redux/reducers/apiSlice";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import * as Sentry from "@sentry/react";

type Props = {
  links?: { label: string; url: string }[];
  isOpen: boolean;
  onClose: () => void;
  organization: any;
};

const AddOrEditOrganizationLinksModal: React.FC<Props> = ({
  isOpen,
  onClose,
  organization,
}) => {
  const [updateOrganization, { isLoading: updateOrganizationLoading }] =
    useUpdateOrganizationMutation();

  const handleSubmit = async (data) => {
    try {
      await updateOrganization({
        // @ts-ignore
        orgData: {
          orgFields: { ...organization.orgFields, links: data.links },
        },
        orgId: organization.id,
      }).unwrap();
      onClose();

      const newLinksCount = data.links.length || 0;
      const previousLinksCount = organization?.orgFields?.links?.length || 0;

      const diff = Math.abs(newLinksCount - previousLinksCount);
      const previousLinks = organization.orgFields.links;
      const newLinks = data.links;

      let editedCount = 0;
      for (let i = 0; i < previousLinks.length; i++) {
        let id = previousLinks[i].id;
        let previousLink = previousLinks[i];
        let correspondingNewLink = newLinks.find(
          (newLink) => newLink.id === id
        );
        if (correspondingNewLink) {
          console.log(previousLink, correspondingNewLink);
          if (
            correspondingNewLink.url !== previousLink.url ||
            correspondingNewLink.label !== previousLink.label
          ) {
            editedCount++;
          }
        }
      }

      if (diff !== 0) {
        const linksRemoved = newLinksCount < previousLinksCount;
        const message = `you successfully
          ${linksRemoved ? `removed` : `added`} ${diff} link${
          diff > 1 ? "s" : ""
        } ${
          editedCount <= 0
            ? ""
            : `and edited ${editedCount} link${editedCount > 1 ? "s" : ""}`
        }`;

        toast.success(message);
      } else {
        if (editedCount > 0) {
          toast.success(
            `You successfully edited ${editedCount} link${`${
              editedCount > 1 ? "s" : ""
            }`}`
          );
        }
      }
    } catch (e) {
      Sentry.captureException(e);
      toast.error(
        "Something unexpected happened while trying to submit your links"
      );
    }
  };

  return (
    <FormLinksContainer>
      <Formik
        validationSchema={yup.object({
          links: yup
            .array()
            .of(
              yup.object({
                id: yup.string().uuid(),
                label: yup.string().required("Label is required"),
                url: yup.string().test("is-url", "Invalid URL", (value) => {
                  return urlRegex.test(value || ""); // Check if the value matches the URL regex
                }),
              })
            )
            .max(6)
            .required(),
        })}
        onSubmit={(data) => {
          handleSubmit(data);
        }}
        initialValues={{
          links: organization?.orgFields?.links || [{ label: "", url: "" }],
        }}
      >
        {(formik) => (
          <Modal
            primaryLoading={updateOrganizationLoading}
            onPrimaryButtonClicked={() => {
              formik.handleSubmit();
            }}
            secondaryButtonText="Cancel"
            primaryButtonText="Save"
            modalTitle="Edit links"
            isOpen={isOpen}
            onClose={onClose}
            disablePrimaryButton={
              Object.keys(formik.errors).length > 0 || updateOrganizationLoading
            }
            modalSubtitle="You can add up to 6 links"
          >
            <ModalWrapper>
              {formik.values.links.map((link, index) => (
                <div key={link.id} style={{ marginBottom: "18px" }}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-around"}
                    alignItems={"center"}
                  >
                    <Box>
                      <InputV2
                        name={`links[${index}].label`}
                        value={formik.values.links[index].label}
                        placeholder="Link name"
                      />
                    </Box>
                    <Box>
                      <InputV2
                        name={`links[${index}].url`}
                        placeholder="URL"
                        value={formik.values.links[index].url}
                      />
                    </Box>
                    <Box>
                      <button
                        onClick={() =>
                          formik.setFieldValue(
                            "links",
                            formik.values.links.filter(
                              (currentLink) => currentLink.id !== link.id
                            )
                          )
                        }
                      >
                        <DeleteIcon />
                      </button>
                    </Box>
                  </Box>
                </div>
              ))}
              {formik.values.links.length < 6 && (
                <AddLinkContainer>
                  <button
                    onClick={() => {
                      formik.setFieldValue("links", [
                        ...formik.values.links,
                        { id: uuid(), label: "", url: "" },
                      ]);
                    }}
                  >
                    <PlusRoundCircle color="orange" />
                    <span>Add new link</span>
                  </button>
                </AddLinkContainer>
              )}
            </ModalWrapper>
          </Modal>
        )}
      </Formik>
    </FormLinksContainer>
  );
};

export default AddOrEditOrganizationLinksModal;
