import styled from "styled-components";

export const CircularProgress = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: #08216414,
  backdrop-filter: blur(2px);
  z-index: 9999;
`;
