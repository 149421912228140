import {
  SuggestedForYouWrapper,
  labelStyle,
  dividerStyle,
  customTabsSpacing,
} from "./styles";
import { People } from "../Tabs/People/People";
import { Organizations } from "../Tabs/Organizations/Organizations";
import { Tabs } from "../Tabs/Tabs";
import { useGetSuggestedQuery } from "../../../redux/reducers/apiSlice";

export const SuggestedForYou = () => {
  const {
    data: suggested,
    isLoading: loadingSuggested,
    isError,
    error,
  } = useGetSuggestedQuery();

  return (
    <SuggestedForYouWrapper>
      <h2>Suggested for you</h2>
      <Tabs<"People" | "Organizations">
        customTabLabelStyle={labelStyle}
        customDividerStyle={dividerStyle}
        customTabsSpacing={customTabsSpacing}
        defaultSelectedTab={"People"}
        tabs={[
          {
            label: "People",
            component: (
              <People
                loading={loadingSuggested}
                people={suggested?.users || []}
              />
            ),
          },
          {
            label: "Organizations",
            component: (
              <Organizations
                loading={loadingSuggested}
                // @ts-ignore
                organizations={suggested?.organizations || []}
              />
            ),
          },
        ]}
      />
    </SuggestedForYouWrapper>
  );
};
