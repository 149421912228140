import styled from 'styled-components';
import { ModalContent } from '@chakra-ui/react';

export const SettingsModalContent = styled(ModalContent)`
  &.chakra-modal__content {
    .chakra-modal__header {
      font-size: 24px;
      line-height: calc(29 / 24);
      color: #000;
      padding: 24px 44px 0 44px;
    }
    .chakra-modal__footer {
      padding: 24px;
    }
    .chakra-modal__close-btn {
      top: 25px;
      right: 30px;
    }
    .chakra-modal__body {
      padding: 24px 44px 30px;
    }
    .chakra-tabs__tab-panel {
      padding: 24px 0 0;
    }
  }
`;
