import styled from "styled-components";

export const PageContainer = styled.div``;
export const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 24px;
  box-shadow: 0px 5px 5px #eee;

  h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    margin-left: 8px;
  }
`;

export const TabContainer = styled.div`
  padding: 0px 24px;
  overflow-y; auto;
`;

export const NavButton = styled.button``;
