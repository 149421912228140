import React from "react";
import toast from "react-hot-toast";
import { useDeactivateUserMutation } from "../../../redux/reducers/apiSlice";
import { MESSAGE } from "../../Constants/Message";
import { Modal } from "./Modal";
import { Auth } from "aws-amplify";
import * as Sentry from "@sentry/react";

const DeactivateUserModal = ({ isOpen, onClose }) => {
  const [deactivateUser, { isLoading }] = useDeactivateUserMutation();

  const signOut = async () => {
    window.localStorage.clear();
    Auth.signOut();
  };

  const onDeactivateUser = async () => {
    try {
      await deactivateUser();
      signOut();
      toast(
        "You just deactivated your account. You're going to be signed out...",
        {
          style: {
            background: "white",
            border: "1px solid #eee",
          },
        }
      );
      setTimeout(() => {}, 1000);
    } catch (e) {
      Sentry.captureException(e);
      toast.error(MESSAGE.SYSTEM_ERROR);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      primaryButtonText={"Yes, deactivate my account"}
      secondaryButtonText={"Cancel"}
      modalTitle={"Are you sure you want to deactivate your account?"}
      onPrimaryButtonClicked={() => onDeactivateUser()}
      primaryLoading={false}
      disablePrimaryButton={false}
    >
      <p
        style={{
          textAlign: "center",
          height: "10vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        This action is non-reversible.
      </p>
    </Modal>
  );
};

export default DeactivateUserModal;
