import { useMemo } from "react";
import { useGetSuggestedQuery } from "../../../redux/reducers/apiSlice";
import { truncate, isOrganization } from "../../utils/helper";
import { ProfileAvatar } from "../ProfileAvatar/ProfileAvatar";
import FollowButton from "../../Pages/Profile/FollowButton";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import {
  SuggestionCard,
  SuggestionCardsCarousel,
  SuggestionsContainer,
  SuggestionsHeader,
  SuggestionsTitle,
  ViewAllSuggestionsButton,
} from "./styles";

const SuggestedForYouMobile = () => {
  const {
    data: suggested,
    isLoading: loadingSuggested,
    isError,
    error,
  } = useGetSuggestedQuery();

  const navigate = useNavigate();

  const shuffledData = useMemo(() => {
    if (suggested?.organizations && suggested?.users) {
      const data = [...suggested.organizations, ...suggested.users];
      return _.shuffle(data);
    }

    return [];
  }, [suggested?.organizations, suggested?.users]);

  if (!shuffledData || shuffledData?.length === 0) return null;

  return (
    <SuggestionsContainer>
      <SuggestionsHeader>
        <SuggestionsTitle>Suggested for you</SuggestionsTitle>
        <ViewAllSuggestionsButton
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            navigate("/suggestions");
          }}
        >
          View all
        </ViewAllSuggestionsButton>
      </SuggestionsHeader>
      <SuggestionCardsCarousel>
        {shuffledData.map((suggestion) => {
          if (isOrganization(suggestion)) {
            return (
              <SuggestionCard key={suggestion.id}>
                <ProfileAvatar
                  src={suggestion.image.url}
                  name={suggestion.name}
                />
                <div className="full_name">
                  {truncate(suggestion.name, 15, 15)}
                </div>
                <div className="title">{suggestion.type}</div>
                <div className="button-spacer">
                  <FollowButton userId={suggestion.id} accountType="ORG" />
                </div>
              </SuggestionCard>
            );
          }
          return (
            <SuggestionCard key={suggestion.id}>
              <ProfileAvatar
                src={suggestion.image?.url}
                name={"Darlene Robertson"}
              />
              <div className="full_name">
                {truncate(
                  `${suggestion.name?.firstName} ${suggestion.name?.lastName}`,
                  10,
                  10
                )}
              </div>
              <div className="title">{truncate(suggestion.title, 10, 10)}</div>
              <div className="button-spacer">
                <FollowButton userId={suggestion.id} accountType="USER" />
              </div>
            </SuggestionCard>
          );
        })}
      </SuggestionCardsCarousel>
    </SuggestionsContainer>
  );
};

export default SuggestedForYouMobile;
