import React, { useState, useMemo } from "react";
import toast from "react-hot-toast";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  useDisclosure,
  Spinner,
} from "@chakra-ui/react";
import {
  Heart,
  SnapReact,
  FistReact,
  ClappingReact,
  HeartReact,
} from "../../Assets/SvgIcons";
import {
  BoxReact,
  ContainerReact,
  ContainerEmotion,
  BoxCountEmotion,
  CountEmotion,
} from "./styles";

import { useAddInteractionsToPostMutation } from "../../../redux/reducers/apiSlice";
import { INTERACTION_ICONS } from "../../utils/constants";
import { usePost } from "../../hooks/usePost";
import * as Sentry from "@sentry/react";

export const Reactions = ({ post }) => {
  const { currentUserHasInteracted } = usePost(post);

  const [addInteractionsToPost, { isLoading: addInteractionsToPostLoading }] =
    useAddInteractionsToPostMutation();
  const [timeoutId, setTimeoutId] = useState();
  const { isOpen, onToggle, onOpen, onClose } = useDisclosure();

  const likeUnlikeAction = async (interactionType) => {
    try {
      await addInteractionsToPost({
        type: interactionType,
        id: post?.id,
      }).unwrap();
      onToggle();
    } catch (e) {
      Sentry.captureException(e);
      toast.error("Error while interacting with post");
    }
  };

  const handleMouseLeave = () => {
    const newTimeoutId = setTimeout(() => {
      onToggle();
    }, 2000);
    if (newTimeoutId) {
      // @ts-ignore
      setTimeoutId(newTimeoutId);
    }
  };

  const handleMouseOver = () => {
    clearTimeout(timeoutId);
    onOpen();
  };

  const interactions = useMemo(() => {
    return [...post?.interactionCount]
      .filter((interaction) => interaction.type !== "COMMENT")
      .sort((a, b) => a.type.localeCompare(b.type))
      .map((interaction, id) => {
        const Interaction = INTERACTION_ICONS[interaction.type];
        return (
          <BoxCountEmotion
            key={id}
            onClick={() => likeUnlikeAction(interaction.type)}
          >
            <Interaction />
            {addInteractionsToPostLoading ? (
              <Spinner size="xl" color="red" />
            ) : (
              <CountEmotion>{interaction.count}</CountEmotion>
            )}
          </BoxCountEmotion>
        );
      });
  }, [post?.interactionCount]);

  return (
    <>
      <button className="icon">
        <Popover
          trigger="hover"
          isLazy
          isOpen={isOpen}
          returnFocusOnClose={false}
          placement={"top-start"}
          // direction={'ltr'}
        >
          <PopoverTrigger>
            <div
              style={{ cursor: "pointer" }}
              onMouseEnter={onToggle}
              onClick={() => likeUnlikeAction("HEART")}
              onMouseLeave={handleMouseLeave}
            >
              <Heart color={currentUserHasInteracted} />
            </div>
          </PopoverTrigger>
          <PopoverContent
            onMouseLeave={handleMouseLeave}
            onMouseOver={handleMouseOver}
            width="160px"
          >
            <BoxReact>
              <ContainerReact
                onMouseLeave={handleMouseLeave}
                onMouseOver={handleMouseOver}
                onClick={() => likeUnlikeAction("SNAPS")}
              >
                <SnapReact />
              </ContainerReact>
              <ContainerReact
                onMouseLeave={handleMouseLeave}
                onMouseOver={handleMouseOver}
                onClick={() => likeUnlikeAction("FIST")}
              >
                <FistReact />
              </ContainerReact>
              <ContainerReact
                onMouseLeave={handleMouseLeave}
                onMouseOver={handleMouseOver}
                onClick={() => likeUnlikeAction("HIGHFIVE")}
              >
                <ClappingReact />
              </ContainerReact>
              <ContainerReact
                onMouseLeave={handleMouseLeave}
                onMouseOver={handleMouseOver}
                onClick={() => likeUnlikeAction("HEART")}
              >
                <HeartReact />
              </ContainerReact>
            </BoxReact>
          </PopoverContent>
        </Popover>
      </button>
      <ContainerEmotion>{interactions && interactions}</ContainerEmotion>
    </>
  );
};
