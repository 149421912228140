import styled from "styled-components";

import { devices } from "../../../Theme.css";

export const IntroCardWrapper = styled.div`
  padding-right: 110px;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 100;
  @media ${devices.max_lg} {
    padding-right: 30px;
  }
  @media ${devices.max_md} {
    padding-right: 0;
  }
  .box-row {
    display: flex;
    align-items: center;
    box-shadow: 1px 1px 6px rgb(64 26 15 / 25%);
    background: #ffffff;
    border-radius: 0px 0px 45px 45px;
    padding: 34px 44px 34px 93px;
    @media ${devices.max_lg} {
      padding-left: 30px;
    }
    @media ${devices.max_md} {
      flex-wrap: wrap;
      border-radius: 45px;
      padding: 30px;
    }
  }
  .col-detail {
    flex: 1;
    padding-right: 50px;
    @media ${devices.max_lg} {
      padding-right: 0;
    }
    @media ${devices.max_md} {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  .col-video {
    @media ${devices.max_md} {
      width: 100%;
    }
  }
  .box-txt {
    .txt-title {
      font-weight: 600;
      font-size: 24px;
      line-height: calc(29 / 24);
      color: #000;
      margin-bottom: 16px;
    }
    .txt {
      font-size: 16px;
      line-height: calc(20 / 16);
      color: #000;
    }
  }
  .box-video {
    overflow: hidden;
    width: 289px;
    height: 182px;
    border-radius: 25px;
    margin: auto;
  }

  @media ${devices.max_sm} {
    background-color: red;

    .box-row {
      border-radius: 0px;
    }
  }
`;
