import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import * as Yup from "yup";
import { Auth } from "aws-amplify";
import toast from "react-hot-toast";
import { Form, Formik } from "formik";
import { COGNITO } from "../../utils/constants";
import { Box, Button, Text, InputLeftElement } from "@chakra-ui/react";
import { InputV2 } from "../../Reusable";
import { SercurityIcon } from "../../Assets/SvgIcons";
import { customConsole } from "../../utils/helper";

import { SignUpWrapper } from "./Styles";
import { MESSAGE } from "../../Constants/Message";
import * as Sentry from "@sentry/react";

const TIME_TO_WAIT_BEFORE_RESENDING_CODE = 30000;

const SignUpConfirm = ({ setUser }) => {
  const location = useLocation();
  //@ts-ignore
  const { email, password }: any = location.state ? location.state : {};
  const [loading, setLoading] = useState(false);
  const [hasResentCode, setHasResentCode] = useState(false);
  const [loadingResendCodeTimer, setLoadingResendCodeTimer] = useState(false);
  const [resendCodeTimerCount, setResendCodeTimerCount] = useState(
    TIME_TO_WAIT_BEFORE_RESENDING_CODE / 1000
  );

  let navigate = useNavigate();

  useEffect(() => {
    let timer: NodeJS.Timer;
    let intervalTimer: NodeJS.Timeout;

    if (hasResentCode) {
      setLoadingResendCodeTimer(true);

      intervalTimer = setInterval(() => {
        setResendCodeTimerCount((count) => count - 1);
      }, 1000);

      timer = setTimeout(() => {
        setHasResentCode(false);
        setLoadingResendCodeTimer(false);
        clearInterval(intervalTimer);
      }, TIME_TO_WAIT_BEFORE_RESENDING_CODE);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
      if (intervalTimer) {
        clearInterval(intervalTimer);
      }
    };
  }, [hasResentCode]);

  const yupValidate = Yup.object({
    code: Yup.string().required("User Verification Code is Required"),
    email: Yup.string().required("Username is Required"),
  });

  const handleResendCode = async () => {
    console.log("email", "");
    try {
      await Auth.resendSignUp(email);
      setHasResentCode(true);
    } catch (e) {
      Sentry.captureException(e);
      setHasResentCode(false);
      customConsole("ResendCodeError: ", new Error(e));
      toast.error(
        "Something unexpected happened while resending your code. Try again later"
      );
    }
  };

  const handleSubmit = async (data) => {
    try {
      setLoading(true);
      // get confirm response
      const confirmResponse = await Auth.confirmSignUp(
        data.email,
        data.code.trim()
      );

      if (confirmResponse === COGNITO.CONFIRM_SIGNUP_SUCCESS_RESPONSE) {
        if (location.state) {
          const user = await Auth.signIn(email, password);
          // TODO: check the value of that user attributes to see if it makes sense to save cookie here
          if (user) {
            setLoading(false);
            setUser(user?.attributes);
            navigate("/");
          }
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      setLoading(false);
      customConsole("log", "error confirming sign up", error.code);
      if (error.code === "CodeMismatchException") {
        toast.error("Invalid code. Please try again");
      } else if ("ExpiredCodeException") {
        toast.error("The code has expired. Please try again");
      } else {
        toast.error(MESSAGE.SYSTEM_ERROR);
      }
    }
  };

  return (
    <SignUpWrapper>
      <div className="col-logo">
        <img
          src="/images/logo-2.png"
          alt="Measure"
          className="img-logo mx-auto"
        />
      </div>

      <div className="col-form">
        <div className="box-wrapper mx-auto" style={{ maxWidth: 410 }}>
          <img
            src="/images/logo-3.png"
            alt="Measure"
            className="img-logo mx-auto"
          />

          <Box mb="32px">
            <Text
              color="#000"
              lineHeight={17 / 14}
              margin="auto"
              className="text-center"
            >
              Please enter the security code sent to the email you signed up
              with to create your account.
            </Text>
          </Box>
        </div>

        <Formik
          initialValues={{
            email: email,
            code: "",
          }}
          validationSchema={yupValidate}
          onSubmit={async (data, handlers) => {
            handlers.setSubmitting(true);
            handlers.validateForm(data);
            await handleSubmit(data);
            handlers.setSubmitting(false);
          }}
        >
          {(formik) => {
            return (
              <Form>
                <div className="box-wrapper mx-auto" style={{ maxWidth: 410 }}>
                  <Box mb="32px">
                    <InputV2
                      name="code"
                      placeholder="Security code"
                      inputLeftElement={
                        <InputLeftElement
                          pointerEvents="none"
                          children={<SercurityIcon />}
                        />
                      }
                    />
                  </Box>

                  <Box mb="16px">
                    <Button
                      type="submit"
                      colorScheme="primary"
                      isLoading={loading}
                      loadingText="Create account"
                      className="w-100"
                    >
                      Create account
                    </Button>
                  </Box>
                  <div
                    style={{
                      marginTop: "16px",
                      marginBottom: "16px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      <button
                        disabled={loadingResendCodeTimer}
                        onClick={handleResendCode}
                        style={{
                          background: loadingResendCodeTimer
                            ? "#eee"
                            : "transparent",
                          borderRadius: "20px",
                          padding: "10px 20px",
                        }}
                      >
                        <Text
                          fontSize={16}
                          fontWeight="semibold"
                          style={{ color: "rgb(238, 90, 41)" }}
                        >
                          {loadingResendCodeTimer &&
                            `Code sent! You have to wait ${resendCodeTimerCount} seconds before sending another one`}
                          {!loadingResendCodeTimer &&
                            "Didn't receive a code? Click here to resend"}
                        </Text>
                      </button>
                    </div>
                  </div>

                  <Text
                    fontSize={16}
                    fontWeight="semibold"
                    color="#000"
                    textAlign="center"
                    padding="6px 0"
                  >
                    Go back to{" "}
                    <Text
                      as={Link}
                      to="/"
                      fontSize={16}
                      fontWeight="semibold"
                      _hover={{ color: "primary.600" }}
                    >
                      Login?
                    </Text>
                  </Text>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </SignUpWrapper>
  );
};

export default SignUpConfirm;
