import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import React from "react";
import { ModalBody } from "./Styles";
import useScreenType from "react-screentype-hook";

const AcceptableUseModal = ({ isOpen, onClose }) => {
  const { isMobile } = useScreenType();

  return (
    <Modal
      size={isMobile ? "full" : "xl"}
      onClose={onClose}
      isOpen={isOpen}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>MEASURE IGNITE ACCEPTABLE USE POLICY</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <h2>Last Updated: December 1, 2023</h2>
          <p>
            This Acceptable Use Policy (“Policy”) governs your use of the
            services offered by MEASURE Austin and its affiliates (“Services”)
            and our website(s) including MEASURE Ignite (“MEASURE Ignite”). We
            may modify this Policy by posting a revised version on the MEASURE
            Ignite website or the MEASURE Austin website. By using the Services
            or accessing the MEASURE Ignite website, you agree to the latest
            version of this Policy.
          </p>
          <p>
            You may not use, or facilitate or allow others to use, the Services
            or the MEASURE Ignite website:
          </p>
          <ul>
            <li> for any illegal or fraudulent activity;</li>
            <li>to violate the rights of others;</li>
            <li>
              {" "}
              to threaten, incite, promote, or actively encourage violence,
              terrorism, or other serious harm;
            </li>
            <li>
              to share private information of another person or entity without
              such person’s or entity’s consent;
            </li>
            <li>
              to engage in hate speech, discrimination, or any form of
              oppressive behavior;
            </li>
            <li>
              for any content or activity that promotes child sexual
              exploitation or abuse;
            </li>
            <li>
              to violate the security, integrity, or availability of any user,
              network, computer or communications system, software application,
              or network or computing device;
            </li>
            <li>
              to distribute, publish, send, or facilitate the sending of
              unsolicited mass email or other messages, promotions, advertising,
              or solicitations (or “spam”); to deliberately spread false or
              misleading information.
            </li>
          </ul>
          <h2>Investigation and Enforcement</h2>
          <p>
            We may investigate any suspected violation of this Policy, and
            remove or disable access to any content or resource that violates
            this Policy. You agree to cooperate with us to remedy any violation.
          </p>
          <p>
            When determining whether there has been a violation of this Policy,
            we may consider your ability and willingness to comply with this
            Policy.
          </p>
          <p>
            Reporting of Violations To report any violation or suspected
            violation of this Policy, please contact us at{" "}
            <a href="mailto:ignite@wemeasure.org">ignite@wemeasure.org</a>. We
            are committed to addressing concerns in a timely manner. Please note
            that the MEASURE Austin and MEASURE Ignite team may not process or
            respond to spam or to reports that contain offensive or vulgar
            language. Threats of harm to MEASURE Austin, MEASURE Ignite, MEASURE
            Austin personnel, or third parties may be reported to law
            enforcement.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AcceptableUseModal;
