import React from 'react';
import { Field } from 'formik';
import { FormControl, FormLabel, FormErrorMessage, RadioGroup, Stack, Radio } from '@chakra-ui/react';

export const RadioGroupV2 = ({ name, title, options, inline, restRadioGroup, restRadio, onChange, ...rest }: any) => {
  return (
    <Field name={name} {...rest}>
      {({ field, form }) => (
        <FormControl isInvalid={form.errors[name] && form.touched[name]}>
          {title && <FormLabel htmlFor={name}>{title}</FormLabel>}

          <RadioGroup {...field} id={name} onChange={onChange} {...restRadioGroup}>
            <Stack direction={inline ? 'row' : 'column'} flexWrap="wrap">
              {Array.isArray(options) &&
                options.length > 0 &&
                options.map((item) => (
                  <Radio key={item.value} value={item.value} colorScheme="primary" {...restRadio}>
                    {item.label || ''}
                  </Radio>
                ))}
            </Stack>
          </RadioGroup>

          <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};
