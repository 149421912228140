import { css } from 'styled-components';

export const Common = css`
  :host,
  :root,
  [data-theme] {
    --chakra-colors-chakra-placeholder-color: #262626 !important;
  }
  a,
  button {
    cursor: pointer;
    transition: all 0.4s ease;
  }
`;
