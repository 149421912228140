import styled from "styled-components";
import { devices } from "../../Theme.css";

export const SuggestedForYouWrapper = styled.div`
  background-color: white;
  box-shadow: 1px 1px 6px 0px #401a0f40;
  width: 400px;
  height: 449.5px;
  border-radius: 40px;
  padding: 24px;

  h2 {
    //styleName: button text;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }

  @media ${devices.max_sm} {
    display: none;
  }
`;

export const labelStyle = `
  color: #000;
  text-align: center;
  /* button text */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
`;

export const dividerStyle = `
  margin-top: -2px;
  margin-bottom: 24px;
`;

export const customTabsSpacing = `
  margin-top: 24px;
`;
