import styled from "styled-components";

export const CommentWrapper = styled.div<{ fromNotification?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .name {
    font-weight: bold;
  }

  .name-and-comment-container {
    margin-left: 12px;
  }

  border: 1px solid #eee;
  ${(props) => props.fromNotification && `border: 2px solid orange;`}
  height: 100px;
  padding: 24px;
  border-radius: 25px;
  margin-bottom: 12px;
`;

export const ProfilePicture = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    object-fit: contain;
  }
`;
