import React, { useEffect, useState } from "react";
import hotToast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import {
  CardInnerWrapper,
  CardText,
  MyOrganizationCard,
  MyOrganizationsPageWrapper,
  OrganizationsContainer,
  PageTitle,
} from "./Styles";
import { Union } from "../../Assets/SvgIcons";
import { OrganizationCard } from "../../Components/OrganizationCard/OrganizationCard";
import FullPageNexusLogoAnimation from "../../Reusable/FullPageNexusLogoAnimation/FullPageNexusLogoAnimation";
import { useOrganization } from "../../hooks/useOrganization";

const MyOrganizations = () => {
  const navigate = useNavigate();

  const { myOrganizations, myOrganizationsLoading } = useOrganization();

  if (myOrganizationsLoading) {
    return <FullPageNexusLogoAnimation />;
  }

  if (myOrganizations && myOrganizations.length > 0)
    return (
      <>
        <MyOrganizationsPageWrapper>
          {myOrganizations?.length < 6 && (
            <Button
              className="create-new-org-btn"
              onClick={() => navigate("/organizations/new")}
              type="submit"
              colorScheme="primary"
            >
              Create new
            </Button>
          )}
          <PageTitle>My Organizations</PageTitle>
          <OrganizationsContainer>
            {myOrganizations?.map((org) => (
              <button
                onClick={() =>
                  navigate(`/organizations/${org.id}`, {
                    state: {
                      role: org.myRole,
                    },
                  })
                }
                key={org.id}
              >
                <OrganizationCard
                  image={org.image}
                  name={org.name}
                  type={org.type}
                  memberCount={org.memberCount}
                />
              </button>
            ))}
          </OrganizationsContainer>
        </MyOrganizationsPageWrapper>
      </>
    );

  return (
    <MyOrganizationsPageWrapper>
      <MyOrganizationCard>
        <CardInnerWrapper>
          <Union boxSize={150} />
          <CardText>You don't have any organizations.</CardText>
          <Button
            onClick={() => navigate("/organizations/new")}
            type="submit"
            colorScheme="primary"
          >
            Create a new organization
          </Button>
        </CardInnerWrapper>
      </MyOrganizationCard>
    </MyOrganizationsPageWrapper>
  );
};
export default MyOrganizations;
