export const timePeriodOptions = [
  { value: "Monthly", label: "Monthly" },
  { value: "Quarterly", label: "Quaterly" },
  { value: "Yearly", label: "Yearly" },
];

export const visiblityOptions = [
  { value: "Private", label: "Private" },
  { value: "Public", label: "Public" },
];

export const quaterlyOptions = [
  { value: "Q1", label: "Q1" },
  { value: "Q2", label: "Q2" },
  { value: "Q3", label: "Q3" },
  { value: "Q4", label: "Q4" },
];

export const monthlyOptions = [
  { fIndex: 4, value: "april", label: "April", quarter: "Q1" },
  { fIndex: 5, value: "may", label: "May", quarter: "Q1" },
  { fIndex: 6, value: "june", label: "June", quarter: "Q1" },
  { fIndex: 7, value: "july", label: "July", quarter: "Q2" },
  { fIndex: 8, value: "august", label: "August", quarter: "Q2" },
  { fIndex: 9, value: "september", label: "September", quarter: "Q2" },
  { fIndex: 10, value: "october", label: "October", quarter: "Q3" },
  { fIndex: 11, value: "november", label: "November", quarter: "Q3" },
  { fIndex: 12, value: "december", label: "December", quarter: "Q3" },
  { fIndex: 1, value: "january", label: "January", quarter: "Q4" },
  { fIndex: 2, value: "february", label: "February", quarter: "Q4" },
  { fIndex: 3, value: "march", label: "March", quarter: "Q4" },
];

export const yearlyOptions = [
  {
    value: `${new Date().getFullYear()}`,
    label: `${new Date().getFullYear()}`,
  },
];

export const goalCategoriesOptions = [
  {
    categoryId: "goalCategory1",
    categoryName: "goalCategory1",
    value: "goalCategory1",
    label: "Goal category 1",
  },
  {
    categoryId: "goalCategory2",
    categoryName: "goalCategory2",
    value: "goalCategory2",
    label: "Goal cate 2",
  },
];

export const metricsOptions = [
  {
    metricId: "21",
    metricName: "Metric 1",
    value: "metric1",
    label: "Metric 1",
  },
  {
    metricId: "22",
    metricName: "Metric 2",
    value: "metric2",
    label: "Metric 2",
  },
];

export const progressMetric = [
  { value: "dollar", label: "Dollar" },
  { value: "number", label: "Number" },
  { value: "percentage", label: "percentage" },
];

export const achivedTargetConstants = {
  Yearly: [
    {
      achieved: 0,
      timeLineData: `${new Date().getFullYear()}`,
      updatedAt: Date.now(),
    },
  ],
  Quarterly: quaterlyOptions.map((quarter, i) => ({
    achieved: 0,
    timeLineData: quarter.value,
    updatedAt: Date.now(),
  })),
  Monthly: monthlyOptions.map((month, i) => ({
    achieved: 0,
    timeLineData: month.label,
    updatedAt: Date.now(),
  })),
};

export const yesOrNo = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

export const causeOptions = [
  {
    value: "community-economic-development",
    label: "Community & Economic Development",
  },
  { value: "agriculture-environment", label: "Agriculture & Environment" },
  { value: "arts-culture", label: "Arts & Culture" },
  { value: "human-services", label: "Human Services" },
  { value: "civic-engagement", label: "Civic Engagement" },
  { value: "digital-inclusion", label: "Digital InclusionHealth" },
  { value: "health-wellness", label: "Health & Wellness" },
  { value: "legal-system", label: "Legal System" },
  { value: "mental-health", label: "Mental Health" },
  {
    value: "police-accountability-public-safety",
    label: "Police Accountability & Public Safety",
  },
  {
    value: "professional-development-training",
    label: "Professional Development & Training",
  },
  { value: "wealth-building", label: "Wealth Building" },
  { value: "youth-empowerment", label: "Youth Empowerment" },
  { value: "education", label: "Education" },
  { value: "immigration-reform", label: "Immigration Reform" },
  { value: "lgbtqia-rights", label: "LGBTQIA Rights" },
  { value: "indigenous-rights", label: "Indigenous Rights" },
  { value: "disability-justice", label: "Disability Justice" },
  { value: "faith-spirituality", label: "Faith & Spirituality" },
];

export const roles = [
  { value: "Admin", label: "Admin", id: "1" },
  { value: "Standard", label: "Standard", id: "2" },
  { value: "ReadOnly", label: "Read only", id: "3" },
];

export const communitiesWeServe = [
  {
    value: "Select All",
    label: "Select All",
  },
  {
    value:
      "Black, Hispanic, Asian, Native American, and/or Multi-Racial Groups (BHANAM)",
    label:
      "Black, Hispanic, Asian, Native American, and/or Multi-Racial Groups (BHANAM)",
  },
  {
    value: "Powerful Groups Targeted for Oppression (PGTOs)*",
    label: "Powerful Groups Targeted for Oppression (PGTOs)*",
  },
  {
    value: "Powerful Women Subjected to Intersecting Oppressions (PWSIOs)",
    label: "Powerful Women Subjected to Intersecting Oppressions (PWSIOs)",
  },
  {
    value: "Powerful Groups Subjected to Economic Deprivation (PGSED)",
    label: "Powerful Groups Subjected to Economic Deprivation (PGSED)",
  },
  {
    value: "Powerful Groups Subjected Educational Neglect (PGSEN)",
    label: "Powerful Groups Subjected Educational Neglect (PGSEN)",
  },
  {
    value: "Powerful Groups Subjected to Marginalization (PGSM)",
    label: "Powerful Groups Subjected to Marginalization (PGSM)",
  },
  {
    value: "People of the Global Majority (POTGM)",
    label: "People of the Global Majority (POTGM)",
  },
  {
    value: "Powerful Groups Subjected to Underrepresentation (PGSU)",
    label: "Powerful Groups Subjected to Underrepresentation (PGSU)",
  },
  {
    value:
      "Individuals Across Africana, Latina, Asian, and Native American Diasporas (ALANDs)",
    label:
      "Individuals Across Africana, Latina, Asian, and Native American Diasporas (ALANDs)",
  },
  { value: "Adults", label: "Adults" },
  { value: "Indigenous People", label: "Indigenous People" },
  { value: "Youth", label: "Youth" },
  { value: "Black Girls", label: "Black Girls" },
  { value: "Black Boys", label: "Black Boys" },
  { value: "Brown Girls", label: "Brown Girls" },
  { value: "Brown Boys", label: "Brown Boys" },
  { value: "Students", label: "Students" },
  { value: "Aging Adults", label: "Aging Adults" },
  {
    value: "People Living Without Homes",
    label: "People Living Without Homes",
  },
  {
    value: "People Experiencing a Mental Health Diagnoses",
    label: "People Experiencing a Mental Health Diagnoses",
  },
  {
    value: "People Experiencing a Health Diagnosis",
    label: "People Experiencing a Health Diagnosis",
  },
  {
    value: "People Experiencing a Lack of Food",
    label: "People Experiencing a Lack of Food",
  },
  { value: "LGBQTIA++", label: "LGBQTIA++" },
  { value: "Babies", label: "Babies" },
  {
    value: "Another community not listed",
    label: "Another community not listed",
  },
];

const commonFields = [
  { label: "Not Listed:", value: "not-listed" },
  { label: "Prefer not to reply", value: "prefer-not-to-reply" },
];

export const genderOptions = [
  { label: "Woman", value: "woman" },
  { label: "Man", value: "man" },
  { label: "Transgender woman", value: "transgender-trans-woman" },
  { label: "Transgender man", value: "transgender-trans-man" },
  { label: "Non-Binary/Non Conforming", value: "non-binary" },
  { label: "Two Spirit", value: "two-spirit" },
  ...commonFields,
];

export const pronounsOptions = [
  { label: "He/Him/His", value: "he-him-his" },
  { label: "She/Her/Hers", value: "she-her-hers" },
  { label: "They/Them/Their", value: "they-them-theirs" },
  ...commonFields,
];

export const ethnicityOptions = [
  { label: "Asian or Asian american", value: "asian-or-asian-american" },
  {
    label: "Hawaiian or Pacific Islander",
    value: "hawaiian-or-pacific-islander",
  },
  { label: "Black or African American", value: "black-or-african-american" },
  { label: "Hispanic or Latino", value: "hispanic-or-latino" },
  {
    label: "Native American or Alaska Native",
    value: "native-american-or-american",
  },
  {
    label: "White or Caucasian",
    value: "white-or-caucasian",
  },
  ...commonFields,
];

export function getByValue(arr, value) {
  if (value?.includes("not-listed:")) {
    let _value = value.replace("not-listed:", "");
    _value = _value.trim();
    return {
      label: _value,
      value: _value,
    };
  }
  return arr.find((element) => element.value === value);
}
