import React, { useState } from "react";
import hotToast from "react-hot-toast";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { Box, Button, Flex, Text, Stack } from "@chakra-ui/react";
import StepGeneralInfo from "./StepGeneralInfo";
import StepSpark from "../../../Components/StepSpark/StepSpark";
import { SignUpOnboardingWrapper } from "./Styles";
import Avatar from "../../../Components/Profile/Avatar/Avatar";
import { Auth } from "@aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import { MESSAGE } from "../../../Constants/Message";
import {
  updateFieldNotListedForSubmission,
  notListedInputFieldHasError,
} from "../../../utils/onboarding";
import {
  useUpdateUserMutation,
  useUpdateProfilePictureMutation,
  useGetProfileQuery,
  api,
} from "../../../../redux/reducers/apiSlice";
import FullPageNexusLogoAnimation from "../../../Reusable/FullPageNexusLogoAnimation/FullPageNexusLogoAnimation";
import { signupOnboardingSchema } from "../../Organizations/AddNewOrganizationForm/schema";
import { customConsole } from "../../../utils/helper";
import * as Sentry from "@sentry/react";

const SignUpOnboarding = (props) => {
  const [step, setStep] = useState("GENERAL_INFO");
  const navigate = useNavigate();
  const [updateUser, { isLoading }] = useUpdateUserMutation();
  const dispatch = useDispatch();
  const {
    data: currentProfileData,
    isLoading: currentProfileDataIsLoading,
    isFetching,
    isSuccess,
    isError: currentProfileDataError,
    error,
  } = useGetProfileQuery();

  const [updateProfilePicture] = useUpdateProfilePictureMutation();

  async function signOut() {
    try {
      dispatch(api.util.resetApiState());
      await Auth.signOut();
      navigate("/");
    } catch (error) {
      Sentry.captureException(error);
      customConsole("log", "error signing out: ", error);
    }
  }

  const handleFinish = async (data) => {
    const notListedInputEmpty =
      notListedInputFieldHasError(data, "gender", hotToast) ||
      notListedInputFieldHasError(data, "ethnicity", hotToast) ||
      notListedInputFieldHasError(data, "pronouns", hotToast);

    if (notListedInputEmpty) {
      setStep("GENERAL_INFO");
      return;
    }

    try {
      const payload = {
        name: {
          lastName: data.name.lastName,
          firstName: data.name.firstName,
        },
        email: currentProfileData?.email,
        title: data.title,
        gender: updateFieldNotListedForSubmission(data.gender, data.genderText),
        pronouns: updateFieldNotListedForSubmission(
          data.pronouns,
          data.pronounsText
        ),
        ethnicity: updateFieldNotListedForSubmission(
          data.ethnicity,
          data.ethnicityText
        ),
        sparks: data.sparks,
        location: {
          zip: "",
          city: data.location.city,
          state: data.location.state,
          address1: "",
          address2: "",
        },
      };

      await updateUser(payload).unwrap();
    } catch (error) {
      Sentry.captureException(error);
      console.error("SignupOnboardingError", error);
      hotToast.error(MESSAGE.SYSTEM_ERROR);
    }
  };

  if (isLoading || currentProfileDataIsLoading) {
    return <FullPageNexusLogoAnimation />;
  }

  return (
    <SignUpOnboardingWrapper className="sign-up-onboarding-wrapper">
      <div className="col-logo bg-light-gradient">
        <div className="box-inner">
          <img
            src="/images/logo-4.png"
            alt="Measure"
            className="img-logo mx-auto"
          />

          <Text
            maxWidth="338px"
            fontSize={[18, null, null, 20, 24]}
            fontWeight="semibold"
            color="#000"
            margin="88px auto 0"
          >
            {step === "GENERAL_INFO" && (
              <>
                Did you know?
                <br />
                <br />
                In 2020, Measure surveyed over 100 Black-led organizations in
                Austin, TX and 79% shared that they didn’t have systems for
                collecting data.
                <br />
                <br />
                We're here to change that.
              </>
            )}

            {step === "SPARK" && (
              <>
                What's your spark?
                <br />
                <br />
                At Measure, we identify your sparks as the causes that motivate
                you to ignite change.
              </>
            )}
          </Text>
        </div>
      </div>

      <div className="col-form">
        <Text fontWeight="semibold" color="#000" textAlign="center" mb="32px">
          {step === "GENERAL_INFO" && <>Finish setting up your profile.</>}
          {step === "SPARK" && (
            <>
              Choose 3 sparks that ignite your fire for change. (Sparks are the
              causes that you care about)
            </>
          )}
        </Text>

        {step === "GENERAL_INFO" && (
          <Flex justifyContent="center" mb="32px">
            <Avatar
              defaultImage={currentProfileData?.image?.url}
              updateImage={updateProfilePicture}
              id={currentProfileData?.id}
            />
          </Flex>
        )}

        <Formik
          validationSchema={signupOnboardingSchema}
          initialValues={{
            name: {
              firstName: "",
              lastName: "",
            },
            title: "",
            location: {
              country: "United States",
              state: "",
              city: "",
              zip: "",
            },
            gender: "",
            genderText: "",
            pronouns: "",
            pronounsText: "",
            ethnicity: "",
            ethnicityText: "",
            sparks: "",
          }}
          onSubmit={async (data, handlers) => {
            handlers.setSubmitting(true);
            handlers.validateForm(data);
            handleFinish(data);
            handlers.setSubmitting(false);
          }}
        >
          {(formik) => {
            customConsole("log", "FORMIK ERRORS", formik.errors);
            function disableButton(step: string): boolean {
              if (step == "GENERAL_INFO") {
                if (
                  formik.values.name.firstName &&
                  formik.values.name.lastName &&
                  formik.values.title &&
                  formik.values.location &&
                  formik.values.location.state &&
                  formik.values.location.city &&
                  formik.values.gender &&
                  formik.values.pronouns &&
                  formik.values.ethnicity
                ) {
                  return false;
                }
              }
              if (step == "SPARK") {
                if (formik.values.sparks && formik.values.sparks.length == 3)
                  return false;
              }
              return true;
            }
            return (
              <Form onSubmit={formik.handleSubmit}>
                <Box maxWidth={866} margin="auto">
                  {step === "GENERAL_INFO" && (
                    <StepGeneralInfo formik={formik} />
                  )}
                  {step === "SPARK" && <StepSpark formik={formik} />}
                </Box>

                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  spacing="10px"
                  className="box-submit-btns"
                >
                  {step === "GENERAL_INFO" && (
                    <>
                      <Button
                        variant="outline"
                        loadingText="Sign in"
                        className="btn-outline-dark"
                        onClick={signOut}
                      >
                        Cancel
                      </Button>

                      <Button
                        colorScheme="primary"
                        minWidth="160px"
                        loadingText="Next"
                        isLoading={false}
                        isDisabled={disableButton("GENERAL_INFO")}
                        onClick={() => {
                          setStep("SPARK");
                        }}
                        className="gray-for-disabled"
                      >
                        Next
                      </Button>
                    </>
                  )}

                  {step === "SPARK" && (
                    <>
                      <Button
                        variant="outline"
                        className="btn-outline-dark"
                        onClick={() => setStep("GENERAL_INFO")}
                      >
                        Back
                      </Button>

                      <Button
                        type="submit"
                        colorScheme="primary"
                        minWidth="160px"
                        loadingText="Finish"
                        isLoading={false}
                        isDisabled={disableButton("SPARK")}
                        className="gray-for-disabled"
                      >
                        Finish
                      </Button>
                    </>
                  )}
                </Stack>
              </Form>
            );
          }}
        </Formik>
      </div>
    </SignUpOnboardingWrapper>
  );
};

export default SignUpOnboarding;
