import React from "react";
import { ProfileAvatar } from "../ProfileAvatar/ProfileAvatar";
import { Modal } from "./Modal";
import { Props } from "./types";
import {
  ShareQRCodeModalInnerWrapper,
  QRCodeLayout,
  QRCodeWrapper,
  QRCode,
} from "./Styles";

import { truncate } from "../../utils/helper";

const ShareQRCodeModal = ({ isOpen, onClose, organization }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} modalTitle={"Share organization"}>
      <ShareQRCodeModalInnerWrapper>
        <ProfileAvatar size="2xl" src={organization?.image?.url} />
        <h1>{truncate(organization.name)}</h1>
        <h2>{organization.type.toUpperCase()}</h2>
        <QRCodeLayout>
          <QRCodeWrapper>
            <QRCode
              size={200}
              value={window.location.href}
              viewBox={`0 0 200 200`}
            />
          </QRCodeWrapper>
        </QRCodeLayout>
      </ShareQRCodeModalInnerWrapper>
    </Modal>
  );
};

export default ShareQRCodeModal;
