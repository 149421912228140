import * as yup from "yup";
import {
  metricMonthOrQuaterFieldOptions,
  metricYearSelectInputOptions,
} from "../../../utils/helper";
import { MESSAGE, MinMaxMessage } from "../../../Constants/Message";

const phoneRegExp = /^(?:\+?1[-.]?)?(\d{3})[-.]?(\d{3})[-.]?(\d{4})$/;

export const addNewOrganizationFormSchema = yup.object({
  step: yup.string().oneOf(["GENERAL_INFO", "SPARK"]).required(),
  image: yup.object({
    url: yup.string(),
  }),
  name: yup.string().required("Organization name is a required field"),
  type: yup
    .string()
    .oneOf(["grassroots", "non-profit", "foundation", "corporation"])
    .required(),
  mission: yup.string(),
  vision: yup.string(),
  problem: yup.string(),
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, "Invalid phone number")
    .required("Phone number is required"),
  sparks: yup.array().when("step", {
    is: "SPARK",
    then: yup.array().length(3, "You should have 3 sparks").required(),
    otherwise: yup.array(),
  }),
  location: yup.object({
    zip: yup.string().required("ZIP code is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    address1: yup.string().required("Address is required"),
  }),
  orgFields: yup.object().when("type", {
    is: (type) => type === "foundation" || type === "non-profit",
    then: yup.object({
      careModelCertified: yup.boolean().required(),
      ein: yup
        .number()
        .test(
          "checkLength",
          "EIN must be exactly 9 characters",
          (val) => val?.toString().length === 9
        )
        .required("EIN is required"),
      antiRacist: yup.string(),
    }),
    otherwise: yup.object({
      careModelCertified: yup.boolean(),
      antiRacist: yup.string(),
    }),
  }),
  labels: yup.array().required().min(1),
  labelNotListed: yup.string().when("labels", {
    is: (labels: string[]) => labels.includes("not-listed"),
    then: yup
      .string()
      .required("Since you chose 'Other' you should provide a value"),
    otherwise: yup.string(),
  }),
  communitiesServed: yup.array().required().min(1),
  communityServedNotListed: yup.string().when("communitiesServed", {
    is: (communitiesServed: string[]) =>
      communitiesServed.includes("not-listed"),
    then: yup
      .string()
      .required("Since you chose 'Other' you should provide a value"),
    otherwise: yup.string(),
  }),
});

export const createGoalSchema = yup.object({
  name: yup.string().required("Name is required field"),
  description: yup.string().required("Description is a required field"),
});

export const createMetricSchema = yup.object({
  predetermined: yup.boolean().required(),
  description: yup
    .string()
    .max(255, "Description should be 255 characters in max")
    .required(),
  // startDate: yup.date().required(),
  // endDate: yup.date().required(),
  progress: yup.object({
    target: yup.string(),
    targetValue: yup
      .number()
      .min(1, "Target should be greater than 0")
      .required("Target should be greater"),
    current: yup.number(),
    targetType: yup.string().oneOf(["DOLLARS", "NUMBERS", "PERCENTAGES"]),
  }),
  term: yup.string().oneOf(["QUARTERLY", "MONTHLY"]).required(),
  year: yup
    .string()
    .oneOf(metricYearSelectInputOptions().map((year) => year.value))
    .required(),
  month: yup.string().when("term", {
    is: "MONTHLY",
    then: yup
      .string()
      .oneOf(
        metricMonthOrQuaterFieldOptions("MONTTHLY").map((field) => field.value)
      )
      .required(),
    otherwise: yup
      .string()
      .oneOf(
        metricMonthOrQuaterFieldOptions("QUARTERLY").map((field) => field.value)
      )
      .required(),
  }), //
});

export const signupOnboardingSchema = yup.object({
  name: yup
    .object({
      firstName: yup.string().required("First name is required"),
      lastName: yup.string().required("Last name is required"),
    })
    .required(),
  title: yup.string().required("Title is required"),
  location: yup.object({
    country: yup.string().required("Country is required"),
    state: yup.string().required("State is required"),
  }),
  gender: yup.string().required("Gender is required"),
  pronouns: yup.string().required("Pronoun is required"),
  ethnicity: yup.string().required("Ethnicity is required"),
  genderText: yup.string().when("pronouns", {
    is: (pronouns) => pronouns?.includes("not-listed:"),
    then: yup
      .string()
      .required("When you choose 'Not Listed' you should specify "),
    otherwise: yup.string(),
  }),
  pronounsText: yup.string().when("pronouns", {
    is: (pronouns) => pronouns?.includes("not-listed:"),
    then: yup
      .string()
      .required("When you choose 'Not Listed' you should specify "),
    otherwise: yup.string(),
  }),
  ethnicityText: yup.string().when("ethnicity", {
    is: (ethnicity) => ethnicity?.includes("not-listed:"),
    then: yup
      .string()
      .required("When you choose 'Not Listed' you should specify "),
    otherwise: yup.string(),
  }),
  sparks: yup.array().length(3, "You should choose 3 sparks"),
});

const regex_NoWhiteSpace = /^\S*$/;
// Min 8 characte, 1 letter case , 1 upper case and 1 sepcial charater

const regex_Password =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,30}$/;

export const signupSchema = yup.object({
  email: yup
    .string()
    .matches(regex_NoWhiteSpace, {
      message: "Email must not contain whitespace",
      excludeEmptyString: true,
    })
    .email(MESSAGE.EMAIL_INVALID)
    .required(MESSAGE.INPUT_REQUIRED)
    .max(100, MinMaxMessage("max", "Email", "100")),
  password: yup
    .string()
    .required(MESSAGE.INPUT_REQUIRED)
    .matches(regex_Password, {
      message: "Please meet the password requirements",
      excludeEmptyString: true,
    }),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password must match"),
  confirmTerm: yup.bool().oneOf([true], MESSAGE.AGREE_REQUIRED),
  confirmAdultAdultAge: yup.bool().oneOf([true], MESSAGE.ADULT_AGE_REQUIRED),
});
