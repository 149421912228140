import React, { useState, useRef } from "react";
import toast from "react-hot-toast";
import { CarretDown } from "../../../Assets/SvgIcons";
import { ProfileAvatar } from "../../ProfileAvatar/ProfileAvatar";
import {
  MembersTableRow,
  MembersTableCell,
  RemoveButton,
  RoleButton,
} from "./Styles";
import { useDetectOutsideClick } from "../../../hooks/useDetectOutsideClick";
import { useOrganization } from "../../../hooks/useOrganization";
import {
  useGetProfileQuery,
  useUpdateMemberRoleMutation,
} from "../../../../redux/reducers/apiSlice";
import { Modal } from "../../Modal/Modal";
import { capitalizeFirstLetter } from "../../../utils/helper";
import { truncate } from "../../../utils/helper";
import * as Sentry from "@sentry/react";

const MemberTableRow = ({ member, onRemoveMember }) => {
  const { data: currentUserProfile } = useGetProfileQuery();
  const { currentUserRole, currentOrganization } = useOrganization();
  const [isRoleDropdownVisible, setIsRoleDropdownVisible] = useState(false);
  const roleListRef = useRef<HTMLDivElement>(null);
  const [updateMemberRole, { isLoading: updateMemberRoleLoading }] =
    useUpdateMemberRoleMutation();
  const [newRole, setNewRole] = useState<"MEMBER" | "ADMIN" | undefined>(
    undefined
  );
  const [updateRoleModalVisible, setUpdateRoleModalVisible] = useState(false);

  useDetectOutsideClick({
    customRef: roleListRef,
    onOutsideClicked: () => setIsRoleDropdownVisible(false),
  });

  const handleRoleSelected = async (role: "MEMBER" | "ADMIN" | undefined) => {
    const memberFullName = `${member.name.firstName} ${member.name.lastName}`;
    if (currentOrganization?.id && role) {
      try {
        await updateMemberRole({
          orgId: currentOrganization?.id,
          memberId: member.id,
          role,
        });
        setUpdateRoleModalVisible(false);
        toast.success(`You updated the role of ${truncate(memberFullName)}`);
      } catch (e) {
        Sentry.captureException(e);
        toast.error(
          `Something unexpected happened while trying to update the role of ${truncate(
            memberFullName
          )}`
        );
      }
    }
  };

  const onRoleSelected = (newRole: "MEMBER" | "ADMIN") => {
    setNewRole(newRole);
    setUpdateRoleModalVisible(true);
  };

  return (
    <MembersTableRow key={member.id}>
      <MembersTableCell containsImage>
        <ProfileAvatar
          src={member?.image?.url}
          name={`${member?.name?.firstName} ${member?.name?.lastName}`}
        />
      </MembersTableCell>
      <MembersTableCell>
        <span className="name">
          {member.name.firstName
            ? truncate(`${member.name.firstName} ${member.name.lastName}`)
            : ""}
        </span>
      </MembersTableCell>
      <MembersTableCell>{member.email}</MembersTableCell>
      <MembersTableCell>
        <button
          disabled={currentUserRole !== "ADMIN"}
          onClick={() => setIsRoleDropdownVisible(true)}
        >
          {member.role}
          {currentUserRole === "ADMIN" && <CarretDown />}
        </button>
        {isRoleDropdownVisible && (
          // @ts-ignore
          <ul ref={roleListRef}>
            {["ADMIN", "MEMBER"].map((role, id) => (
              <li key={id}>
                <RoleButton
                  disabled={member.role === role}
                  key={id}
                  onClick={() => onRoleSelected(role as "MEMBER" | "ADMIN")}
                >
                  {capitalizeFirstLetter(role)}
                </RoleButton>
              </li>
            ))}
          </ul>
        )}
      </MembersTableCell>
      {currentUserRole === "ADMIN" && (
        <MembersTableCell>
          <RemoveButton
            disabled={currentUserProfile?.id === member.id}
            onClick={() => onRemoveMember(member.id)}
          >
            Remove
          </RemoveButton>
        </MembersTableCell>
      )}
      {updateRoleModalVisible && (
        <Modal
          modalTitle={`Are you sure you want to update the role of ${member.name.firstName} ${member.name.lastName}?`}
          isOpen={updateRoleModalVisible}
          onClose={() => setUpdateRoleModalVisible(false)}
          primaryButtonText="Update"
          disablePrimaryButton={updateMemberRoleLoading}
          primaryLoading={updateMemberRoleLoading}
          onPrimaryButtonClicked={() => handleRoleSelected(newRole)}
          secondaryButtonText="Cancel"
        />
      )}
    </MembersTableRow>
  );
};

export default MemberTableRow;
