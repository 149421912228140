import styled from 'styled-components';

export const CommonLoading = styled.div`
  position: relative;
  .common-loading-spin {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 400px;
    z-index: 4;
    .common-loading-spinner {
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }
  .common-loading-container {
    position: relative;
    transition: opacity 0.3s;
    &.common-loading-blur {
      position: relative;
      opacity: 0.1;
      clear: both;
      pointer-events: none;
    }
  }
`;
