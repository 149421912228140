import { CarretLeft } from "../../Assets/SvgIcons";
import { useGetSuggestedQuery } from "../../../redux/reducers/apiSlice";
import { Tabs } from "../../Components/Tabs/Tabs";
import { People } from "../../Components/Tabs/People/People";
import {
  labelStyle,
  dividerStyle,
  customTabsSpacing,
} from "../../Components/SuggestedForYou/styles";
import { PageContainer, PageHeader, NavButton, TabContainer } from "./styles";
import { Organizations } from "../../Components/Tabs/Organizations/Organizations";
import { Navigate, useNavigate } from "react-router-dom";
import useScreenType from "react-screentype-hook";

const ViewAllSuggestions = () => {
  const {
    data: suggested,
    isLoading: loadingSuggested,
    isError,
    error,
  } = useGetSuggestedQuery();

  const navigate = useNavigate();

  const { isMobile } = useScreenType();
  console.log("IS MOBILE", isMobile);

  if (!isMobile) <Navigate to="/" />;

  return (
    <PageContainer>
      <PageHeader>
        <NavButton
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            navigate("/");
          }}
        >
          <CarretLeft />
        </NavButton>
        <h1>Suggested for you</h1>
      </PageHeader>
      <TabContainer>
        <Tabs<"People" | "Organizations">
          customTabLabelStyle={labelStyle}
          customDividerStyle={dividerStyle}
          customTabsSpacing={customTabsSpacing}
          defaultSelectedTab={"People"}
          tabs={[
            {
              label: "People",
              component: (
                <People
                  loading={loadingSuggested}
                  people={suggested?.users || []}
                />
              ),
            },
            {
              label: "Organizations",
              component: (
                <Organizations
                  loading={loadingSuggested}
                  // @ts-ignore
                  organizations={suggested?.organizations || []}
                />
              ),
            },
          ]}
        />
      </TabContainer>
    </PageContainer>
  );
};

export default ViewAllSuggestions;
