import { forwardRef } from "react";
import { Interaction } from "../../../redux/reducers/types";
import { CaptionComponent } from "../CaptionComponent/CaptionComponent";
import { ProfileAvatar } from "../ProfileAvatar/ProfileAvatar";
import { CommentWrapper } from "./styles";

interface Props {
  comment: Interaction;
  fromNotification?: boolean;
}

export const Comment = forwardRef(
  ({ comment, fromNotification }: Props, ref) => {
    return (
      // @ts-ignore
      <CommentWrapper fromNotification={fromNotification} ref={ref}>
        <ProfileAvatar
          src={comment.ownerProfilePhoto}
          name={comment.ownerName}
        />
        <div className="name-and-comment-container">
          <span className="name">{comment.ownerName}</span>
          <CaptionComponent caption={comment.data.message} />
        </div>
      </CommentWrapper>
    );
  }
);
