import { css } from 'styled-components';

export const Tab = css`
  body {
    .common-tabs {
      .chakra-tabs__tablist {
        border-bottom: 1px solid #d1d1d1;
      }
      .chakra-tabs__tab {
        font-size: 16px;
        line-height: calc(20 / 16);
        color: #000;
        border-bottom: 2px solid transparent;
        outline: 0;
        padding: 5px 20px;
        margin-bottom: 0;
        &[aria-selected='true'],
        &[data-selected] {
          font-weight: 600;
          border-color: ${({ theme }) => theme.color.primary};
        }
      }
    }
    .common-tabs-2 {
      .chakra-tabs__tablist {
        border-bottom: 0;
      }
      .chakra-tabs__tab {
        position: relative;
        font-size: 24px;
        line-height: calc(29 / 24);
        color: #000;
        border: 0;
        outline: 0;
        padding: 0 0 8px;
        margin: 0 22px;
        &:first-child {
          margin-left: 0 !important;
        }
        &:last-child {
          margin-right: 0 !important;
        }
        &:before {
          display: none;
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 4px;
          background: ${({ theme }) => theme.color.primary};
          border-radius: 10px;
        }
        &[aria-selected='true'],
        &[data-selected] {
          font-weight: 600;
          &:before {
            display: block;
          }
        }
      }
    }
  }
`;
