import React, { useEffect, useState } from "react";
import { Button, SimpleGrid, Spinner } from "@chakra-ui/react";
import toast from "react-hot-toast";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import {
  genderOptions,
  getByValue,
  pronounsOptions,
  ethnicityOptions,
} from "../../Constants/DropdownOptions";
import ProfileModal from "../../Components/Profile/ProfileModal/ProfileModal";
import { MessageIcon } from "../../Assets/SvgIcons";
import { MyGeneralInfo, ProfilePage, MySparks, ProfileSpark } from "./Styles";
import {
  useGetProfileQuery,
  useGetSparksQuery,
  useLazyGetUserByIdQuery,
  useGetPostGroupQuery,
} from "../../../redux/reducers/apiSlice";
import FollowingModal from "../../Components/Profile/FollowingModal/FollowingModal";
import { Posts } from "../../Components/Tabs/Posts/Posts";
import { ProfileAvatar } from "../../Components/ProfileAvatar/ProfileAvatar";
import FullPageNexusLogoAnimation from "../../Reusable/FullPageNexusLogoAnimation/FullPageNexusLogoAnimation";
import FollowButton from "./FollowButton";
import { truncate } from "../../utils/helper";

const Profile = () => {
  const { id: userIdParam } = useParams();
  const navigate = useNavigate();
  const [
    getUserById,
    { data: userProfileData, isLoading: userProfileDataIsLoading },
  ] = useLazyGetUserByIdQuery();
  const { data: currentProfileData, isLoading: currentProfileDataIsLoading } =
    useGetProfileQuery();

  const {
    data: posts,
    isLoading: postsLoading,
    isError: isPostsError,
  } = useGetPostGroupQuery(userProfileData?.id || currentProfileData?.id);

  const { data: sparks, isLoading: sparksLoading } = useGetSparksQuery();

  useEffect(() => {
    if (isPostsError) {
      toast.error(
        "Something went wrong while fetching the posts. Try again later"
      );
    }
  }, [isPostsError]);

  useState<boolean>(false);
  const [step, setStep] = useState("");

  const [isOpenFollowingModal, setIsOpenFollowingModal] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [isOpenEditProfile, setIsOpenEditProfile] = useState(false);

  useEffect(() => {
    const queryStrings = queryString.parse(location.search);
    const editProfile: unknown = queryStrings.editProfile;
    if (editProfile) {
      setIsOpenEditProfile(true);
      setStep("GENERAL_INFO");
    }
  }, [location.search]);

  useEffect(() => {
    setIsOpenFollowingModal(false);
  }, [userIdParam, isOpenEditProfile]);

  useEffect(() => {
    if (userIdParam) {
      getUserById(userIdParam);
    }
  }, [userIdParam]);

  const isCurrentUser = userProfileData?.id === currentProfileData?.id;
  const finalProfileData = isCurrentUser ? currentProfileData : userProfileData;

  return (
    <div>
      {currentProfileDataIsLoading || userProfileDataIsLoading ? (
        <FullPageNexusLogoAnimation />
      ) : (
        <>
          <ProfilePage>
            <MyGeneralInfo className="my-general-info">
              <div className="box-row">
                <div className="box-info">
                  <div className="box-name">
                    <div className="box-name-inner">
                      <div className="fullname">
                        {finalProfileData?.name.firstName &&
                          finalProfileData?.name.lastName &&
                          truncate(
                            `${finalProfileData?.name?.firstName} ${finalProfileData?.name?.lastName}`
                          )}
                      </div>
                      <div className="sub">{finalProfileData?.title}</div>
                    </div>
                  </div>
                  <div className="box-avatar">
                    <div className="box-avatar-inner">
                      <div className="col-avatar">
                        <ProfileAvatar
                          src={finalProfileData?.image?.url}
                          name={`${finalProfileData?.name.firstName} ${finalProfileData?.name.lastName}`}
                          width="230px"
                          height="230px"
                          border={`2px solid #eee`}
                        />
                      </div>
                      <div className="col-detail">
                        <ul>
                          {finalProfileData?.preferences
                            ?.pronounsVisibility && (
                            <li>
                              {
                                getByValue(
                                  pronounsOptions,
                                  finalProfileData?.pronouns
                                )?.label
                              }
                            </li>
                          )}
                          {finalProfileData?.preferences?.genderVisibility && (
                            <li>
                              {
                                getByValue(
                                  genderOptions,
                                  finalProfileData?.gender
                                )?.label
                              }
                            </li>
                          )}
                          {finalProfileData?.preferences?.emailVisibility && (
                            <li>{finalProfileData?.email}</li>
                          )}
                          {finalProfileData?.preferences?.locationVisiblity && (
                            <li>
                              {finalProfileData?.location?.city
                                ? finalProfileData?.location?.city + ", "
                                : ""}
                              {finalProfileData?.location?.state}
                            </li>
                          )}
                          <li>
                            {
                              getByValue(
                                ethnicityOptions,
                                finalProfileData?.ethnicity
                              )?.label
                            }
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="box-action">
                  <ul className="box-follow">
                    <li>
                      <div>
                        <button
                          disabled={!isCurrentUser}
                          onClick={() => {
                            setIsOpenFollowingModal(true), setTabIndex(1);
                          }}
                          className="txt-title text-hover-primary cursor-pointer"
                        >
                          Followers
                        </button>
                      </div>
                      <div className="txt">
                        {finalProfileData?.followerCount}
                      </div>
                    </li>
                    <li>
                      <div>
                        <button
                          disabled={!isCurrentUser}
                          onClick={() => {
                            setIsOpenFollowingModal(true), setTabIndex(0);
                          }}
                          className="txt-title text-hover-primary cursor-pointer"
                        >
                          Following
                        </button>
                      </div>
                      <div className="txt">
                        {finalProfileData?.followingCount}
                      </div>
                    </li>
                  </ul>
                  <div className="box-btn">
                    {isCurrentUser ? (
                      <>
                        <div style={{ marginTop: "8px" }}>
                          <Button
                            variant="outline"
                            colorScheme="primary"
                            className="btn-outline-primary"
                            onClick={() => {
                              navigate(`${location.pathname}?editProfile=true`);
                              setStep("GENERAL_INFO");
                            }}
                          >
                            Edit profile
                          </Button>
                        </div>
                      </>
                    ) : (
                      <div>
                        <div>
                          {/** @ts-ignore */}
                          <FollowButton userId={finalProfileData?.id} />
                        </div>
                        {!isCurrentUser ? (
                          <div style={{ marginTop: "8px" }}>
                            <Button
                              variant="outline"
                              colorScheme="primary"
                              isLoading={false}
                              isDisabled={false}
                              leftIcon={<MessageIcon />}
                              onClick={() =>
                                navigate(`/messages/${finalProfileData?.id}`)
                              }
                            >
                              Message
                            </Button>
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </MyGeneralInfo>

            <MySparks className="my-sparks">
              {sparksLoading ? (
                <Spinner color="red" />
              ) : (
                <>
                  <div className="box-header">
                    <div className="box-name">
                      {finalProfileData?.name?.firstName &&
                        truncate(finalProfileData?.name?.firstName)}
                      ’s sparks
                    </div>
                    <div className="box-btn">
                      {isCurrentUser ? (
                        <Button
                          colorScheme="primary"
                          variant="link"
                          onClick={() => {
                            navigate(`${location.pathname}?editProfile=true`),
                              setStep("SPARK");
                          }}
                        >
                          Edit my sparks
                        </Button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  <SimpleGrid
                    columns={[1, null, 3]}
                    spacingX="20px"
                    className="box-list"
                  >
                    {finalProfileData?.sparks?.map((item, index) => {
                      let spark = sparks?.find((spark) => spark.name === item);

                      return (
                        <ProfileSpark key={index}>
                          <img
                            src={spark?.logo}
                            style={{ width: "40px", height: "40px" }}
                          />
                          <p>{spark?.name}</p>
                        </ProfileSpark>
                      );
                    })}
                  </SimpleGrid>
                </>
              )}
            </MySparks>
            {posts && (
              <Posts
                showPostCreationForm={isCurrentUser}
                posts={posts}
                postsLoading={postsLoading}
                withSuggestions={false}
                hideLoadMore
              />
            )}
          </ProfilePage>
          {isOpenFollowingModal && isCurrentUser && (
            <FollowingModal
              isOpen={isOpenFollowingModal}
              onClose={() => setIsOpenFollowingModal(false)}
              tabIndex={tabIndex}
              setTabIndex={setTabIndex}
            />
          )}
          {isOpenEditProfile && (
            <ProfileModal
              isOpen={isOpenEditProfile}
              onClose={() => {
                setIsOpenEditProfile(false);
                navigate(`${location.pathname}`);
              }}
              step={step}
              title={step !== "SPARK" ? "Edit my profile" : "Edit my sparks"}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Profile;
