import { useEffect, useState, useMemo } from "react";
import { find } from "linkifyjs";
import toast from "react-hot-toast";
import {
  useLazyGetPostByIdQuery,
  useDeletePostMutation,
  useGetProfileQuery,
  useLazyGetOrganizationDetailsQuery,
} from "../../redux/reducers/apiSlice";
import { MESSAGE } from "../Constants/Message";
import { Post } from "../../redux/reducers/types";
import * as Sentry from "@sentry/react";

export const usePost = (post: Post) => {
  useState(false);
  const { data: currentUserProfileData } = useGetProfileQuery();
  const [
    getPostById,
    { isLoading: sharedPostLoading, error: sharedPostError, data: sharedPost },
  ] = useLazyGetPostByIdQuery();

  const [
    getOrganizationDetails,
    {
      isLoading: sharedMetricOrganizationLoading,
      isError: sharedMetricOrganizationError,
      data: sharedMetricOrganization,
    },
  ] = useLazyGetOrganizationDetailsQuery();

  const [captionLink, setCaptionLink] = useState(null);

  const fetchSharedMetricOrganization = async () => {
    if (post.sharedMetricOrg) {
      try {
        await getOrganizationDetails(post.sharedMetricOrg);
      } catch (e) {
        Sentry.captureException(e);
        toast.error("Something unexpected happened");
      }
    }
  };

  useEffect(() => {
    if (post?.sharedMetricOrg && post?.sharedMetricId) {
      fetchSharedMetricOrganization();
    }
  }, [post?.sharedMetricOrg, post?.sharedMetricId]);

  useEffect(() => {
    if (sharedPostError || sharedMetricOrganizationError) {
      // toast.error(
      //   "Something went wrong while trying to fetch the shared post. Try refreshing the page"
      // );
    }
  }, [sharedMetricOrganizationError]);

  const currentUserHasInteracted = useMemo(
    () =>
      post?.interactions?.some(
        (interaction) =>
          interaction.ownerId === currentUserProfileData?.id &&
          interaction.type !== "COMMENT"
      )
        ? "#EE5A29"
        : "black",
    [post?.interactions]
  );

  useEffect(() => {
    if (post?.caption && post?.caption !== "") {
      const links = find(post?.caption);
      const urls = links.filter((link) => link.type === "url");
      const captionLinks = urls.map(({ value }) => value);
      if (captionLinks.length > 0) {
        setCaptionLink(captionLinks[0]);
      }
    }
  }, [post?.caption]);

  useEffect(() => {
    if (post?.sharedPostId) {
      getPostById(post.sharedPostId);
    }
  }, [post?.sharedPostId]);

  const sharedMetric = useMemo(() => {
    if (sharedMetricOrganization && sharedMetricOrganization.goals) {
      const goals = sharedMetricOrganization.goals;
      if (goals && goals.length > 0) {
        for (let i = 0; i < goals.length; i++) {
          const goal = goals[i];
          const metrics = goal.metrics;
          if (metrics.length > 0) {
            const metric = metrics.find(
              (metric) => metric.id === post.sharedMetricId
            );
            if (metric) {
              return metric;
            }
          }
        }
      }
      return undefined;
    }

    return undefined;
  }, [sharedMetricOrganization, post.sharedMetricId, post.sharedMetricOrg]);

  const [deletePost, { isLoading: deletePostLoading }] =
    useDeletePostMutation();

  const handleDeletePost = async () => {
    try {
      await deletePost(post.id).unwrap();
      toast.success("You deleted a post");
    } catch (e) {
      Sentry.captureException(e);
      toast.error(MESSAGE.SYSTEM_ERROR);
    }
  };

  const comments = useMemo(() => {
    const currentPost = post;
    return [...currentPost?.interactions].filter(
      (interaction) =>
        interaction.type === "COMMENT" && interaction.data.message
    );
  }, [post?.interactions]);

  const commentCount = useMemo(() => {
    return (
      post?.interactionCount?.find(
        (interaction) => interaction.type === "COMMENT"
      )?.count || 0
    );
  }, [post?.interactionCount]);

  const isAdminOfOrganizationWithId = (orgId: string) => {
    const myOrganizations = currentUserProfileData?.organizations || [];
    for (let i = 0; i < myOrganizations.length; i++) {
      return (
        myOrganizations[i].id === orgId && myOrganizations[i].myRole === "ADMIN"
      );
    }

    return false;
  };

  const optionsEnabled =
    currentUserProfileData?.id === post.ownerId ||
    isAdminOfOrganizationWithId(post.ownerId);

  return {
    captionLink,
    deletePostLoading,
    comments,
    commentCount,
    handleDeletePost,
    sharedPost,
    sharedPostLoading,
    currentUserHasInteracted,
    isCurrentUserOwnerOfPost: currentUserProfileData?.id === post.ownerId,
    sharedMetricOrganizationLoading,
    sharedMetric,
    optionsEnabled,
  };
};
