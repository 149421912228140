import React, { useState, useMemo } from "react";
import { Button } from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import { GoalCard } from "./GoalCard";
import {
  AddGoalFormWrapper,
  PageTitle,
  Subtitle,
  NoGoalCard,
  BackButtonWrapper,
  Goals,
  AddGoalButton,
} from "./styles";
import { PlusRoundCircle, GrayTarget } from "../../Assets/SvgIcons";
import { Back } from "../../Components/Back/Back";
import CreateOrEditGoalModal from "../../Components/Modal/CreateOrEditGoalModal";
import { useSpark } from "../../hooks/useSpark";
import FullPageNexusLogoAnimation from "../../Reusable/FullPageNexusLogoAnimation/FullPageNexusLogoAnimation";
import { useOrganization } from "../../hooks/useOrganization";
import { customConsole } from "../../utils/helper";

const GoalsManagement = () => {
  const { organizationId: organizationIdParam, sparkName } = useParams();
  const [modalVisible, setModalVisible] = useState(false);
  const [goalToEdit, setGoalToEdit] = useState<
    | {
        id: string;
        name: string;
        description: string;
      }
    | undefined
  >(undefined);

  const navigate = useNavigate();

  const { currentOrganization, currentOrganizationLoading, currentUserRole } =
    useOrganization();

  const { goalsBySparkName: filteredGoals } = useOrganization();
  const { spark, sparksLoading } = useSpark();

  customConsole("log", "Spark", spark);

  const handleCreateGoal = () => {
    setGoalToEdit(undefined);
    setModalVisible(true);
  };

  if (!organizationIdParam || !sparkName) {
    return <p>"You removed a resource in the URL"</p>;
  }

  if (sparksLoading || currentOrganizationLoading)
    return <FullPageNexusLogoAnimation />;

  return (
    <AddGoalFormWrapper>
      <BackButtonWrapper>
        <Back />
      </BackButtonWrapper>
      <PageTitle>{sparkName}</PageTitle>
      {currentUserRole === "ADMIN" && (
        <Subtitle>
          You can add up to 3 goals per spark and 3 metrics per goal.
        </Subtitle>
      )}
      {filteredGoals.length === 0 && currentUserRole !== "ADMIN" && (
        <p>No goals were added yet.</p>
      )}
      {filteredGoals.length === 0 && currentUserRole === "ADMIN" && (
        <NoGoalCard>
          <GrayTarget />
          <p>You don't have any goals</p>
          <Button
            isDisabled={currentUserRole !== "ADMIN"}
            onClick={() => setModalVisible(true)}
            colorScheme="orange"
          >
            Create a new goal
          </Button>
        </NoGoalCard>
      )}

      {filteredGoals.length > 0 && currentOrganization?.id && (
        <Goals>
          {filteredGoals.map((goal) => (
            <GoalCard
              key={goal.id}
              goal={goal}
              organizationId={currentOrganization?.id}
              isAdmin={currentUserRole === "ADMIN"}
              sparkId={spark?.id}
              onEdit={() => {
                setGoalToEdit({
                  name: goal.name,
                  description: goal.description,
                  id: goal.id,
                });
                setModalVisible(true);
              }}
            />
          ))}
          {currentUserRole === "ADMIN" && filteredGoals.length < 3 && (
            <div className="button-row">
              <AddGoalButton onClick={handleCreateGoal}>
                <PlusRoundCircle color="black" />
                <span className="goal-button-text">Add new goal</span>
              </AddGoalButton>
            </div>
          )}
        </Goals>
      )}

      {modalVisible && currentOrganization?.id && (
        <CreateOrEditGoalModal
          goalId={goalToEdit?.id}
          goalName={goalToEdit?.name}
          goalDescription={goalToEdit?.description}
          onClose={() => setModalVisible(false)}
          organizationId={currentOrganization?.id}
          spark={spark}
          isOpen={modalVisible}
        />
      )}
    </AddGoalFormWrapper>
  );
};

export default GoalsManagement;
