import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useGetProfileQuery } from "../../../redux/reducers/apiSlice";
import { MessageIcon, MyOrganizationIcon } from "../../Assets/SvgIcons";
import SidebarMenu from "./SidebarMenu";
import { SidebarWrapper, ProfileInformations } from "./Styles";
import { ProfileAvatar } from "../../Components/ProfileAvatar/ProfileAvatar";
import { useDispatch } from "react-redux";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { customConsole } from "../../utils/helper";
import { api } from "../../../redux/reducers/apiSlice";
import { Policy } from "../../Components/Policy/Policy";
import { Modal } from "../../Components/Modal/Modal";
import IgniteSurveyForm from "../../Components/Modal/IgniteSurveyForm";
import * as Sentry from "@sentry/react";

const Sidebar = ({ sidebarAnimation, amountUnreads }) => {
  const { data: currentProfileData } = useGetProfileQuery();
  const [isSurveyModalOpen, setIsSurveyModalOpen] = useState(false);
  const [currentAnimation, setCurrentAnimation] = useState<
    "default" | "moveRight" | "moveLeft"
  >("moveLeft");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setCurrentAnimation(sidebarAnimation);

    let surveyTimer = setTimeout(() => {
      if (localStorage.getItem("hasShownSurvey") === "false") {
        setIsSurveyModalOpen(true);
        localStorage.setItem("hasShownSurvey", "true");
      }
    }, 30000);

    return () => {
      clearTimeout(surveyTimer);
    };
  }, [sidebarAnimation]);

  useEffect(() => {
    setCurrentAnimation("moveLeft");
  }, [location.pathname]);

  const signOut = async () => {
    try {
      navigate("/");
      dispatch(api.util.resetApiState()); // resetting api after calling Auth.signout will break
      await Auth.signOut();
    } catch (error) {
      Sentry.captureException(error);
      customConsole("log", "error signing out: ", error);
    }
  };

  return (
    <SidebarWrapper animation={currentAnimation} className={`main-sidebar`}>
      <Link to={`/users/${currentProfileData?.id}`}>
        <ProfileInformations>
          <div>
            <ProfileAvatar
              src={currentProfileData?.image?.url}
              name={`${currentProfileData?.name.firstName} ${currentProfileData?.name.lastName}`}
            />
          </div>
          <p>
            {currentProfileData?.name?.firstName}{" "}
            {currentProfileData?.name?.lastName}
          </p>
        </ProfileInformations>
      </Link>
      <SidebarMenu
        to="/"
        option="Home"
        icon={
          <>
            <img
              src="/images/logo-5.png"
              alt="Measure"
              className="img-normal"
            />
            <img
              src="/images/logo-5-active.png"
              alt="Measure"
              className="img-active"
            />
          </>
        }
      />

      <SidebarMenu
        to="/my-organizations"
        option="My Organizations"
        icon={<MyOrganizationIcon />}
      />

      <SidebarMenu
        textBadge={amountUnreads}
        to="/messages"
        option="Messages"
        icon={<MessageIcon />}
      />
      <div className="signout">
        <button onClick={() => signOut()}>Sign out</button>
      </div>
      <Policy type="sidebar" />
      {isSurveyModalOpen && (
        <Modal
          // @ts-ignore
          size="2xl"
          isOpen={isSurveyModalOpen}
          onClose={() => setIsSurveyModalOpen(false)}
        >
          <IgniteSurveyForm />
        </Modal>
      )}
    </SidebarWrapper>
  );
};

export default Sidebar;
