import { SuggestionDataItem } from "../../../types";
import { ProfileAvatar } from "../ProfileAvatar/ProfileAvatar";

type Props = {
  suggestionDataItem: SuggestionDataItem;
};

const CustomMention = ({ suggestionDataItem }: Props) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
    }}
  >
    <div style={{ marginRight: "12px" }}>
      <ProfileAvatar src={suggestionDataItem.avatar} />
    </div>
    <div>
      <div style={{ fontWeight: "bold" }}>{suggestionDataItem.display}</div>
      <div>{suggestionDataItem.title}</div>
    </div>
  </div>
);

export default CustomMention;
