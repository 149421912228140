import React from "react";
import { Spinner } from "@chakra-ui/react";
import styled from "styled-components";

const LoadingWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SpinnerLoading = () => {
  return (
    <LoadingWrapper>
      <Spinner color="red" />
    </LoadingWrapper>
  );
};
