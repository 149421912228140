import React, { useRef, useEffect, ReactNode, FC } from 'react';

interface Props {
  children: ReactNode;
  onClickOutside: () => void;
}

const ClickOutsideHandler: FC<Props> = ({ children, onClickOutside }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        onClickOutside();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClickOutside]);

  return <div ref={wrapperRef}>{children}</div>;
};

export default ClickOutsideHandler;
