import React, { useEffect, useCallback, useState } from "react";
import {
  Modal,
  ModalCloseButton,
  ModalBody,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  ModalOverlay,
} from "@chakra-ui/react";
import { UserOutline } from "../../../Assets/SvgIcons";
import { FollowingModalContent } from "./Styles";
import FollowingDataRow from "./FollowingDataRow";
import { Following, Follower } from "../../../../redux/reducers/types";
import {
  useGetUsersFollowedQuery,
  useGetFollowersQuery,
} from "../../../../redux/reducers/apiSlice";

const FollowingModal = ({
  isOpen,
  onClose,
  tabIndex,
  setTabIndex,
  ...rest
}) => {
  const { data: followingList } = useGetUsersFollowedQuery();
  const { data: followers } = useGetFollowersQuery();

  const [listFollower, setFollower] = useState<Follower[]>([]);
  const [listFollowing, setFollowing] = useState<Following[]>([]);

  useEffect(() => {
    if (followers) {
      setFollower(followers);
    }
  }, [followers]);

  useEffect(() => {
    if (followingList) {
      setFollowing(followingList);
    }
  }, [followingList]);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  const renderDataRow = (type: "follower" | "following") => {
    const list: Follower[] | Following[] =
      type === "follower" ? listFollower : listFollowing;

    return list && list.length > 0 ? (
      <div className="box-users">
        <ul className="list">
          {list.map((item, index) => (
            <FollowingDataRow
              accountType={item.accountType}
              key={index}
              index={index}
              userId={item?.following || item.id}
            />
          ))}
        </ul>
      </div>
    ) : (
      <div className="box-empty">
        <UserOutline />
        <div className="txt">
          Not {type === "follower" ? "being followed" : "following"} right now.
        </div>
      </div>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      isCentered
      scrollBehavior="inside"
      onClose={onClose}
      {...rest}
    >
      <ModalOverlay />
      <FollowingModalContent className="common-modal-content">
        <ModalCloseButton />
        <ModalBody>
          <div className="box-tabs">
            <Tabs
              index={tabIndex}
              align="center"
              className="common-tabs"
              onChange={handleTabsChange}
            >
              <TabList>
                <Tab>Following</Tab>
                <Tab>Followers</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>{renderDataRow("following")}</TabPanel>
                <TabPanel>{renderDataRow("follower")}</TabPanel>
              </TabPanels>
            </Tabs>
          </div>
        </ModalBody>
      </FollowingModalContent>
    </Modal>
  );
};

export default FollowingModal;
