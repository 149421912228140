import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import { Modal } from "../Modal/Modal";
import TermsAndConditionsModal from "../Modal/TermsAndConditionsModal";
import AcceptableUseModal from "../Modal/AcceptableUseModal";
import PrivacyPolicyModal from "../Modal/PrivacyPolicyModal";
import {
  PolicyFooterStyles,
  DotContainer,
  LinkContainer,
  LegalLink,
} from "./styles";
import { POLICY_TYPES } from "../../utils/constants";
import ReportAnIssueModal from "../Modal/ReportAnIssueModal";
import IgniteSurveyForm from "../Modal/IgniteSurveyForm";

export const Policy: React.FC<{ type: "footer" | "sidebar" }> = ({ type }) => {
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showAcceptableUseModal, setShowAcceptableUseModal] = useState(false);
  const [showTermsAndConditionsModal, setShowTermsAndConditions] =
    useState(false);
  const [showReportAnIssueModal, setShowReportAnIssueModal] = useState(false);
  const [isSurveyModalOpen, setIsSurveyModalOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const queryStrings = queryString.parse(location.search);
    const hasPrivacy = POLICY_TYPES.PRIVACY in queryStrings;
    const hasTermsAndConditions =
      POLICY_TYPES.TERMS_AND_CONDITIONS in queryStrings;
    const hasAcceptableUsePolicy = POLICY_TYPES.ACCEPTABLE_USE in queryStrings;

    if (hasPrivacy) {
      setShowPrivacyModal(true);
    }

    if (hasAcceptableUsePolicy) {
      setShowAcceptableUseModal(true);
    }

    if (hasTermsAndConditions) {
      setShowTermsAndConditions(true);
    }
  }, [location.search]);

  const openModal = (type: POLICY_TYPES) => {
    navigate(`${location.pathname}?${type}`);
  };

  const closeModal = (type: POLICY_TYPES) => {
    switch (type) {
      case POLICY_TYPES.ACCEPTABLE_USE:
        setShowAcceptableUseModal(false);
        break;
      case POLICY_TYPES.PRIVACY:
        setShowPrivacyModal(false);
        break;
      case POLICY_TYPES.TERMS_AND_CONDITIONS:
        setShowTermsAndConditions(false);
        break;
    }
    navigate("/");
  };

  return (
    <>
      {(type === "sidebar" || !type) && (
        <LinkContainer>
          <div>
            <LegalLink onClick={() => openModal(POLICY_TYPES.PRIVACY)}>
              Privacy Policy
            </LegalLink>
          </div>
          <div style={{ marginTop: "8px" }}>
            <LegalLink onClick={() => openModal(POLICY_TYPES.ACCEPTABLE_USE)}>
              Acceptable Use Policy
            </LegalLink>
          </div>
          <div style={{ marginTop: "8px" }}>
            <LegalLink
              onClick={() => openModal(POLICY_TYPES.TERMS_AND_CONDITIONS)}
            >
              Terms and Conditions
            </LegalLink>
          </div>
          <div style={{ marginTop: "8px" }}>
            <LegalLink onClick={() => setShowReportAnIssueModal(true)}>
              Report an issue
            </LegalLink>
          </div>
          <div style={{ marginTop: "8px" }}>
            <LegalLink onClick={() => setIsSurveyModalOpen(true)}>
              <a>Take the survey</a>
            </LegalLink>
          </div>
        </LinkContainer>
      )}
      {type === "footer" && (
        <PolicyFooterStyles>
          <div className="link-wrapper">
            <a onClick={() => openModal(POLICY_TYPES.ACCEPTABLE_USE)}>
              Acceptable Use Policy
            </a>
            <DotContainer>
              <>&#183;</>
            </DotContainer>
            <a onClick={() => openModal(POLICY_TYPES.PRIVACY)}>
              Privacy Policy
            </a>
            <DotContainer>
              <>&#183;</>
            </DotContainer>
            <a onClick={() => openModal(POLICY_TYPES.TERMS_AND_CONDITIONS)}>
              Terms and conditions
            </a>
            <DotContainer>
              <>&#183;</>
            </DotContainer>
            <a onClick={() => setShowReportAnIssueModal(true)}>
              Report an issue
            </a>
          </div>
        </PolicyFooterStyles>
      )}
      {showPrivacyModal && (
        <PrivacyPolicyModal
          isOpen={showPrivacyModal}
          onClose={() => closeModal(POLICY_TYPES.PRIVACY)}
        />
      )}
      {showAcceptableUseModal && (
        <AcceptableUseModal
          isOpen={showAcceptableUseModal}
          onClose={() => closeModal(POLICY_TYPES.ACCEPTABLE_USE)}
        />
      )}
      {showTermsAndConditionsModal && (
        <TermsAndConditionsModal
          isOpen={showTermsAndConditionsModal}
          onClose={() => closeModal(POLICY_TYPES.TERMS_AND_CONDITIONS)}
        />
      )}
      {showReportAnIssueModal && (
        <ReportAnIssueModal
          isOpen={showReportAnIssueModal}
          onClose={() => setShowReportAnIssueModal(false)}
        />
      )}
      {isSurveyModalOpen && (
        <Modal
          // @ts-ignore
          size="2xl"
          isOpen={isSurveyModalOpen}
          onClose={() => setIsSurveyModalOpen(false)}
        >
          <IgniteSurveyForm />
        </Modal>
      )}
    </>
  );
};
