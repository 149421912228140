import { ChangeEvent, useRef, useState } from "react";
import { CloseButton, Spinner } from "@chakra-ui/react";
import { MentionsInput, Mention } from "react-mentions";
import toast from "react-hot-toast";
import {
  useCreatePostMutation,
  useGetProfileQuery,
  useTagUserMutation,
} from "../../../redux/reducers/apiSlice";
import { AddImage } from "../../Assets/SvgIcons";
import {
  AddNewPostCardWrapper,
  PostButton,
  InputFirstRow,
  InputSecondRow,
  AddImageButton,
  ButtonLabel,
  mentionStyle,
} from "./styles";

import { getUsersFromTags } from "../../utils/helper";

import { ProfileAvatar } from "../ProfileAvatar/ProfileAvatar";
import { useOrganization } from "../../hooks/useOrganization";
import { useMentions } from "../../hooks/useMentions";
import CustomMention from "../CustomMentions/CustomMentions";
import { SuggestionDataItem } from "../../../types";
import * as Sentry from "@sentry/react";

export const AddNewPostCard = () => {
  const [postType, setPostType] = useState<"TEXT" | "MEDIA">("TEXT");
  const [previewImage, setPreviewImage] = useState<string>("");
  const [imgFile, setImgFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const { mentionsData } = useMentions();

  const [tagUser, { isLoading: loadingTagUser }] = useTagUserMutation();

  const { currentOrganization, currentUserRole } = useOrganization();

  const {
    data: currentUserProfileData,
    isLoading: isCurrentUserProfileDataLoading,
  } = useGetProfileQuery();

  const [createPost, { isLoading: createPostLoading }] =
    useCreatePostMutation();

  const [caption, setCaption] = useState("");

  const handleFile = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e?.target?.files;

    if (fileList) {
      const file = fileList[0];
      if (file) {
        setImgFile(file);
        setPostType("MEDIA");
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onloadend = () => {
          const previewableFile = fileReader.result;
          if (previewableFile) {
            setPreviewImage(previewableFile.toString());
          }
        };
      }
    }
  };

  const onPostSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("type", postType);
      if (imgFile) {
        formData.append("photo", imgFile);
      }
      if (caption !== "") {
        formData.append("caption", caption);
      }
      if (currentOrganization && currentUserRole === "ADMIN") {
        formData.append("ownerId", currentOrganization.id);
        formData.append("ownerType", "ORGANIZATION");
      }
      const post = await createPost(formData).unwrap();

      // tag people
      await tagUser({
        targetUserIds: getUsersFromTags(caption).map((user) => user.id),
        postId: post.id,
      });

      setPostType("TEXT");
      setPreviewImage("");
      setCaption("");
      setImgFile(null);
      setCaption("");
      window.scrollTo({ top: 0, behavior: "smooth" });
    } catch (e) {
      Sentry.captureException(e);
      console.error("PostSubmitError", e);
      toast.error(
        "Something went wrong while creating your post. Try again later"
      );
    }
  };

  const handleCloseFilePreview = () => {
    setPostType("TEXT");
    setImgFile(null);
    setPreviewImage("");
    if (fileInputRef?.current && fileInputRef?.current.value) {
      fileInputRef.current.value = "";
    }
  };

  const handleBrowse = () => {
    fileInputRef.current?.click();
  };

  return (
    <AddNewPostCardWrapper>
      <InputFirstRow>
        {isCurrentUserProfileDataLoading && <Spinner color="red" />}
        {!isCurrentUserProfileDataLoading && (
          <div style={{ marginRight: "12px", paddingTop: 18 }}>
            <ProfileAvatar
              src={
                currentOrganization && currentUserRole === "ADMIN"
                  ? currentOrganization?.image?.url
                  : currentUserProfileData?.image?.url
              }
              name={
                currentOrganization && currentUserRole === "ADMIN"
                  ? currentOrganization.name
                  : `${currentUserProfileData?.name.firstName} ${currentUserProfileData?.name.lastName}`
              }
              border={`2px solid #eee`}
            />
          </div>
        )}
        <MentionsInput
          value={caption}
          onChange={(event) => setCaption(event.target.value)}
          maxLength={200}
          placeholder="What's happening?"
          singleLine
          style={mentionStyle}
        >
          <Mention
            trigger="@"
            data={mentionsData}
            className="mention"
            renderSuggestion={(suggestionDataItem: SuggestionDataItem) => (
              <CustomMention suggestionDataItem={suggestionDataItem} />
            )}
          />
        </MentionsInput>
      </InputFirstRow>
      {/* {mentionInputValue} */}
      {previewImage !== "" && (
        <div className="img-container">
          <div className="close-img-preview" onClick={handleCloseFilePreview}>
            <CloseButton />
          </div>
          <img src={previewImage} />
        </div>
      )}
      <InputSecondRow>
        <div>
          <AddImageButton onClick={handleBrowse}>
            <AddImage />
            <ButtonLabel>Add image</ButtonLabel>
          </AddImageButton>
          <input
            ref={fileInputRef}
            accept=".jpg, .jpeg, .png"
            onChange={handleFile}
            name="add-image"
            type="file"
          />
        </div>
        <PostButton disabled={createPostLoading} onClick={onPostSubmit}>
          {(createPostLoading || loadingTagUser) && <Spinner size={"xs"} />}
          Post
        </PostButton>
      </InputSecondRow>
    </AddNewPostCardWrapper>
  );
};
