import styled from "styled-components";
import { devices } from "../../../Theme.css";

export const MemberAmount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const MemberAmountLabel = styled.span`
  display: block;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 8px;
`;

export const MembersHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;

  @media ${devices.max_sm} {
    display: none;
  }
`;

export const AddMemberGroup = styled.div``;

export const MembersHeaderGroup = styled.div``;

export const AddMemberText = styled.div<{ color: string }>`
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140.5%; /* 19.67px */
  color: ${(props) => props.color}; /* 19.67px */
  margin-left: 5px;
`;

export const AddMemberButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MembersTable = styled.table`
  width: 100%;

  th {
    color: #666;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 140.5%;
    text-align: left;
    padding-left: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  td {
    color: #000;
    font-family: DM Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;

    ul {
      list-style-type: none;

      li:first-child {
        font-weight: bold;
      }
    }
  }

  @media ${devices.max_sm} {
    tr {
      td:first-child {
        padding-left: 30px;
      }
      td:last-child {
        padding-right: 30px;
      }
    }
  }
`;

type MembersTableCellProps = {
  containsImage?: boolean;
};

export const MembersTableCell = styled.td<MembersTableCellProps>`
  ${(props) =>
    props.containsImage &&
    `display: flex; justify-content: center; align-items: center; min-height: 72px;`}
  ${(props) => !props.containsImage && `padding-left: 24px;`}

  svg {
    margin-left: 5px;
    margin-top: 5px;
  }

  @media ${devices.max_sm} {
    min-width: 100px;
  }
`;

export const RoleButton = styled.button<{ disabled?: boolean }>`
  ${(props) =>
    props.disabled &&
    `  
    background: #eee;
    color: #aaa;
  `}
  width: 100%;
  height: 100%;
  padding: 12px;
`;

export const MembersTableRow = styled.tr`
  padding-left: 1000px;
  :nth-child(even) {
    background: #f9f9f9;
  }
`;

export const MemberPictureContainer = styled.div`
  width: 48px;
  height: 48px;
  background-color: #d9d9d9;
  border-radius: 50%;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    object-fit: contain;
  }
`;

export const MembersCard = styled.div`
  @media ${devices.max_sm} {
    border-radius: 0;
    box-shadow: none;
    overflow-x: scroll;
  }

  width: 100%;
  border-radius: 40px;
  background: #fff;
  box-shadow: 1px 1px 6px 0px rgba(64, 26, 15, 0.25);
  overflow: hidden;

  ul {
    background-color: white;
    position: absolute;
    /* top: 40px;
    right: 120px; */
    list-style-type: none;
    box-shadow: 1px 1px 6px 0px rgba(64, 26, 15, 0.25);
    border-radius: 6px;
    li {
      border-bottom: 1px solid #d1d1d1;
    }
  }
`;

export const RemoveButton = styled.button`
  border-radius: 100px;
  border: 1px solid #ef6b2d;
  background: #fff;
  color: #ef6b2d;
  padding: 8px 16px;
  // fonts
  color: #ef6b2d;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140.5%;

  ${(props) =>
    props.disabled &&
    `
     border: 1px #aaa solid;
     color: #aaa;
    `}

  &:hover {
    cursor: pointer;
  }
`;
