import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLazyGetPostByIdQuery } from "../../../redux/reducers/apiSlice";
import { Post } from "../../../redux/reducers/types";
import { Back } from "../../Components/Back/Back";
import ShareModal from "../../Components/Modal/ShareModal";
import { PostCard } from "../../Components/PostCard/PostCard";
import SharedPost from "../../Components/PostCard/SharedPost";
import { Loading } from "../../Reusable";

const PostDetails = () => {
  const [showShareModal, setShowShareModal] = useState(false);
  const [postToShare, setPostToShare] = useState<Post | undefined>(undefined);
  const [getPostById, { isLoading, data: post }] = useLazyGetPostByIdQuery();

  const { postId } = useParams();

  useEffect(() => {
    if (postId) {
      getPostById(postId);
    }
  }, []);

  if (isLoading || !post) {
    return <Loading />;
  }

  return (
    <div style={{ paddingTop: "24px" }}>
      <Back />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "24px",
          paddingBottom: "24px",
        }}
      >
        {post.sharedPostId || post.sharedMetricId ? (
          <SharedPost
            post={post}
            onShare={() => {
              setPostToShare(post);
              setShowShareModal(true);
            }}
            commentsFullHeight={true}
          />
        ) : (
          <PostCard
            onShare={() => {
              if (post) {
                setPostToShare(post);
                setShowShareModal(true);
              }
            }}
            commentsFullHeight={true}
            post={post}
          />
        )}

        {showShareModal && postToShare && (
          <ShareModal
            onClose={() => {
              setShowShareModal(false);
              setPostToShare(undefined);
            }}
            isOpen={showShareModal && !!postToShare}
            /** @ts-ignore */
            type="POST"
            entity={postToShare}
          />
        )}
      </div>
    </div>
  );
};

export default PostDetails;
