import React, { useState } from "react";
import {
  TabWrapper,
  Layout,
  PostsDivider,
  PostSpacer,
  PostMobileDivider,
  PostCardAndDividerWrapper,
  ButtonContainer,
} from "./styles";
import { Button, Spinner } from "@chakra-ui/react";
import { SuggestedForYou } from "../../SuggestedForYou/SuggestedForYou";
import { PostCard } from "../../PostCard/PostCard";
import { AddNewPostCard } from "../../AddNewPostCard/AddNewPostCard";
import { SpinnerLoading } from "../../SpinnerLoading/SpinnerLoading";
import ShareModal from "../../Modal/ShareModal";
import { Post } from "../../../../redux/reducers/types";
import SharedPost from "../../PostCard/SharedPost";
import { ArrowUpIcon } from "@chakra-ui/icons";
import useScreenType from "react-screentype-hook";

export const Posts: React.FC<{
  withSuggestions?: boolean;
  posts?: Post[];
  postsLoading: boolean;
  showPostCreationForm?: boolean;
  hasIntroCard?: boolean;
  onLoadMore?: () => void;
  disableLoadMore?: boolean;
  resetPage?: () => void;
  hideLoadMore?: boolean;
}> = ({
  withSuggestions = true,
  posts,
  postsLoading,
  showPostCreationForm,
  hasIntroCard,
  onLoadMore,
  disableLoadMore,
  hideLoadMore = false,
}) => {
  const [showShareModal, setShowShareModal] = useState(false);
  const [postToShare, setPostToShare] = useState<Post | undefined>(undefined);
  const { isMobile } = useScreenType();

  if (postsLoading) return <SpinnerLoading />;

  return (
    <TabWrapper hasIntroCard={hasIntroCard}>
      <Layout withSuggestions={withSuggestions}>
        <div className="postcard-and-posts-container">
          {showPostCreationForm && (
            <PostCardAndDividerWrapper fixed={false}>
              <AddNewPostCard />
              <PostsDivider />
            </PostCardAndDividerWrapper>
          )}
          <div className="posts-container">
            {posts?.map((post) => (
              <React.Fragment key={post.id}>
                <PostSpacer>
                  {post.sharedPostId || post.sharedMetricId ? (
                    <SharedPost
                      post={post}
                      onShare={() => {
                        setPostToShare(post);
                        setShowShareModal(true);
                      }}
                    />
                  ) : (
                    <PostCard
                      onShare={(post) => {
                        setPostToShare(post);
                        setShowShareModal(true);
                      }}
                      post={post}
                    />
                  )}
                </PostSpacer>
                <PostMobileDivider />
              </React.Fragment>
            ))}
            {!hideLoadMore && (
              <ButtonContainer>
                <Button
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                  variant="outline"
                >
                  <ArrowUpIcon />
                </Button>
                <Button
                  onClick={onLoadMore}
                  colorScheme="primary"
                  loadingText="Saving..."
                  isLoading={disableLoadMore}
                  isDisabled={postsLoading}
                  marginLeft="10px"
                  className="gray-for-disabled"
                >
                  Load more
                  {postsLoading && <Spinner />}
                </Button>
              </ButtonContainer>
            )}
          </div>
        </div>
        {withSuggestions && !isMobile && (
          <div className="suggested-for-you-container">
            <SuggestedForYou />
          </div>
        )}
      </Layout>
      {showShareModal && postToShare && (
        <ShareModal
          onClose={() => {
            setShowShareModal(false);
            setPostToShare(undefined);
          }}
          isOpen={showShareModal && !!postToShare}
          /** @ts-ignore */
          type="POST"
          entity={postToShare}
        />
      )}
    </TabWrapper>
  );
};
