import styled from "styled-components";
import { devices } from "../../Theme.css";

export const OrganizationsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(320px, 1fr)
  ); /* Define your grid columns */
  grid-template-rows: repeat(auto-fill, minmax(86px, 1fr));
  column-gap: 20px;
  row-gap: 44px;
  @media ${devices.max_sm} {
    margin: 0 12px;
  }
`;

export const PageTitle = styled.h1`
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 20px;
  @media ${devices.max_sm} {
    margin-left: 12px;
  }
`;

export const ButtonWrapper = styled.div``;

export const OrganizationDetailsPageWrapper = styled.div`
  margin-top: 4rem;
  margin-left: 2rem;
  @media ${devices.max_sm} {
    margin-left: 0;
  }
`;

export const CardInnerWrapper = styled.div`
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const CardText = styled.p`
  width: 160px;
  font-weight: 500;
  margin-bottom: 16px;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  @media ${devices.max_sm} {
    position: absolute;
    top: calc(-4rem - ${(props) => props.theme.height.header});
    left: 0;
    z-index: ${(props) => props.theme.height.header - 3};
    flex-direction: column;
    align-items: flex-end;
    padding-right: 10px;
  }
`;

export const BackButtonWrapper = styled.div`
  display: block;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 24px;
  width: 100%;
  margin-bottom: 24px;
  @media ${devices.max_sm} {
    display: none;
  }
`;

export const MyOrganizationCard = styled.div`
  margin: 0 auto 0 auto;
  background: #fff;
  box-shadow: 1px 1px 6px rgba(64, 26, 15, 0.25);
  border-radius: 40px;
  padding: 24px;
  min-width: 555px;
  max-width: calc(100% - 150px);
  .box-textarea {
    display: flex;
    flex-wrap: nowrap;
    gap: 14px;
    .box-avatar {
      display: flex;
      align-items: center;
      // flex: 1 1 14px;
    }
    .box-caption {
      width: 100%;
      border-radius: 30px;
      color: #000;
      background: #eee;
      border-color: #eee;
      box-shadow: none;
      .chakra-textarea {
        flex: 1 1 calc(100% - 14px);
        resize: none;
        &:hover,
        &:focus {
          resize: vertical;
        }
      }
      .text-post-caption {
        overflow: hidden;
        max-height: 100px;
        margin-left: 20px;
        background: none;
        width: calc(100% - 20px);
        resize: none;
        padding-right: 0;
        padding-left: 0;
        margin-bottom: 5px;
        margin-top: 5px;
        border-radius: 0;
      }
    }
  }

  @media ${devices.max_sm} {
    min-width: max-content;
    max-width: 90vw;
    width: 100vw;
    margin: 0 auto;
  }
`;

export const MyOrganizationsPageWrapper = styled.div`
  margin-top: 4rem;
  margin-left: 2rem;
  .create-new-org-btn {
    position: absolute;
    top: -32px;
    right: 32px;
  }
  @media ${devices.max_sm} {
    margin-left: 0;
    .create-new-org-btn {
      top: 170%;
      right: 32px;
    }
  }
`;

export const InviteInput = styled.input`
  width: 80%;
  border-radius: 100px;
  border: 1px solid #fff;
  padding: 8px 16px;
  box-shadow: 1px 1px 6px 0px rgba(64, 26, 15, 0.75);
`;

export const Pills = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px 12px;
`;

export const Pill = styled.div`
  /* typograpy */
  width: fit-content;
  height: fit-content;
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: #eee;
  padding: 8px 16px;
  border-radius: 100px;
  margin-bottom: 12px;
  margin-right: 12px;
  button {
    margin-right: 2px;
  }
`;

export const InviteModalBody = styled.div`
  width: 100%;
`;

export const EmailError = styled.p`
  color: #f00;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
  font-weight: bold;
`;

export const InviteInputWrapper = styled.div`
  margin-bottom: 24px;
  margin-left: 16px;
`;
