import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Auth } from "aws-amplify";
import {
  User,
  Organization,
  SuggestedUsersAndOrganizations,
  RemoveMemberParams,
  Spark,
  Post,
  WorldCityData,
  InteractionType,
  Following,
  Follower,
  Metric,
  FollowUserResponse,
  EditGoalResponsePayload,
  NotificationResponse,
} from "./types";
import camelcaseKeys from "camelcase-keys";
import { customConsole } from "../../src/utils/helper";
import toast from "react-hot-toast";
import axios from "axios";

import Nes from "@hapi/nes/lib/client";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    // @ts-ignore
    baseUrl: `${import.meta.env.VITE_COMMUNITY_ENDPOINT}`,
    prepareHeaders: async (headers) => {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken().getJwtToken();

      if (idToken) {
        headers.set("Authorization", `Bearer ${idToken}`);
      }
      return headers;
    },
  }),
  tagTypes: [
    "User",
    "Organization",
    "Spark",
    "Post",
    "WorldCity",
    "MetricDescription",
    "Notification",
  ],
  endpoints: (builder) => ({
    getProfile: builder.query<User, void>({
      query: () => "/user",
      providesTags: (result, error, arg) =>
        result
          ? [
              { type: "User" as const, id: result.id },
              ...(result.organizations?.map(({ id }) => ({
                type: "Organization" as const,
                id,
              })) || ["Organization"]),
            ]
          : ["User", "Organization"],
    }),
    deactivateUser: builder.mutation<User, void>({
      query: () => ({
        url: "/user/deactivate",
        method: "PUT",
      }),
      invalidatesTags: ["User"],
    }),
    followUser: builder.mutation<
      FollowUserResponse,
      {
        userId: string;
        type: "USER" | "ORG";
      }
    >({
      query: (params) => ({
        url: `/user/following/${params.userId}?type=${params.type}`,
        method: "PUT",
      }),
      invalidatesTags: (result, error, arg) =>
        result?.id
          ? [
              {
                type:
                  result.followingType === "USER"
                    ? ("User" as const)
                    : ("Organization" as const),
                id: result.following,
              },
              "User",
            ]
          : ["User"],
    }),
    getUsersFollowed: builder.query<Following[], void>({
      query: () => ({
        url: "/user/following",
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ following }) => ({
                type: "User" as const,
                id: following,
              })),
              "User",
            ]
          : ["User"],
    }),
    deleteNotifications: builder.mutation<NotificationResponse, { id: string }>(
      {
        query: (params) => ({
          url: `/notifications/${params.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Notification"],
      }
    ),
    tagUser: builder.mutation<
      NotificationResponse,
      {
        targetUserIds: string[];
        postId?: string | undefined;
        interactionId?: string | undefined;
      }
    >({
      query: ({ targetUserIds, postId, interactionId }) => ({
        url: `/users/tag`,
        method: "POST",
        body: {
          targetUserIds,
          postId,
          interactionId,
        },
      }),
      invalidatesTags: ["Notification"],
    }),
    getNotifications: builder.query<NotificationResponse[], void>({
      query: () => ({
        url: "/notifications",
        method: "GET",
      }),
      providesTags: ["Notification"],
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getState }
      ) {
        // create a websocket connection when the cache subscription starts
        const client = new Nes.Client(
          //@ts-ignore
          `${import.meta.env.VITE_WS_COMMUNITY_ENDPOINT}`
        );
        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;
          const cache = getState();

          // @ts-ignore
          const userId = cache.api.queries["getProfile(undefined)"]?.data.id; // Replace with the actual user ID

          const connectToWebSocket = async () => {
            try {
              const session = await Auth.currentSession();
              const token = session.getIdToken().getJwtToken();

              const nesTokenCallResponse = await axios({
                url: `${
                  // @ts-ignore
                  import.meta.env.VITE_COMMUNITY_ENDPOINT
                }/custom-nes/auth`,
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                method: "GET",
              });

              const NES_TOKEN = nesTokenCallResponse.data.token;

              await client.connect({
                auth: NES_TOKEN,
              });
              client.onConnect = () => {
                customConsole("log", "WebSocket connected ✅");
              };

              client.subscribe(
                `/notifications/${userId}`,
                (notification: NotificationResponse) => {
                  toast("You just received a notification");
                  updateCachedData((draft) => {
                    // @ts-ignore
                    draft.unshift(camelcaseKeys(notification));
                  });
                }
              );

              client.onDisconnect = () => {
                customConsole("log", "WebSocket disconnected 🙄");
              };
            } catch (error) {
              customConsole("error", "WebSocket connection error 🟥:", error);
            }
          };

          connectToWebSocket();

          // Cleanup function
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved;
        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
        client.disconnect();
      },
    }),
    viewNotifications: builder.mutation<NotificationResponse, { id: string }>({
      query: (params) => ({
        url: `/notifications/${params.id}/view`,
        method: "PATCH",
      }),
      invalidatesTags: ["Notification"],
    }),
    getFollowers: builder.query<Follower[], void>({
      query: () => ({
        url: "/user/followers",
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "User" as const,
                id,
              })),
            ]
          : ["User"],
    }),
    unFollowAccount: builder.mutation<
      void,
      { type: "USER" | "ORG"; userId: string }
    >({
      query: (params) => ({
        url: `/user/following/${params.userId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) =>
        error
          ? []
          : arg.type === "USER"
          ? [{ type: "User" as const, id: arg.userId }]
          : [{ type: "Organization" as const, id: arg.userId }],
    }),
    getUsers: builder.query<User[], void>({
      query: () => `/users`,
      providesTags: ["User"],
    }),
    getUserById: builder.query<User, string>({
      query: (id) => `/users/${id}`,
      providesTags: (result, error, id) =>
        result ? [{ type: "User" as const, id }] : ["User"],
    }),
    searchUser: builder.query({
      query: (name) => `search?name=${name}`,
      providesTags: (result, error, id) => [
        ...result.users.map(({ id }) => ({ type: "User" as const, id })),
        ...result.organizations.map(({ id }) => ({
          type: "Organization" as const,
          id,
        })),
      ],
    }),
    updateUser: builder.mutation({
      query: (userData) => ({
        url: "/user",
        method: "PUT",
        body: userData,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "User" as const, id: result.id },
      ],
    }),
    updateProfilePicture: builder.mutation<{}, { id: string; data: FormData }>({
      query: ({ data }) => {
        return {
          url: "/user/photo",
          method: "POST",
          body: data,
          formData: true,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "User" as const, id: arg.id },
        "Post",
      ],
    }),
    updateOrganizationProfilePicture: builder.mutation<
      {},
      { bodyFormData: FormData; orgId: string }
    >({
      query: ({ bodyFormData, orgId }) => {
        return {
          url: `/organizations/${orgId}/photo`,
          method: "POST",
          body: bodyFormData,
          formData: true,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Organization", id: arg.orgId },
      ],
    }),
    addOrganization: builder.mutation<Organization, Organization>({
      query: (orgData: Organization) => ({
        url: `organizations`,
        method: "POST",
        body: orgData,
      }),
      invalidatesTags: [
        { type: "Organization", id: "LIST" },
        "Organization",
        "User",
      ],
    }),
    searchForMember: builder.query<{}, { orgId: string; email: string }>({
      query: (params) => ({
        url: `/organizations/${params.orgId}/invitations/search?email=${params.email}`,
        method: "GET",
      }),
    }),
    inviteMemberIntoOrganization: builder.mutation<
      {},
      { emails: { email: string; id: string | undefined }[]; orgId: string }
    >({
      query: ({ emails, orgId }) => ({
        url: `/organizations/${orgId}/invitations`,
        method: "POST",
        body: [...emails],
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Organization", id: arg.orgId },
      ],
    }),
    getOrganizationDetails: builder.query<Organization, string>({
      query: (id: string) => ({
        url: `organizations/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "Organization", id: id }],
    }),
    updateOrganization: builder.mutation<
      Organization,
      { orgId: string; orgData: Organization }
    >({
      query: ({ orgId, orgData }) => ({
        url: `organizations/${orgId}`,
        method: "PUT",
        body: orgData,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Organization", id: arg.orgId },
      ],
    }),
    deleteOrganization: builder.mutation<Organization, string>({
      query: (id: string) => ({
        url: `organizations/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, arg, id) => [{ type: "Organization", id }],
    }),
    updateMemberRole: builder.mutation<
      void,
      { orgId: string; memberId: string; role: "ADMIN" | "MEMBER" }
    >({
      query: (params) => ({
        url: `/organizations/${params.orgId}/members/${params.memberId}`,
        method: "PUT",
        body: { role: params.role },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Organization" as const, id: arg.orgId },
      ],
    }),
    removeMember: builder.mutation<void, RemoveMemberParams>({
      query: (params: RemoveMemberParams) => ({
        url: `/organizations/${params.orgId}/members/${params.memberId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Organization", id: arg.orgId },
      ],
    }),
    getSuggested: builder.query<SuggestedUsersAndOrganizations, void>({
      query: () => ({
        url: `search/suggested`,
        methor: "GET",
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.users.map(({ id }) => ({ type: "User" as const, id })),
              "User",
              ...result.organizations.map(({ id }) => ({
                type: "Organization" as const,
                id,
              })),
            ]
          : [],
    }),
    getSparks: builder.query<Spark[], void>({
      query: () => ({
        url: "/lookups/sparks",
        method: "GET",
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Spark" as const, id })),
              "Spark",
            ]
          : ["Spark"],
    }),
    createOrganizationGoal: builder.mutation<
      {
        name: string;
        description: string;
        spark: Spark;
        organization: string;
        id: string;
      },
      {
        organizationId: string;
        goal: {
          name: string;
          description: string;
          spark: { name: string; id: string };
        };
      }
    >({
      query: (params) => ({
        url: `/organizations/${params.organizationId}/goals`,
        body: params.goal,
        method: "POST",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Organization", id: arg.organizationId },
      ],
    }),
    editOrganizationGoal: builder.mutation<
      EditGoalResponsePayload,
      {
        organizationId: string;
        goalId: string;
        data: {
          name: string;
          description: string;
        };
      }
    >({
      query: (params) => ({
        url: `/organizations/${params.organizationId}/goals/${params.goalId}`,
        method: "PUT",
        body: params.data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Organization", id: arg.organizationId },
      ],
    }),
    deleteOrganizationGoal: builder.mutation<
      {},
      {
        orgId: string;
        goalId: string;
      }
    >({
      query: (params) => ({
        url: `/organizations/${params.orgId}/goals/${params.goalId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Organization" as const, id: arg.orgId },
      ],
    }),
    addMetric: builder.mutation<
      {},
      {
        orgId: string;
        goalId: string;
        data: {
          description: string;
          startDate: string;
          endDate?: string;
          term: "QUARTERLY" | "MONTHLY";
          progress: {
            target: number;
            current: number;
            targetType: "DOLLARS" | "NUMBERS";
          };
        };
      }
    >({
      query: (params) => ({
        url: `/organizations/${params.orgId}/goals/${params.goalId}/metrics`,
        method: "POST",
        body: params.data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Organization" as const, id: arg.orgId },
      ],
    }),
    updateMetric: builder.mutation<
      {},
      { orgId: string; goalId: string; metricId: string; data: Metric }
    >({
      query: (params) => ({
        url: `/organizations/${params.orgId}/goals/${params.goalId}/metrics/${params.metricId}`,
        method: "PUT",
        body: params.data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Organization" as const, id: arg.orgId },
      ],
    }),
    deleteMetric: builder.mutation<
      {},
      { orgId: string; goalId: string; metricId: string }
    >({
      query: (params) => ({
        url: `/organizations/${params.orgId}/goals/${params.goalId}/metrics/${params.metricId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Organization" as const, id: arg.orgId },
      ],
    }),
    getMetricBySpark: builder.query<void, string>({
      query: (id) => ({
        url: `/lookups/metrics/${id}`,
        method: "GET",
        providesTag: ["MetricDescription"],
      }),
    }),
    createPost: builder.mutation<Post, FormData>({
      query: (postData) => ({
        url: "/posts",
        method: "POST",
        body: postData,
        formData: true,
      }),
      invalidatesTags: [{ type: "Post" as const, id: "LIST" }],
    }),
    getPostGroup: builder.query<Post[], string | void>({
      query: (userOrOrgId) => ({
        url: `/posts/group/${userOrOrgId}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Post" as const, id })),
              { type: "Post" as const, id: "LIST" },
            ]
          : [{ type: "Post" as const, id: "LIST" }],
    }),
    getAllPosts: builder.query<Post[], { limit?: number; page?: number }>({
      query: ({ limit = 10, page = 1 }) => ({
        url: `/posts?limit=${limit}&page=${page}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Post" as const, id })),
              { type: "Post" as const, id: "LIST" },
              "Post",
            ]
          : ["Post", { type: "Post" as const, id: "LIST" }],
    }),
    getPostById: builder.query<Post, string>({
      query: (id) => ({
        url: `/posts/${id}`,
        method: "GET",
        providesTags: (result, error, arg) => [
          { type: "Post" as const, id: result.id },
        ],
      }),
    }),
    deletePost: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `/posts/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, argId) => [
        { type: "Post" as const, id: argId },
      ],
    }),
    addInteractionsToPost: builder.mutation<
      {
        type: string;
        data: { message: string };
        ownerId: string;
        postId: string;
        ownerType: string;
        id: string;
      },
      { id: string; type: InteractionType; data?: { message: string } }
    >({
      query: (postInteraction) => {
        return {
          url: `/posts/${postInteraction.id}/interactions`,
          method: "POST",
          body: {
            type: postInteraction.type,
            data: postInteraction?.data || undefined,
          },
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Post" as const, id: arg.id },
      ],
    }),
    getWorldCities: builder.query<WorldCityData[], void>({
      query: () => ({
        url: "/lookups/us-cities",
        method: "GET",
      }),
      providesTags: ["WorldCity"],
    }),
  }),
});

export const {
  // notifications and tag
  useGetNotificationsQuery,
  useDeleteNotificationsMutation,
  useViewNotificationsMutation,
  useTagUserMutation,
  useGetUsersQuery,
  // profile
  useGetProfileQuery,
  useLazyGetProfileQuery,
  useUpdateProfilePictureMutation,
  useDeactivateUserMutation,
  // user
  useGetUserByIdQuery,
  useLazyGetUserByIdQuery,
  useUpdateUserMutation,
  useLazySearchUserQuery,
  // following
  useFollowUserMutation,
  useGetFollowersQuery,
  useLazyGetFollowersQuery,
  useGetUsersFollowedQuery,
  useLazyGetUsersFollowedQuery,
  useUnFollowAccountMutation,
  // post
  useLazyGetPostGroupQuery,
  useGetPostGroupQuery,
  useLazyGetAllPostsQuery,
  useGetAllPostsQuery,
  useCreatePostMutation,
  useDeletePostMutation,
  useLazyGetPostByIdQuery,
  // interactions
  useAddInteractionsToPostMutation,
  // organization
  useGetOrganizationDetailsQuery,
  useLazyGetOrganizationDetailsQuery,
  useDeleteOrganizationMutation,
  useAddOrganizationMutation,
  useUpdateOrganizationMutation,
  useUpdateOrganizationProfilePictureMutation,
  // goals
  useCreateOrganizationGoalMutation,
  useEditOrganizationGoalMutation,
  useDeleteOrganizationGoalMutation,
  // metric
  useUpdateMetricMutation,
  useAddMetricMutation,
  useGetMetricBySparkQuery,
  useLazyGetMetricBySparkQuery,
  useDeleteMetricMutation,
  // Member
  useRemoveMemberMutation,
  useInviteMemberIntoOrganizationMutation,
  useLazySearchForMemberQuery,
  useUpdateMemberRoleMutation,
  // other
  useGetSuggestedQuery,
  // spark
  useGetSparksQuery,
  // world cities
  useLazyGetWorldCitiesQuery,
} = api;
