import styled from 'styled-components';
import { ModalContent } from '@chakra-ui/react';

export const FollowingModalContent = styled(ModalContent)`
  max-height: calc(100vh - 10px) !important;
  margin: 0;
  .chakra-modal__body {
    overflow: hidden;
  }
  .box-search {
    padding-right: 48px;
    margin-bottom: 15px;
  }
  .box-tabs {
    padding: 0 50px;
    .chakra-tabs__tab-panel {
      max-height: calc(100vh - 150px);
      overflow-y: auto;
      overflow-x: hidden;
      padding: 24px 0 0;
    }
  }
  .box-users {
    .list {
      .item {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
      }
      .avatar {
        flex: 0 0 auto;
        width: 48px;
      }
      .box-name {
        flex: 1 1 auto;
        text-align: left;
        padding-left: 12px;
        .lg {
          font-weight: 600;
          font-size: 14px;
          line-height: 140.5%;
          color: #000;
          margin-bottom: 2px;
        }
        .md {
          font-size: 14px;
          line-height: calc(17 / 14);
          color: #000;
        }
      }
      .box-action {
        flex: 0 0 auto;
        width: 35px;
      }
      .btn-add {
      }
    }
  }
  .box-empty {
    text-align: center;
    .chakra-icon {
      font-size: 56px;
      color: #d1d1d1;
      margin: 0 auto 16px;
    }
    .txt {
      max-width: 220px;
      font-weight: 500;
      font-size: 16px;
      line-height: calc(21 / 16);
      color: #000;
      margin: 0 auto;
    }
  }
`;
