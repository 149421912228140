import styled from "styled-components";
import { devices } from "../../Theme.css";

export const MetricCard = styled.div`
  border-radius: 40px;
  border: 1px solid var(--Light-grey, #d1d1d1);
  background: #fff;
  padding: 24px;
  margin: 24px 0px;
`;

export const MetricCardTopLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const MetricCardTitleContainer = styled.div``;

export const MetricCardTitle = styled.div`
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140.5%;
  padding-right: 10px;
`;

export const MoreIconButton = styled.button`
  position: absolute;
  right: 45px;
  margin-top: 1.3%;
`;

export const MetricAttribute = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 6.5px;
`;

export const MetricAttributeIconContainer = styled.div``;

export const MetricAttributeText = styled.p`
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 4px;
`;

export const MetricAttributesContainer = styled.div`
  margin-top: 6.5px;
`;

export const ProgressBarBackground = styled.div`
  width: 70%;
  height: 10px;
  display: flex;
  justify-content: flex-start;
  background-color: #eee;
  border-radius: 100px;
  margin: auto;
  overflow: hidden;

  @media ${devices.max_sm} {
    width: 100%;
  }
`;

export const ProgressBar = styled.div<{ progress: number }>`
  width: ${(props) => props.progress}%;
  height: 10px;
  border-radius: 100px;
  background: linear-gradient(to right, #d14d23, #ff985e, #f7d85f);
`;

export const ProgressBarContainer = styled.div`
  margin: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  @media ${devices.max_sm} {
    margin: 0px;
  }
`;

export const ProgressLabelContainer = styled.div`
  width: max-content;
  margin-left: 16px;
`;

export const ProgressBarLabel = styled.p`
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ProgressBarWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: auto;
`;

export const FloatDropdownLayout = styled.div`
  width: fit-content;
  position: absolute;
  z-index: 1000;
  margin-top: 126px;
  right: 60px;
`;
