import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Box,
  SimpleGrid,
  Spinner,
  InputLeftElement,
  InputRightElement,
  Input,
  InputGroup,
  CloseButton,
} from "@chakra-ui/react";
import { SearchIcon } from "../../Assets/SvgIcons";
import {
  LoadingWrapper,
  SparkIcon,
  StepSparkWrapper,
  Checkbox,
  CheckboxContainer,
  LabelText,
} from "./styles";
import { useGetSparksQuery } from "../../../redux/reducers/apiSlice";
import { Spark } from "../../../redux/reducers/types";
import { customConsole } from "../../utils/helper";

const StepSpark = ({ formik }) => {
  const { data: sparksData, isLoading: sparksDataLoading } =
    useGetSparksQuery();
  const [searchInput, setSearchInput] = useState("");
  const [sparks, setSparks] = useState<Spark[]>([]);

  const filterSparks = (search: string, sparksData) => {
    return sparksData?.filter((spark) => {
      return spark.name.toLowerCase().startsWith(search.toLowerCase());
    });
  };

  const isCheckDisabled = (value) => {
    if (
      formik.values.sparks.length == 3 &&
      !formik.values.sparks.includes(value)
    ) {
      return true;
    }
    return false;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setSearchInput(inputValue);
  };

  useEffect(() => {
    if (searchInput !== "") {
      const filteredSparks = filterSparks(searchInput, sparksData);
      setSparks(filteredSparks);
    } else {
      if (sparksData) {
        setSparks(sparksData);
      } else {
        setSparks([]);
      }
    }
  }, [searchInput, sparksData]);

  const handleCheckboxChange = (e) => {
    const value = e.target.value;
    const sparks = [...formik.values.sparks];
    if (sparks.includes(value)) {
      formik.setFieldValue(
        "sparks",
        sparks.filter((spark) => spark !== value)
      );
    } else {
      formik.setFieldValue("sparks", [...sparks, value]);
    }
  };

  customConsole("log", sparks);

  if (sparksDataLoading)
    return (
      <LoadingWrapper>
        <Spinner color="red" />
      </LoadingWrapper>
    );
  return (
    <StepSparkWrapper>
      <div className="search-container">
        <InputGroup>
          <InputLeftElement pointerEvents="none" children={<SearchIcon />} />
          <Input onChange={handleChange} value={searchInput} placeholder={""} />
          {searchInput.length > 0 && (
            <InputRightElement
              children={
                <CloseButton
                  onClick={() => {
                    setSparks(sparksData || []);
                    setSearchInput("");
                  }}
                />
              }
            />
          )}
        </InputGroup>
      </div>

      {!sparksDataLoading && (
        <SimpleGrid columns={{ sm: 1, md: 2 }} spacingX="44px">
          {sparks?.map((item) => (
            <Box key={item.id} mb="20px">
              <CheckboxContainer disabled={isCheckDisabled(item.name)}>
                <Checkbox
                  name="spark"
                  key={item.id}
                  value={item.name}
                  checked={formik.values.sparks.includes(item.name)}
                  disabled={isCheckDisabled(item.name)}
                  onChange={handleCheckboxChange}
                />
                <SparkIcon>
                  <img src={item.logo} />
                </SparkIcon>
                <LabelText>{item.name}</LabelText>
              </CheckboxContainer>
            </Box>
          ))}
        </SimpleGrid>
      )}
    </StepSparkWrapper>
  );
};

export default StepSpark;
